import { Tabs } from "@components/Tabs"
import { Fragment } from "react"
import { MandateForm } from "./MandateForm";
import { MandateActionTable } from "./MandateActionTable";
import { trpc } from "@client/index";

interface Props {
  saleId: string;
}

export const MandateDropdown = ({ saleId }: Props) => {
  const { data: isCreated, isFetched } = trpc.sale.mandate.isCreated.useQuery({ saleId });

  return (
    <Fragment>
      <Tabs
        defaultKey="create"
        tabs={[
          { name: "Demande de mandat", key: "create" },
          { name: "Signer et consulter", key: "show", disabled: !isCreated && isFetched },
        ] as const}
        components={{
          create: <MandateForm saleId={saleId!} />,
          show: <MandateActionTable saleId={saleId!} />,
        }} />
    </Fragment>
  )
}