import { Fragment } from "react";
import { ActionTable } from "@components/ActionTable";
import { Button } from "@components/buttons/Buttons";
import { CompanyInfosModal } from "@components/modal/CompanyInfosModal";
import Modal from "@components/modal/Modal";
import { useModal } from "@hooks/useModal";
import { usePb } from "@hooks/usePb";
import { usePbDownload } from "@hooks/usePbDownload";
import { CompanyOfferState, type CompanyOffer } from "@interfaces/offer.interface";
import { useQuery } from "@tanstack/react-query";
import { expand } from "@utils/pb";
import { toast } from "react-toastify";
import { OfferSignModal } from "./OfferAcceptModal";
import { OfferSendMessageToCompanyModal } from "./OfferSendMessageToCompanyModal";
import { OfferRefusedModal } from "./OfferRefusedModal";
import { formatPrice } from "@utils/utils";

// Fonction pour formater la date
const formateDate = (date: string) => {
  return new Date(date).toLocaleString('fr-FR', {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
}

// Modal pour les conditions suspensives
interface SuspensiveConditionsModalProps {
  id: string;
  suspensiveConditions: string;
}

const SuspensiveConditionsModal = ({ id, suspensiveConditions }: SuspensiveConditionsModalProps) => {
  const modal = useModal();

  return (
    <Modal id={id} title="Conditions suspensives" description={suspensiveConditions}>
      <Button name="Fermer" onClick={() => modal.close(id)} />
    </Modal>
  );
}

// Ligne du tableau avec les actions de l'offre
interface CompanyOfferActionTableRowProps {
  companyOffer: CompanyOffer;
  readOnly?: boolean;
}

const CompanyOfferActionTableRow = ({ companyOffer, readOnly = false }: CompanyOfferActionTableRowProps) => {
  const modal = useModal();
  const ACCEPT_MODAL_ID = `${companyOffer.id}-accept`;
  const REFUSED_MODAL_ID = `${companyOffer.id}-refuse`;
  const SEND_MESSAGE_MODAL_ID = `${companyOffer.id}-message`;
  const COMPANY_INFOS_MODAL_ID = `${companyOffer.id}-infos`;
  const SUSPENSIVE_CONDITIONS_MODAL_ID = `${companyOffer.id}-conditions`;

  const mutation = usePbDownload();

  const hasSuspensiveConditions = Boolean(companyOffer.suspensiveConditions);

  return (
    <Fragment>
      <ActionTable.Row>{formateDate(companyOffer.created)}</ActionTable.Row>
      <ActionTable.Row>{companyOffer.expand.company.name}</ActionTable.Row>
      <ActionTable.Row>{formatPrice(companyOffer.price)}</ActionTable.Row>
      <ActionTable.Row>
        {companyOffer.state === CompanyOfferState.signed
          ? "Signé"
          : companyOffer.state === CompanyOfferState.refused
            ? "Refusé"
            : "En attente"}
      </ActionTable.Row>
      <ActionTable.Row>
        {hasSuspensiveConditions ? (
          <Fragment>
            Oui&nbsp;
            <Button.Small name="voir" onClick={() => modal.open(SUSPENSIVE_CONDITIONS_MODAL_ID)} />
          </Fragment>
        ) : "Non"}
      </ActionTable.Row>
      {readOnly ?
      <ActionTable.Row>
          <Button.Small
            name="Télécharger"
            onClick={() => mutation.mutate({ resource: companyOffer, filename: companyOffer["offer"] })} />
        </ActionTable.Row>
      :
      <ActionTable.ActionsButton>
        <div className="actions--menu">
          <Button.Small
            name="Valider"
            disabled={companyOffer.state !== CompanyOfferState.waiting}
            onClick={() => modal.open(ACCEPT_MODAL_ID)}
          />
          <Button.Small
            name="Refuser"
            disabled={companyOffer.state !== CompanyOfferState.waiting}
            onClick={() => modal.open(REFUSED_MODAL_ID)}
          />
          <Button.Small name="Ecrire à l'agence" onClick={() => modal.open(SEND_MESSAGE_MODAL_ID)} />
          {/* <Button.Small name="Plus d'infos" onClick={() => modal.open(COMPANY_INFOS_MODAL_ID)} /> */}
        </div>
      </ActionTable.ActionsButton>
      }
    </Fragment>
  );
}

// Composant principal pour afficher le tableau et les modales
interface Props {
  saleId: string;
  readOnly: boolean;
}

export const OfferActionTable = ({ saleId, readOnly = false }: Props) => {
  const pb = usePb();

  const { data: companyOffers = [], isPending } = useQuery<CompanyOffer[]>({
    queryKey: ["companyOffers", saleId],
    queryFn: async () => {
      return pb.collection("companyOffers").getFullList({
        filter: `sale = "${saleId}"`,
        expand: expand(["company"]),
      });
    },
    enabled: !!saleId,
  });

  if (readOnly) {
    return (
    <Fragment>
      <ActionTable
        isPending={isPending}
        emptyMessage="Vous n'avez pas encore reçu d'offres"
        headers={["Date", "Nom agence", "Prix net vendeur", "Etat", "C.S*", "Actions"]}
      >
        {companyOffers.map((companyOffer) => (
          <CompanyOfferActionTableRow key={companyOffer.id} companyOffer={companyOffer} readOnly />
        ))}
      </ActionTable>
      <p style={{color: 'white'}}>*Conditions suspensives.</p>
    </Fragment>
    )
  }

  return (
    <Fragment>
      <ActionTable
        isPending={isPending}
        emptyMessage="Vous n'avez pas encore reçu d'offres"
        headers={["Date", "Nom agence", "Prix net vendeur", "Etat", "C.S*", "Actions"]}
      >
        {companyOffers.map((companyOffer) => (
          <CompanyOfferActionTableRow key={companyOffer.id} companyOffer={companyOffer} />
        ))}
      </ActionTable>
      <p style={{color: 'white'}}>*Conditions suspensives.</p>

      {companyOffers.map((companyOffer) => (
        <Fragment key={companyOffer.id}>
          <OfferRefusedModal id={`${companyOffer.id}-refuse`} companyOffer={companyOffer} />
          <OfferSendMessageToCompanyModal id={`${companyOffer.id}-message`} companyOffer={companyOffer} />
          <OfferSignModal id={`${companyOffer.id}-accept`} companyOffer={companyOffer} />
          <SuspensiveConditionsModal id={`${companyOffer.id}-conditions`} suspensiveConditions={companyOffer.suspensiveConditions} />
          <CompanyInfosModal id={`${companyOffer.id}-infos`} company={companyOffer.expand.company} />
        </Fragment>
      ))}
    </Fragment>
  );
};
