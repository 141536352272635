import type { Base } from "@interfaces/shared.interface";

export const createCompanyPassword = (username: string, token: string) => {
  return `${username}::${token}`;
}

export enum CompanyType {
  realestate = "realestate",
  auditor = "auditor",
  cleaner = "cleaner"
}


export const getCompanyTypeFR = (type: CompanyType) => {
  switch (type) {
    case CompanyType.realestate:
      return "Agence immobilière";
    case CompanyType.auditor:
      return "Entreprise de diagnostics";
    case CompanyType.cleaner:
      return "Entreprise de débarras";
  }
}

export interface Company extends Base {
  username: string;
  password: string;
  email: string;
  name: string;
  phone: string;
  address: string;
  zipcode: string;
  departments: string;
  city: string;
  type: CompanyType;
  expand: {
    companyEmails_via_company: CompanyEmail[];
  }
}

export interface CompanyEmail extends Base {
  email: string;
  firstname: string;
  lastname: string;
  company: string;
  expand: {
    company: Company;
  };
}
