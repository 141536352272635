import { Navigate, createBrowserRouter } from 'react-router-dom';
import { Layout } from '@layouts/Layout';
import { LayoutGoodAbroad } from '@layouts/LayoutGoodAbroad';
import { LayoutContact } from "@layouts/LayoutContact";
import { Me } from './dashboard/Me';
import { Me as AdminMe } from './admin/Me';
import { Home } from './dashboard/Home';
import { Sale } from './dashboard/Sale';
import { GoodAbroad } from './dashboard/GoodAbroad';
import 'react-toastify/dist/ReactToastify.min.css';
import { Login } from './Login';
import { AuthLayout } from '@layouts/AuthLayout';
import { Register } from './dashboard/Register';
import { AdminLayout } from '@layouts/AdminLayout';
import { UserTable } from '@components/features/admin/UserTable';
import { RegisterTable } from '@components/features/admin/RegisterTable';
import { CompanyTable } from '@components/features/admin/CompanyTable';
import { UserUpdate } from '@components/features/admin/UserUpdate';
import { useMe, usePb } from '@hooks/usePb';
import { EstimateWithYouSlotConfirm } from './externals/estimates/EstimateWithYouSlotConfirm';
import { EstimateDeposit } from './externals/estimates/EstimateDeposit';
import { EstimateWithoutYouConfirm } from './externals/estimates/EstimateWithoutYouConfirm';
import { EstimateWithoutYouSlotConfirm } from './externals/estimates/EstimateWithoutYouSlotConfirm';
import { LayoutExternal } from '@layouts/LayoutExternal';
import { ContactAdmin } from './dashboard/ContactAdmin';
import { SaleFile } from './dashboard/SaleFile';
import { EstimateTable } from '@components/features/admin/EstimateTable';
import { DiagnosticConfirm } from './externals/diagnostics/DiagnosticConfirm';
import { DiagnosticQuotationDeposit } from './externals/diagnostics/DiagnosticQuotationDeposit';
import { DiagnosticDeposit } from './externals/diagnostics/DiagnosticDeposit';
import { EstimateOnline } from './dashboard/EstimateOnline';
import { LayoutEstimateOnline } from '@layouts/LayoutEstimateOnline';
import { ForgotPassword } from './ForgotPassword';
import { ResetPassword } from './ResetPassword';
import { AbroadGoods } from '@components/features/admin/AbroadGoods';
import { PropertyTitleTable } from '@components/features/admin/PropertyTitleTable';
import { CompanyEnergyAuditDeposit } from './externals/energyAudits/CompanyEnergyAuditDeposit';
import { EnergyAuditConfirm } from './externals/energyAudits/EnergyAuditConfirm';
import { EnergyAuditQuotationDeposit } from './externals/energyAudits/EnergyAuditQuotationDeposit';
import { DiagnosticTable } from '@components/features/admin/diagnostics/DiagnosticTable';
import { MandateDeposit } from './externals/mandates/MandateDeposit';
import { MandateOfferDeposit } from './externals/mandates/MandateOfferDeposit';
import { StorageConfirm } from './externals/storages/StorageConfirm';
import { StorageQuotationDeposit } from './externals/storages/StorageQuotationDeposit';
import { StorageDeposit } from './externals/storages/StorageDeposit';
import { MandateTable } from '@components/features/admin/MandateTable';
import { OfferTable } from '@components/features/admin/OfferTable';
import { NotaryFileTable } from '@components/features/admin/NotaryFileTable';
import { LayoutWorkEstimate } from '@layouts/LayoutWorkEstimate';
import { WorkEstimate } from './dashboard/WorkEstimate';
import { ActualisedEstimateDeposit } from './externals/estimates/ActualisedEstimateDeposit';
import { CompanyReportDeposit } from './externals/mandates/CompanyReportDeposit';
import { CompanyAmendmentDeposit } from './externals/mandates/CompanyAmendmentDeposit';
import { SaleStoppedTable } from '@components/features/admin/SaleStopped';
import { StorageTable } from '@components/features/admin/StorageTable';
import { CommissionTable } from '@components/features/admin/CommissionTable';
import { CopropertyTitleTable } from '@components/features/admin/CopropertyTitleTable';
import { SaleTable } from '@components/features/admin/SaleTable';
import { DownloadFiles } from './DownloadFiles';

export const router = (isLogged: boolean) => {
  const me = useMe();
  const pb = usePb()

  const homePath = (me as any)?.collectionName === "users"
    ? "/dashboard"
    : "/admin";

  return createBrowserRouter([
    {
      path: '',
      errorElement: <>404</>,
      element: isLogged ? <Navigate to={homePath} /> : <AuthLayout />,
      children: [
        { path: '', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'password-reset/:token', element: <ResetPassword /> },
      ]
    },
    {
      path: "download",
      element: <LayoutExternal />,
      children: [
        { path: '', element: <DownloadFiles /> },
      ]
    },
    {
      path: "externals",
      element: <LayoutExternal />,
      children: [
        {
          path: "company-reports", element: <CompanyReportDeposit />
        },
        {
          path: "company-amendments", element: <CompanyAmendmentDeposit />
        },
        {
          path: "estimates/:estimateId/actualised",
          children: [
            { path: "estimate-deposit", element: <ActualisedEstimateDeposit /> },
          ]
        },
        {
          path: "estimates/:estimateId",
          children: [
            { path: "estimate-deposit", element: <EstimateDeposit /> },
            { path: "with-you/slot-confirm", element: <EstimateWithYouSlotConfirm /> },
            { path: "without-you/confirm", element: <EstimateWithoutYouConfirm /> },
            { path: "without-you/slot-confirm", element: <EstimateWithoutYouSlotConfirm /> },
          ]
        },
        {
          path: "diagnostics/:diagnosticId",
          children: [
            { path: "confirm", element: <DiagnosticConfirm /> },
            { path: "quotation-deposit", element: <DiagnosticQuotationDeposit /> },
            { path: "diagnostic-deposit", element: <DiagnosticDeposit /> }
          ]
        },
        {
          path: "company-energy-audits/:companyEnergyAuditId",
          children: [
            { path: "energy-audit-deposit", element: <CompanyEnergyAuditDeposit /> },
          ]
        },
        {
          path: "energy-audits/:energyAuditId",
          children: [
            { path: "confirm", element: <EnergyAuditConfirm /> },
            { path: "quotation-deposit", element: <EnergyAuditQuotationDeposit /> },
          ]
        },
        {
          path: "mandates/:mandateId",
          children: [
            { path: "deposit", element: <MandateDeposit /> },
          ]
        },
        {
          path: "offer",
          children: [
            { path: "deposit", element: <MandateOfferDeposit /> },
          ]
        },
        {
          path: "storages/:storageId",
          children: [
            { path: "confirm", element: <StorageConfirm /> },
            { path: "quotation-deposit", element: <StorageQuotationDeposit /> },
            { path: "storage-deposit", element: <StorageDeposit /> }
          ]
        },
      ],
    },
    {
      path: 'admin',
      element: isLogged && pb.authStore.isAdmin ? <AdminLayout /> : <Navigate to={"/"} />,
      children: [
        { path: 'me', element: <AdminMe /> },
        { path: 'users/:id?', element: <UserTable /> },
        { path: 'registers/:id?', element: <RegisterTable /> },
        { path: 'companies/:id?', element: <CompanyTable /> },
        { path: 'users/:userId', element: <UserUpdate /> },
        { path: 'estimates/:id?', element: <EstimateTable /> },
        { path: 'diagnostics/:id?', element: <DiagnosticTable /> },
        { path: 'abroad-goods/:id?', element: <AbroadGoods /> },
        { path: 'property-titles/:id?', element: <PropertyTitleTable /> },
        { path: 'mandates/:id?', element: <MandateTable /> },
        { path: 'offers/:id?', element: <OfferTable /> },
        { path: 'notary-files/:id?', element: <NotaryFileTable /> },
        { path: 'stopped-sales/:id?', element: <SaleStoppedTable /> },
        { path: 'storages/:id?', element: <StorageTable /> },
        { path: 'commissions/:id?', element: <CommissionTable /> },
        { path: 'coproperty-titles/:id?', element: <CopropertyTitleTable /> },
        { path: 'sales/:id?', element: <SaleTable /> },
      ]
    },
    {
      path: 'dashboard',
      element: isLogged ? <Layout /> : <Navigate to={"/"} />,
      children: [
        { path: '', element: <Home /> },
        { path: 'me', element: <Me /> },
        { path: 'sales/:saleId', element: <Sale /> },
        { path: "sales-file", element: <Home /> },
        { path: "sales-file/:saleId", element: <SaleFile /> }
      ]
    },
    {
      path: 'dashboard',
      element: isLogged ? <LayoutGoodAbroad /> : <Navigate to={"/"} />,
      children: [
        { path: 'good-abroad', element: <GoodAbroad /> }
      ]
    },
    {
      path: 'dashboard',
      element: isLogged ? <LayoutWorkEstimate /> : <Navigate to={"/"} />,
      children: [
        { path: 'work-estimate', element: <WorkEstimate />, }
      ]
    },
    {
      path: 'dashboard',
      element: isLogged ? <LayoutContact /> : <Navigate to={"/"} />,
      children: [
        { path: 'contact', element: <ContactAdmin />, }
      ]
    },
    {
      path: 'dashboard',
      element: isLogged ? <LayoutEstimateOnline /> : <Navigate to={"/"} />,
      children: [
        { path: 'estimate-online', element: <EstimateOnline />, }
      ]
    }
  ]);
}