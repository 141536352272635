import { Button } from "@components/buttons/Buttons";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input";
import { useForm } from "react-hook-form";
import "./MandateForm.scss";
import { MandateClausesModal } from "./MandateClausesModal";
import { useModal } from "@hooks/useModal";
import { MandateSalePiecesModal } from "./MandateSalePiecesModal";
import { MandateIndivisairesModal } from "./MandateIndivisairesModal";
import type { z } from "zod";
import { CreateMandateSchema } from "@schemas/mandate.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { trpc } from "@client/index";
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { usePb } from "@hooks/usePb";
import { getQueryKey } from "@trpc/react-query";

interface Props {
  saleId: string;
}

export const MandateForm = ({ saleId }: Props) => {
  const pb = usePb();
  const modal = useModal();
  const queryClient = useQueryClient()

  const { data: isCreated } = trpc.sale.mandate.isCreated.useQuery({ saleId });

  const { data: companyEstimates = [] } = useQuery({
    queryKey: ["companyEstimates", saleId],
    queryFn: async () => {
      return pb.collection("companyEstimates").getFullList({
        filter: `estimate.sale = "${saleId}"`,
        expand: "company"
      })
    }
  });

  const formMethods = useForm<z.infer<typeof CreateMandateSchema>>({
    resolver: zodResolver(CreateMandateSchema),
    defaultValues: {
      price: undefined,
      keysInstructions: "",
      hasIndivisaires: "no",
      indivisaires: [],
      clauses: [],
      companies: []
    }
  });

  useEffect(() => {
    if (companyEstimates) {
      formMethods.setValue("companies", companyEstimates.map(companyEstimate => ({
        company: companyEstimate.expand.company,
        selected: true
      })));
    }
  }, [formMethods, companyEstimates]);

  const mutation = trpc.sale.mandate.create.useMutation({
    onSuccess: () => {
      toast.success("Votre demande de mandat a bien été créée.");

      formMethods.reset();

      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.mandate.isCreated) });
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.getSaleDropwdownsStatus) });

      modal.closeAll();
    },
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
  });

  const clauses = formMethods.watch("clauses");

  const hasClauses = clauses.length > 0;

  const onValidate = async () => {
    const isValid = await formMethods.trigger(["price", "keysInstructions"]);

    if (isValid) {
      modal.open(MandateSalePiecesModal.name);
    }
  }

  return (
    <Form formMethods={formMethods}>
      <div className="mandate-input--wrapper empty">
        <Input
          type="number"
          name="price"
          placeholder="Prix sans la commission d’agence" />

        <Input.Textarea
          name="keysInstructions"
          placeholder="Informations pour les clés (facultatif)" />

        <Button
          className="c-btn--outline"
          name={hasClauses ? "Consulter ou modifier les clauses" : "Définir les clauses du mandat (facultatif)"}
          onClick={() => modal.open(MandateClausesModal.name)} />
      </div>

      <p style={{color: 'white'}}>Les agences ayant évalué le bien dans la rubrique estimation du bien recevront votre demande.</p>

      <div className="estimate-input--wrapper empty">
        <Button
          name="Valider la demande de mandat"
          disabled={isCreated}
          onClick={onValidate} />
      </div>

      <MandateClausesModal />

      <MandateSalePiecesModal />

      <MandateIndivisairesModal
        saleId={saleId}
        isPending={mutation.isPending}
        onSubmit={formMethods.handleSubmit(values => mutation.mutate({ saleId, ...values }))} />
    </Form>
  );
}