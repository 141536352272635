import { ActionTable } from "@components/ActionTable"
import { Button } from "@components/buttons/Buttons";
import { usePb } from "@hooks/usePb";
import type { Company } from "@interfaces/company.interface";
import { EstimateState, EstimateType, type CompanyEstimate, type Estimate } from "@interfaces/sale.interface";
import { formatPrice } from "@utils/utils";
import { Fragment } from "react";
import { useQuery } from "@tanstack/react-query";
import "./EstimateActionTable.scss"
import { usePbDownload } from "@hooks/usePbDownload";
import { expand } from "@utils/pb";
import { trpc } from "@client/index";

const formateDate = (date: string) => {
  return new Date(date).toLocaleString('fr-FR', {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
}

interface SellerPriceRowItemProps {
  sellerPriceLow?: number;
  sellerPriceHigh?: number;
}

const SellerPriceRowItem = ({ sellerPriceHigh, sellerPriceLow }: SellerPriceRowItemProps) => {
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>{sellerPriceLow ? formatPrice(sellerPriceLow) : "- €"}</span>
        <span>{sellerPriceHigh ? formatPrice(sellerPriceHigh) : "- €"}</span>
      </div>

    </div>
  )
}

interface EstimateActionTableRowProps {
  companyEstimate: CompanyEstimate;
}

const EstimateTableRow = ({ companyEstimate }: EstimateActionTableRowProps) => {
  const mutation = usePbDownload();

  return (
    <Fragment>

      <ActionTable.Row>
        {formateDate(companyEstimate.created)}
      </ActionTable.Row>

      <ActionTable.Row>
        {companyEstimate.expand.company.name}
      </ActionTable.Row>

      <ActionTable.Row>
        <SellerPriceRowItem
          sellerPriceLow={companyEstimate.sellerPriceLow}
          sellerPriceHigh={companyEstimate.sellerPriceHigh}
        />
      </ActionTable.Row>

      <ActionTable.Row>
        <Button.Small
          name="Télécharger"
          disabled={!companyEstimate?.report}
          onClick={() => mutation.mutate({ resource: companyEstimate, filename: companyEstimate["report"] })} />
      </ActionTable.Row>
    </Fragment>
  )
}

interface EstimateRequestActionTableRowProps {
  estimate: Estimate;
}

const EstimateRequestActionTableRow = ({ estimate }: EstimateRequestActionTableRowProps) => {
  return (
    <Fragment>
      <ActionTable.Row>
        {formateDate(estimate.created)}
      </ActionTable.Row>

        <ActionTable.Row>
          En attente agences
        </ActionTable.Row>

        <ActionTable.Row>
          N.C
        </ActionTable.Row>

        <ActionTable.Row>
          -
        </ActionTable.Row>
    </Fragment>
  )
}

interface CompanyWaitingEstimateActionTableRowProps {
  company: Company;
}

const CompanyWaitingEstimateActionTableRow = ({ company }: CompanyWaitingEstimateActionTableRowProps) => {
  return (
    <Fragment>

      <ActionTable.Row>
        N.C
      </ActionTable.Row>

      <ActionTable.Row>
        {company.name}
      </ActionTable.Row>

      <ActionTable.Row>
        <SellerPriceRowItem />
      </ActionTable.Row>

      <ActionTable.Row>
        -
      </ActionTable.Row>
    </Fragment>
  )
}

interface Props {
  saleId: string;
  readOnly?: boolean;
}

export const EstimateActionTable = ({ saleId, readOnly = false }: Props) => {
  const pb = usePb();

  const estimateRequests: Estimate[] = [];
  const companyEstimates: CompanyEstimate[] = [];
  const companyWaitingEstimates: Company[] = [];

  const { data, isPending } = trpc.sale.estimate.getEstimates.useQuery({ saleId }, {
    enabled: !!saleId,
  });

  const estimates = (data || []) as Estimate[];

  for (const estimate of estimates) {
    const slots = (estimate.expand?.slots || []);

    if (estimate.state === EstimateState.started) {
      estimateRequests.push(estimate);
    }

    if (estimate.state === EstimateState.inProgress && !Boolean(estimate?.expand?.companyEstimates_via_estimate?.length)) {
      for (const slot of slots) {
        if (Boolean(slot?.expand?.assignedTo)) {
          companyWaitingEstimates.push(slot.expand.assignedTo);
        }
      }
    }

    if (Boolean(estimate?.expand?.companyEstimates_via_estimate?.length)) {
      companyEstimates.push(...estimate.expand.companyEstimates_via_estimate);
    }
  }

  if (readOnly) {
    return (
      <ActionTable isPending={isPending} emptyMessage="Aucune demande d'estimation"  headers={['Date', 'Agence', 'Prix N.V', 'Action']}>
        {companyEstimates.map(companyEstimate => (
          <EstimateTableRow key={companyEstimate.id} companyEstimate={companyEstimate} />
        ))}
      </ActionTable>
    )
  }

  return (
    <>
    <ActionTable isPending={isPending} headers={['Date', 'Agence', 'Prix N.V', 'Action']}  emptyMessage="Merci de procéder à une première demande d'estimation">
      {estimateRequests.map(estimate => (
        <EstimateRequestActionTableRow key={estimate.id} estimate={estimate} />
      ))}

      {companyWaitingEstimates.map(company => (
        <CompanyWaitingEstimateActionTableRow key={company.id} company={company} />
      ))}

      {companyEstimates.map(companyEstimate => (
        <EstimateTableRow key={companyEstimate.id} companyEstimate={companyEstimate} />
      ))}
    </ActionTable>
    </>
  )
}