import "./Table.scss";
import { flexRender, getCoreRowModel, useReactTable, type CellContext, type ColumnDef, type Row, type Table as TanTable } from "@tanstack/react-table";
import React, { Fragment, type PropsWithChildren } from "react";
import { Pagination } from "./Pagination";

interface TableProps<T> {
  table: TanTable<T>;
  isFetching?: boolean;
  children?: ({ row }: { row: Row<T> }) => React.ReactNode;
}

interface SubTableProps<T> extends Pick<TableProps<T>, 'table' | 'children'> { }

const typedMemo: <T>(c: T) => T = React.memo;

const Body = <T,>({ table, children }: TableProps<T>) => {
  return (
    <tbody>
      {table.getRowModel().rows.map(row => {
        return (
          <Fragment key={row.id}>
            <tr>
              {row.getVisibleCells().map(cell => {
                return (
                  <td key={cell.id}>
                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )}
                  </td>
                )
              })}
            </tr>

            {(row.getIsExpanded() && typeof children === "function") && (
              <tr className="hide--infos">
                <td colSpan={row.getVisibleCells().length}>
                  {children({ row })}
                </td>
              </tr>
            )}

          </Fragment>
        )
      })}
    </tbody>
  )
}

export const Table = <T,>({ table, children }: TableProps<T>) => {
  return (
    <div className="tableWrapper">
      <table className="table">

        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    <div
                      className={
                        header.column.getCanSort()
                          ? 'cursor-pointer'
                          : ''
                      }
                      onClick={header.column.getToggleSortingHandler()}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>

        <Body table={table} children={children} />
      </table>

      <Pagination table={table} />

    </div>
  )
}

Table.Subtable = typedMemo(({ table, children }: SubTableProps<any>) => {
  return (
    <div>
      <table className="table">
        <Body table={table} children={children} />
      </table>
    </div>
  )
});

interface CellProps {
  value: string;
  bold?: boolean;
  uppercase?: boolean;
}

Table.Cell = ({ value, bold, uppercase }: CellProps) => {
  const style: React.CSSProperties = {
    fontWeight: bold ? "bold" : "initial",
    textTransform: uppercase ? "uppercase" : "initial"
  }

  return (
    <div style={style}>
      {value}
    </div>
  )
}

Table.BasicCell = (cell: CellContext<any, any>) => {
  return <div>
    {cell.getValue()}
  </div>
}
