import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@components/buttons/Buttons";
import { Toggle } from "@components/inputs/Toggle";
import Modal from "@components/modal/Modal";
import { Form } from "@components/form/Form";
import { WarnProtectorSchema, type WarnProtector } from "@schemas/estimate.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import "./EstimateDepositModal.scss"

interface Props {
  isFetching: boolean;
  handleForm: () => void;
}

interface WarningProps {
  message: string;
}

const Warning = ({ message }: WarningProps) => {
  return (
    <div className="modal--warning">
      <p>
        {message}

        <span> Merci de modifier votre document.</span>
      </p>
    </div>
  );
};

export const EstimateDepositModal = ({ handleForm, isFetching }: Props) => {
  const [messages, setMessages] = useState<string[]>([]);

  const formMethods = useForm({
    resolver: zodResolver(WarnProtectorSchema),
    defaultValues: {
      price: "price-net",
      owner: "owner-yes",
    } satisfies WarnProtector,
  });

  const checkMessages = (values: WarnProtector) => {
    const messages = [];

    if (values.price === "price-agency") {
      messages.push(`Le prix doit être indiqué en prix net vendeur pour que le document soit recevable par un juge.`);
    }

    if (values.owner === "owner-no") {
      messages.push(`Le document doit être adressé au nom du propritétaire pour être recevable par un juge. `);
    }

    setMessages(messages);

    if (messages.length === 0) {
      handleForm();
    }
  };

  return (
    <Modal id={EstimateDepositModal.name} title="Vérification de dernière minute">
      <Form formMethods={formMethods}>

        {messages.map((message) => (
          <Warning key={message} message={message} />
        ))}

        <h4>Le prix dans votre PDF est indiqué en</h4>

        <Toggle
          name="price"
          choices={[
            {
              label: "Prix net vendeur",
              value: "price-net",
            },
            {
              label: "Prix frais d'agence inclus",
              value: "price-agency",
            },
          ]} />

        <h4>Je confirme avoir adressé l'estimation au nom du propriétaire</h4>

        <Toggle
          name="owner"
          choices={[
            {
              label: "Oui",
              value: "owner-yes",
            },
            {
              label: "Non",
              value: "owner-no",
            },
          ]} />

        <Button
          name="Valider"
          loading={isFetching}
          onClick={formMethods.handleSubmit(checkMessages)} />
      </Form>
    </Modal>
  );
};
