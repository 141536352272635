import { z } from "zod";
import { validateFile } from "./shared.schema";

export const CreateDiagnosticSchema = z
  .object({
    invoiceContactEmail: z.string({
      required_error: "Ce champ est requis",
    }).min(1, { message: "Ce champ est requis" }),
    keyPickupAddress: z.string({
      required_error: "Ce champ est requis",
    }).min(1, { message: "Ce champ est requis" }),
    keyDropoffAddress: z.string({
      required_error: "Ce champ est requis",
    }).min(1, { message: "Ce champ est requis" }),
    contactPerson: z.string({
      required_error: "Ce champ est requis",
    }).min(1, { message: "Ce champ est requis" }),
    instructions: z.string({
      required_error: "Ce champ est requis",
    }),
  });

export const DiagnosticQuotationDepositSchema = z.object({
  price: z.string().min(0),
  quotation: validateFile(),
});

export const DiagnosticDepositSchema = z.object({
  isEnergyAuditRequired: z.coerce.boolean(),
  diagnosticFile: validateFile(),
  invoice: validateFile(),
  energyAuditQuotation: z.any().optional(),
  energyAuditPrice: z.coerce.number().optional(),
})

export const SignDiagnosticQuotationSchema = z.object({
  quotation: validateFile(),
});