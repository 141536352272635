import React from "react";
import "./Form.scss";
import { type FieldValues, FormProvider, type UseFormReturn } from "react-hook-form"
import { classNames } from "@utils/utils";

export interface FormProps<TFieldValues extends FieldValues> {
  children: React.ReactNode;
  formMethods: UseFormReturn<TFieldValues>;
  formProps?: React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;
}

export const Form = <TFieldValues extends FieldValues,>({ formMethods, children, formProps }: FormProps<TFieldValues>) => {
  return (
    <FormProvider {...formMethods}>
      <form {...formProps}>
        {children}
      </form>
    </FormProvider>
  )
}

interface Props<TFieldValues extends FieldValues> extends FormProps<TFieldValues> {
  title?: string;
  subtitle?: string;
}

Form.DefaultLayout = <TFieldValues extends FieldValues,>({ title, subtitle, ...formProps }: Props<TFieldValues>) => {
  return (
    <div className="form--container">
      {(!!title || !!subtitle) && (
        <div>
          {title && <h1>
            {title}
          </h1>}

          {subtitle && <p>
            {subtitle}
          </p>}
        </div>
      )}

      <Form {...formProps}>
        {formProps.children}
      </Form>
    </div >
  )
}

interface FormActionsProps {
  centered?: boolean;
  children: React.ReactNode
}

Form.Actions = ({ centered, children }: FormActionsProps) => {
  return (
    <div className={classNames("actions", centered ? "center" : "")}>
      {children}
    </div>
  )
}