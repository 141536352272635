import { Button } from "@components/buttons/Buttons";
import { Table } from "@components/table/Table";
import { usePb } from "@hooks/usePb";
import { formatFullAddress } from "@interfaces/address.interface";
import type { Protectee } from "@interfaces/protectee.interface";
import { EstimateState, type Estimate } from "@interfaces/sale.interface";
import { sortSlotByStartTime, type Slot } from "@interfaces/slot.interface";
import type { User } from "@interfaces/user.interface";
import { type ColumnDef, type SortingState, useReactTable, getSortedRowModel, getCoreRowModel, getPaginationRowModel, getFilteredRowModel, getExpandedRowModel, createColumnHelper, type PaginationState, type Row } from "@tanstack/react-table";
import { expand } from "@utils/pb";
import type { ListResult } from "pocketbase";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { usePbDownload } from "@hooks/usePbDownload";
import { useParams } from "react-router-dom";

const today = new Date();
const columnHelper = createColumnHelper<Estimate>();
const columnSlotHelper = createColumnHelper<Slot>();

function formatTime(date: string | undefined): string {
  if (!date) return "-";
  const dateObject = new Date(date);
  if (isNaN(dateObject.getTime())) return "-";

  return dateObject.toLocaleTimeString("fr-FR", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/Paris",
  }).replace(":", "h");
}

function formatDateDDmmYYYY(date: string | undefined): string {
  if (!date) return "-";
  const dateObject = new Date(date);
  if (isNaN(dateObject.getTime())) return "-";

  return dateObject.toLocaleDateString("fr-FR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

function getStatus(rowData) {
  const slots = rowData?.expand?.slots || [];
  const assignedSlots = slots.filter(slot => Boolean(slot.assignedTo)).sort(sortSlotByStartTime);
  if (assignedSlots.length === 0) return "En attente";

  const lastSlot = assignedSlots[assignedSlots.length - 1];
  const isUpcoming = lastSlot?.endTime && new Date(lastSlot.endTime).getTime() > today.getTime();

  let depotNumber = 0;

  if (!rowData.expand.companyEstimates_via_estimate) {
    return "En attente";
  }

  rowData.expand.companyEstimates_via_estimate.forEach(companyEstimate => {
    if (companyEstimate.report) {
      depotNumber++;
    }
  });

  if (isUpcoming) return "RDV à venir";
  if (depotNumber === 0) return "En attente";
  if (depotNumber < rowData.numberOfParticipants) return "Dépot partiel";
  return "Déposé";
}

export const EstimateTable = () => {
  const pb = usePb();
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });
  const { id } = useParams();
  const page = pageIndex + 1;
  const [mode, setMode] = React.useState<"all" | "with-you" | "without-you">("all");

  const { data, isFetching } = useQuery<ListResult<Estimate>>({
    queryKey: ["estimates", page, pageSize, id],
    queryFn: async () => {
      const filter = id ? `id = "${id}"` : '';
      const response = await pb.collection("estimates").getList(page, pageSize, {
        expand: expand([
          "slots.assignedTo",
          "sale.protector",
          "sale.protectee",
          "sale.address",
          "estimateContacts_via_estimate.company",
          "companyEstimates_via_estimate.company"
        ]),
        ...(filter && { filter }),
      });
      return response;
    },
  });

  const estimates = data?.items || [];
  const filteredData = estimates.filter((item) => {
    if (mode === "all") return true;
    return (mode === 'with-you' ? item.participation === 'with-you' : item.participation === 'without-you') && item.slots.length > 0;
  });

  const totalPages = data?.totalPages || 1;

  const columns = React.useMemo<ColumnDef<Estimate>[]>(
    () => [
      //@ts-ignore
      columnHelper.accessor("expand.sale.expand.protector", {
        header: "Mandataire",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: (rowA, rowB, columnId) => {
          const protectorA = rowA.getValue<User>(columnId);
          const protectorB = rowB.getValue<User>(columnId);
          return protectorA.lastname.localeCompare(protectorB.lastname);
        },
        cell: (ctx) => {
          const { firstname, lastname } = ctx.getValue();
          return `${lastname} ${firstname}`;
        }
      }),
      //@ts-ignore
      columnHelper.accessor("expand.sale.expand.protectee", {
        header: "Majeur",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: (rowA, rowB, columnId) => {
          const protecteeA = rowA.getValue<Protectee>(columnId);
          const protecteeB = rowB.getValue<Protectee>(columnId);
          return protecteeA.lastname.localeCompare(protecteeB.lastname);
        },
        cell: (ctx) => {
          const { firstname, lastname } = ctx.getValue();
          return `${lastname} ${firstname}`;
        }
      }),
      columnHelper.display({
        header: "Adresse",
        cell: ({ row }) => formatFullAddress(row.original.expand.sale.expand.address)
      }),
      //@ts-ignore
      columnHelper.accessor("created", {
        header: "Date",
        enableSorting: true,
        enableMultiSort: true,
        cell: ({ row }) => {
          const slots = row.original?.expand?.slots || [];
          if(!slots.length) {
            return formatDateDDmmYYYY(row.original.created);
          }
          const firstAssignedSlot = slots[0];
          return formatDateDDmmYYYY(firstAssignedSlot.startTime);
        },
        sortingFn: (rowA, rowB) => {
          const slotsA = rowA.original.expand?.slots ? rowA.original.expand?.slots[0].startTime : rowA.original.created
          const slotsB = rowB.original.expand?.slots ? rowB.original.expand?.slots[0].startTime : rowB.original.created

          const dateA = new Date(slotsA);
          const dateB = new Date(slotsB);

          return dateB.getTime() - dateA.getTime();
        },
      }),
      columnHelper.display({
        header: "Créneau",
        cell: ({ row }) => {
          const slots = row.original?.expand?.slots || [];
          const assignedSlots = slots.filter(slot => Boolean(slot.assignedTo)).sort(sortSlotByStartTime);
          if (assignedSlots.length === 0) return "En attente entreprise";

          const firstSlot = assignedSlots[0];
          const lastSlot = assignedSlots[assignedSlots.length - 1];
          const startTime = firstSlot?.startTime ? formatTime(firstSlot.startTime) : "-";
          const endTime = lastSlot?.endTime ? formatTime(lastSlot.endTime) : "-";

          return `${startTime} - ${endTime}`;
        }
      }),
      //@ts-ignore
      columnHelper.accessor((data) => data, {
        header: "Status",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: (rowA, rowB, columnId) => {
          const statusOrder = ["En attente", "RDV à venir", "Dépot partiel", "Déposé"];
          const statusA = getStatus(rowA.original);
          const statusB = getStatus(rowB.original);
          return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
        },
        cell: ({ row }) => {
          const status = getStatus(row.original);
          return <Table.Cell value={status} bold />;
        }
      }),
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <Button.Small
            name={row.getIsExpanded() ? "Fermer" : "Détails"}
            disabled={row.original.state === EstimateState.started}
            onClick={() => row.toggleExpanded()} />
        )
      }),
    ],
    []
  );

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const pagination = React.useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);

  const table = useReactTable({
    data: filteredData,
    columns,
    enableMultiSort: true,
    manualPagination: true,
    pageCount: totalPages,
    state: { pagination, sorting },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <>
      <Table table={table} isFetching={isFetching}>
        {({ row }) => <SubTable row={row} estimates={estimates} />}
      </Table>
      {/* Ajout du toggle flottant pour changer de mode */}
      <div style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000
      }}>
        <Button.Small
          style={{ padding: '10px' }}
          name={`Mode: ${mode === 'all' ? 'Tous' : mode === 'with-you' ? 'Avec vous' : 'Sans vous'}`}
          onClick={() => setMode(
            mode === 'all' ? 'with-you' : mode === 'with-you' ? 'without-you' : 'all'
          )}
        />
      </div>
    </>
  );
};

interface SubTableProps {
  row: Row<Estimate>;
  estimates: Estimate[];
}

const SubTable = ({ row, estimates }: SubTableProps) => {
  const estimateContacts = React.useMemo(
    () => estimates.flatMap(estimate => estimate.expand.estimateContacts_via_estimate || []),
    [estimates]
  );

  const mutation = usePbDownload();

  const subColumns = React.useMemo<ColumnDef<Slot>[]>(
    () => [
      columnSlotHelper.display({
        id: "assignedTo",
        cell: ({ row }) => row.original.expand.assignedTo?.name && <Table.Cell value={row.original.expand.assignedTo.name} bold uppercase />
      }),
      columnSlotHelper.display({
        id: "phone",
        cell: ({ row }) => estimateContacts.find(ec => ec.company === row.original.assignedTo)?.phone
      }),
      columnSlotHelper.display({
        id: "email",
        cell: ({ row }) => row.original.expand.assignedTo?.email && <Table.Cell value={row.original.expand.assignedTo.email} />
      }),
      columnSlotHelper.display({
        id: "startTime",
        cell: ({ row }) => row.original.startTime && <Table.Cell value={formatDateDDmmYYYY(row.original.startTime)} bold />
      }),
      columnSlotHelper.display({
        id: "date",
        cell: ({ row }) => {
          const startTime = row.original.startTime ? formatTime(row.original.startTime) : "-";
          const endTime = row.original.endTime ? formatTime(row.original.endTime) : "-";
          return `${startTime} - ${endTime}`;
        }
      }),
      columnSlotHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => {
          const estimate = estimates.find(est => est.slots.includes(row.original.id));
          const companyEstimate = estimate?.expand?.companyEstimates_via_estimate?.find(ce => ce.company === row.original.assignedTo);
          return companyEstimate && (
            <Button.Small name="Télécharger" onClick={() => mutation.mutate({ resource: companyEstimate, filename: companyEstimate["report"] })} />
          );
        }
      }),
    ],
    [estimateContacts, estimates]
  );

  const subRows = row.original?.expand?.slots?.filter(slot => Boolean(slot.assignedTo)) || [];
  const table = useReactTable({
    data: subRows,
    autoResetPageIndex: false,
    autoResetExpanded: false,
    columns: subColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return <Table.Subtable table={table} />;
};
