import { Fragment } from "react";
import "./PropertyDocsList.scss";
import { useModal } from "@hooks/useModal";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { trpc } from "@client/index";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { getQueryKey } from "@trpc/react-query";
import { Form } from "@components/form/Form";
import { Button } from "@components/buttons/Buttons";
import { UploadModal } from "@components/modal/UploadModal";
import { usePbDownload } from "@hooks/usePbDownload";
import { UploadSalePieceDocumentSchema } from "@schemas/salePiece.schema";

interface Props {
  saleId: string;
}

const UPLOAD_MODAL_ID = "cni-upload";

export const CNI = ({ saleId }: Props) => {
  const modal = useModal();
  const queryClient = useQueryClient();
  const downloadMutation = usePbDownload();

  const { data: salePieceData } = trpc.sale.salePiece.getBySaleId.useQuery(saleId);

  const formMethods = useForm<z.infer<typeof UploadSalePieceDocumentSchema>>({
    resolver: zodResolver(UploadSalePieceDocumentSchema),
    defaultValues: {
      document: undefined,
    },
  });

  const mutation = trpc.sale.salePiece.cni.upload.useMutation({
    onSuccess: () => {
      formMethods.reset();
      modal.close(UPLOAD_MODAL_ID);
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.salePiece.getBySaleId) });
      toast.success("Votre document a bien été ajouté.");
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const handleDelete = trpc.sale.salePiece.cni.remove.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.salePiece.getBySaleId) });
      toast.success("Le document a été supprimé.");
    },
    onError() {
      toast.error("Une erreur est survenue lors de la suppression.");
    },
  });

  const handleDeleteConfirmation = () => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer ce document ?");
    if (confirmDelete) {
      handleDelete.mutate({ saleId });
    }
  };

  const isUploaded = Boolean(salePieceData?.cni);

  const handleSubmit = (body: z.infer<typeof UploadSalePieceDocumentSchema>) => {
    if (body.document) {
      const formData = new FormData();
      formData.append("saleId", saleId);
      formData.append("document", body.document);
      mutation.mutate(formData);
    } else {
      toast.error("Veuillez ajouter un document.");
    }
  };

  return (
    <Fragment>
      <li className={isUploaded ? "valid" : "non-valid"}>
        <p>CNI du propriétaire</p>
        <div>
          <Button.Small
            xs
            valid
            name={isUploaded ? "Modifier" : "Ajouter"}
            onClick={() => modal.open(UPLOAD_MODAL_ID)}
          />

          {isUploaded && (
            <Fragment>
              <Button.Small
                xs
                valid
                name="Supprimer"
                onClick={handleDeleteConfirmation}
              />
              <Button.Small
                xs
                valid
                name="Télécharger"
                onClick={() => downloadMutation.mutate({ resource: salePieceData, filename: salePieceData!["cni"] })} />
            </Fragment>
          )}
        </div>
      </li>

      <Form formMethods={formMethods}>
        <UploadModal
          id={UPLOAD_MODAL_ID}
          name="document"
          title="CNI du propriétaire"
          isLoading={mutation.isPending}
          onConfirm={formMethods.handleSubmit(handleSubmit)}
        />
      </Form>
    </Fragment>
  );
};
