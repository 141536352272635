import { Table } from "@components/table/Table";
import { usePb } from "@hooks/usePb";
import {
  type ColumnDef,
  type SortingState,
  useReactTable,
  getSortedRowModel,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  createColumnHelper,
  type PaginationState,
} from "@tanstack/react-table";
import { expand } from "@utils/pb";
import type { ListResult } from "pocketbase";
import React from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { SaleState, type Sale } from "@interfaces/sale.interface";
import { formatFullAddress } from "@interfaces/address.interface";
import { formatDateDDmmYYYY } from "@utils/utils";
import { Button } from "@components/buttons/Buttons";

const columnHelper = createColumnHelper<Sale>();

export const SaleStoppedTable = () => {
  const pb = usePb();
  const queryClient = useQueryClient();

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 100,
    });

  const page = pageIndex + 1;

  const { data } = useQuery<ListResult<Sale>>({
    queryKey: ["sales", page, pageSize],
    queryFn: async () => {
      return pb.collection("sales").getList(page, pageSize, {
        filter: `state = "${SaleState.stopped}"`,
        expand: expand([
          "address",
          "protectee",
          "protector"
        ]),
      });
    },
  });

  const { items: sales = [], totalPages } = data! || {};

  const columns = React.useMemo<ColumnDef<Sale>[]>(
    () => [
      columnHelper.display({
        header: "Mandataire",
        cell: ({ row }) => {
          return (
            <Table.Cell value={`${row.original.expand.protector.lastname} ${row.original.expand.protector.firstname}`} />
          );
        },
      }),
      columnHelper.display({
        header: "Majeur",
        cell: ({ row }) => {
          return (
            <Table.Cell value={`${row.original.expand.protectee.lastname} ${row.original.expand.protectee.firstname}`} bold />
          );
        },
      }),
      columnHelper.display({
        header: "Adresse",
        cell: ({ row }) => {
          return (
            <Table.Cell value={formatFullAddress(row.original.expand.address)} />
          );
        },
      }),
      columnHelper.display({
        header: "Date",
        cell: ({ row }) => {
          return (
            <Table.Cell value={formatDateDDmmYYYY(row.original.updated)} />
          );
        },
      }),
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => <Button.Small
          name={row.getIsExpanded() ? "Fermer" : "Détails"}
          onClick={() => row.toggleExpanded()} />
      }),
    ],
    []
  );

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: sales,
    columns,
    enableMultiSort: true,
    manualPagination: true,
    pageCount: totalPages,
    state: {
      pagination,
      sorting,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <Table table={table}>
      {({ row }) => (
        <SubTable sale={row.original} />
      )}
    </Table>
  );
};

interface SubTableProps {
  sale: Sale;
}

const SubTable = ({ sale }: SubTableProps) => {
  const subColumns = React.useMemo<ColumnDef<Sale>[]>(
    () => [
      columnHelper.display({
        header: "Cause",
        cell: ({ row }) => {
          return (
            <Table.Cell value={row.original.cause} />
          );
        },
      }),
      columnHelper.display({
        header: "Instructions pour les clés",
        cell: ({ row }) => {
          return <Table.Cell value={row.original.keysInstructions} />;
        }
      }),
    ],
    [sale]
  )

  const table = useReactTable({
    data: [sale],
    autoResetPageIndex: false,
    autoResetExpanded: false,
    columns: subColumns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
  });

  return (
    <Table.Subtable table={table} />
  )
}
