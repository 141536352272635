import { z } from "zod";

export const CreateProtecteeAddressSchema = z.object({
  street: z.string().min(1).max(255),
  zipcode: z.string().min(1).max(10),
  city: z.string().min(1).max(255),
});

export const CreateProtecteeSchema = z.object({
  firstname: z.string().min(1),
  lastname: z.string().min(1),
  street: z.string().min(1).max(255),
  zipcode: z.string().min(1).max(10),
  city: z.string().min(1).max(255),
});

const SearchProtecteeSchema = z.object({
  term: z.string().min(3).max(255),
});

export type CreateProtectee = z.infer<typeof CreateProtecteeSchema>;
export type SearchProtectee = z.infer<typeof SearchProtecteeSchema>;
