import type { Company } from "./company.interface";
import type { Diagnostic } from "./diagnostic.interface";
import type { Sale } from "./sale.interface";
import type { Base, ID } from "./shared.interface";
import type { BookableRecord } from "./slot.interface";

export enum CompanyEnergyAuditState {
  waiting = "waiting", // Le devis a été déposé sur la plateforme, le mandataire doit le signer
  accepted = "accepted", // Le mandataire a signé un devis
  refused = "refused", // Le mandataire a signé un devis, tout les autres sont refusés
}

export type EnergyAudit = {
  invoiceContactEmail: string;
  keyPickupAddress: string;
  keyDropoffAddress: string;
  contactPerson: string;
  instructions: string;
  signedCompanyEnergyAudit: ID;
  sale: ID;
  expand: {
    sale: Sale;
    signedCompanyEnergyAudit: CompanyEnergyAudit;
    companyEnergyAudits_via_energyAudit: CompanyEnergyAudit[];
  }
} & Base & BookableRecord;

export interface CompanyEnergyAudit extends Base {
  company: ID;
  sale: ID;
  quotation: any;
  invoice: any;
  fromDiagnostic: ID;
  energyAuditFile: any;
  energyAudit: ID;
  price: number;
  state: CompanyEnergyAuditState;
  expand: {
    sale: Sale;
    company: Company;
    energyAudit: EnergyAudit;
    fromDiagnostic: Diagnostic;
  }
}