import { Button } from "@components/buttons/Buttons"
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input"
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import type { z } from "zod";
import { LoginSchema } from "@schemas/login.schema";
import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthContext } from "@contexts/auth.context";
import { trpc } from "@client/index";

export const Login = () => {
  const navigate = useNavigate();

  const { setLogged } = useAuthContext();

  const formMethods = useForm<z.infer<typeof LoginSchema>>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const mutation = trpc.auth.login.useMutation({
    onSuccess: (data) => {
      setLogged(true);

      const path = data.record.collectionName === "users"
        ? "/dashboard" :
        "/admin";

      navigate(path);
    },
    onError(error) {
      if (error.data?.httpStatus === 400) {
        toast.error("Identifiant ou mot de passe incorrect.");
      } else {
        toast.error(error.message ?? "Une erreur est survenue.");
      }
    },
  });

  return (
    <Form.DefaultLayout
      title="Connexion à la plateforme Athena"
      subtitle="Entrez vos identifiants dans le formulaire suivant."
      formMethods={formMethods}>

      <Input
        border
        autoComplete="email"
        placeholder="Adresse email"
        name="email"
      />
      <Input
        border
        autoComplete="current-password"
        placeholder="Mot de passe"
        name="password"
        type="password"
      />

      <Button
        name="Se connecter"
        onClick={formMethods.handleSubmit(values => mutation.mutate(values))}
        loading={mutation.isPending} />

      <Form.Actions>
        <Button.Link
          dark
          to="/register"
          title="Je n'ai pas de compte, m'inscrire" />
        <Button.Link
          dark
          to="/forgot-password"
          title="Mot de passe oublié" />
      </Form.Actions>
    </Form.DefaultLayout>
  )
}