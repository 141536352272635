import "./MandateDeposit.scss";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { Form } from "@components/form/Form";
import { ExternalPropertyDesc } from "@components/ExternalPropertyDesc";
import Input from "@components/inputs/Input";
import { Button } from "@components/buttons/Buttons";
import { ExternalNotificationCard } from "@components/ExternalNotificationCard";
import { CompanyAmendmentDepositSchema } from "@schemas/mandate.schema";
import { trpc } from "@client/index";
import { getQueryKey } from "@trpc/react-query";
import { ExternalGenericInfos } from "@components/ExternalGenericInfos";
import Modal from "@components/modal/Modal";
import { useModal } from "@hooks/useModal";
import { Fragment } from "react";
import { formatPrice } from "@utils/utils";
import { type Mandate } from "@interfaces/mandate.interface";
import { Toggle } from "@components/inputs/Toggle";
import { Warning } from "@components/Warning";

interface LastCheckBeforeSubmitModalProps {
  mandate: Mandate;
  loading: boolean;
  onSubmit: () => void;
}

const FormSchema = z.object({
  confirmClauses: z.enum(["yes"]),
  mandatePrice: z.enum(["net"]),
  confirmIndivisaires: z.enum(["yes"]),
});

const LastCheckBeforeSubmitModal = ({ mandate, loading, onSubmit }: LastCheckBeforeSubmitModalProps) => {
  const formMethods = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      confirmClauses: "yes",
      mandatePrice: "net",
      confirmIndivisaires: "yes",
    },
  });

  return (
    <Modal id={LastCheckBeforeSubmitModal.name} title="Dernières verifications">

      <div className="warning--wrapper">

        {Boolean(formMethods.formState.errors.confirmClauses) && (
          <Warning
            message="Attention, même si le mandat a été signé sans ces stipulations, cet oubli pourra entraîner la responsabilité de l'agence."
            redMessage="Merci d'intégrer les clauses demandées à votre mandat." />
        )}

        {Boolean(formMethods.formState.errors.mandatePrice) && (
          <Warning message="Le prix sur le mandat doit être indiquer en net vendeur" redMessage="Merci de modifier votre document." />
        )}

        {Boolean(formMethods.formState.errors.confirmIndivisaires) && (
          <Warning
            message="Le mandat doit être signé par l'ensemble des indivisaires avant d'être transmis à la plateforme."
            redMessage="Merci de procéder à ces signatures." />
        )}
      </div>

      <Form.DefaultLayout formMethods={formMethods}>
        <div className="last-verif">

          {mandate.clauses.map((clause, index) => {
            return (
              <p style={{ color: "#fff" }}>
                Clause n°{index + 1}: {clause}
              </p>
            )
          })}

          <h3>Je confirme avoir intégré les clauses ci-dessus</h3>
          <Toggle
            name="confirmClauses"
            choices={[
              { value: "yes", label: "OUI" },
              { value: "no", label: "NON" },
            ]} />

          <h3>Le prix dans le mandat est indiqué en</h3>
          <Toggle
            name="mandatePrice"
            choices={[
              { value: "net", label: "Prix net vendeur" },
              { value: "agency", label: "Prix frais d'agence inclus" },
            ]} />

          <h3>L'ensemble des indivisaires (s'il y en a), ont signés</h3>
          <Toggle
            name="confirmIndivisaires"
            choices={[
              { value: "yes", label: "OUI" },
              { value: "no", label: "NON" },
            ]} />
        </div>
        <Form.Actions centered>
          <Button
            name="Valider"
            loading={loading}
            onClick={formMethods.handleSubmit(() => onSubmit())} />
        </Form.Actions>
      </Form.DefaultLayout>
    </Modal>
  )
}

export const CompanyAmendmentDeposit = () => {
  const modal = useModal();
  const queryClient = useQueryClient();

  const formMethods = useForm<z.infer<typeof CompanyAmendmentDepositSchema>>({
    resolver: zodResolver(CompanyAmendmentDepositSchema),
    defaultValues: {
      amendment: null
    },
  });

  const guardQueryResult = trpc.external.mandate.depositAmendmentGetResources.useQuery(undefined, {
    retry: false,
  });

  const mutation = trpc.external.mandate.depositAmendment.useMutation({
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.external.mandate.depositAmendmentGetResources) });
    }
  });

  const handleSubmit = (body: z.infer<typeof CompanyAmendmentDepositSchema>) => {
    const formData = new FormData();

    formData.append("amendment", body.amendment!);

    mutation.mutate(formData);
  }

  const onValidate = async () => {
    const isValid = await formMethods.trigger();

    if (isValid) {
      modal.open(LastCheckBeforeSubmitModal.name)
    }
  }

  const errorStatus = guardQueryResult.error?.data?.httpStatus;

  if (mutation.isSuccess) {
    return <ExternalNotificationCard title="Merci d'avoir déposé votre avenant" />
  }

  if (errorStatus === 403 || errorStatus === 404) {
    return <ExternalNotificationCard title="Cette page n'est plus disponible" />
  }

  if (typeof errorStatus === "number") {
    return <ExternalNotificationCard title="Une erreur est survenue. Merci de réessayer plus tard." />
  }

  if (!guardQueryResult.data) {
    return null;
  }

  const { mandate, sale } = guardQueryResult.data;

  const address = sale.expand.address;

  const protecteeName = `${sale.expand.protectee.lastname} ${sale.expand.protectee.firstname}`;

  return (
    <Fragment>
      <Form.DefaultLayout formMethods={formMethods}>

        <div className="mailForm last">
          <h1 className="title">DEPÔT D'AVENANT AU MANDAT</h1>
          <ExternalPropertyDesc address={address} />

          <div className="important-infos">
            <h4>Merci d'intégrer les clauses ci-dessous à votre mandat</h4>

            {mandate.clauses.map((clause, index) => {
              return (
                <p key={index}>
                  Clause n°{index + 1}: {clause}
                </p>
              )
            })}

            <ExternalGenericInfos
              title=""
              informations={{
                "PRIX NET VENDEUR": formatPrice(mandate.price),
              }} />
          </div>

          <Input.File
            name="amendment"
            label="Insérer l'avenant réalisé en PDF"
            accept="application/pdf" />

        </div>

        <Form.Actions centered>
          <Button
            name="Valider"
            onClick={onValidate} />
        </Form.Actions>
      </Form.DefaultLayout>

      <LastCheckBeforeSubmitModal
        mandate={mandate as Mandate}
        loading={mutation.isPending}
        onSubmit={formMethods.handleSubmit(handleSubmit)} />
    </Fragment>
  )
}