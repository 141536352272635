import React, { useState } from 'react';
import "./Dropdown.scss";
import { classNames } from '@utils/utils';
import { useDropdownContext } from '@contexts/dropdown.context';

interface Props {
  id: string;
  title: string;
  disabled?: boolean;
  status?: "valid" | "non-valid" | "active" | "folders" | (string & {});
  children: React.ReactNode;
}

export const Dropdown = ({ id, title, status, disabled, children }: Props) => {
  const context = useDropdownContext();
  const [hovered, setHovered] = useState(false);

  const isOpen = !!context.dropdowns.find(dropdown => dropdown.id === id)?.isOpen;

  const onClick = () => {
    if (disabled) {
      return;
    }

    if (isOpen) {
      context.close(id);
    } else {
      context.closeAll();
      context.open(id);
    }
  };

  React.useEffect(() => {
    context.registerDropdown(id);

    return () => {
      context.unregisterDropdown(id);
    };
  }, []);

  const getStatus = () => {
    if (disabled) {
      return "non-valid";
    }

    if (isOpen) {
      return "active";
    }

    if (status) {
      return status;
    }

    return "folders";
  };

  const handleMouseEnter = () => {
    if (disabled) {
      setHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (disabled) {
      setHovered(false);
    }
  };

  return (
    <div className={classNames("c-cat", getStatus())}>
      <label
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="dropdown-label cursor-pointer"
      >
        {disabled && hovered ? "Cette fonctionnalité sera accessible dès que vous aurez passé l'étape estimation" : title}
      </label>

      {isOpen && (
        <div className="c-cat--open">
          {children}
        </div>
      )}
    </div>
  );
};
