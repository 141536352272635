import { z } from "zod";

export const EstimateOnlineSchema = z.object({
  propertyCondition: z.string().min(1),
  numberOfRooms: z.coerce.number(),
  protecteeFirstname: z
    .string({
      invalid_type_error: "Address must be a string",
      required_error: "Address is required",
    })
    .min(1),
  protecteeLastname: z
    .string({
      invalid_type_error: "Address must be a string",
      required_error: "Address is required",
    })
    .min(1),
  address: z
    .string({
      invalid_type_error: "Address must be a string",
      required_error: "Address is required",
    })
    .min(1)
    .max(255),
  city: z
    .string({
      invalid_type_error: "Address must be a string",
      required_error: "Address is required",
    }),
  zipcode: z
    .string({
      invalid_type_error: "Address must be a string",
      required_error: "Address is required",
    }),
  surface: z
    .string({
      invalid_type_error: "Surface must be a number",
      required_error: "Surface is required",
    })
    .min(1, { message: "Ce champ est requis" }),
  typeOfBuilding: z.string({
    invalid_type_error: "Type of building must be a string",
    required_error: "Type of building is required",
  }),
});
