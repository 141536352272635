import "./MandateDeposit.scss";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { Form } from "@components/form/Form";
import { ExternalPropertyDesc } from "@components/ExternalPropertyDesc";
import Input from "@components/inputs/Input";
import { Button } from "@components/buttons/Buttons";
import { ExternalNotificationCard } from "@components/ExternalNotificationCard";
import { trpc } from "@client/index";
import { getQueryKey } from "@trpc/react-query";
import { ExternalGenericInfos } from "@components/ExternalGenericInfos";
import { formatPrice } from "@utils/utils";
import { CompanyReportDepositSchema } from "@schemas/mandate.schema";

export const CompanyReportDeposit = () => {
  const queryClient = useQueryClient();

  const formMethods = useForm<z.infer<typeof CompanyReportDepositSchema>>({
    resolver: zodResolver(CompanyReportDepositSchema),
    defaultValues: {
      reportFile: null
    },
  });

  const guardQueryResult = trpc.external.mandate.depositReportGetResources.useQuery(undefined, {
    retry: false,
  });

  const mutation = trpc.external.mandate.depositReport.useMutation({
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.external.mandate.depositReportGetResources) });
    }
  });

  const handleSubmit = (body: z.infer<typeof CompanyReportDepositSchema>) => {
    const formData = new FormData();

    formData.append("reportFile", body.reportFile!);

    mutation.mutate(formData);
  }

  const errorStatus = guardQueryResult.error?.data?.httpStatus;

  if (mutation.isSuccess) {
    return <ExternalNotificationCard title="Merci d'avoir déposé votre compte rendu" />
  }

  if (errorStatus === 403 || errorStatus === 404) {
    return <ExternalNotificationCard title="Cette page n'est plus disponible" />
  }

  if (typeof errorStatus === "number") {
    return <ExternalNotificationCard title="Une erreur est survenue. Merci de réessayer plus tard." />
  }

  if (!guardQueryResult.data) {
    return null;
  }

  const { mandate, sale } = guardQueryResult.data;

  const address = sale.expand.address;

  return (
    <Form.DefaultLayout formMethods={formMethods}>

      <div className="mailForm last">
        <h1 className="title">Compte rendu</h1>
        <ExternalPropertyDesc address={address} />

        <ExternalGenericInfos
          title=""
          informations={{
            "PRIX NET VENDEUR": formatPrice(mandate.price),
          }} />

        <Input.File
          name="reportFile"
          label="Insérer le compte rendu réalisé en PDF"
          accept="application/pdf" />

      </div>

      <Form.Actions centered>
        <Button
          name="Valider"
          loading={mutation.isPending}
          onClick={formMethods.handleSubmit(handleSubmit)} />
      </Form.Actions>

    </Form.DefaultLayout>
  )
}