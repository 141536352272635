import { z } from "zod";
import { validateFile } from "./shared.schema";

export const CreateStorageSchema = z
  .object({
    invoiceContactEmail: z.string({
      required_error: "Ce champ est requis",
    }).min(1, { message: "Ce champ est requis" }),
    keyPickupAddress: z.string({
      required_error: "Ce champ est requis",
    }).min(1, { message: "Ce champ est requis" }),
    keyDropoffAddress: z.string({
      required_error: "Ce champ est requis",
    }).min(1, { message: "Ce champ est requis" }),
    contactPerson: z.string({
      required_error: "Ce champ est requis",
    }).min(1, { message: "Ce champ est requis" }),
    instructions: z.string({
      required_error: "Ce champ est requis",
    }).min(1, { message: "Ce champ est requis" }),
  });

export const StorageQuotationDepositSchema = z.object({
  price: z.string().min(0),
  quotation: validateFile(),
});

export const StorageDepositSchema = z.object({
  invoice: validateFile(),
});

export const SignStorageQuotationSchema = z.object({
  keyPickupAddress: z.string({
    required_error: "Ce champ est requis",
  }).min(1, { message: "Ce champ est requis" }),
  keyDropoffAddress: z.string({
    required_error: "Ce champ est requis",
  }).min(1, { message: "Ce champ est requis" }),
  contactPerson: z.string({
    required_error: "Ce champ est requis",
  }).min(1, { message: "Ce champ est requis" }),
  quotation: validateFile(),
});