import type { Sale } from "./sale.interface";
import type { Base, ID } from "./shared.interface";

export enum NotaryFileState {
  waiting = "waiting",
  opened = "opened"
}

export interface NotaryFile extends Base {
  name: string;
  email: string;
  address: string;
  sale: ID;
  state: NotaryFileState;
  expand: {
    sale: Sale;
  }
}