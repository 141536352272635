import "./ExternalPropertyDesc.scss";

interface Props {
  title: string;
  informations: Record<string, string | number>;
}

export const ExternalGenericInfos = ({ title, informations }: Props) => {
  return (
    <div className="external-property-desc">
      <h3>{title}</h3>

      {Object.entries(informations).map(([key, value]) => (
        <p key={key}>{key} : {value}</p>
      ))}
    </div>
  )
}