import { Button } from "@components/buttons/Buttons";
import { Table } from "@components/table/Table";
import { usePb } from "@hooks/usePb";
import { type Sale } from "@interfaces/sale.interface";
import { type ColumnDef, type SortingState, useReactTable, getSortedRowModel, getCoreRowModel, getPaginationRowModel, getFilteredRowModel, getExpandedRowModel, createColumnHelper, type PaginationState, type Row } from "@tanstack/react-table";
import { expand, filterByIds } from "@utils/pb";
import type { ListResult } from "pocketbase";
import React, { Fragment } from "react";
import { useQuery } from "@tanstack/react-query";
import { CompanyDiagnosticState, type CompanyDiagnostic, type Diagnostic } from "@interfaces/diagnostic.interface";
import { CompanyEnergyAuditState, type CompanyEnergyAudit, type EnergyAudit } from "@interfaces/energyAudit.interface";
import { formatFullAddress } from "@interfaces/address.interface";
import { formatPrice } from "@utils/utils";
import { CompanyEnergyAuditFromDiagnosticEmail, CompanyEnergyAuditSubTable, EnergyAuditSubTable } from "./EnergyAuditTable";
import { usePbDownload } from "@hooks/usePbDownload";
import { useParams } from "react-router-dom";

const columnHelper = createColumnHelper<SaleWithDiagnosticAndEnergyAudit>();
const columnDiagnosticHelper = createColumnHelper<Diagnostic>();
const columnCompagnyDiagnosticHelper = createColumnHelper<CompanyDiagnostic>();

export interface SaleWithDiagnosticAndEnergyAudit extends Sale {
  diagnostics: Diagnostic[];
  energyAudits: EnergyAudit[];
  companyEnergyAudits: CompanyEnergyAudit[];
}

const useGetSaleWithDiagnosticsAndEnergyAudits = ({ page, pageSize }: { page: number; pageSize: number }) => {
  const pb = usePb();

  const { id } = useParams();

  // Récupération de la vente ou de la liste paginée
  const saleQueryResult = useQuery<ListResult<Sale>>({
    queryKey: id ? ["sale", id] : ["sales", page, pageSize],
    queryFn: async () => {
      if (id) {
        return pb.collection("sales").getOne(id, {
          expand: expand(["protector", "protectee", "address"]),
        });
      } else {
        return pb.collection("sales").getList(page, pageSize, {
          expand: expand(["protector", "protectee", "address"]),
        });
      }
    },
  });

  const sales = id ? (saleQueryResult.data ? [saleQueryResult.data] : []) : saleQueryResult.data?.items || [];
  const { totalPages } = saleQueryResult.data || { totalPages: 1 };

  const { data: allDiagnostics = [] } = useQuery<Diagnostic[]>({
    queryKey: ["diagnostics"],
    queryFn: async () => {
      return pb.collection("diagnostics").getFullList({
        filter: filterByIds(sales, "sale.id"),
        expand: expand([
          "companyDiagnostics_via_diagnostic.company",
        ])
      });
    },
    enabled: saleQueryResult.isFetched
  });

  const { data: allEnergyAudits = [] } = useQuery<EnergyAudit[]>({
    queryKey: ["energyAudits"],
    queryFn: async () => {
      return pb.collection("energyAudits").getFullList({
        filter: filterByIds(sales, "sale.id"),
        expand: expand([
          "companyEnergyAudits_via_energyAudit.company",
        ])
      });
    },
    enabled: saleQueryResult.isFetched
  });

  let { data: allCompanyEnergyAudits = [] } = useQuery<CompanyEnergyAudit[]>({
    queryKey: ["companyEnergyAudits"],
    queryFn: async () => {
      return pb.collection("companyEnergyAudits").getFullList({
        filter: `${filterByIds(sales, "sale.id")}`,
        expand: expand([
          "company",
        ])
      });
    },
    enabled: saleQueryResult.isFetched
  });

  allCompanyEnergyAudits = allCompanyEnergyAudits.filter(companyEnergyAudit => !!companyEnergyAudit.fromDiagnostic)

  const data = sales
    .map(sale => {
      const diagnostics = allDiagnostics.filter(diagnostic => diagnostic.sale === sale.id);
      const energyAudits = allEnergyAudits.filter(energyAudit => energyAudit.sale === sale.id);
      const companyEnergyAudits = allCompanyEnergyAudits.filter(companyEnergyAudit => companyEnergyAudit.sale === sale.id);

      return {
        ...sale,
        diagnostics,
        energyAudits,
        companyEnergyAudits
      }
    })
    .filter(({ diagnostics, energyAudits, companyEnergyAudits }) => (
      !!diagnostics.length || !!energyAudits.length || !!companyEnergyAudits.length
    ));

  return {
    data,
    metadata: {
      totalPages
    }
  }
}

export const DiagnosticTable = () => {
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const page = pageIndex + 1;

  const { data: sales = [], metadata } = useGetSaleWithDiagnosticsAndEnergyAudits({ page, pageSize })

  const columns = React.useMemo<ColumnDef<SaleWithDiagnosticAndEnergyAudit>[]>(
    () => [
      columnHelper.display({
        id: "protector",
        header: "Mandataire",
        cell: ({ row }) => {
          const protector = row.original.expand.protector;

          return <Table.Cell value={`${protector.firstname} ${protector.lastname}`} bold />;
        }
      }),
      columnHelper.display({
        id: "protectee",
        header: "Majeur",
        cell: ({ row }) => {
          const protectee = row.original.expand.protectee;

          return `${protectee.firstname} ${protectee.lastname}`;
        }
      }),
      columnHelper.display({
        id: "address",
        header: "Adresse",
        cell: ({ row }) => {
          return formatFullAddress(row.original.expand.address)
        }
      }),
      columnHelper.display({
        id: "status",
        header: "Statut",
        cell: ({ row }) => {
          let diagnosticMessage = "";
          let energyAuditMessage = "";

          const companyDiagnostics = row.original.diagnostics.flatMap(diagnostic => diagnostic?.expand?.companyDiagnostics_via_diagnostic || []);

          const companyDiagnostic = companyDiagnostics.find(companyDiagnostic => companyDiagnostic.state === CompanyDiagnosticState.accepted);

          const isDiagnosticSigned = Boolean(companyDiagnostic);

          if (isDiagnosticSigned) {
            diagnosticMessage = "Diagnostic signé";
          }

          const isDiagnosticDeposited = Boolean(companyDiagnostic?.diagnosticFile);

          if (isDiagnosticDeposited) {
            diagnosticMessage = "Diagnostic OK";
          }

          if (!isDiagnosticSigned && Boolean(companyDiagnostics.length)) {
            diagnosticMessage = "Diagnostic à signer";
          }

          if (!isDiagnosticSigned && !Boolean(companyDiagnostics.length)) {
            diagnosticMessage = "Diagnostic demandé";
          }

          const hasRequestedEnergyAudit = row.original.energyAudits.length;

          const companyEnergyAudits = row.original.energyAudits.flatMap(energyAudit => energyAudit?.expand?.companyEnergyAudits_via_energyAudit || []);

          const companyEnergyAudit = companyEnergyAudits.find(companyEnergyAudit => companyEnergyAudit.state === CompanyEnergyAuditState.accepted);

          const isCompanyEnergyAuditSigned = Boolean(companyEnergyAudit);

          if (isCompanyEnergyAuditSigned) {
            energyAuditMessage = "AE signé";
          }

          const isCompanyEnergyAuditDeposited = Boolean(companyEnergyAudit?.energyAuditFile);

          if (isCompanyEnergyAuditDeposited) {
            energyAuditMessage = "AE OK";
          }

          if (!isCompanyEnergyAuditSigned && Boolean(companyEnergyAudits.length)) {
            energyAuditMessage = "AE à signer";
          }

          if (!isCompanyEnergyAuditSigned && hasRequestedEnergyAudit) {
            energyAuditMessage = "AE demandé";
          }

          if (diagnosticMessage && energyAuditMessage) {
            return `${diagnosticMessage} / ${energyAuditMessage}`;
          }

          if (diagnosticMessage) {
            return diagnosticMessage;
          }

          if (energyAuditMessage) {
            return energyAuditMessage;
          }
        }
      }),
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => {
          const subdatas = row.original.diagnostics || row.original.energyAudits;

          return (
            <Button.Small
              name={row.getIsExpanded() ? "Fermer" : "Détails"}
              disabled={!Boolean(subdatas.length)}
              onClick={() => row.toggleExpanded()} />
          )
        }
      }),
    ],
    []
  )

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: sales,
    columns,
    enableMultiSort: true,
    manualPagination: true,
    pageCount: metadata.totalPages,
    state: {
      pagination,
      sorting
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <Table table={table}>
      {({ row }) => (
        <Fragment>
          <DiagnosticSubTable row={row} allDiagnostics={row.original.diagnostics} />

          <EnergyAuditSubTable row={row} energyAudits={row.original.energyAudits} companyEnergyAudits={row.original.companyEnergyAudits} />

          {row.original.companyEnergyAudits.length && (
            <CompanyEnergyAuditFromDiagnosticEmail energyAudits={row.original.energyAudits} companyEnergyAudits={row.original.companyEnergyAudits} />
          )}
        </Fragment>
      )}
    </Table>
  )
}

interface SubTableProps {
  row: Row<SaleWithDiagnosticAndEnergyAudit>;
  allDiagnostics: Diagnostic[];
}

export const DiagnosticSubTable = ({ row, allDiagnostics }: SubTableProps) => {
  const columns = React.useMemo<ColumnDef<Diagnostic>[]>(
    () => [
      columnDiagnosticHelper.display({
        id: "type",
        header: "Type",
        cell: "Demande diagnostic"
      }),
      columnDiagnosticHelper.display({
        id: "date",
        header: "Date",
        cell: ({ row }) => {
          const formattedDate = new Intl.DateTimeFormat("fr-FR", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour12: false,
            timeZone: "Europe/Paris"
          }).format(new Date(row.original.created));

          return <Table.Cell value={formattedDate} bold />;
        }
      }),
      columnDiagnosticHelper.display({
        id: "status",
        header: "Statut",
        cell: ({ row }) => {
          const companyDiagnostics = row.original?.expand?.companyDiagnostics_via_diagnostic || [];

          const isSigned = companyDiagnostics.some(companyDiagnostic => companyDiagnostic.state === CompanyDiagnosticState.accepted);

          const isRefused = allDiagnostics
            .filter(diagnostic => diagnostic.id !== row.original.id)
            .some(diagnostic => (diagnostic.expand?.companyDiagnostics_via_diagnostic || []).some(companyDiagnostic => companyDiagnostic.state === CompanyDiagnosticState.accepted));

          if (isSigned) {
            return <Table.Cell value="Accepté" />;
          }

          if (isRefused) {
            return <Table.Cell value="Refusé" />;
          }

          return <Table.Cell value="En attente" />;
        }
      }),
      columnDiagnosticHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => {
          const companyDiagnostics = row.original?.expand?.companyDiagnostics_via_diagnostic || [];

          return (
            <Button.Small
              name={row.getIsExpanded() ? "Fermer" : "Détails"}
              disabled={!Boolean(companyDiagnostics.length)}
              onClick={() => row.toggleExpanded()} />
          )
        }
      }),
    ],
    []
  );

  const table = useReactTable({
    data: row.original.diagnostics,
    autoResetPageIndex: false,
    autoResetExpanded: false,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
  });

  return (
    <Table.Subtable table={table}>
      {({ row }) => <CompanyDiagnosticSubTable row={row} />}
    </Table.Subtable>
  )
}

interface CompanyDiagnosticSubTableProps {
  row: Row<Diagnostic>;
}

export const CompanyDiagnosticSubTable = ({ row }: CompanyDiagnosticSubTableProps) => {
  const mutation = usePbDownload();

  const columns = React.useMemo<ColumnDef<CompanyDiagnostic>[]>(
    () => [
      columnCompagnyDiagnosticHelper.display({
        id: "company",
        header: "Entreprise",
        cell: ({ row }) => {
          return <Table.Cell value={row.original.expand.company.name} bold />;
        }
      }),
      columnCompagnyDiagnosticHelper.display({
        id: "email",
        header: "Email",
        cell: ({ row }) => {
          return <Table.Cell value={row.original.expand.company.email} />;
        }
      }),
      columnCompagnyDiagnosticHelper.display({
        id: "phone",
        header: "Téléphone",
        cell: ({ row }) => {
          return <Table.Cell value={row.original.expand.company.phone} />;
        }
      }),
      columnCompagnyDiagnosticHelper.display({
        id: "price",
        header: "Prix",
        cell: ({ row }) => {
          return <Table.Cell value={formatPrice(row.original.price)} />;
        }
      }),
      columnCompagnyDiagnosticHelper.display({
        id: "status",
        header: "Statut",
        cell: ({ row }) => {
          const formattedStatus = row.original.state === CompanyDiagnosticState.accepted
            ? "Signé"
            : row.original.state === CompanyDiagnosticState.refused
              ? "Refusé"
              : "En attente";

          return <Table.Cell value={formattedStatus} />;
        }
      }),
      columnCompagnyDiagnosticHelper.display({
        id: "date",
        header: "Date",
        cell: ({ row }) => {
          const formattedDate = new Intl.DateTimeFormat("fr-FR", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour12: false,
            timeZone: "Europe/Paris"
          }).format(new Date(row.original.created));

          return <Table.Cell value={formattedDate} bold />;
        }
      }),
      columnCompagnyDiagnosticHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => {
          return <Button.Small
            name="Télécharger"
            onClick={() => mutation.mutate({ resource: row.original, filename: row.original["diagnosticFile"] })} />
        }
      }),
    ],
    []
  );

  const table = useReactTable({
    data: row.original?.expand?.companyDiagnostics_via_diagnostic || [],
    autoResetPageIndex: false,
    autoResetExpanded: false,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
  });

  return (
    <Table.Subtable table={table} />
  )
}
