import { Table } from "@components/table/Table";
import { usePb } from "@hooks/usePb";
import {
  type ColumnDef,
  type SortingState,
  useReactTable,
  getSortedRowModel,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  createColumnHelper,
  type PaginationState,
} from "@tanstack/react-table";
import { expand } from "@utils/pb";
import type { ListResult } from "pocketbase";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  AbroadGoodState,
  type AbroadGood,
} from "@interfaces/arboad-good.interface";

const columnHelper = createColumnHelper<AbroadGood>();

export const AbroadGoods = () => {
  const pb = usePb();
  const queryClient = useQueryClient();

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 100,
    });

  const page = pageIndex + 1;

  const { data } = useQuery<ListResult<AbroadGood>>({
    queryKey: ["abroadGoods", page, pageSize],
    queryFn: async () => {
      return pb.collection("abroadGoods").getList(page, pageSize, {
        expand: expand(["protector"]),
      });
    },
  });

  const { items: abroadGoods = [], totalPages } = data! || {};

  const mutation = useMutation({
    mutationFn: async ({ id, state }: { id: string, state: AbroadGoodState }) => {
      return pb.collection("abroadGoods").update(id, {
        state
      });
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["abroadGoods"] });

      toast.success("Le statut a bien été modifié.");
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const getState = (state: AbroadGoodState) => {
    if (state === AbroadGoodState.finalized) {
      return "Finalisé";
    }
    if (state === AbroadGoodState.inProgress) {
      return "En cours";
    }
    if (state === AbroadGoodState.awaitingAppointment) {
      return "En attente de rendez-vous";
    }
  };

  const columns = React.useMemo<ColumnDef<AbroadGood>[]>(
    () => [
      columnHelper.display({
        header: "Mandataire",
        cell: ({ row }) => {
          return (
            <Table.Cell
              value={`${row.original.expand.protector.lastname} ${row.original.expand.protector.firstname}`}
              bold
            />
          );
        },
      }),
      columnHelper.display({
        header: "N° TEL",
        cell: ({ row }) => {
          return row.original.phone;
        },
      }),
      columnHelper.display({
        header: "Mail",
        cell: ({ row }) => {
          return row.original.email;
        },
      }),
      columnHelper.display({
        enableSorting: true,
        header: "DATE DEMANDE",
        cell: ({ row }) => {
          const formattedDate = new Intl.DateTimeFormat("fr-FR", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour12: false,
            timeZone: "Europe/Paris",
          }).format(new Date(row.original.created));

          return <Table.Cell value={formattedDate} bold />;
        },
      }),
      columnHelper.display({
        header: "Pays",
        cell: ({ row }) => {
          return row.original.country;
        },
      }),
      // @ts-expect-error
      columnHelper.accessor((data) => data, {
        header: "Status",
        cell: ({ row }) => {
          return (
            <select
              name="state"
              value={row.original.state}
              onChange={(e) => {
                const value = e.target.value as AbroadGoodState;

                mutation.mutate({ id: row.original.id, state: value });
              }}>
              {Object.values(AbroadGoodState).map((state) => (
                <option key={state} value={state}>
                  {getState(state)}
                </option>
              ))}
            </select>
          )
        },
      }),
    ],
    []
  );

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: abroadGoods,
    columns,
    enableMultiSort: true,
    manualPagination: true,
    pageCount: totalPages,
    state: {
      pagination,
      sorting,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <Table table={table} />
  );
};
