import { ActionTable } from "@components/ActionTable";
import { usePb } from "@hooks/usePb";
import { Fragment } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CompanyMandateState, type CompanyMandate } from "@interfaces/mandate.interface";
import { expand } from "@utils/pb";
import { Button } from "@components/buttons/Buttons";
import { useModal } from "@hooks/useModal";
import { usePbDownload } from "@hooks/usePbDownload";
import { CompanyInfosModal } from "@components/modal/CompanyInfosModal";
import { trpc } from "@client/index";
import { toast } from "react-toastify";
import type { z } from "zod";
import Modal from "@components/modal/Modal";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { SignCompanyMandateSchema } from "@schemas/mandate.schema";

const formateDate = (date: string) => {
  return new Date(date).toLocaleString('fr-FR', {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
}

interface SignModalProps {
  id: string;
  companyMandate: CompanyMandate;
}

export const MandateSignModal = ({ id: modalId, companyMandate }: SignModalProps) => {
  const modal = useModal();
  const queryClient = useQueryClient();
  const downloadMutation = usePbDownload();

  const formMethods = useForm<z.infer<typeof SignCompanyMandateSchema>>({
    resolver: zodResolver(SignCompanyMandateSchema),
    defaultValues: {
      signedMandate: undefined
    }
  });

  const signedMandate = formMethods.watch("signedMandate");

  const mutation = trpc.sale.mandate.uploadSignedCompanyMandate.useMutation({
    onSuccess: () => {
      formMethods.reset();
      modal.close(modalId);
      queryClient.invalidateQueries({ queryKey: ["companyMandates", companyMandate.expand.mandate.sale] });
      toast.success(`Votre signature a bien été prise en compte`);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const handleSubmit = (values: z.infer<typeof SignCompanyMandateSchema>) => {
    const formData = new FormData();
    formData.set("signedMandate", values["signedMandate"]);
    formData.set("companyMandateId", companyMandate.id);
    mutation.mutate(formData);
  }

  return (
    <Modal id={modalId} title="Signer le mandat">
      <div className="devis-diag--text">
        <label>
          Télécharger le document pour l’imprimer. Le signer et le scanner pour le déposer à nouveau à l'aide du bouton insérer.
        </label>
        <br />
        <p>Il sera ensuite automatiquement transmis à l'entreprise concernée.</p>
      </div>

      <Form formMethods={formMethods}>
        <div className="modal--actions">
          <Form.Actions>
            <Button
              name="Télécharger"
              onClick={() => downloadMutation.mutate({ resource: companyMandate, filename: companyMandate["mandateFile"] })} />
            <Input.File
              name="signedMandate"
              label="Insérer"
              disabled={!downloadMutation.isSuccess}
              accept="application/pdf"
              asButton />
            <Button
              name="Envoyer"
              disabled={!Boolean(signedMandate)}
              loading={mutation.isPending}
              onClick={formMethods.handleSubmit(handleSubmit)} />
          </Form.Actions>
        </div>
      </Form>
    </Modal>
  );
};

interface MandateRequestActionTableRowProps {
  companyMandate: CompanyMandate;
  readOnly: boolean;
}

const MandateRequestActionTableRow = ({ companyMandate, readOnly = false }: MandateRequestActionTableRowProps) => {
  const modal = useModal();
  const mutation = usePbDownload();

  const MANDATE_SIGN_MODAL_ID = `${MandateSignModal.name}::${companyMandate.id}`;
  const COMPANY_INFOS_MODAL_ID = `${CompanyInfosModal.name}::${companyMandate.id}`;

  const isMandatDeposited = Boolean(companyMandate?.mandateFile);

  return (
    <Fragment>
      <ActionTable.Row>
        {formateDate(companyMandate.created)}
      </ActionTable.Row>
      <ActionTable.Row>
        {companyMandate.expand.company?.name}
      </ActionTable.Row>
      <ActionTable.Row>
        {companyMandate.state === CompanyMandateState.signed
          ? "Signé"
          : !isMandatDeposited
            ? "En attente agence"
            : "À signer"}
      </ActionTable.Row>

      {readOnly ?
      <ActionTable.Row>
        <Button.Small
        name="Télécharger"
        disabled={!companyMandate?.mandateFile}
        onClick={() => mutation.mutate({ resource: companyMandate, filename: companyMandate["mandateFile"] })} />
      </ActionTable.Row>
      :
      <>
      {isMandatDeposited && (
        <ActionTable.ActionsButton>
          <div className="actions--menu">
            <Button.Small
              name="Signer"
              disabled={!companyMandate?.mandateFile || companyMandate.state === CompanyMandateState.signed}
              onClick={() => modal.open(MANDATE_SIGN_MODAL_ID)} />
            <Button.Small
              name="Télécharger"
              disabled={!companyMandate?.mandateFile}
              onClick={() => mutation.mutate({ resource: companyMandate, filename: companyMandate["mandateFile"] })} />
            <Button.Small
              name="Plus d'infos"
              onClick={() => modal.open(COMPANY_INFOS_MODAL_ID)} />
          </div>
        </ActionTable.ActionsButton>
      )}
      </>
      }
    </Fragment>
  );
};

interface Props {
  saleId: string;
  readOnly: boolean;
}

export const MandateActionTable = ({ saleId, readOnly = false }: Props) => {
  const pb = usePb();
  const { data: companyMandates = [], isPending } = useQuery<CompanyMandate[]>({
    queryKey: ["companyMandates", saleId],
    queryFn: async () => {
      return pb.collection("companyMandates").getFullList({
        filter: `mandate.sale = "${saleId}"`,
        expand: expand(["mandate", "company"])
      });
    },
    enabled: !!saleId
  });

  if (readOnly) {
    return (
      <Fragment>
      <ActionTable isPending={isPending} emptyMessage="Merci de procéder à une première demande de mandat" headers={['Date', 'Agence', 'Statut', 'Actions']}>
        {companyMandates.filter((companyMandate) => { return companyMandate?.mandateFile}).map(companyMandate => (
          <MandateRequestActionTableRow key={companyMandate.id} companyMandate={companyMandate} readOnly />
        ))}
      </ActionTable>
    </Fragment>
    )
  }

  return (
    <Fragment>
      <ActionTable isPending={isPending} emptyMessage="Merci de procéder à une première demande de mandat" headers={['Date', 'Agence', 'Statut', 'Actions']}>
        {companyMandates.map(companyMandate => (
          <MandateRequestActionTableRow key={companyMandate.id} companyMandate={companyMandate} />
        ))}
      </ActionTable>

      {/* Rendu des modales en dehors du tableau */}
      {companyMandates.map(companyMandate => (
        <Fragment key={companyMandate.id}>
          <MandateSignModal id={`${MandateSignModal.name}::${companyMandate.id}`} companyMandate={companyMandate} />
          <CompanyInfosModal id={`${CompanyInfosModal.name}::${companyMandate.id}`} company={companyMandate.expand.company} />
        </Fragment>
      ))}
    </Fragment>
  );
};
