import { useModal } from '@hooks/useModal';
import './MandateSalePiecesModal.scss';
import Modal from '@components/modal/Modal';
import { Button } from '@components/buttons/Buttons';
import { MandateIndivisairesModal } from './MandateIndivisairesModal';

interface Props { }

export const MandateSalePiecesModal = ({ }: Props) => {
  const modal = useModal();

  return (
    <Modal id={MandateSalePiecesModal.name} title="Ajouter des pièces à la vente">
      <div className="sale-pieces-modal">
        <p>
          La frise à droite de votre écran « pièce pour la vente », vous permet d'insérer ou de demander des pièces nécessaires aux agences pour établir leur mandat qui leur sont transmises lors de cette étape par la plateforme.
        </p>

        <p>
          Les pièces présentes sont indiquées par une encoche verte, celle manquantes par une croix jaune.
        </p>
        <p>
          Même si des pièces sont manquantes vous pouvez quand même envoyer votre demande en appuyant sur confirmer, mais certaines agences pourraient ne pas être en mesure de vous envoyer un mandat.
        </p>

        <p>Les pièces de copropriété ne sont nécessaires que pour les appartements.</p>

        <Button
          name="Confirmer"
          onClick={() => {
            modal.close(MandateSalePiecesModal.name);
            modal.open(MandateIndivisairesModal.name);
          }}
          className="confirm-button" />

        <p
          className="cursor-pointer link"
          style={{ textAlign: "center" }}
          onClick={() => modal.close(MandateSalePiecesModal.name)}>
          Revenir en arrière
        </p>
      </div>
    </Modal>
  );
};
