import { Button } from "@components/buttons/Buttons";
import { Table } from "@components/table/Table";
import { type ColumnDef, useReactTable, getCoreRowModel, createColumnHelper, type Row } from "@tanstack/react-table";
import React from "react";
import { CompanyEnergyAuditState, type CompanyEnergyAudit, type EnergyAudit } from "@interfaces/energyAudit.interface";
import { formatPrice } from "@utils/utils";
import type { SaleWithDiagnosticAndEnergyAudit } from "./DiagnosticTable";
import { usePbDownload } from "@hooks/usePbDownload";

const columnHelper = createColumnHelper<EnergyAudit>();
const columnCompanyEnergyAuditHelper = createColumnHelper<CompanyEnergyAudit>();

interface EnergyAuditSubTableProps {
  row: Row<SaleWithDiagnosticAndEnergyAudit>;
  energyAudits: EnergyAudit[];
  companyEnergyAudits: CompanyEnergyAudit[];
}

export const CompanyEnergyAuditFromDiagnosticEmail = ({ energyAudits, companyEnergyAudits }: { energyAudits: EnergyAudit[]; companyEnergyAudits: CompanyEnergyAudit[] }) => {
  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      columnHelper.display({
        id: "type",
        header: "Type",
        cell: "Demande AE via mail"
      }),
      columnHelper.display({
        id: "status",
        header: "Statut",
        cell: () => {
          const isSigned = companyEnergyAudits.some(companyEnergyAudit => companyEnergyAudit.state === CompanyEnergyAuditState.accepted)

          const isRefused = energyAudits
            .flatMap(energyAudit => energyAudit?.expand?.companyEnergyAudits_via_energyAudit || [])
            .some(companyEnergyAudit => companyEnergyAudit.state === CompanyEnergyAuditState.accepted);

          if (isSigned) {
            return <Table.Cell value="Accepté" />;
          }

          if (isRefused) {
            return <Table.Cell value="Refusé" />;
          }

          return <Table.Cell value="En attente" />;
        }
      }),
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => {
          return (
            <Button.Small
              name={row.getIsExpanded() ? "Fermer" : "Détails"}
              onClick={() => row.toggleExpanded()} />
          )
        }
      }),
    ],
    []
  );

  const table = useReactTable({
    data: [{}],
    autoResetPageIndex: false,
    autoResetExpanded: false,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
  });

  return (
    <Table.Subtable table={table}>
      {() => <CompanyEnergyAuditSubTable companyEnergyAudits={companyEnergyAudits} />}
    </Table.Subtable>
  )
}

export const EnergyAuditSubTable = ({ row, energyAudits, companyEnergyAudits }: EnergyAuditSubTableProps) => {
  const columns = React.useMemo<ColumnDef<EnergyAudit>[]>(
    () => [
      columnHelper.display({
        id: "type",
        header: "Type",
        cell: "Demande AE"
      }),
      columnHelper.display({
        id: "date",
        header: "Date",
        cell: ({ row }) => {
          const formattedDate = new Intl.DateTimeFormat("fr-FR", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour12: false,
            timeZone: "Europe/Paris"
          }).format(new Date(row.original.created));

          return <Table.Cell value={formattedDate} bold />;
        }
      }),
      columnHelper.display({
        id: "status",
        header: "Statut",
        cell: ({ row }) => {
          const allCompanyEnergyAudits = energyAudits.flatMap(energyAudit => energyAudit?.expand?.companyEnergyAudits_via_energyAudit || []);

          allCompanyEnergyAudits.push(...companyEnergyAudits);

          const isRefused = allCompanyEnergyAudits.some(companyEnergyAudit => companyEnergyAudit.state === CompanyEnergyAuditState.accepted);

          const companyEnergyAuditsFromEnergyAudit = row.original?.expand?.companyEnergyAudits_via_energyAudit || [];

          const isSigned = companyEnergyAuditsFromEnergyAudit.some(companyEnergyAudit => companyEnergyAudit.state === CompanyEnergyAuditState.accepted)

          if (isSigned) {
            return <Table.Cell value="Accepté" />;
          }

          if (isRefused) {
            return <Table.Cell value="Refusé" />;
          }

          return <Table.Cell value="En attente" />;
        }
      }),
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => {
          const companyEnergyAudits = row.original?.expand?.companyEnergyAudits_via_energyAudit || [];

          return (
            <Button.Small
              name={row.getIsExpanded() ? "Fermer" : "Détails"}
              disabled={!Boolean(companyEnergyAudits.length)}
              onClick={() => row.toggleExpanded()} />
          )
        }
      }),
    ],
    []
  );

  const table = useReactTable({
    data: row.original.energyAudits,
    autoResetPageIndex: false,
    autoResetExpanded: false,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
  });

  return (
    <Table.Subtable table={table}>
      {({ row }) => <CompanyEnergyAuditSubTable companyEnergyAudits={row.original?.expand?.companyEnergyAudits_via_energyAudit || []} />}
    </Table.Subtable>
  )
}

interface CompanyEnergyAuditSubTableProps {
  companyEnergyAudits: CompanyEnergyAudit[];
}

export const CompanyEnergyAuditSubTable = ({ companyEnergyAudits }: CompanyEnergyAuditSubTableProps) => {
  const mutation = usePbDownload();

  const columns = React.useMemo<ColumnDef<CompanyEnergyAudit>[]>(
    () => [
      columnCompanyEnergyAuditHelper.display({
        id: "company",
        header: "Entreprise",
        cell: ({ row }) => {
          return <Table.Cell value={row.original.expand.company.name} bold />;
        }
      }),
      columnCompanyEnergyAuditHelper.display({
        id: "email",
        header: "Email",
        cell: ({ row }) => {
          return <Table.Cell value={row.original.expand.company.email} />;
        }
      }),
      columnCompanyEnergyAuditHelper.display({
        id: "phone",
        header: "Téléphone",
        cell: ({ row }) => {
          return <Table.Cell value={row.original.expand.company.phone} />;
        }
      }),
      columnCompanyEnergyAuditHelper.display({
        id: "price",
        header: "Prix",
        cell: ({ row }) => {
          return <Table.Cell value={formatPrice(row.original.price)} />;
        }
      }),
      columnCompanyEnergyAuditHelper.display({
        id: "status",
        header: "Statut",
        cell: ({ row }) => {
          const formattedStatus = row.original.state === CompanyEnergyAuditState.accepted
            ? "Signé"
            : row.original.state === CompanyEnergyAuditState.refused
              ? "Refusé"
              : "En attente";

          return <Table.Cell value={formattedStatus} />;
        }
      }),
      columnCompanyEnergyAuditHelper.display({
        id: "date",
        header: "Date",
        cell: ({ row }) => {
          const formattedDate = new Intl.DateTimeFormat("fr-FR", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour12: false,
            timeZone: "Europe/Paris"
          }).format(new Date(row.original.created));

          return <Table.Cell value={formattedDate} bold />;
        }
      }),
      columnCompanyEnergyAuditHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => {
          return <Button.Small
            name="Télécharger"
            onClick={() => mutation.mutate({ resource: row.original, filename: row.original["energyAuditFile"] })} />
        }
      }),
    ],
    []
  );

  const table = useReactTable({
    data: companyEnergyAudits,
    autoResetPageIndex: false,
    autoResetExpanded: false,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
  });

  return (
    <Table.Subtable table={table} />
  )
}
