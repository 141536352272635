import { Fragment, useState } from "react";
import "./Tabs.scss";
import { Button } from "./buttons/Buttons";
import { classNames } from "@utils/utils";

type Tab = {
  name: string;
  key: string;
  disabled?: boolean;
};

interface Props<T extends readonly Tab[]> {
  tabs: T,
  defaultKey: TabKeys<T>,
  onChange?: (key: TabKeys<T>) => void,
  components?: Components<T>
}

type Components<T extends readonly Tab[]> = {
  [Key in T[number]['key']]: React.ReactNode;
}

type TabKeys<T extends readonly Tab[]> = T[number]['key'];

export const Tabs = <T extends readonly Tab[],>({
  tabs,
  components,
  defaultKey
}: Props<T>) => {
  const [selectedTab, setSelectedTab] = useState(defaultKey);

  const setActiveTab = (key: string) => {
    setSelectedTab(key);
  }

  return (
    <Fragment>
      <div className="tabs dropdown estim">
        {tabs.map(({ name, key, disabled }) => (
          <Button
            className={classNames(selectedTab === key ? "active" : "")}
            key={key}
            name={name}
            disabled={disabled}
            onClick={() => setActiveTab(key)} />
        ))}
      </div>

      {!!components?.[selectedTab] && components[selectedTab]}
    </Fragment>
  )
}