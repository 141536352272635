import { z } from "zod";
import { toast } from "react-toastify";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { CreateGoodAbroadSchema } from "@schemas/good-abroad.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { usePb } from "@hooks/usePb";
import { Form } from "@components/form/Form";
import { Button } from "@components/buttons/Buttons";
import Input from "@components/inputs/Input";
import { AbroadGoodState } from "@interfaces/arboad-good.interface";

export const GoodAbroad = () => {
  const pb = usePb();
  const queryClient = useQueryClient();
  const id = pb.authStore.model?.id;

  const formMethods = useForm<z.infer<typeof CreateGoodAbroadSchema>>({
    resolver: zodResolver(CreateGoodAbroadSchema),
    defaultValues: {
      phone: "",
      email: "",
      country: "",
      protector: id,
    },
  });

  const mutation = useMutation({
    mutationFn: async (body: z.infer<typeof CreateGoodAbroadSchema>) => {
      return pb.collection("abroadGoods").create({
        ...body,
        state: AbroadGoodState.inProgress
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["abroadGoods"] });

      formMethods.reset();

      toast.success(`Votre demande a bien été envoyée.`);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  return (
    <Form.DefaultLayout
      formMethods={formMethods}
      title="VENDRE UN BIEN A L'ETRANGER"
      subtitle="Veuillez remplir les champs suivants pour nous transmettre votre demande de vente de bien à l'étranger.">
      <Input
        name="phone"
        className={`c-input icon ico--search_blue`}
        placeholder="Numéro de téléphone"
        border
      />
      <Input
        name="email"
        className={`c-input icon ico--search_blue`}
        placeholder="Mail mandataire"
        border
      />
      <Input
        name="country"
        className={`c-input icon ico--search_blue`}
        placeholder="Pays du bien à vendre"
        border
      />
      <Input
        name="protector"
        type="hidden"
        className={`c-input icon ico--search_blue`}
        border
      />
      <Button
        name="Valider"
        onClick={formMethods.handleSubmit((values) => mutation.mutate(values))}
        loading={mutation.isPending}
      ></Button>
    </Form.DefaultLayout>
  );
};
