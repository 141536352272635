import "./Helper.scss";

interface Props {
  description: string;
}

export const Helper = ({ description }: Props) => {
  return (
    <div className="estimate-input--helper">
      <p>?</p>
      <span>{description}</span>
    </div>
  )
}
