import { usePagination } from "@hooks/usePagination";
import type { Table } from "@tanstack/react-table";

export const Pagination = ({ table }: { table: Table<any>; }) => {
  const paginationRange = usePagination({
    total: table.getPageCount() * table.getState().pagination.pageSize,
    perPage: table.getState().pagination.pageSize,
    currentPage: table.getState().pagination.pageIndex + 1
  });

  const goToPage = (page: number | string, index: number) => {
    const { pageIndex } = table.getState().pagination;

    if (typeof page === 'number') {

      table.setPageIndex(page - 1);

    } else {

      const currentIndex = paginationRange.findIndex(pagination => pagination === pageIndex);

      if (currentIndex < index) {

        table.setPageIndex((paginationRange as number[])[index - 1]);

      } else {

        table.setPageIndex(((paginationRange as number[])[index + 1]) - 2);

      }

    }
  }

  return (
    <div className="paginate">
      <div>
        <p>
          Page <span>{table.getState().pagination.pageIndex + 1}</span> sur <span>{table.getPageCount()}</span>
        </p>
      </div>

      <div>
        <nav>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}>
            Précédent
          </button>

          {paginationRange.map((page, index) => (
            <button
              key={index}
              onClick={() => goToPage(page, index)}>
              {page}
            </button>
          ))}

          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()} >
            Suivant
          </button>
        </nav>
      </div>
    </div>
  )
}
