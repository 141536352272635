import { Table } from "@components/table/Table";
import { usePb } from "@hooks/usePb";
import {
  type ColumnDef,
  type SortingState,
  useReactTable,
  getSortedRowModel,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  createColumnHelper,
  type PaginationState,
} from "@tanstack/react-table";
import { expand } from "@utils/pb";
import type { ListResult } from "pocketbase";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { formatFullAddress } from "@interfaces/address.interface";
import { formatDateDDmmYYYY, formatPrice } from "@utils/utils";
import { Button } from "@components/buttons/Buttons";
import { CompanyOfferCommissionState, type CompanyOffer } from "@interfaces/offer.interface";
import type { Company } from "@interfaces/company.interface";
import { toast } from "react-toastify";

const columnHelper = createColumnHelper<CompanyOffer>();

export const CommissionTable = () => {
  const pb = usePb();
  const queryClient = useQueryClient();

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 100,
    });

  const page = pageIndex + 1;

  const { data } = useQuery<ListResult<CompanyOffer>>({
    queryKey: ["offers-commissions", page, pageSize],
    queryFn: async () => {
      return pb.collection("companyOffers").getList(page, pageSize, {
        expand: expand([
          "company",
          "sale.address",
          "sale.protectee",
          "sale.protector"
        ]),
      });
    },
  });

  const getState = (state: CompanyOfferCommissionState) => {
    if (state === CompanyOfferCommissionState.waiting) {
      return "À payer";
    }

    return "Payée"
  }

  const mutation = useMutation({
    mutationFn: async ({ id, commissionState }: { id: string, commissionState: CompanyOfferCommissionState }) => {
      return pb.collection("companyOffers").update(id, {
        commissionState
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["offers-commissions", page, pageSize] });

      toast.success(`Le statut a bien été modifié.`);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const { items: sales = [], totalPages } = data! || {};

  const columns = React.useMemo<ColumnDef<CompanyOffer>[]>(
    () => [
      columnHelper.display({
        header: "Mandataire",
        cell: ({ row }) => {
          const sale = row.original.expand.sale;

          const protectorName = `${sale.expand.protectee.firstname} ${sale.expand.protectee.lastname}`;

          return (
            <Table.Cell value={protectorName} />
          );
        },
      }),
      columnHelper.display({
        header: "Majeur",
        cell: ({ row }) => {
          const sale = row.original.expand.sale;

          const protecteeName = `${sale.expand.protectee.firstname} ${sale.expand.protectee.lastname}`;

          return (
            <Table.Cell value={protecteeName} bold />
          );
        },
      }),
      columnHelper.display({
        header: "Adresse",
        cell: ({ row }) => {
          const sale = row.original.expand.sale;

          return (
            <Table.Cell value={formatFullAddress(sale.expand.address)} />
          );
        },
      }),
      columnHelper.display({
        header: "Date offre acceptée",
        cell: ({ row }) => {
          return (
            <Table.Cell value={formatDateDDmmYYYY(row.original.updated)} />
          );
        },
      }),
      columnHelper.display({
        header: "Montant",
        cell: ({ row }) => {
          return (
            <Table.Cell value={formatPrice(row.original.price)} />
          );
        },
      }),
      columnHelper.display({
        header: "Acheteur",
        cell: ({ row }) => {
          return (
            <Table.Cell value={row.original.buyerName} />
          );
        },
      }),
      columnHelper.display({
        header: "Notaire acquéreur",
        cell: ({ row }) => {
          return (
            <Table.Cell value={row.original.notaryName} />
          );
        },
      }),
      columnHelper.display({
        header: "Statut",
        cell: ({ row }) => {
          return (
            <select
              name="commissionState"
              value={row.original.commissionState}
              onChange={(e) => {
                const value = e.target.value as CompanyOfferCommissionState;

                mutation.mutate({ id: row.original.id, commissionState: value });
              }}>
              {Object.values(CompanyOfferCommissionState).map((commissionState) => (
                <option key={commissionState} value={commissionState}>
                  {getState(commissionState)}
                </option>
              ))}
            </select>
          )
        },
      }),
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => <Button.Small
          name={row.getIsExpanded() ? "Fermer" : "Détails"}
          onClick={() => row.toggleExpanded()} />
      }),
    ],
    []
  );

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: sales,
    columns,
    enableMultiSort: true,
    manualPagination: true,
    pageCount: totalPages,
    state: {
      pagination,
      sorting,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <Table table={table}>
      {({ row }) => (
        <SubTable company={row.original.expand.company} />
      )}
    </Table>
  );
};

interface SubTableProps {
  company: Company;
}

const subColumnHelper = createColumnHelper<Company>();

const SubTable = ({ company }: SubTableProps) => {
  const subColumns = React.useMemo<ColumnDef<Company>[]>(
    () => [
      subColumnHelper.display({
        header: "Nom",
        cell: ({ row }) => {
          return (
            <Table.Cell value={row.original.name} />
          );
        },
      }),
      subColumnHelper.display({
        header: "Téléphone",
        cell: ({ row }) => {
          return (
            <Table.Cell value={row.original.phone} />
          );
        },
      }),
      subColumnHelper.display({
        header: "Email",
        cell: ({ row }) => {
          return (
            <Table.Cell value={row.original.email} />
          );
        },
      }),
    ],
    []
  )

  const table = useReactTable({
    data: [company],
    autoResetPageIndex: false,
    autoResetExpanded: false,
    columns: subColumns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
  });

  return (
    <Table.Subtable table={table} />
  )
}
