import { Tabs } from "@components/Tabs";
import { Fragment } from "react";
import EstimateForm from "./EstimateForm";
import { EstimateActionTable } from "./EstimateActionTable";
import { Button } from "@components/buttons/Buttons";
import { trpc } from "@client/index";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";

interface Props {
  saleId: string;
}

export const EstimateDropdown = ({ saleId }: Props) => {
  const queryClient = useQueryClient();

  const estimateQuery = trpc.sale.estimate.getEstimates.useQuery({ saleId }, {
    enabled: !!saleId,
  });

  const estimates = (estimateQuery?.data || []);

  const hasCompanyEstimates = estimates.some(estimate => Boolean(estimate?.expand?.companyEstimates_via_estimate?.length))

  const mutation = trpc.sale.estimate.resend.useMutation({
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.estimate.getEstimates) });

      toast.success("Votre demande d'estimation actualisée a été effectuée.");
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  return (
    <Fragment>

      <Tabs
        defaultKey="create"
        tabs={[
          { name: "Demande d'estimation", key: "create" },
          { name: "Consulter estimations", key: "show" },
        ] as const}
        components={{
          create: <EstimateForm saleId={saleId!} />,
          show: (
            <Fragment>
              <EstimateActionTable saleId={saleId!} />

              {hasCompanyEstimates && (
                <Button
                  className="c-btn--outline"
                  loading={mutation.isPending}
                  name="Réactualisation des estimations"
                  onClick={() => mutation.mutate({ saleId })} />
              )}
            </Fragment>
          )
        }} />

    </Fragment>
  )
}