import { useController, useFormContext, type FieldError, type FieldErrors } from "react-hook-form";

export const extractFormError = (fieldErrors: FieldErrors<any>, name: string): FieldError => {
  return name
    .split('.')
    .reduce((prev: Record<string, any>, curr: string) => prev?.[curr], fieldErrors) as FieldError;
}

export const useInput = (name: string) => {
  const { control, ...props } = useFormContext();

  const error = extractFormError(props.formState.errors, name);

  const isError = Boolean(error);

  const {
    field,
  } = useController({
    name,
    control,
  });

  return {
    field,
    control,
    error,
    isError,
    ...props
  }
}