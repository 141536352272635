import "./ExternalPageTitle.scss";

interface Props {
  title: string;
}

export const ExternalPageTitle = ({ title }: Props) => {
  return (
    <h3 className="external-page-title">{title}</h3>
  )
}