import { Button } from "@components/buttons/Buttons";
import { Table } from "@components/table/Table";
import { usePb } from "@hooks/usePb";
import { useSkipper } from "@hooks/useSkipper";
import { useModal } from "@hooks/useModal";
import { type ColumnDef, useReactTable, getSortedRowModel, getCoreRowModel, getPaginationRowModel, getFilteredRowModel, getExpandedRowModel, createColumnHelper, type RowData } from "@tanstack/react-table";
import React, { Fragment } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { trpc } from "@client/index";
import { toast } from "react-toastify";
import Modal from "@components/modal/Modal";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input";
import { useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { UpdateProfileSchema } from "@schemas/user.schema";
import { z } from "zod";
import type { Company } from "@interfaces/company.interface";
import Select from "react-select";

declare module "@tanstack/react-table" {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

const columnHelper = createColumnHelper();

export const UserTable = () => {
  const pb = usePb();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const modal = useModal();

  const [selectedUser, setSelectedUser] = React.useState(null);

  const usurpMutation = trpc.admin.user.usurp.useMutation({
    onSuccess() {
      navigate("/dashboard");
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const formMethods = useForm({
    resolver: zodResolver(UpdateProfileSchema),
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      reject: [],
      clauses: [{ text: "" }], // Initialisation avec un champ de clause vide
    },
  });

  // Utilisation de useFieldArray pour gérer les clauses
  const { fields: clauses, append, remove } = useFieldArray({
    control: formMethods.control,
    name: "clauses", // Le champ dans lequel sont stockées les clauses
  });

  // Requêtes pour récupérer les données des utilisateurs et entreprises
  const { data: users = [] } = useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      return pb.collection("users").getFullList({
        filter: "acceptedAt != '' && rejectedAt = ''",
      });
    },
  });

  const { data: companies = [] } = useQuery({
    queryKey: ["companies"],
    queryFn: async () => {
      return pb.collection("companies").getFullList({
        batch: 9999,
        expand: "companyEmails_via_company",
      });
    },
  });

  // Mutation pour mettre à jour l'utilisateur
  const updateMutation = trpc.user.updateUser.useMutation({
    onSuccess: () => {
      toast.success("L'utilisateur a bien été modifié.");
      queryClient.invalidateQueries({ queryKey: ["users"] });
      formMethods.reset();
      setSelectedUser(null);
      modal.close("edit-user");
    },
    onError() {
      toast.error("Une erreur est survenue lors de la mise à jour.");
    },
  });

  // Ouverture de la modale d'édition d'un utilisateur
  const handleEditClick = (user) => {
    setSelectedUser(user);
    
    // Réinitialisation du formulaire avec les données de l'utilisateur
    formMethods.reset({
      ...user,
      clauses: user.clauses?.length ? user.clauses : [{ text: "" }], // Réinitialisation des clauses
    });
    modal.open("edit-user");
  };

  // Colonnes du tableau
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "firstname",
        header: "Prénom",
        enableSorting: true,
        cell: Table.BasicCell,
      },
      {
        accessorKey: "lastname",
        header: "Nom",
        enableSorting: true,
        cell: Table.BasicCell,
      },
      {
        accessorKey: "email",
        header: "Email",
        enableSorting: true,
        cell: (info) => info.getValue(),
      },
      columnHelper.accessor("department", {
        header: "Département",
        cell: Table.BasicCell,
      }),
      columnHelper.accessor("phone", {
        header: "Téléphone",
        cell: Table.BasicCell,
      }),
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <Fragment>
            <Button.Small
              name="Se connecter"
              onClick={() => usurpMutation.mutate({ userId: row.original.id })} />
              <br/><br/>
            <Button.Small
              name="Modifier"
              onClick={() => handleEditClick(row.original)}
            />
          </Fragment>
        ),
      }),
    ],
    []
  );

  // Configuration de la table avec react-table
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  const table = useReactTable({
    data: users,
    columns,
    autoResetPageIndex,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        skipAutoResetPageIndex();
      },
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <Fragment>
      <Table table={table} />

      <Modal id="edit-user" title="Modifier un utilisateur">
        <Form formMethods={formMethods}>
          <Input placeholder="Prénom" name="firstname" />
          <Input placeholder="Nom" name="lastname" />
          <Input placeholder="Email" name="email" type="email" />
          <Input placeholder="Téléphone" name="phone" />

          <strong style={{ color: "white" }}>Clauses :</strong>
          {clauses.map((clause, index) => (
            <div key={clause.id} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Input
                placeholder={`Clause ${index + 1}`}
                {...formMethods.register(`clauses.${index}.text`)} // Utilisation de `register` pour bien lier chaque champ
                style={{ width: "100%" }}
              />
              <span onClick={() => remove(index)} style={{fontWeight:'900', color: 'white', fontSize: '2rem'}}>x</span>
            </div>
          ))}
          <Button.Small
            name="Ajouter une clause"
            onClick={() => append({ text: "" })} // Ajouter une clause vide
          />

          <strong style={{ color: "white" }}>Entreprises à exclure :</strong>
          <Select
            isMulti
            options={companies.map((company) => ({
              value: company,
              label: company.name,
            }))}
            value={companies
              .filter((company) =>
                formMethods.watch("reject")?.includes(company.id)
              )
              .map((company) => ({
                value: company,
                label: company.name,
              }))}
            onChange={(selectedOptions) =>
              formMethods.setValue(
                "reject",
                selectedOptions.map((option) => option.value.id)
              )
            }
            styles={{
              container: (provided) => ({
                ...provided,
                width: "100%",
              }),
            }}
          />

          <Button
            name="Enregistrer"
            onClick={formMethods.handleSubmit((values) => {
              updateMutation.mutate({
                id: selectedUser?.id!,
                ...values,
                clauses: values.clauses,
              });
            })}
            loading={updateMutation.isPending}
          />
        </Form>
      </Modal>
    </Fragment>
  );
};
