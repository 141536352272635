import { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import "./Sale.scss";
import { Dropdown } from '@components/dropdown/Dropdown';
import EstimateForm from '@components/features/dashboard/sales/estimates/EstimateForm';
import { PropertyDesc } from '@components/PropertyDesc';
import { Tabs } from '@components/Tabs';
import { EstimateActionTable } from '@components/features/dashboard/sales/estimates/EstimateActionTable';
import { ToggleNavigationHeader } from '@components/navigation/ToggleNavigationHeader';
import { DiagnosticsDropdown } from '@components/features/dashboard/sales/diagnostics/DiagnosticsDropdown';
import { PropertyDocsList } from '@components/property-docs/PropertyDocsList';
import { trpc } from '@client/index';
import { MandateDropdown } from '@components/features/dashboard/sales/mandates/MandateDropdown';
import { OfferDropdown } from '@components/features/dashboard/sales/offers/OfferDropdown';
import { StorageDropdown } from '@components/features/dashboard/sales/storages/StorageDropdown';
import { NotaryFileDropdown } from '@components/features/dashboard/sales/notary-files/NotaryFileDropdown';
import { PropertyDoesNotSellDropdown } from '@components/features/dashboard/sales/propertyDoesNotSell/PropertyDoesNotSellDropdown';
import { Button } from '@components/buttons/Buttons';
import Modal from '@components/modal/Modal';
import { useForm } from 'react-hook-form';
import { Form } from '@components/form/Form';
import Input from '@components/inputs/Input';
import { useModal } from '@hooks/useModal';
import { zodResolver } from '@hookform/resolvers/zod';
import { StopSaleSchema } from '@schemas/sale.schema';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { EstimateDropdown } from '@components/features/dashboard/sales/estimates/EstimateDropdown';
import { getQueryKey } from '@trpc/react-query';

interface StopSaleModalProps {
  saleId: string;
}

const StopSaleModal = ({ saleId }: StopSaleModalProps) => {
  const modal = useModal();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const formMethods = useForm<z.infer<typeof StopSaleSchema>>({
    resolver: zodResolver(StopSaleSchema),
    defaultValues: {
      cause: "",
      keysInstructions: ""
    }
  });

  const mutation = trpc.sale.stop.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['companies'] });
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.protectee.search) });

      navigate(`/dashboard/sales-file/${saleId}`);

      toast.success(`La vente a bien été stoppée.`);

      modal.closeAll();
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  return (
    <Modal id={StopSaleModal.name} title="J'arrête la vente">
      <Form formMethods={formMethods}>
        <Input name="cause" placeholder="Motif" />
        <Input name="keysInstructions" placeholder="Instructions pour les clés (facultatif)" />

        <Button
          name="J'arrête la vente. Action irréversible"
          loading={mutation.isPending}
          onClick={formMethods.handleSubmit(values => mutation.mutate({ saleId, ...values }))} />

      </Form>
    </Modal>
  )
}

interface ArchiveSaleModalProps {
  saleId: string;
}

const ArchiveSaleModal = ({ saleId }: ArchiveSaleModalProps) => {
  const modal = useModal();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = trpc.sale.archived.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sales'] });
      toast.success(`La vente a bien été archivée.`);
      navigate(`/dashboard/sales-file/${saleId}`);
      modal.closeAll();
    },
    onError: () => {
      toast.error("Une erreur est survenue lors de l'archivage.");
    }
  });

  return (
    <Modal id={ArchiveSaleModal.name} title="J'archive la vente">
      <div>
        <p style={{color: 'white'}}>Êtes-vous sûr de vouloir archiver cette vente ? Cette action est irréversible.</p>
        <Button
          name="J'archive la vente"
          loading={mutation.isPending}
          onClick={() => mutation.mutate({ saleId })} />
      </div>
    </Modal>
  );
};

export const Sale = () => {
  const modal = useModal();
  const { saleId } = useParams<{ saleId: string }>();

  const { data, isFetched } = trpc.sale.estimate.isAddressCompleted.useQuery({ saleId: saleId! });

  const isAddressCompleted = data && isFetched;

  const {
    data: dropdownStatus = {
      notaryFiles: undefined,
      mandate: undefined,
      estimate: undefined,
      diagnostic: undefined,
      offer: undefined,
      storage: undefined
    }
  } = trpc.sale.getSaleDropwdownsStatus.useQuery({ saleId: saleId! }, {
    enabled: !!saleId
  });

  return (
    <Fragment>
      <div className="c-sell--driving">

        <Dropdown id="estimate" title="Estimation du bien" status={dropdownStatus.estimate}>
          <EstimateDropdown saleId={saleId!} />
        </Dropdown>

        <Dropdown id="diagnostic" title="Organisation des diagnostics" disabled={!isAddressCompleted} status={dropdownStatus.diagnostic}>
          <DiagnosticsDropdown saleId={saleId!} />
        </Dropdown>

        <Dropdown id="mandate" title="Demander les mandats" disabled={!isAddressCompleted} status={dropdownStatus.mandate}>
          <MandateDropdown saleId={saleId!} />
        </Dropdown>

        <Dropdown id="offer" title="Les offres" disabled={!isAddressCompleted} status={dropdownStatus.offer}>
          <OfferDropdown saleId={saleId!} />
        </Dropdown>

        <Dropdown id="storage" title="Organiser débarras (optionnel)" disabled={!isAddressCompleted} status={dropdownStatus.storage}>
          <StorageDropdown saleId={saleId!} />
        </Dropdown>

        <Dropdown id="notary-file" title="Ouvrir le dossier chez le notaire" disabled={!isAddressCompleted} status={dropdownStatus.notaryFiles}>
          <NotaryFileDropdown saleId={saleId!} />
        </Dropdown>

        <Dropdown id="property-does-not-sell" title="Le bien ne se vend pas, que faire ?" disabled={!isAddressCompleted}>
          <PropertyDoesNotSellDropdown saleId={saleId!} />
        </Dropdown>

        <Button
          className="red"
          name="J'arrête la vente"
          onClick={() => modal.open(StopSaleModal.name)} />

        <Button
          className="orange"
          name="J'archive la vente"
          onClick={() => modal.open(ArchiveSaleModal.name)} />

      </div>

      <div className="c-block-sheet">
        <PropertyDesc saleId={saleId} />

        <div className="c-propertydocs">
          <span>Pièces de la vente</span>
          <ul>
            <PropertyDocsList saleId={saleId!} />
          </ul>
        </div>
      </div>

      <StopSaleModal saleId={saleId!} />
      <ArchiveSaleModal saleId={saleId!} />
    </Fragment>
  );
};
