import { TypeOfBuilding, formatFullAddress, type Address } from "@interfaces/address.interface";
import "./ExternalPropertyDesc.scss";
import { capitalizeFirstLetter } from "@utils/utils";

interface Props {
  address: Address;
}

const formatPropertyFloor = (numberOfFloors: number) => {
  if (numberOfFloors === 0) {
    return "RDC";
  }

  if (numberOfFloors === 1) {
    return "1er étage";
  }

  return `${numberOfFloors}eme étage`;
}

export const ExternalPropertyDesc = ({ address }: Props) => {
  return (
    <div className="external-property-desc">

      <h1>Adresse: {formatFullAddress(address)}</h1>
      <br />

      <div className="confirm-estim--text_ext">
        <h3>Fiche d'identité du bien</h3>
        <p>Surface : {address.surface}m2</p>
        <p>Nombre de pièce : {address.numberOfRooms}</p>
        <p>Année de construction : {address.yearOfConstruction}</p>
        <p>Type de logement : {capitalizeFirstLetter(address.typeOfBuilding)}</p>
        {address.typeOfBuilding === TypeOfBuilding.appartement && <p>Etage : {formatPropertyFloor(address.numberOfFloors)}</p>}
        <p>Type de chauffage : {address.typeOfHeating}</p>
      </div>
    </div>
  )
}