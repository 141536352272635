import { z } from "zod";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { EstimateOnlineSchema } from "@schemas/estimate-online.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@components/form/Form";
import { Button } from "@components/buttons/Buttons";
import Input from "@components/inputs/Input";
import { Select } from "@components/inputs/Select";
import { TypeOfBuilding } from "@interfaces/address.interface";
import { trpc } from "@client/index";

export const EstimateOnline = () => {
  const formMethods = useForm<z.infer<typeof EstimateOnlineSchema>>({
    resolver: zodResolver(EstimateOnlineSchema),
    defaultValues: {
      propertyCondition: "",
      numberOfRooms: undefined,
      protecteeFirstname: "",
      protecteeLastname: "",
      city: "",
      zipcode: "",
      address: "",
      surface: "",
      typeOfBuilding: TypeOfBuilding.appartement,
    },
  });

  const mutation = trpc.estimateOnline.create.useMutation({
    onSuccess: (id: string) => {
      window.open(`https://plateforme-athena.com/${id}.pdf`, "_blank")

      formMethods.reset();
    },
    onError: () => {
      toast.error("Une erreur est survenue lors de la récupération des données");
    },
  })

  return (
    <Form.DefaultLayout
      formMethods={formMethods}
      title="PRÉ-ESTIMATION EN LIGNE"
      subtitle="Veuillez remplir les champs suivants pour nous transmettre votre demande de pré-estimation en ligne.">

      <Input
        border
        name="protecteeLastname"
        placeholder="Nom du majeur protégé" />

      <Input
        border
        name="protecteeFirstname"
        placeholder="Prénom du majeur protégé" />

      <Input
        border
        name="numberOfRooms"
        placeholder="Nombre de pièces" />

      <Input
        border
        name="address"
        placeholder="Adresse" />

      <Input
        border
        name="zipcode"
        placeholder="Code postal" />


      <Input
        border
        name="city"
        placeholder="Ville" />

      <Input
        border
        type="number"
        name="surface"
        placeholder="Superficie (en m²)"
      />

      <Select
        border
        options={[
          { value: TypeOfBuilding.appartement, label: "Appartement" },
          { value: TypeOfBuilding.maison, label: "Maison" },
        ]}
        name="typeOfBuilding"
      />

      <Select
        border
        name="propertyCondition"
        options={[
          { value: "", label: "Etat du bien", disabled: true },
          { value: "Standard", label: "Standard" },
          { value: "À rafraîchir", label: "À rafraîchir" },
          { value: "À refaire intégralement", label: "À refaire intégralement" },
          { value: "Ne sais pas", label: "Ne sais pas" },
        ]} />

      <Button
        name="Estimer"
        onClick={formMethods.handleSubmit((values) => mutation.mutate(values))}
        loading={mutation.isPending} />

    </Form.DefaultLayout>
  );
};
