import type { Company } from "./company.interface";
import type { Sale } from "./sale.interface";
import type { Base, ID } from "./shared.interface";

export interface Indivisaire {
  name: string;
  email: string;
  phone: string;
}

export interface Mandate extends Base {
  price: number;
  keysInstructions: string;
  clauses: string[];
  sale: ID;
  indivisaires: Indivisaire[];
  companies: ID[];
  expand: {
    sale: Sale;
    companies: Company[];
    companyMandates_via_mandate: CompanyMandate[];
  }
}

export enum CompanyMandateState {
  waiting = "waiting",
  signed = "signed"
}

export interface CompanyMandate extends Base {
  mandate: ID;
  mandateFile: any;
  company: ID;
  state: CompanyMandateState;
  expand: {
    company: Company;
    mandate: Mandate;
  }
}

export enum CompanyReportState {
  waiting = "waiting",
  done = "done"
}

export interface CompanyReport extends Base {
  sale: ID;
  company: ID;
  state: CompanyReportState;
  report: any;
  expand: {
    sale: Sale;
    company: Company;
  }
}

export enum CompanyAmendmentState {
  waiting = "waiting",
  signed = "signed"
}

export interface CompanyAmendment extends Base {
  sale: ID;
  price: number;
  mandate: ID;
  company: ID;
  amendment: any;
  state: CompanyAmendmentState;
  expand: {
    sale: Sale;
    mandate: Mandate;
    company: Company;
  }
}