import { ActionTable } from "@components/ActionTable"
import { Button } from "@components/buttons/Buttons";
import { usePb } from "@hooks/usePb";
import { Fragment } from "react";
import { useQuery } from "@tanstack/react-query";
import { usePbDownload } from "@hooks/usePbDownload";
import { expand } from "@utils/pb";
import { CompanyReportState, type CompanyReport } from "@interfaces/mandate.interface";

const formateDate = (date: string) => {
  return new Date(date).toLocaleString('fr-FR', {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
}

interface CompanyReportRequestActionTableRowProps {
  companyReport: CompanyReport;
}

const CompanyReportRequestActionTableRow = ({ companyReport }: CompanyReportRequestActionTableRowProps) => {
  const mutation = usePbDownload();

  return (
    <Fragment>
      <ActionTable.Row>
        {formateDate(companyReport.created)}
      </ActionTable.Row>

      <ActionTable.Row>
        {companyReport.expand.company.name}
      </ActionTable.Row>

      {companyReport.state === CompanyReportState.waiting ?
        <div className="estima-action--wrapper empty">
          <ActionTable.Row>
            En attente
          </ActionTable.Row>
        </div>
        :
        <ActionTable.Row>
          <Button.Small
            name="Télécharger"
            disabled={!companyReport?.report}
            onClick={() => mutation.mutate({ resource: companyReport, filename: companyReport["report"] })} />
        </ActionTable.Row>}
    </Fragment>
  )
}

interface Props {
  saleId: string;
}

export const CompanyReportActionTable = ({ saleId, }: Props) => {
  const pb = usePb();

  const { data: companyReports = [], isPending } = useQuery<CompanyReport[]>({
    queryKey: ["companyReports", saleId],
    queryFn: async () => {
      return pb.collection("companyReports").getFullList({
        filter: `sale = "${saleId}"`,
        expand: expand([
          "company"
        ])
      });
    },
    enabled: !!saleId
  });

  return (
    <ActionTable isPending={isPending} emptyMessage="Aucune demande de comptes rendus en cours">
      {companyReports.map(companyReport => (
        <CompanyReportRequestActionTableRow key={companyReport.id} companyReport={companyReport} />
      ))}
    </ActionTable>
  )
}