import { useInput } from "@hooks/useInput";
import React, { useRef } from "react";

interface Props {
  name: string;
  multiple?: boolean;
}

export const DragNdropUpload = ({ name, multiple }: Props) => {
  const { field, isError } = useInput(name);

  const _ref = useRef<HTMLInputElement>(null);

  const files: File[] = field.value
    ? multiple
      ? field.value
      : [field.value]
    : [];

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Convert the FileList to an array
    const selectedFiles = multiple ? Array.from(event.target.files!) : event.target.files![0];

    field.onChange(selectedFiles);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const droppedFiles = event.dataTransfer.files;

    // Convert the FileList to an array for multiple files
    const selectedFiles = multiple ? Array.from(droppedFiles) : droppedFiles[0];

    field.onChange(selectedFiles);
  };

  const handleRemoveFile = (index: number) => {
    if (multiple) {
      const temp = files.filter((_, idx) => idx !== index);
      field.onChange(temp);
    } else {
      field.onChange(null);
    }

    _ref!.current!.value = "";
  };

  return (
    <section>
      <div
        className="file--wrapper"
        onClick={() => _ref.current?.click()}
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <div className="upload-info">
          <div>
            {multiple ? (
              <p>Glisser le(s) document(s) ici ou cliquer pour sélectionner</p>
            ) : (
              <p>Glisser le document ici ou cliquer pour sélectionner</p>
            )}
          </div>
        </div>
        <input
          type="file"
          hidden
          id="browse"
          onChange={handleFileChange}
          accept=".pdf"
          multiple={multiple}
          ref={_ref}
        />
      </div>

      {files.length > 0 && (
        <div className="file-list">
          <div className="file-list__container">
            {files.map((file, index) => (
              <div key={index} className="file-item">
                <div className="file-info">
                  <p>{file.name}</p>
                </div>
                <div className="file-actions">
                  <div
                    className="c-btn c-btn--outline"
                    onClick={() => handleRemoveFile(index)}
                  >
                    Supprimer
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  );
};
