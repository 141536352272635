import { Button } from "@components/buttons/Buttons"
import { Fragment } from "react"
import { useModal } from "@hooks/useModal";
import { Form } from "@components/form/Form";
import { useForm } from "react-hook-form";
import Input from "@components/inputs/Input";
import { zodResolver } from "@hookform/resolvers/zod";
import { CreateNotaryFileSchema } from "@schemas/notary-file.schema";
import { z } from "zod";
import { trpc } from "@client/index";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";
import "./NotaryFileDropdown.scss"

interface Props {
  saleId: string;
}

export const NotaryFileDropdown = ({ saleId }: Props) => {
  const queryClient = useQueryClient();

  const formMethods = useForm<z.infer<typeof CreateNotaryFileSchema>>({
    resolver: zodResolver(CreateNotaryFileSchema),
    defaultValues: {
      name: "",
      address: "",
      email: ""
    }
  });

  const notaryFileQuery = trpc.sale.notaryFile.isCreated.useQuery({ saleId });

  const isDisabled = notaryFileQuery.isFetched && Boolean(notaryFileQuery.data);

  const mutation = trpc.sale.notaryFile.create.useMutation({
    onSuccess() {
      toast.success("Votre demande de dossier a été effectuée");

      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.notaryFile.isCreated) });
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.getSaleDropwdownsStatus) });

      formMethods.reset();
    },
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
  })

  return (
    <Fragment>
      <div className="notary-file--dropdown">

        <Form formMethods={formMethods}>
          <Input name="name" placeholder="Nom du notaire" />
          <Input name="address" placeholder="Adresse postale" />
          <Input name="email" placeholder="Email (facultatif)" />
        </Form>

        <p>
          Les pièces présentes sur la plateforme utiles au notaire pour l'instruction du dossier lui seront automatiquement envoyées par nos soins
        </p>
        <div className="estimate-input--wrapper">
          <Button
            name="Valider"
            disabled={isDisabled}
            loading={mutation.isPending}
            onClick={formMethods.handleSubmit((values) => mutation.mutate({ saleId, ...values }))} />
        </div>
      </div>
    </Fragment>
  )
}