import { Button } from "@components/buttons/Buttons";
import { Table } from "@components/table/Table";
import { usePb } from "@hooks/usePb";
import { formatFullAddress } from "@interfaces/address.interface";
import { NotaryFileState, type NotaryFile } from "@interfaces/notaryFile.interface";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { type ColumnDef, type SortingState, useReactTable, getSortedRowModel, getCoreRowModel, getPaginationRowModel, getFilteredRowModel, getExpandedRowModel, createColumnHelper, type PaginationState } from "@tanstack/react-table";
import { expand } from "@utils/pb";
import type { ListResult } from "pocketbase";
import React from "react";
import { toast } from "react-toastify";

const columnHelper = createColumnHelper<NotaryFile>();

export const NotaryFileTable = () => {
  const pb = usePb();
  const queryClient = useQueryClient();

  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const page = pageIndex + 1;

  const { data } = useQuery<ListResult<NotaryFile>>({
    queryKey: ["notaryFiles", page, pageSize],
    queryFn: async () => {
      return pb.collection("notaryFiles").getList(page, pageSize, {
        expand: expand([
          "sale.address",
          "sale.protector",
          "sale.protectee"
        ])
      })
    }
  });

  const { items: notaryFiles = [], totalPages } = data! || {};

  const mutation = useMutation({
    mutationFn: async ({ id, state }: { id: string, state: NotaryFileState }) => {
      return pb.collection("notaryFiles").update(id, {
        state
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notaryFiles", page, pageSize] });

      toast.success(`La demande de dossier notaire a été modifiée.`);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const getState = (state: NotaryFileState) => {
    if (state === NotaryFileState.waiting) {
      return "A ouvrir";
    }

    return "Ouvert"
  };

  const columns = React.useMemo<ColumnDef<NotaryFile>[]>(
    () => [
      columnHelper.display({
        header: "Mandataire",
        cell: ({ row }) => {
          const { sale } = row.original.expand;

          const protector = sale.expand.protector;

          return `${protector.lastname} ${protector.firstname}`
        },
      }),
      columnHelper.display({
        header: "Majeur",
        cell: ({ row }) => {
          const { sale } = row.original.expand;

          const protectee = sale.expand.protectee;

          return `${protectee.lastname} ${protectee.firstname}`
        },
      }),
      columnHelper.display({
        header: "Adresse",
        cell: ({ row }) => {
          const { sale } = row.original.expand;

          return formatFullAddress(sale.expand.address)
        },
      }),
      columnHelper.display({
        enableSorting: true,
        header: "Date",
        cell: ({ row }) => {
          const formattedDate = new Intl.DateTimeFormat("fr-FR", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour12: false,
            timeZone: "Europe/Paris",
          }).format(new Date(row.original.created));

          return <Table.Cell value={formattedDate} bold />;
        },
      }),
      // @ts-expect-error
      columnHelper.accessor((data) => data, {
        header: "Status",
        cell: ({ row }) => {
          return (
            <select
              name="state"
              value={row.original.state}
              onChange={(e) => {
                const value = e.target.value as NotaryFileState;

                mutation.mutate({ id: row.original.id, state: value });
              }}>
              {Object.values(NotaryFileState).map((state) => (
                <option key={state} value={state}>
                  {getState(state)}
                </option>
              ))}
            </select>
          )
        },
      }),
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => {
          return (
            <Button.Small
              name={row.getIsExpanded() ? "Fermer" : "Détails"}
              onClick={() => row.toggleExpanded()} />
          )
        }
      }),
    ],
    []
  )

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: notaryFiles,
    columns,
    enableMultiSort: true,
    manualPagination: true,
    pageCount: totalPages,
    state: {
      pagination,
      sorting
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <Table table={table}>
      {({ row }) => (
        <Subtable notaryFile={row.original} />
      )}
    </Table>
  )
}

interface Props {
  notaryFile: NotaryFile;
}

const Subtable = ({ notaryFile }: Props) => {
  const columns = React.useMemo<ColumnDef<NotaryFile>[]>(
    () => [
      columnHelper.display({
        header: "Nom",
        cell: ({ row }) => {
          return <Table.Cell value={row.original.name} bold />;
        },
      }),
      columnHelper.display({
        enableSorting: true,
        header: "Date",
        cell: ({ row }) => {
          const formattedDate = new Intl.DateTimeFormat("fr-FR", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour12: false,
            timeZone: "Europe/Paris",
          }).format(new Date(row.original.updated));

          return <Table.Cell value={formattedDate} />;
        },
      }),
      columnHelper.display({
        header: "Adresse",
        cell: ({ row }) => {
          return row.original.address
        },
      }),
      columnHelper.display({
        header: "Email",
        cell: ({ row }) => {
          return row.original.email
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    data: [notaryFile],
    autoResetPageIndex: false,
    autoResetExpanded: false,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
  });

  return (
    <Table.Subtable table={table} />
  )
}