import { z } from "zod";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Form } from "@components/form/Form";
import { Button } from "@components/buttons/Buttons";
import { Toggle } from "@components/inputs/Toggle";
import { zodResolver } from "@hookform/resolvers/zod";
import "./EnergyAuditConfirm.scss"
import { ExternalNotificationCard } from "@components/ExternalNotificationCard";
import { ConfirmInterestSchema } from "@schemas/common.schema";
import { trpc } from "@client/index";
import { getQueryKey } from "@trpc/react-query";

export const EnergyAuditConfirm = () => {
  const queryClient = useQueryClient();

  const { energyAuditId } = useParams<{ energyAuditId: string; }>();

  const formMethods = useForm<z.infer<typeof ConfirmInterestSchema>>({
    resolver: zodResolver(ConfirmInterestSchema),
    defaultValues: {
      confirmation: "yes",
    },
  });

  const guardQuery = trpc.external.energyAudit.confirmGetResources.useQuery({ energyAuditId: energyAuditId! }, {
    retry: false,
    enabled: !!energyAuditId
  });

  const mutation = trpc.external.energyAudit.confirm.useMutation({
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.external.energyAudit.confirmGetResources) });
    }
  });

  const errorStatus = guardQuery.error?.data?.httpStatus;

  if (mutation.isSuccess) {
    return <ExternalNotificationCard title="Merci d'avoir répondu à cette demande de devis d'audit énergétique" />
  }

  if (errorStatus === 403 || errorStatus === 404) {
    return <ExternalNotificationCard title="Cette page n'est plus disponible" />
  }

  if (typeof errorStatus === "number") {
    return <ExternalNotificationCard title="Une erreur est survenue. Merci de réessayer plus tard." />
  }

  if (!guardQuery.data) {
    return null;
  }

  const { sale } = guardQuery.data.expand;

  return (
    <Form.DefaultLayout formMethods={formMethods}>
      <div className="estimate-without--container">
        <h3>Nouvelle demande de devis d'audit énergétique</h3>
        <small><b>Ville:</b> {sale?.expand.address.city}</small>

        <span>Merci de confirmer votre intérêt pour la demande de devis d'audit énergétique</span>

        <Toggle
          name="confirmation"
          required
          darker
          choices={[
            {
              label: "Oui",
              value: "yes",
            },
            {
              label: "Non",
              value: "no",
            },
          ]} />

        <p>Une fois votre choix confirmé, vous recevrez les informations concernant les clés et l'adresse précise.</p>

        <p>Attention, ce choix bloque les autres entreprises du département.</p>
      </div>

      <div className="actions--mail">
      <Form.Actions centered>
        <Button
          name="Valider"
          onClick={formMethods.handleSubmit((values) => mutation.mutate({ energyAuditId: energyAuditId!, ...values }))}
          loading={mutation.isPending} />
      </Form.Actions>
      </div>
    </Form.DefaultLayout>
  );
};
