import { Table } from "@components/table/Table";
import { usePb } from "@hooks/usePb";
import {
  type ColumnDef,
  type SortingState,
  useReactTable,
  getSortedRowModel,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  createColumnHelper,
  type PaginationState,
} from "@tanstack/react-table";
import { expand } from "@utils/pb";
import type { ListResult } from "pocketbase";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { SaleState, type Sale } from "@interfaces/sale.interface";
import { formatFullAddress } from "@interfaces/address.interface";
import { formatDateDDmmYYYY, formatPrice } from "@utils/utils";
import { Button } from "@components/buttons/Buttons";
import { CompanyStorageState, type CompanyStorage, type Storage } from "@interfaces/storage.interface";
import { usePbDownload } from "@hooks/usePbDownload";

const columnHelper = createColumnHelper<Storage>();

export const StorageTable = () => {
  const pb = usePb();
  const queryClient = useQueryClient();

  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const page = pageIndex + 1;

  const { data } = useQuery<ListResult<Storage>>({
    queryKey: ["storages", page, pageSize],
    queryFn: async () => {
      return pb.collection("storages").getList(page, pageSize, {
        expand: expand([
          "sale.address",
          "sale.protector",
          "sale.protectee",
          "companyStorages_via_storage.company"
        ]),
      });
    },
  });

  const { items: storages = [], totalPages } = data! || {};

  const getState = (storage: Storage) => {
    const companyStorages = storage.expand?.companyStorages_via_storage || [];

    const isSigned = companyStorages.some(companyStorage => companyStorage.state === CompanyStorageState.accepted);

    const hasInvoice = companyStorages.some(companyStorage => Boolean(companyStorage.invoice));

    if (isSigned && hasInvoice) {
      return "Débarras OK";
    }

    if (isSigned) {
      return "Signé";
    }

    const hasAtLeastOneQuotation = companyStorages.some(companyStorage => Boolean(companyStorage.quotation));

    if (hasAtLeastOneQuotation) {
      return "À signer";
    }

    return "Demandé";
  }

  const columns = React.useMemo<ColumnDef<Storage>[]>(
    () => [
      columnHelper.display({
        header: "Mandataire",
        cell: ({ row }) => {
          const protector = row.original?.expand.sale?.expand.protector;

          const protectorName = `${protector.firstname} ${protector.lastname}`;

          return (
            <Table.Cell value={protectorName} />
          );
        },
      }),
      columnHelper.display({
        header: "Majeur",
        cell: ({ row }) => {
          const protectee = row.original?.expand.sale?.expand.protectee;

          const protecteeName = `${protectee.firstname} ${protectee.lastname}`;

          return (
            <Table.Cell value={protecteeName} bold />
          );
        },
      }),
      columnHelper.display({
        header: "Adresse",
        cell: ({ row }) => {
          return (
            <Table.Cell value={formatFullAddress(row.original?.expand?.sale?.expand.address)} />
          );
        },
      }),
      columnHelper.display({
        header: "Date",
        cell: ({ row }) => {
          return (
            <Table.Cell value={formatDateDDmmYYYY(row.original.created)} />
          );
        },
      }),
      columnHelper.display({
        header: "Etat",
        cell: ({ row }) => {
          return (
            <Table.Cell value={getState(row.original)} />
          );
        },
      }),
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => {
          const companyStorages = row.original?.expand?.companyStorages_via_storage || [];

          return (
            <Button.Small
              name={row.getIsExpanded() ? "Fermer" : "Détails"}
              disabled={!companyStorages.length}
              onClick={() => row.toggleExpanded()} />
          )
        }
      }),
    ],
    []
  );

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: storages,
    columns,
    enableMultiSort: true,
    manualPagination: true,
    pageCount: totalPages,
    state: {
      pagination,
      sorting,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <Table table={table}>
      {({ row }) => (
        <SubTable companyStorages={row.original?.expand?.companyStorages_via_storage || []} />
      )}
    </Table>
  );
};

interface SubTableProps {
  companyStorages: CompanyStorage[];
}

const subColumnHelper = createColumnHelper<CompanyStorage>();

const SubTable = ({ companyStorages }: SubTableProps) => {
  const mutation = usePbDownload();

  const getState = (companyStorage: CompanyStorage) => {
    if (Boolean(companyStorage.invoice)) {
      return "Débarras OK";
    }

    if (companyStorage.state === CompanyStorageState.accepted) {
      return "Signé"
    }

    if (companyStorage.state === CompanyStorageState.refused) {
      return "Refusé"
    }

    if (Boolean(companyStorage.quotation)) {
      return "À signer"
    }

    return "En attente dépot"
  }

  const subColumns = React.useMemo<ColumnDef<CompanyStorage>[]>(
    () => [
      subColumnHelper.display({
        header: "Company",
        cell: ({ row }) => {
          return (
            <Table.Cell value={row.original.expand.company.name} />
          );
        },
      }),
      subColumnHelper.display({
        header: "Phone",
        cell: ({ row }) => {
          return (
            <Table.Cell value={row.original.expand.company.phone} />
          );
        },
      }),
      subColumnHelper.display({
        header: "Email",
        cell: ({ row }) => {
          return (
            <Table.Cell value={row.original.expand.company.email} />
          );
        },
      }),
      subColumnHelper.display({
        header: "Price",
        cell: ({ row }) => {
          return (
            <Table.Cell value={formatPrice(row.original.price)} />
          );
        },
      }),
      subColumnHelper.display({
        header: "Etat",
        cell: ({ row }) => {
          return (
            <Table.Cell value={getState(row.original)} />
          );
        },
      }),
      subColumnHelper.display({
        header: "Download",
        cell: ({ row }) => {
          return (
            <Button.Small
              name="Télécharger"
              disabled={!row.original.quotation}
              onClick={() => mutation.mutate({ resource: row.original, filename: row.original["quotation"] })} />
          );
        },
      }),
    ],
    []
  )

  const table = useReactTable({
    data: companyStorages,
    autoResetPageIndex: false,
    autoResetExpanded: false,
    columns: subColumns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
  });

  return (
    <Table.Subtable table={table} />
  )
}
