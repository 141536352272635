import { AdminSidebar } from '@components/AdminSidebar';
import { AdminAside } from '@components/features/admin/AdminAside';
import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

export const AdminLayout = () => (
  <Fragment>
    <section className="fx fx-vs">
      {/* <AdminSidebar /> */}

      <AdminAside />

      <Outlet />
    </section>
  </Fragment>
);