import { Fragment, useRef, type InputHTMLAttributes } from "react";
import "./inputs.scss";
import { useInput } from "@hooks/useInput";
import { classNames } from "src/utils/utils";
import { Button } from "@components/buttons/Buttons";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  status?: "valid" | "unvalid";
  border?: boolean;
  background?: boolean;
  message?: string;
}

const Input = ({ name, status, border, background, className, message, ...rest }: Props) => {
  const { field, isError } = useInput(name);

  return (
    <>
      <input
        className={classNames("c-input",
          className!,
          border ? "border" : "",
          background ? "background" : "",
          isError ? "error" : ""
        )}
        {...rest}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        name={field.name}
        ref={field.ref}
      />

      {message && (
        <span className="input-message">
          {message}
        </span>
      )}
    </>
  )
};

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  status?: "valid" | "unvalid";
  border?: boolean;
  background?: boolean;
}

Input.Textarea = ({ name, status, border, background, className, ...rest }: TextAreaProps) => {
  const { field, isError } = useInput(name);

  return (
    <textarea
      rows={4}
      style={{ width: "100%" }}
      className={classNames(
        className!,
        border ? "border" : "",
        background ? "background" : "",
        isError ? "error" : ""
      )}
      {...rest}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      name={field.name}
      ref={field.ref}
    />
  )
};

interface InputFileProps extends Omit<Props, "type"> {
  asButton?: boolean;
  multiple?: boolean;
  label: string;
  disabled?: boolean;
}

Input.File = ({ name, label, status, border, background, className, multiple, asButton, disabled, ...rest }: InputFileProps) => {
  const _ref = useRef<HTMLInputElement>(null)
  const { field, isError } = useInput(name);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = multiple
      ? [...Array.from(event.target.files ?? [])]
      : event.target.files![0];

    field.onChange(files);
  }

  const value = (field.value as File | File[]);

  const fileName = Array.isArray(value)
    ? `(${value.length}) Documents`
    : (field.value as File)?.name;

  const hasValue = Array.isArray(value)
    ? Boolean(value.length)
    : Boolean(value);

  const customizedLabel = hasValue ? fileName : label;

  return (
    <Fragment>
      {asButton && (
        <Fragment>
          {/* <Button.Icon name="X" onClick={() => field.onChange(null)} /> */}
          <Button name={customizedLabel} onClick={() => _ref.current?.click()} disabled={disabled} />
        </Fragment>
      )}

      <div className="inputFile" style={{ display: asButton ? "none" : "initial" }}>
        <input
          id={name}
          className={classNames("c-input",
            className!,
            border ? "border" : "",
            background ? "background" : "",
            isError ? "error" : ""
          )}
          multiple={multiple}
          {...rest}
          type="file"
          onChange={onChange}
          onBlur={field.onBlur}
          name={field.name}
          disabled={disabled}
          ref={ref => {
            field.ref(ref);

            _ref.current = ref;
          }}
        />
        <label htmlFor={name} className={isError ? "error" : ""}>{customizedLabel}</label>
      </div>
    </Fragment>
  )
}

interface InputCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

Input.Checkbox = ({ name, ...rest }: InputCheckboxProps) => {
  const { field, isError } = useInput(name);

  return <input
    type="checkbox"
    {...rest}
    onChange={field.onChange}
    onBlur={field.onBlur}
    value={field.value}
    name={field.name}
    ref={field.ref}
  />
}

export default Input;
