import { Button } from "@components/buttons/Buttons";
import { Table } from "@components/table/Table";
import { usePb } from "@hooks/usePb";
import { formatFullAddress } from "@interfaces/address.interface";
import type { Protectee } from "@interfaces/protectee.interface";
import type { User } from "@interfaces/user.interface";
import {
  type ColumnDef,
  type SortingState,
  useReactTable,
  getSortedRowModel,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  createColumnHelper,
  type PaginationState,
  type Row,
} from "@tanstack/react-table";
import type { ListResult } from "pocketbase";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { usePbDownload } from "@hooks/usePbDownload";
import { expand } from "@utils/pb";
import { formatPrice } from "@utils/utils";
import { CompanyOfferState, type CompanyOffer } from "@interfaces/offer.interface";
import type { Sale } from "@interfaces/sale.interface";
import { CompanyMandateState } from "@interfaces/mandate.interface";
import { useParams } from "react-router-dom"; // Importer useParams

const columnHelper = createColumnHelper<Sale>();
const subColumnHelper = createColumnHelper<CompanyOffer>();

const getCompanyOfferState = (companyOffer: CompanyOffer) => {
  if (!companyOffer.offer) {
    return "En attente";
  }

  if (companyOffer.state === CompanyOfferState.waiting) {
    return "A signer";
  }

  if (companyOffer.state === CompanyOfferState.signed) {
    return "Signé";
  }

  if (companyOffer.state === CompanyOfferState.refused) {
    return "Refusé";
  }

  return "-";
};

const getSaleState = (companyOffers: CompanyOffer[]) => {
  if (
    companyOffers.some(
      (companyOffer) => getCompanyOfferState(companyOffer) === "Signé"
    )
  ) {
    return "Acceptée";
  }

  if (
    companyOffers.some(
      (companyOffer) => getCompanyOfferState(companyOffer) === "A signer"
    )
  ) {
    return "A signer";
  }

  if (
    companyOffers.every(
      (companyOffer) =>
        getCompanyOfferState(companyOffer) === "En attente" || getCompanyOfferState(companyOffer) === "Refusé"
    )
  ) {
    return "En attente offre";
  }

  return "-";
};

export const OfferTable = () => {
  const pb = usePb();
  const { id } = useParams(); // Récupérer l'id des paramètres

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 100,
    });

  const page = pageIndex + 1;

  // Mise à jour de la requête pour gérer soit un id spécifique, soit une liste paginée
  const { data: salesData, isPending: isSalePending } = useQuery<ListResult<Sale>>({
    queryKey: ["sales", page, pageSize, id],
    queryFn: async () => {
      if (id) {
        return pb.collection("sales").getOne(id, {
          expand: expand([
            "protector",
            "protectee",
            "address",
            "companyOffers_via_sale.company",
            "mandates_via_sale.companyMandates_via_mandate",
          ]),
        });
      } else {
        return pb.collection("sales").getList(page, pageSize, {
          expand: expand([
            "protector",
            "protectee",
            "address",
            "companyOffers_via_sale.company",
            "mandates_via_sale.companyMandates_via_mandate",
          ]),
        });
      }
    },
  });

  // Gestion des résultats selon la présence de l'id
  let allSales = [];
  let totalPages = 1;

  if (id) {
    allSales = salesData ? [salesData] : [];
  } else {
    allSales = salesData?.items || [];
    totalPages = salesData?.totalPages || 1;
  }

  const sales = allSales.filter(sale => {
    const mandates = sale?.expand?.mandates_via_sale || [];

    return !!mandates.find(mandate => {
      const companyMandates = mandate?.expand?.companyMandates_via_mandate || [];

      return !!companyMandates.find(companyMandate => companyMandate.state === CompanyMandateState.signed);
    });
  });

  const columns = React.useMemo<ColumnDef<Sale>[]>(
    () => [
      // @ts-expect-error
      columnHelper.accessor("expand.protector", {
        header: "MANDATAIRE",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: (rowA: Row<Sale>, rowB: Row<Sale>, columnId: string) => {
          const protectorA = rowA.getValue<User>(columnId);
          const protectorB = rowB.getValue<User>(columnId);

          const nameA = `${protectorA.lastname} ${protectorA.firstname}`;
          const nameB = `${protectorB.lastname} ${protectorB.firstname}`;

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        cell: (ctx) => {
          const { firstname, lastname } = ctx.getValue();

          return `${lastname} ${firstname}`;
        },
      }),
      // @ts-expect-error
      columnHelper.accessor("expand.protectee", {
        header: "MAJEUR",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: (rowA: Row<Sale>, rowB: Row<Sale>, columnId: string) => {
          const protecteeA = rowA.getValue<Protectee>(columnId);
          const protecteeB = rowB.getValue<Protectee>(columnId);

          const nameA = `${protecteeA.lastname} ${protecteeA.firstname}`;
          const nameB = `${protecteeB.lastname} ${protecteeB.firstname}`;

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        cell: (ctx) => {
          const { firstname, lastname } = ctx.getValue();

          return <Table.Cell value={`${lastname} ${firstname}`} bold />;
        },
      }),
      columnHelper.display({
        header: "ADRESSE",
        cell: ({ row }) => {
          return formatFullAddress(row.original.expand.address);
        },
      }),
      columnHelper.accessor("expand.companyOffers_via_sale.price", {
        header: "PRIX NV",
        cell: ({ row }) => {
          const offerAccepted =
            row.original.expand?.companyOffers_via_sale?.find(
              (companyOffer) => companyOffer.state === CompanyOfferState.signed
            );
          if (offerAccepted) {
            return <Table.Cell value={formatPrice(offerAccepted.price)} />;
          }

          return <Table.Cell value={"-"} />;
        },
      }),
      columnHelper.display({
        header: "ETAT",
        cell: ({ row }) => {
          const mandateState = getSaleState(
            row.original?.expand?.companyOffers_via_sale || []
          );
          return <Table.Cell value={mandateState} bold />;
        },
      }),
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <Button.Small
            name={row.getIsExpanded() ? "Fermer" : "Détails"}
            disabled={!row.original.expand?.companyOffers_via_sale}
            onClick={() => row.toggleExpanded()}
          />
        ),
      }),
    ],
    []
  );

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: sales,
    columns,
    enableMultiSort: true,
    manualPagination: true,
    pageCount: totalPages,
    state: {
      pagination,
      sorting,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <Table table={table} isFetching={isSalePending}>
      {({ row }) => (
        <SubTable companyOffers={row.original.expand?.companyOffers_via_sale || []} />
      )}
    </Table>
  );
};

interface SubTableProps {
  companyOffers: CompanyOffer[];
}

const SubTable = ({ companyOffers }: SubTableProps) => {
  const mutation = usePbDownload();

  const subColumns = React.useMemo<ColumnDef<CompanyOffer>[]>(
    () => [
      subColumnHelper.display({
        id: "name",
        cell: ({ row }) => {
          if (row.original) {
            return (
              <Table.Cell
                value={row.original.expand.company.name}
                bold
                uppercase
              />
            );
          }
        },
      }),
      subColumnHelper.display({
        id: "phone",
        cell: ({ row }) => {
          return <Table.Cell value={row.original.expand.company.phone} />;
        },
      }),
      subColumnHelper.display({
        id: "email",
        cell: ({ row }) => {
          return <Table.Cell value={row.original.expand.company.email} />;
        },
      }),
      subColumnHelper.display({
        id: "prix",
        cell: ({ row }) => {
          return <Table.Cell value={formatPrice(row.original.price)} />;
        },
      }),
      subColumnHelper.display({
        id: "status",
        cell: ({ row }) => {
          return <Table.Cell value={getCompanyOfferState(row.original)} />;
        },
      }),
      subColumnHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => {
          const companyOffer = row.original;

          const batchDownload = () => {
            if (Boolean(companyOffer?.freePieces?.length)) {
              for (const freePiece of companyOffer.freePieces) {
                mutation.mutate({ resource: companyOffer, filename: freePiece })
              }
            }

            if (companyOffer.offer) {
              mutation.mutate({ resource: companyOffer, filename: companyOffer["offer"] });
            }

            if (companyOffer.buyerCni) {
              mutation.mutate({ resource: companyOffer, filename: companyOffer["buyerCni"] });
            }
          };

          return (
            <Button.Small
              name="Télécharger"
              disabled={!companyOffer.offer}
              onClick={batchDownload}
            />
          );
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    data: companyOffers,
    autoResetPageIndex: false,
    autoResetExpanded: false,
    columns: subColumns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
  });

  return <Table.Subtable table={table} />;
};
