export const classNames = (...classes: (string | undefined)[]): string => {
  return classes.filter(Boolean).join(" ");
};

export function deleteCookie(name: string) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const noop = () => { }

export const formatPrice = (price: number) => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(price);
}

export const getEnv = (key: string): string => {
  return process.env[key] || import.meta.env[key];
}

export const isDateValid = (date: Date) => date instanceof Date && !isNaN(date.valueOf());

export function formatDate(date: string): string {
  return new Date(date).toLocaleDateString("fr-FR", {
    day: "numeric",
    month: "long",
    year: "numeric",
    timeZone: "Europe/Paris"
  });
}

export const formatDateDDmmYYYY = (date: string) => {
  return new Intl.DateTimeFormat("fr-FR", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour12: false,
    timeZone: "Europe/Paris",
  }).format(new Date(date));
};

export const formatTime = (date: Date | string) => {
  return new Date(date).toLocaleTimeString("fr-FR", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/Paris"
  }).replace(":", "h")
}

export function formatDateTime(date: string): string {
  const time = formatTime(date);

  return `${formatDate(date)} à ${time}`;
}

export const processFormDataInputMultipleFiles = <TInput>(input: TInput, key: keyof TInput) => {
  // @ts-expect-error
  return [].concat(input?.[key]?.["[]"] || []);
}

export const inferEnum = <TEnum extends {},>(_enum: TEnum): [keyof TEnum] => {
  return Object.values(_enum) as [keyof TEnum];
}

export const downloadPublicFile = (filename: string, rename?: string) => {
  const link = document.createElement("a");
  link.href = import.meta.env.PROD ? `/files/${filename}` : `/public/files/${filename}`;
  link.download = rename || filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const downloadFileFromLink = (url: string) => {
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}