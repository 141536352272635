import { ActionTable } from "@components/ActionTable";
import { Button } from "@components/buttons/Buttons";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input";
import Modal from "@components/modal/Modal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useModal } from "@hooks/useModal";
import { usePb } from "@hooks/usePb";
import { usePbDownload } from "@hooks/usePbDownload";
import { CompanyDiagnosticState, type CompanyDiagnostic, type Diagnostic } from "@interfaces/diagnostic.interface";
import { SignDiagnosticQuotationSchema } from "@schemas/diagnostic.schema";
import { formatPrice } from "@utils/utils";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import type { z } from "zod";
import "./DiagnosticActionTable.scss";
import { trpc } from "@client/index";
import { CompanyInfosModal } from "@components/modal/CompanyInfosModal";

const formateDate = (date: string) => {
  return new Date(date).toLocaleString('fr-FR', {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
};

interface DiagnosticSignModalProps {
  companyDiagnostic: CompanyDiagnostic;
}

const modalId = (modalId: string, companyDiagnosticId: string) => {
  return `${modalId}::${companyDiagnosticId}`;
};

export const DiagnosticSignModal = ({ companyDiagnostic }: DiagnosticSignModalProps) => {
  const modal = useModal();
  const queryClient = useQueryClient();
  const { saleId } = useParams<{ saleId: string }>();
  const MODAL_ID = modalId(DiagnosticSignModal.name, companyDiagnostic.id);

  const formMethods = useForm<z.infer<typeof SignDiagnosticQuotationSchema>>({
    resolver: zodResolver(SignDiagnosticQuotationSchema),
    defaultValues: {
      quotation: undefined
    }
  });

  const quotation = formMethods.watch("quotation");
  const downloadMutation = usePbDownload();

  const mutation = trpc.sale.companyDiagnostic.uploadSignedDiagnosticQuotation.useMutation({
    onSuccess: () => {
      formMethods.reset();
      modal.close(MODAL_ID);
      queryClient.invalidateQueries({ queryKey: ["diagnostics", saleId] });
      toast.success(`Votre signature a bien été prise en compte`);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const handleSubmit = (values: z.infer<typeof SignDiagnosticQuotationSchema>) => {
    const formData = new FormData();
    formData.set("saleId", saleId!);
    formData.set("companyDiagnosticId", companyDiagnostic.id);
    formData.set("quotation", values["quotation"]);
    mutation.mutate(formData);
  };

  return (
    <Modal id={MODAL_ID} title="Signer le devis de diagnostic">
      <div className="devis-diag--text">
        <label>
          Télécharger le document pour l’imprimer. Le signer lui-même et le scanner pour le déposer à nouveau dans insérer.
        </label>
        <br />
        <p>
          Il sera ensuite automatiquement transmis à l'entreprise concernée. L'entreprise dont le devis n'a pas été accepté sera également automatiquement prévenue.
        </p>
      </div>

      <Form formMethods={formMethods}>
        <div className="modal--actions">
          <Form.Actions>
            <Button name="Télécharger" onClick={() => downloadMutation.mutate({ resource: companyDiagnostic, filename: companyDiagnostic["quotation"] })} />
            <Input.File name="quotation" label="Insérer" disabled={!downloadMutation.isSuccess && companyDiagnostic.state === CompanyDiagnosticState.waiting} accept="application/pdf" asButton />
            <Button name="Envoyer" disabled={!Boolean(quotation)} loading={mutation.isPending} onClick={formMethods.handleSubmit(handleSubmit)} />
          </Form.Actions>
        </div>
      </Form>
    </Modal>
  );
};

interface EstimateActionTableRowProps {
  companyDiagnostic: CompanyDiagnostic;
  hasAlreadySignedACompanyDiagnostic: boolean;
  readOnly: boolean;
}

const CompanyDiagnosticTableRow = ({ hasAlreadySignedACompanyDiagnostic, companyDiagnostic, readOnly = false }: EstimateActionTableRowProps) => {
  const modal = useModal();
  const CompanyInfosModalId = modalId(CompanyInfosModal.name, companyDiagnostic.company);
  const DiagnosticSignModalId = modalId(DiagnosticSignModal.name, companyDiagnostic.id);

  const [isActionMenuOpen, setActionMenuOpen] = useState(false);
  const mutation = usePbDownload();

  const status = companyDiagnostic.state === CompanyDiagnosticState.accepted
    ? "Accepté"
    : companyDiagnostic.state === CompanyDiagnosticState.refused
      ? "Refusé"
      : "En attente";

  return (
    <Fragment>
      <ActionTable.Row>{formateDate(companyDiagnostic.created)}</ActionTable.Row>
      <ActionTable.Row>{companyDiagnostic.expand.company.name}</ActionTable.Row>
      <ActionTable.Row>{formatPrice(companyDiagnostic.price)}</ActionTable.Row>
      <ActionTable.Row>{status}</ActionTable.Row>
      
      {readOnly ? (
        <ActionTable.Row>
          <Button.Small style={{marginBottom: '10px'}} name="Devis" disabled={!companyDiagnostic?.quotation} onClick={() => mutation.mutate({ resource: companyDiagnostic, filename: companyDiagnostic["quotation"] })} />
          <Button.Small style={{marginBottom: '10px'}} name="Diagnostic" disabled={!companyDiagnostic?.diagnosticFile} onClick={() => mutation.mutate({ resource: companyDiagnostic, filename: companyDiagnostic["diagnosticFile"] })} />
          <Button.Small style={{marginBottom: '10px'}} name="Facture" disabled={!companyDiagnostic?.invoice} onClick={() => mutation.mutate({ resource: companyDiagnostic, filename: companyDiagnostic["invoice"] })} />
        </ActionTable.Row>
      ) : (
        <ActionTable.Row>
          <Button.Small className="actions-close" name={`${isActionMenuOpen ? "Fermer" : "Actions"}`} onClick={() => setActionMenuOpen(prevState => !prevState)} />
          {isActionMenuOpen && (
            <div className="actions--menu">
              <Button.Small name="Valider" disabled={hasAlreadySignedACompanyDiagnostic || !companyDiagnostic?.quotation} onClick={() => modal.open(DiagnosticSignModalId)} />
              <Button.Small name="Devis" disabled={!companyDiagnostic?.quotation} onClick={() => mutation.mutate({ resource: companyDiagnostic, filename: companyDiagnostic["quotation"] })} />
              <Button.Small name="Diagnostic" disabled={!companyDiagnostic?.diagnosticFile} onClick={() => mutation.mutate({ resource: companyDiagnostic, filename: companyDiagnostic["diagnosticFile"] })} />
              <Button.Small name="Facture" disabled={!companyDiagnostic?.invoice} onClick={() => mutation.mutate({ resource: companyDiagnostic, filename: companyDiagnostic["invoice"] })} />
              <Button.Small name="Plus d'infos" onClick={() => modal.open(CompanyInfosModalId)} />
            </div>
          )}
        </ActionTable.Row>
      )}
    </Fragment>
  );
};

interface DiagnosticWaitingTableRowProps {
  diagnostic: Diagnostic;
}

const DiagnosticWaitingTableRow = ({ diagnostic }: DiagnosticWaitingTableRowProps) => {
  return (
    <Fragment>
      <ActionTable.Row>{formateDate(diagnostic.created)}</ActionTable.Row>
      <ActionTable.Row>N.C</ActionTable.Row>
      <ActionTable.Row>N.C</ActionTable.Row>
      <ActionTable.Row>En attente du devis</ActionTable.Row>
      <ActionTable.Row>-</ActionTable.Row>
    </Fragment>
  );
};

interface Props {
  saleId: string;
  readOnly?: boolean;
}

export const DiagnosticActionTable = ({ saleId, readOnly = false }: Props) => {
  const pb = usePb();
  const waitingDiagnostics: Diagnostic[] = [];
  const companyDiagnostics: CompanyDiagnostic[] = [];

  const { data: diagnostics = [], isPending } = useQuery<Diagnostic[]>({
    queryKey: ["diagnostics", saleId],
    queryFn: async () => {
      return pb.collection("diagnostics").getFullList({
        filter: `sale = "${saleId}"`,
        expand: "companyDiagnostics_via_diagnostic.company,companyDiagnostics_via_diagnostic.record",
      });
    },
    enabled: !!saleId
  });

  for (const diagnostic of diagnostics) {
    if (Boolean(diagnostic?.expand?.companyDiagnostics_via_diagnostic?.length)) {
      companyDiagnostics.push(...diagnostic.expand.companyDiagnostics_via_diagnostic);
    } else {
      waitingDiagnostics.push(diagnostic);
    }
  }

  const hasAlreadySignedACompanyDiagnostic = !!companyDiagnostics.find(companyDiagnostic => companyDiagnostic.state === CompanyDiagnosticState.accepted);

  if (readOnly) {
    return (
    <Fragment>
    <ActionTable isPending={isPending} headers={['Date', 'Entreprise', 'Montant', 'Statut', 'Actions']} emptyMessage="Pas de demandes de diagnostics en cours.">
      {companyDiagnostics.map(companyDiagnostic => (
        <CompanyDiagnosticTableRow key={companyDiagnostic.id} companyDiagnostic={companyDiagnostic} hasAlreadySignedACompanyDiagnostic={hasAlreadySignedACompanyDiagnostic} readOnly/>
      ))}
    </ActionTable>
    </Fragment>
    )
  }

  return (
    <Fragment>
      <ActionTable isPending={isPending} headers={['Date', 'Entreprise', 'Montant', 'Statut', 'Actions']} emptyMessage="Pas de demandes de diagnostics en cours.">
        {companyDiagnostics.map(companyDiagnostic => (
          <CompanyDiagnosticTableRow key={companyDiagnostic.id} companyDiagnostic={companyDiagnostic} hasAlreadySignedACompanyDiagnostic={hasAlreadySignedACompanyDiagnostic} />
        ))}
        {waitingDiagnostics.map(diagnostic => (
          <DiagnosticWaitingTableRow key={diagnostic.id} diagnostic={diagnostic} />
        ))}
      </ActionTable>

      {/* Rendu des modales en dehors du tableau */}
      {companyDiagnostics.map(companyDiagnostic => (
        <Fragment key={companyDiagnostic.id}>
          <DiagnosticSignModal companyDiagnostic={companyDiagnostic} />
          <CompanyInfosModal id={modalId(CompanyInfosModal.name, companyDiagnostic.company)} company={companyDiagnostic.expand.company} />
        </Fragment>
      ))}
    </Fragment>
  );
};
