import { Button } from "@components/buttons/Buttons";
import "./WorkEstimate.scss"
export const WorkEstimate = () => {
  return (
      <div className="work-estimate">
        <img src="/assets/appicons/plateo-logo.svg" alt="Logo Plateo"/>
        <Button
          name="Cliquez ici pour accéder au service Plateo"
          onClick={() => window.open("https://tally.so/r/3y695p")} />
      </div>
    
  );
};
