import type { ButtonHTMLAttributes } from "react";
import { Link, type LinkProps } from "react-router-dom";
import { classNames } from "../../utils/utils";
import "./buttons.scss";

export interface BaseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  name: string;
  full?: boolean;
  loading?: boolean;
}

export const Button = ({ loading, full, name, ...props }: BaseButtonProps) => {
  return (
    <button type="button" {...props} className={classNames("c-btn", props.className || "", full ? "full" : "")} >
      {loading ? <div className="ld ld-ring ld-cycle"></div> : name}
    </button>
  )
};

interface ButtonSmallProps extends BaseButtonProps {
  valid?: boolean;
  unvalid?: boolean;
  xs?: boolean;
  dark?: boolean;
}

Button.Small = (props: ButtonSmallProps) => {
  const { valid, unvalid, xs, dark, ...buttonProps } = props;

  const _classNames = (['valid', 'unvalid', 'xs', 'dark'] as const)
    .filter((className) => !!props[className])
    .join(' ');

  return (<Button className={classNames("small", _classNames)} {...buttonProps} />)
}

interface ButtonLinkProps extends LinkProps {
  dark?: boolean;
}

Button.Link = ({ dark, title, ...props }: ButtonLinkProps) => {
  return (
    <Link {...props} className={classNames("btn--link", dark ? "dark" : "")}>
      {title}
    </Link>
  )
}

interface ButtonIconProps extends BaseButtonProps {
  icon?: string;
}

Button.Icon = ({ icon, ...props }: ButtonIconProps) => {
  return (<Button {...props} />)
}

