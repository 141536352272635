import { expand } from '@utils/pb';
import { Table } from "@components/table/Table";
import { usePb } from '@hooks/usePb';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { createColumnHelper, getCoreRowModel, getExpandedRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable, type ColumnDef, type PaginationState, type Row, type SortingState } from '@tanstack/react-table';
import type { ListResult } from 'pocketbase';
import type { Protectee } from '@interfaces/protectee.interface';
import { SaleState, type Sale } from '@interfaces/sale.interface';
import type { User } from '@interfaces/user.interface';
import { formatFullAddress } from '@interfaces/address.interface';
import { formatDateDDmmYYYY } from '@utils/utils';
import { Button } from '@components/buttons/Buttons';
import { trpc } from '@client/index';
import { toast } from 'react-toastify';

// Utilisation de ColumnHelper pour construire des colonnes dynamiques dans React Table
const columnHelper = createColumnHelper<Sale>();

// Fonction pour obtenir la traduction des états de vente en français
const getSaleState = (state: SaleState) => {
  switch (state) {
    case SaleState.started:
      return 'En cours';
    case SaleState.finalized:
      return 'Finalisé';
    case SaleState.canceled:
      return 'Annulé';
    case SaleState.stopped:
      return 'Arrêté';
    case SaleState.archived:
      return 'Archivé';
    default:
      return 'En cours';
  }
};

// Liste des états de vente avec leurs labels en français
const saleStates = [
  { value: SaleState.started, label: 'En cours' },
  { value: SaleState.finalized, label: 'Finalisé' },
  { value: SaleState.canceled, label: 'Annulé' },
  { value: SaleState.stopped, label: 'Arrêté' },
  { value: SaleState.archived, label: 'Archivé' },
];

export const SaleTable = () => {
  const pb = usePb();
  const queryClient = useQueryClient();

  // État pour la pagination
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const page = pageIndex + 1;

  // Récupérer la liste des ventes depuis PocketBase via la query `sales`
  const { data, isFetching } = useQuery<ListResult<Sale>>({
    queryKey: ["sales", page, pageSize],
    queryFn: async () => {
      return pb.collection("sales").getList(page, pageSize, {
        expand: expand([
          "protector",
          "protectee",
          "address",
          "salePieces_via_sale",
          "mandates_via_sale",
          "companyOffers_via_sale",
          "estimates_via_sale",
          "diagnostics_via_sale",
          "energyAudits_via_sale",
          "companyReports_via_sale"
        ])
      });
    },
  });

  // Mutation TRPC pour mettre à jour l'état d'une vente
  const updateSaleStatusMutation = trpc.sale.updateSaleStatus.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["sales"]); // Réactualise les ventes après mise à jour
      toast.success("Le statut de la vente a été mis à jour.");
    },
    onError: () => {
      toast.error("Une erreur est survenue lors de la mise à jour.");
    },
  });

  // Extraction des ventes et du nombre total de pages
  const { items: sales = [], totalPages } = data! || {};

  // Colonnes du tableau de ventes
  const columns = React.useMemo<ColumnDef<Sale>[]>(
    () => [
      columnHelper.accessor("expand.protector", {
        header: "MANDATAIRE",
        enableSorting: true,
        enableMultiSort: true,
        cell: (ctx) => {
          const { firstname, lastname } = ctx.getValue();
          return `${lastname} ${firstname}`;
        },
      }),
      columnHelper.accessor("expand.protectee", {
        header: "MAJEUR",
        enableSorting: true,
        enableMultiSort: true,
        cell: (ctx) => {
          const { firstname, lastname } = ctx.getValue();
          return <Table.Cell value={`${lastname} ${firstname}`} bold />;
        },
      }),
      columnHelper.display({
        header: "ADRESSE",
        cell: ({ row }) => {
          return formatFullAddress(row.original.expand.address);
        },
      }),
      columnHelper.accessor("created", {
        header: "DATE",
        enableSorting: true,
        cell: ({ row }) => {
          return <Table.Cell value={formatDateDDmmYYYY(row.original.created)} />;
        },
      }),
      columnHelper.display({
        header: "ETAT",
        cell: ({ row }) => {
          const currentState = row.original.state || SaleState.started;

          const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            const newState = event.target.value as SaleState;
            updateSaleStatusMutation.mutate({ saleId: row.original.id, newState }); // Appeler la mutation TRPC pour changer le statut
          };

          return (
            <select value={currentState} onChange={handleStateChange}>
              {saleStates.map((state) => (
                <option key={state.value} value={state.value}>
                  {state.label}
                </option>
              ))}
            </select>
          );
        },
      }),
      // columnHelper.display({
      //   id: "actions",
      //   header: "Actions",
      //   cell: ({ row }) => (
      //     <Button.Small
      //       name={row.getIsExpanded() ? "Fermer" : "Détails"}
      //       disabled={false}
      //       onClick={() => row.toggleExpanded()}
      //     />
      //   ),
      // }),
    ],
    [updateSaleStatusMutation]
  );

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  // Configuration de React Table
  const table = useReactTable({
    data: sales,
    columns,
    enableMultiSort: true,
    manualPagination: true,
    pageCount: totalPages,
    state: {
      pagination,
      sorting,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <Table table={table} isFetching={isFetching}>
      {/* Place ici une logique pour les sous-tables si nécessaire */}
    </Table>
  );
};
