import React, { Children, useState } from "react";
import "./ActionTable.scss";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Button } from "./buttons/Buttons";
import { Helper } from "./Helper";

interface ActionTableProps {
  headers?: string[];
  isPending?: boolean;
  emptyMessage?: string;
  pendingRowCount?: number;
  children: React.ReactNode | React.ReactNode[];
}

export const ActionTable = ({ children, isPending, headers, pendingRowCount = 1, emptyMessage = "Aucun résultats" }: ActionTableProps) => {
  const isEmpty = !Children.count(children) && !isPending;

  return (
    <div className="table--container">
      <table className="action-table">
        {headers && (
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
        )}

        {isPending ? (
          <tbody>
            <tr>
              <td colSpan={headers ? headers.length : 1}>
                <Skeleton
                  baseColor="#F5F5F5"
                  highlightColor="#FFFFFF"
                  style={{ borderRadius: "6px", height: 65 }}
                  count={pendingRowCount} />
              </td>
            </tr>
          </tbody>
        ) : isEmpty ? (
          <tbody>
            <tr>
              <td colSpan={headers ? headers.length : 1} style={{ textAlign: "center" }}>
                {emptyMessage}
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {Children.map(children, (element, index) => (
              <tr key={index}>{element}</tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

interface ActionTableRowProps {
  children: React.ReactNode;
}

ActionTable.Row = ({ children }: ActionTableRowProps) => {
  return (
    <td className="action-table-item-row">
      {children}
    </td>
  );
}

interface ActionTableActionsMenuProps {
  children: React.ReactNode;
}

ActionTable.ActionsButton = ({ children }: ActionTableActionsMenuProps) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <ActionTable.Row>
      <Button.Small
        className="actions-close"
        name={isOpen ? "Fermer" : "Actions"}
        onClick={() => setOpen(prevState => !prevState)} />

      {isOpen && (
        <>
          {children}
        </>
      )}
    </ActionTable.Row>
  )
}
