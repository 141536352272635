import { z } from "zod";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Form } from "@components/form/Form";
import { Button } from "@components/buttons/Buttons";
import { Toggle } from "@components/inputs/Toggle";
import { zodResolver } from "@hookform/resolvers/zod";
import "./EstimateWithoutYouConfirm.scss"
import { ExternalNotificationCard } from "@components/ExternalNotificationCard";
import { ConfirmInterestSchema } from "@schemas/common.schema";
import { trpc } from "@client/index";
import { getQueryKey } from "@trpc/react-query";

export const EstimateWithoutYouConfirm = () => {
  const queryClient = useQueryClient();

  const { estimateId } = useParams<{ agencyId: string; estimateId: string; }>();

  const formMethods = useForm<z.infer<typeof ConfirmInterestSchema>>({
    resolver: zodResolver(ConfirmInterestSchema),
    defaultValues: {
      confirmation: "yes",
    },
  });

  const guardQueryResult = trpc.external.estimate.withoutYou.confirmGetResources.useQuery({ estimateId: estimateId! }, {
    retry: false
  })

  const errorStatus = guardQueryResult.error?.data?.httpStatus;

  const mutation = trpc.external.estimate.withoutYou.confirm.useMutation({
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.external.estimate.withoutYou.confirmGetResources) });
    }
  });

  if (mutation.isSuccess) {
    return <ExternalNotificationCard title="Merci d'avoir indiqué votre intérêt pour cette demande d'estimation" />
  }

  if (errorStatus === 403 || errorStatus === 404) {
    return <ExternalNotificationCard title="Cette page n'est plus disponible" />
  }

  if (typeof errorStatus === "number") {
    return <ExternalNotificationCard title="Une erreur est survenue. Merci de réessayer plus tard." />
  }

  if (!guardQueryResult.data) {
    return null;
  }

  const { sale } = guardQueryResult.data;

  return (
    <Form.DefaultLayout formMethods={formMethods}>
      <div className="estimate-without--container">
        <h3>Nouvelle demande d’estimation dans le cadre d’un projet de vente</h3>
        <small><b>Ville:</b> {sale.expand.address.city}</small>

        <span>Merci de confirmer votre intérêt pour la demande d'estimation</span>

        <Toggle
          name="confirmation"
          required
          darker
          choices={[
            {
              label: "Oui",
              value: "yes",
            },
            {
              label: "Non",
              value: "no",
            },
          ]} />

        <p>Une fois votre choix confirmé, Vous recevrez les instructions pour organiser la visite d’estimation. </p>

        <p>Merci de faire les démarches dans les meilleurs délais afin de permettre l’avancée du dossier.</p>
      </div>

        <div className="actions--mail">
      <Form.Actions centered>
        <Button
          name="Valider"
          onClick={formMethods.handleSubmit((values) => mutation.mutate({ estimateId: estimateId!, ...values }))}
          loading={mutation.isPending} />
      </Form.Actions>
      </div>
    </Form.DefaultLayout>
  );
};
