import "./SaleAside.scss";

export const SaleContactAdminAside = () => {
  return (
    <div className={"c-side"}>
      <div className="c-side--header textuel">
      <h1>
        NOUS CONTACTER

        <br/><br/>
        06 69 54 84 85
      </h1>
      </div>
    </div>
  );
};
