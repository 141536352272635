import { Button } from "@components/buttons/Buttons";
import "./EstimateOnline.scss";

export const EstimateOnlineAside = () => {
  return (
    <div className={"c-side textuel"}>
      <div className="c-side--header textuel">
      <h1>
       AU SUJET DES PRÉ-ESTIMATIONS
      </h1>
      <p>
        Les pré-estimations sont données à titre 
        indicatif et ne sont pas valables dans le cadre 
        d'un projet de vente.
      </p>
      {/* <p>
        Pour avoir une estimation complète faites par 
        des agences locales cliquez sur :
      </p>
      <Button
        name="Lancer une estimation via agence"
        // onClick={formMethods.handleSubmit((values) => mutate(values))}
        // loading={isFetching}
      /> */}
      </div>
    </div>
  );
};
