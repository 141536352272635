import type { InputHTMLAttributes } from "react";
import "./Toggle.scss";
import { useInput } from "@hooks/useInput";
import { classNames } from "@utils/utils";

interface Choice {
  label: string;
  value: string | number;
}

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  choices: Choice[];
  name: string;
  darker?: boolean;
  dark?: boolean;
}

const getInputId = (name: string, value: string | number) => `${name}--${value.toString()}`;

export const Toggle = ({ choices, name, dark, darker, type = "radio", ...rest }: Props) => {
  const { field } = useInput(name);

  return (
    <ul className={classNames("input-toggle-form", darker ? "dark-toggle" : "")}>
      {choices.map((choice) => (
        <li key={choice.value}>
          <input
            {...rest}
            id={getInputId(name, choice.value)}
            type={type}
            checked={choice.value === field.value}
            onChange={() => field.onChange(choice.value)}
            onBlur={field.onBlur}
            value={choice.value}
            name={field.name}
            ref={field.ref}
          />
          <label
            className={dark ? "dark" : ""}
            htmlFor={getInputId(name, choice.value)} >
            {choice.label}
          </label>
        </li>
      ))}
    </ul>
  );
};
