import { z } from "zod";
import { toast } from "react-toastify";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { ContactAdminSchema } from "@schemas/contact.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { usePb } from "@hooks/usePb";
import { Form } from "@components/form/Form";
import { Button } from "@components/buttons/Buttons";
import Input from "@components/inputs/Input";
import { trpc } from "@client/index"; // Assure-toi que ce chemin est correct

export const ContactAdmin = () => {
  const pb = usePb();
  const queryClient = useQueryClient();
  const userId = pb.authStore.model?.id;

  const formMethods = useForm<z.infer<typeof ContactAdminSchema>>({
    resolver: zodResolver(ContactAdminSchema),
    defaultValues: {
      phone: "",
      message: "",
    },
  });

  // Utilise `useMutation` de tRPC pour la mutation tRPC
  const sendContactMailMutation = trpc.mail.sendContactMail.useMutation();

  const { mutate, isPending } = useMutation({
    mutationFn: async (body: z.infer<typeof ContactAdminSchema>) => {
      try {
        // Appel à la collection adminContacts dans PocketBase
        const adminContactResponse = await pb.collection("adminContacts").create({
          ...body,
          protector: userId,
        });

        // Appel à la mutation tRPC pour envoyer l'email
        const sendMailResponse = await sendContactMailMutation.mutateAsync(body);

        return { adminContactResponse, sendMailResponse };
      } catch (error) {
        throw new Error("Échec de l'envoi du message");
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminContacts"] });
      formMethods.reset();
      toast.success("Votre message a bien été envoyé.");
    },
    onError: (error) => {
      toast.error(`Une erreur est survenue lors de l'envoi du message : ${error.message}`);
    },
  });

  return (
    <Form.DefaultLayout
      formMethods={formMethods}
      title="CONTACTEZ L'ADMINISTRATION"
      subtitle="Veuillez remplir les champs suivants pour envoyer un message à l'administration."
    >
      <Input border name="phone" placeholder="Téléphone" />
      <Input.Textarea border name="message" placeholder="Message" />

      <Button
        name="Envoyer"
        onClick={formMethods.handleSubmit((values) => mutate(values))}
        loading={isPending}
      />
    </Form.DefaultLayout>
  );
};
