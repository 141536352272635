import { Sidebar } from "@components/Sidebar";
import { SaleWorkEstimateAside } from "@components/features/dashboard/sales/SaleWorkEstimateAside";
import { Fragment } from "react";
import { Outlet } from "react-router-dom";

export const LayoutWorkEstimate = () => {
  return (
    <Fragment>
      <section className="fx" style={{ width: "98%" }}>
        <Sidebar />

        <SaleWorkEstimateAside />

        <Outlet />
      </section>
    </Fragment>
  );
};
