import "./Warning.scss";

interface WarningProps {
  message: string;
  redMessage?: string;
}

export const Warning = ({ message, redMessage }: WarningProps) => {
  return (
    <div className="modal--warning">
      <p>
        {message}

        <span> {redMessage}</span>
      </p>
    </div>
  );
};