import { useInput } from "@hooks/useInput";
import type { SelectHTMLAttributes } from "react";
import "./inputs.scss"

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  border?: boolean;
  options: {
    label: string;
    disabled?: boolean;
    value: string;
  }[];
}

export const Select = ({ name, border, options, className, ...rest }: Props) => {
  const { field } = useInput(name);

  const classes = ["c-input", className, border ? "border" : ""].join(" ");

  return (
    <select
      className={classes}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      name={field.name}
      ref={field.ref}
      {...rest}>
      {options.map(option => (
        <option
          key={option.value}
          value={option.value}
          disabled={option.disabled}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
