import "./SaleAside.scss";

export const SaleWorkEstimateAside = () => {
  return (
    <div className={"c-side textuel"}>
      <div className="c-side--header textuel">
        <h1>
          AU SUJET DES DEVIS POUR TRAVAUX
        </h1>
        <p>
          Tel un Maître d'Oeuvre, Plateo offre un accompagnement indépendant pour optimiser les dépenses en veillant à réaliser uniquement les travaux nécessaires :
        </p>
        <p>
          Rénovation énergétique, remise en état entre 2 locataires ou en fin de bail, adaptation du logement à la perte d'autonomie, vérification de la sécurité et de la salubrité des logements.
        </p>
        <p>
          Ces dernières prestations sont réalisables sur toute la France.
        </p>
        <p>
          En plus, sur les départements du 13 et 84, gestion des dépannages et des travaux sur devis avec des artisans sélectionnés.
        </p>
      </div>
    </div>
  );
};
