import type { Company } from "./company.interface";
import type { Sale } from "./sale.interface";
import type { Base, ID } from "./shared.interface";
import type { BookableRecord } from "./slot.interface";

export type Storage = {
  invoiceContactEmail: string;
  keyPickupAddress: string;
  keyDropoffAddress: string;
  contactPerson: string;
  instructions: string;
  signedCompanyStorage: ID;
  sale: ID;
  expand: {
    sale: Sale;
    signedCompanyStorage: CompanyStorage;
    companyStorages_via_storage: CompanyStorage[];
  }
} & Base & BookableRecord;

export enum CompanyStorageState {
  waiting = "waiting",
  accepted = "accepted",
  refused = "refused"
}

export interface CompanyStorage extends Base {
  company: ID;
  storage: ID;
  quotation: string;
  invoice: string;
  price: number;
  state: CompanyStorageState;
  expand: {
    storage: Storage;
    company: Company;
  }
}