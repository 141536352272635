import { classNames } from "@utils/utils";
import "./Badge.scss"
interface Props {
  name: string;
  status?: "in-progress" | "finished";
}

export const Badge = ({ name, status = "in-progress" }: Props) => {
  return <span className={classNames("badge", status)}>
    {name}
  </span>
}