import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "./buttons/Buttons";
import "./Sidebar.scss";
import { classNames } from "src/utils/utils";
import { useMe } from "@hooks/usePb";
import { useState } from "react";
import { useModal } from "@hooks/useModal";
import { ProtecteeCreateModal } from "./features/dashboard/protectees/ProtecteeCreateModal";
import { IS_SALE_FILE_REGEX } from "@hooks/useIsSaleFile";

interface Props {
  dark?: boolean;
  name?: string;
  role?: string;
  scroll?: boolean;
}

const menuUser = [
  {
    icon: "ico--pending",
    name: "Ventes en cours",
    href: "/dashboard",
    pathRegex: /(^\/dashboard$)|(^\/dashboard\/sales\/.*)/
  },
  {
    icon: "ico--folder",
    name: "Ventes achevées",
    href: "/dashboard/sales-file",
    pathRegex: IS_SALE_FILE_REGEX
  },
  {
    icon: "ico--quote",
    name: "Pré-estimation en ligne",
    href: "/dashboard/estimate-online",
    pathRegex: /(^\/dashboard\/estimate-online)/
  },
  {
    icon: "ico--foreign",
    name: "Biens à l'étranger",
    href: "/dashboard/good-abroad",
    pathRegex: /(^\/dashboard\/good-abroad)/
  },
  {
    icon: "ico--works",
    name: "Devis travaux",
    href: "/dashboard/work-estimate",
    pathRegex: /(^\/dashboard\/work-estimate)/
  },
  {
    icon: "ico--contact",
    name: "Contact",
    href: "/dashboard/contact",
    pathRegex: /(^\/dashboard\/contact)/
  },
];

const roles = {
  attendant: "Mandataire judiciaire",
  representative: "Préposée d'établissement",
  tutelary: "Assocation tutélaire",
};

const SidebarItem = ({ item }: { item: (typeof menuUser)[number] }) => {
  const location = useLocation();
  const [isHovered, setHovered] = useState(false);

  const isActive = item.pathRegex.test(location.pathname);

  const icon = `${item.icon}${(isHovered || isActive) ? "_blue" : "_white"}`;

  return (
    <li
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      <Link to={item.href} className={classNames("nav", isActive ? "active" : "", icon)}>
        {item.name}
      </Link>
    </li>
  )
}

export const Sidebar = (props: Props) => {
  const me = useMe();

  const modal = useModal();

  const navigate = useNavigate();

  const name = `${me.lastname} ${me.firstname}`;

  return (
    <>
    <header
      className={classNames(
        "sidebar",
        props.dark ? "dark" : "",
        !!props.scroll ? "scroll" : ""
      )}>
      <figure>
        <img src="/assets/icons/logo.svg" alt="Logo" />
      </figure>

      <div className="name cursor-pointer" onClick={() => navigate("/dashboard/me")}>
        <span>{name}</span>
        <small>{roles[me.role]}</small>
      </div>

      <Button.Icon name="Lancer une vente" onClick={() => modal.open(ProtecteeCreateModal.name)} />

      <ul className={classNames(props.dark ? "dark" : "")}>
        {menuUser.map((item) => (
          <SidebarItem key={item.name} item={item} />
        ))}
      </ul>

      <ProtecteeCreateModal />
    </header>

    <ul className="legals">
      <li><a href="https://plateforme-athena.com/mentions-legales" target="_blank">Mentions légales</a></li>
      <li><a href="https://assets.plateforme-athena.com/athena-assets/cgu.pdf" target="_blank">CGU</a></li>
      <li><a href="https://assets.plateforme-athena.com/athena-assets/confidentialite.pdf" target="_blank">Politique de confidentialité</a></li>
    </ul>
  </>
  );
};
