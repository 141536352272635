import type { z } from "zod";
import type { Sale } from "@interfaces/sale.interface";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Form } from "@components/form/Form";
import { Button } from "@components/buttons/Buttons";
import Input from "@components/inputs/Input";
import { EstimateDepositModal } from "./EstimateDepositModal";
import { CreateActualisedEstimateCompanySchema } from "@schemas/estimate.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useModal } from "@hooks/useModal";
import "./EstimateDeposit.scss"
import { Fragment } from "react";
import { formatFullAddress } from "@interfaces/address.interface";
import { ExternalNotificationCard } from "@components/ExternalNotificationCard";
import { trpc } from "@client/index";
import { getQueryKey } from "@trpc/react-query";

interface SaleDescriptionProps {
  sale: Sale;
}

const SaleDescription = ({ sale }: SaleDescriptionProps) => {
  return (
    <div className="mailTitle">
      <h2>Dépôt d'estimation</h2>
      <p>Adresse : {formatFullAddress(sale.expand.address)}</p>
      <span>Une fois l’estimation réalisée, merci de remplir les champs ci-dessous et d’insérer le document</span>
    </div>
  )
}

export const ActualisedEstimateDeposit = () => {
  const modal = useModal();
  const queryClient = useQueryClient();

  const { estimateId } = useParams<{ estimateId: string; }>();

  const formMethods = useForm<z.infer<typeof CreateActualisedEstimateCompanySchema>>({
    resolver: zodResolver(CreateActualisedEstimateCompanySchema),
    defaultValues: {
      sellerPriceLow: "",
      sellerPriceHigh: "",
      report: undefined,
    },
  });

  const { error, data } = trpc.external.estimate.depositGetResources.useQuery({ estimateId: estimateId! }, { retry: false });

  const errorStatus = error?.data?.httpStatus;

  const depositMutation = trpc.external.estimate.depositActualised.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.external.estimate.depositGetResources) });

      modal.close(EstimateDepositModal.name);
    },
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    }
  });

  if (depositMutation.isSuccess) {
    return <ExternalNotificationCard title="Merci d'avoir déposé votre estimation!" />
  }

  if (errorStatus === 403 || errorStatus === 404) {
    return <ExternalNotificationCard title="Cette page n'est plus disponible" />
  }

  if (typeof errorStatus === "number") {
    return <ExternalNotificationCard title="Une erreur est survenue. Merci de réessayer plus tard." />
  }

  if (!data) {
    return null;
  }

  const { sale } = data;

  const onSubmit = async (body: z.infer<typeof CreateActualisedEstimateCompanySchema>) => {
    const formData = new FormData();

    formData.set("estimateId", estimateId!);

    for (const name in body) {
      // @ts-expect-error
      formData.append(name, body[name]);
    }

    depositMutation.mutate(formData);
  }

  return (
    <Fragment>
      <Form.DefaultLayout formMethods={formMethods}>

        <SaleDescription sale={sale as Sale} />

        <div className="mailForm last">
          <h3>Estimation du bien</h3>

          <Input
            name="sellerPriceLow"
            border
            min={1}
            placeholder="Prix net vendeur (fourchette basse)"
            type="number" />

          <Input
            name="sellerPriceHigh"
            border
            min={1}
            placeholder="Prix net vendeur (fourchette haute)"
            type="number" />

          <Input.File
            name="report"
            label="Insérer l'estimation réalisée en PDF"
            accept="application/pdf" />
        </div>

        <div className="actions--mail">
          <Form.Actions centered>
            <Button
              name="Déposer l'estimation"
              onClick={() => modal.open(EstimateDepositModal.name)} />
          </Form.Actions>
        </div>

      </Form.DefaultLayout>

      <EstimateDepositModal
        handleForm={formMethods.handleSubmit(onSubmit)}
        isFetching={depositMutation.isPending} />

    </Fragment>
  );
};
