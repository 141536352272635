import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { usePb } from "./usePb";

export const usePbDownloadZip = () => {
  const pb = usePb();

  return useMutation({
    mutationFn: async ({ resource, filename }: { resource: any, filename: string }) => {
      const fileToken = await pb.files.getToken();

      const downloadUrl = pb.files.getUrl(resource, filename, { token: fileToken, download: true });

      const response = await fetch(downloadUrl);
      if (!response.ok) {
        throw new Error("Download failed");
      }

      return await response.blob(); // Retourne le Blob
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });
}
