import React, { useState } from "react";
import "./AlertsPanel.scss"; // Ajoutez vos styles ici
import { trpc } from "@client/index"; // Assurez-vous d'importer correctement le client TRPC
import { useNavigate } from "react-router-dom"; // Importer useNavigate pour rediriger l'utilisateur

const AlertsPanel = () => {
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("unread"); // Onglet actif : "unread" ou "read"
  const navigate = useNavigate(); // Utiliser useNavigate pour rediriger

  // Appel à la route TRPC pour récupérer toutes les alertes
  const { data, isLoading, refetch } = trpc.alert.getAlerts.useQuery();

  // Définir la mutation pour mettre à jour l'état d'une alerte
  const updateAlertMutation = trpc.alert.updateAlert.useMutation({
    onSuccess: () => {
      refetch(); // Rafraîchir la liste après la mise à jour
    },
  });

  if (isLoading) {
    return <p>Chargement des alertes...</p>;
  }

  // Séparer les alertes en non lues et lues
  const unreadAlerts = data?.items?.filter((alert) => alert.state !== "closed" && alert.admin) || [];
  const readAlerts = data?.items?.filter((alert) => alert.state === "closed" && alert.admin) || [];

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  // Fonction pour marquer une notification comme "fermée" et rediriger l'utilisateur
  const closeAlert = (id: string, collectionName: string, recordId: string) => {
    navigate(`/admin/${collectionName}/${recordId}`);
    updateAlertMutation.mutate({ id, state: "closed" });
    setShowModal(!showModal);
  };

  // Fonction pour rouvrir une notification
  const reopenAlert = (id: string) => {
    updateAlertMutation.mutate({ id, state: "new" });
  };

  return (
    <div>
      {/* Bouton d'alertes avec le nombre d'alertes non lues */}
      <button className="alert-button" onClick={toggleModal}>
        Alertes ({unreadAlerts.length})
      </button>

      {/* Modal des alertes */}
      {showModal && (
        <div className="modal-overlay" onClick={toggleModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {/* Système d'onglets */}
            <div className="tabs">
              <button
                className={activeTab === "unread" ? "active" : ""}
                onClick={() => setActiveTab("unread")}
              >
                Non lues ({unreadAlerts.length})
              </button>
              <button
                className={activeTab === "read" ? "active" : ""}
                onClick={() => setActiveTab("read")}
              >
                Déjà lues ({readAlerts.length})
              </button>
            </div><br/>

            {/* Affichage des notifications en fonction de l'onglet actif */}
            {activeTab === "unread" && (
              <div className="alert-list">
                {unreadAlerts.length === 0 ? (
                  <p><br/>Aucune alerte non lue</p>
                ) : (
                  unreadAlerts.map((notif) => (
                    <div key={notif.id} className="notification">
                      <a
                        onClick={() => closeAlert(notif.id, notif.recordCollectionName, notif.recordId)}
                      >
                        {notif.description}
                      </a>
                      <p>{new Date(notif.created).toLocaleDateString()}</p>
                    </div>
                  ))
                )}
              </div>
            )}

            {activeTab === "read" && (
              <div className="alert-list">
                {readAlerts.length === 0 ? (
                  <p><br/>Aucune alerte lue</p>
                ) : (
                  readAlerts.map((notif) => (
                    <div key={notif.id} className="notification">
                      <a
                        onClick={() => reopenAlert(notif.id)}
                      >
                        {notif.description}
                      </a>
                      <p>{new Date(notif.created).toLocaleDateString()}</p>
                    </div>
                  ))
                )}
              </div>
            )}

            <button onClick={toggleModal}>Fermer</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AlertsPanel;
