import { Outlet } from "react-router-dom";
import "./AuthLayout.scss";
import { Fragment } from "react";

export const AuthLayout = () => {
  return (
    <Fragment>
      <div className='auth--layout'>
        <figure>
          <img src="/assets/appicons/logo.svg" alt="logo" />
        </figure>
      </div>

      <Outlet />
    </Fragment>
  )
}