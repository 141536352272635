import { ActionTable } from "@components/ActionTable";
import { Button } from "@components/buttons/Buttons";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input";
import Modal from "@components/modal/Modal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useModal } from "@hooks/useModal";
import { usePb } from "@hooks/usePb";
import { usePbDownload } from "@hooks/usePbDownload";
import { SignDiagnosticQuotationSchema } from "@schemas/diagnostic.schema";
import { formatPrice } from "@utils/utils";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import type { z } from "zod";
import "./EnergyAuditActionTable.scss";
import { trpc } from "@client/index";
import { CompanyEnergyAuditState, type CompanyEnergyAudit, type EnergyAudit } from "@interfaces/energyAudit.interface";
import { expand } from "@utils/pb";
import { CompanyInfosModal } from "@components/modal/CompanyInfosModal";

const formateDate = (date: string) => {
  return new Date(date).toLocaleString('fr-FR', {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
};

interface EnergyAuditSignModalProps {
  companyEnergyAudit: CompanyEnergyAudit;
}

const modalId = (modalId: string, companyEnergyAuditId: string) => {
  return `${modalId}::${companyEnergyAuditId}`;
};

export const EnergyAuditSignModal = ({ companyEnergyAudit }: EnergyAuditSignModalProps) => {
  const modal = useModal();
  const queryClient = useQueryClient();
  const { saleId } = useParams<{ saleId: string }>();
  const MODAL_ID = modalId(EnergyAuditSignModal.name, companyEnergyAudit.id);

  const formMethods = useForm<z.infer<typeof SignDiagnosticQuotationSchema>>({
    resolver: zodResolver(SignDiagnosticQuotationSchema),
    defaultValues: {
      quotation: undefined
    }
  });

  const quotation = formMethods.watch("quotation");
  const downloadMutation = usePbDownload();

  const mutation = trpc.sale.energyAudit.uploadSignedEnergyAuditQuotation.useMutation({
    onSuccess: () => {
      formMethods.reset();
      modal.close(MODAL_ID);
      queryClient.invalidateQueries({ queryKey: ["energyAudits", saleId] });
      queryClient.invalidateQueries({ queryKey: ["companyEnergyAudits", saleId] });
      toast.success(`Votre signature a bien été prise en compte`);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const handleSubmit = (values: z.infer<typeof SignDiagnosticQuotationSchema>) => {
    const formData = new FormData();
    formData.set("saleId", saleId!);
    formData.set("companyEnergyAuditId", companyEnergyAudit.id);
    formData.set("quotation", values["quotation"]);
    mutation.mutate(formData);
  };

  return (
    <Modal id={MODAL_ID} title="Signer le devis d'audit énergétique">
      <div className="devis-diag--text">
        <label>
          Télécharger le document pour l’imprimer. Le signer et le scanner pour le déposer à nouveau à l'aide du bouton insérer.
        </label>
        <br />
        <p>
          Il sera ensuite automatiquement transmis à l'entreprise concernée. L'entreprise dont le devis n'a pas été accepté sera également automatiquement prévenue.
        </p>
      </div>

      <Form formMethods={formMethods}>
        <div className="modal--actions">
          <Form.Actions>
            <Button name="Télécharger" onClick={() => downloadMutation.mutate({ resource: companyEnergyAudit, filename: companyEnergyAudit["quotation"] })} />
            <Input.File name="quotation" label="Insérer" disabled={!downloadMutation.isSuccess && companyEnergyAudit.state === CompanyEnergyAuditState.waiting} accept="application/pdf" asButton />
            <Button name="Envoyer" disabled={!Boolean(quotation)} loading={mutation.isPending} onClick={formMethods.handleSubmit(handleSubmit)} />
          </Form.Actions>
        </div>
      </Form>
    </Modal>
  );
};

interface EnergyAuditActionTableRowProps {
  companyEnergyAudit: CompanyEnergyAudit;
  readOnly: boolean;
}

const EnergyAuditActionTableRow = ({ companyEnergyAudit, readOnly = false }: EnergyAuditActionTableRowProps) => {
  const modal = useModal();
  const CompanyInfosModalId = modalId(CompanyInfosModal.name, companyEnergyAudit.company);
  const EnergyAuditSignModalId = modalId(EnergyAuditSignModal.name, companyEnergyAudit.id);

  const [isActionMenuOpen, setActionMenuOpen] = useState(false);
  const mutation = usePbDownload();

  const status = companyEnergyAudit.state === CompanyEnergyAuditState.accepted
    ? "Accepté"
    : companyEnergyAudit.state === CompanyEnergyAuditState.refused
      ? "Refusé"
      : "En attente";

  return (
    <Fragment>
      <ActionTable.Row>{formateDate(companyEnergyAudit.created)}</ActionTable.Row>
      <ActionTable.Row>{companyEnergyAudit.expand.company.name}</ActionTable.Row>
      <ActionTable.Row>{formatPrice(companyEnergyAudit.price)}</ActionTable.Row>
      <ActionTable.Row>{status}</ActionTable.Row>
      {readOnly ?
        <ActionTable.Row>
            <Button.Small style={{marginBottom: '10px'}} name="Devis" disabled={!companyEnergyAudit?.quotation} onClick={() => mutation.mutate({ resource: companyEnergyAudit, filename: companyEnergyAudit["quotation"] })} />
            <Button.Small style={{marginBottom: '10px'}} name="Audit" disabled={!companyEnergyAudit?.energyAuditFile} onClick={() => mutation.mutate({ resource: companyEnergyAudit, filename: companyEnergyAudit["energyAuditFile"] })} />
            <Button.Small style={{marginBottom: '10px'}} name="Facture" disabled={!companyEnergyAudit?.invoice} onClick={() => mutation.mutate({ resource: companyEnergyAudit, filename: companyEnergyAudit["invoice"] })} />
        </ActionTable.Row>
      :
      <ActionTable.Row>
        <Button.Small className="actions-close" name={`${isActionMenuOpen ? "Fermer" : "Actions"}`} onClick={() => setActionMenuOpen(prevState => !prevState)} />
        {isActionMenuOpen && (
          <div className="actions--menu">
            <Button.Small name="Valider" disabled={companyEnergyAudit?.state !== CompanyEnergyAuditState.waiting} onClick={() => modal.open(EnergyAuditSignModalId)} />
            <Button.Small name="Devis" disabled={!companyEnergyAudit?.quotation} onClick={() => mutation.mutate({ resource: companyEnergyAudit, filename: companyEnergyAudit["quotation"] })} />
            <Button.Small name="Audit" disabled={!companyEnergyAudit?.energyAudit} onClick={() => mutation.mutate({ resource: companyEnergyAudit, filename: companyEnergyAudit["energyAudit"] })} />
            <Button.Small name="Facture" disabled={!companyEnergyAudit?.invoice} onClick={() => mutation.mutate({ resource: companyEnergyAudit, filename: companyEnergyAudit["invoice"] })} />
            <Button.Small name="Plus d'infos" onClick={() => modal.open(CompanyInfosModalId)} />
          </div>
        )}
      </ActionTable.Row>
      }
    </Fragment>
  );
};

interface EnergyAuditWaitingTableRowProps {
  energyAudit: EnergyAudit;
}

const EnergyAuditWaitingTableRow = ({ energyAudit }: EnergyAuditWaitingTableRowProps) => {
  return (
    <Fragment>
      <ActionTable.Row>{formateDate(energyAudit.created)}</ActionTable.Row>
      <ActionTable.Row>N.C</ActionTable.Row>
      <ActionTable.Row>N.C</ActionTable.Row>
      <ActionTable.Row>En attente</ActionTable.Row>
      <ActionTable.Row>-</ActionTable.Row>
    </Fragment>
  );
};

interface Props {
  saleId: string;
  readOnly: boolean;
}

export const EnergyAuditActionTable = ({ saleId, readOnly }: Props) => {
  const pb = usePb();

  const energyAuditRequest = useQuery<EnergyAudit[]>({
    queryKey: ["energyAudits", saleId],
    queryFn: async () => {
      return pb.collection("energyAudits").getFullList({
        filter: `sale = "${saleId}"`,
        expand: expand(["companyEnergyAudits_via_energyAudit"])
      });
    },
    enabled: !!saleId
  });

  const companyEnergyRequest = useQuery<CompanyEnergyAudit[]>({
    queryKey: ["companyEnergyAudits", saleId],
    queryFn: async () => {
      return pb.collection("companyEnergyAudits").getFullList({
        filter: `sale = "${saleId}"`,
        expand: expand(["company"])
      });
    },
    enabled: !!saleId
  });

  const energyAudits = energyAuditRequest.data || [];
  const companyEnergyAudits = companyEnergyRequest.data || [];
  const isPending = energyAuditRequest.isPending || companyEnergyRequest.isPending;

  if (readOnly) {
    return (
      <Fragment>
        <ActionTable isPending={isPending} headers={['Date', 'Entreprise', 'Montant', 'Statut', 'Actions']} emptyMessage="Aucune demande effectuée à ce jour.">
          {companyEnergyAudits.map(companyEnergyAudit => (
            <EnergyAuditActionTableRow key={companyEnergyAudit.id} companyEnergyAudit={companyEnergyAudit} readOnly/>
          ))}
        </ActionTable>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <ActionTable isPending={isPending} headers={['Date', 'Entreprise', 'Montant', 'Statut', 'Actions']} emptyMessage="Aucune demande effectuée à ce jour.">
        {companyEnergyAudits.map(companyEnergyAudit => (
          <EnergyAuditActionTableRow key={companyEnergyAudit.id} companyEnergyAudit={companyEnergyAudit} />
        ))}

        {energyAudits
          .filter(energyAudit => !energyAudit?.expand?.companyEnergyAudits_via_energyAudit?.length)
          .map(energyAudit => (
            <EnergyAuditWaitingTableRow key={energyAudit.id} energyAudit={energyAudit} />
          ))}
      </ActionTable>

      {/* Rendu des modales en dehors du tableau */}
      {companyEnergyAudits.map(companyEnergyAudit => (
        <Fragment key={companyEnergyAudit.id}>
          <EnergyAuditSignModal companyEnergyAudit={companyEnergyAudit} />
          <CompanyInfosModal id={modalId(CompanyInfosModal.name, companyEnergyAudit.company)} company={companyEnergyAudit.expand.company} />
        </Fragment>
      ))}
    </Fragment>
  );
};
