import type { Sale } from "./sale.interface";
import type { Base, ID } from "./shared.interface";

export interface SalePiece extends Base {
  sale: ID;
  propertyTitle: string;
  copropertyTitles: string[];
  cni: string;
  propertyTax: string;
  protectionOrder: string;
  saleOrder: string;
  diagnostic: string;
  energyAudit: string;
  freePieces: string[];
  expand: {
    sale: Sale;
    propertyTitleRequests_via_salePiece: PropertyTitleRequest;
    copropertyTitleRequests_via_salePiece: CopropertyTitleRequest;
  };
}

// Ajouter chaque document ici !
export const SALE_PIECE_NAMES: [keyof SalePiece] = [
  "cni",
  "diagnostic",
  "energyAudit",
  "propertyTax",
  "propertyTitle",
  "protectionOrder",
  "saleOrder",
  "copropertyTitles"
] as unknown as [keyof SalePiece];

type HandleSalePieceFilesCbProps = {
  filename: string;
  property: string;
}

export const handleSalePieceFiles = async <TResult>(salePiece: SalePiece, cb: ((props: HandleSalePieceFilesCbProps) => Promise<TResult>) | ((props: HandleSalePieceFilesCbProps) => TResult)) => {
  const results: TResult[] = [];
  const uploadedPieces = SALE_PIECE_NAMES.filter(name => Boolean(salePiece[name]));

  for (const uploadedPieceName of uploadedPieces) {
    if (Array.isArray(salePiece[uploadedPieceName])) {
      for (const filename of salePiece[uploadedPieceName]) {
        results.push(await cb({ filename, property: uploadedPieceName }));
      }
    } else {
      results.push(await cb({ filename: salePiece[uploadedPieceName] as string, property: uploadedPieceName }));
    }
  }

  const freePieces = salePiece?.freePieces || [];

  for (const freePiece of freePieces) {
    results.push(await cb({ filename: freePiece, property: "freePieces" }));
  }

  return results;
}


export enum PropertyTitleRequestState {
  waiting = "waiting",
  uploaded = "uploaded"
}

export interface PropertyTitleRequest extends Base {
  ownerName: string;
  lotNumber: string;
  state: PropertyTitleRequestState;
  salePiece: string;
  expand: {
    salePiece: SalePiece;
  };
}


export enum CopropertyTitleRequestState {
  waiting = "waiting",
  uploaded = "uploaded"
}

export interface CopropertyTitleRequest extends Base {
  syndicName: string;
  phone: string;
  email: string;
  certificate: string;
  state: CopropertyTitleRequestState;
  salePiece: string;
  expand: {
    salePiece: SalePiece;
  };
}
