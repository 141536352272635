import "./PropertyDesc.scss";
import { usePb } from "@hooks/usePb";
import { TypeOfBuilding, formatFullAddress } from "@interfaces/address.interface";
import type { Address } from "@interfaces/address.interface";
import type { Sale } from "@interfaces/sale.interface";
import { capitalizeFirstLetter } from "@utils/utils";
import { useQuery } from "@tanstack/react-query";

interface Props {
  saleId: string | undefined;
}

const formatPropertyDesc = ({ typeOfBuilding, numberOfRooms, surface, yearOfConstruction }: Address) => {
  return `${capitalizeFirstLetter(typeOfBuilding)} • ${numberOfRooms} pièce(s) • ${surface}m2 • ${yearOfConstruction}`;
}

const formatPropertyFloor = ({ numberOfFloors }: Address) => {
  if (numberOfFloors === 0) {
    return "RDC";
  }

  if (numberOfFloors === 1) {
    return "1er étage";
  }

  return `${numberOfFloors}eme étage`;
}

export const PropertyDesc = ({ saleId }: Props) => {
  const pb = usePb();

  const { data: sale } = useQuery<Sale>({
    queryKey: ["sale", saleId],
    queryFn: async () => {
      return pb.collection("sales").getOne(saleId!, {
        expand: "address",
      });
    },
    enabled: !!saleId
  });

  const address = sale?.expand?.address;

  if (!address) {
    return (
      <div className="c-propertydesc">
        <span>Fiche du bien</span>
        <div className="block-details">
          <p>Aucune information</p>
        </div>
      </div>
    )
  }

  return (
    <div className="c-propertydesc">
      <span>Fiche du bien</span>
      <div className="block-details">
        <p style={{ fontWeight: "bold" }}>{formatFullAddress(address)}</p>
        {address.isComplete && <p>{formatPropertyDesc(address)}</p>}
        {(address.isComplete && address.typeOfBuilding === TypeOfBuilding.appartement) && <p>{formatPropertyFloor(address)}</p>}
        {address.typeOfHeating && <p>Chauffage : {address.typeOfHeating}</p>}
      </div>
    </div>
  )
}