import { useNavigate, useParams } from "react-router-dom";
import { usePb } from "@hooks/usePb";
import { toast } from "react-toastify";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input";
import { Button } from "@components/buttons/Buttons";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useMutation } from "@tanstack/react-query";
import type { ClientResponseError } from "pocketbase";

export const ConfirmPasswordSchema = z.object({
  password: z
    .string({
      invalid_type_error: "Le mot de passe doit être une chaîne de caractères",
      required_error: "Le mot de passe est requis",
    })
    .min(1),
  passwordConfirm: z
    .string({
      invalid_type_error: "La confirmation du mot de passe doit être une chaîne de caractères",
      required_error: "La confirmation du mot de passe est requise",
    })
    .min(1),
}).refine(
  (data) => data.password === data.passwordConfirm,
  {
    message: "Les mots de passe ne correspondent pas.",
    path: ["passwordConfirm"],
  }
);

export const ResetPassword = () => {
  const pb = usePb();
  const navigate = useNavigate();

  const { token } = useParams<{ token: string }>();

  const formMethods = useForm<z.infer<typeof ConfirmPasswordSchema>>({
    resolver: zodResolver(ConfirmPasswordSchema),
    defaultValues: {
      password: "",
      passwordConfirm: "",
    },
  });

  const mutation = useMutation({
    mutationFn: async (body: z.infer<typeof ConfirmPasswordSchema>) => {
      return pb
        .collection("users")
        .confirmPasswordReset(token!, body.password, body.passwordConfirm);
    },
    onSuccess: () => {
      toast.success("Mot de passe réinitialisé avec succès.");
      navigate('/');
    },
    onError(error: ClientResponseError) {
      if (error.status === 400) {
        toast.error('Ce lien est expiré ou invalide');
      } else {
        toast.error("Erreur lors de la réinitialisation du mot de passe.");
      }
    },
  }
  );

  return (
    <Form.DefaultLayout
      title="Réinitialiser le mot de passe"
      subtitle="Entrez votre nouveau mot de passe dans le formulaire"
      formMethods={formMethods}
    >
      <Input
        border
        autoComplete="password"
        placeholder="Mot de passe"
        name="password"
        type="password"
      />

      <Input
        border
        autoComplete="passwordConfirm"
        placeholder="Confirmer le mot de passe"
        name="passwordConfirm"
        type="password"
      />

      <Button
        name="Envoyer"
        onClick={formMethods.handleSubmit((values) => mutation.mutate(values))}
        loading={mutation.isPending}
      />
    </Form.DefaultLayout>
  );
};
