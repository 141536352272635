import { Button } from "@components/buttons/Buttons"
import Modal from "@components/modal/Modal"
import type { Company } from "@interfaces/company.interface"
import type { CreateMandateSchema } from "@schemas/mandate.schema";
import { useFieldArray, useFormContext } from "react-hook-form";
import type { z } from "zod";
import "./MandateSelectCompaniesModal.scss"

interface Props {
  onSubmit: () => void;
  isPending: boolean;
}

export const MandateSelectCompaniesModal = ({ isPending, onSubmit }: Props) => {
  const formContext = useFormContext<z.infer<typeof CreateMandateSchema>>();

  const companies = useFieldArray({
    name: "companies",
    control: formContext.control
  });

  return (
    <Modal id={MandateSelectCompaniesModal.name} title="Choix des agences immobilières">
      <div className="select-companies--modal">
        <p>Pour votre demande de mandat, merci de choisir au minimum 2 agences.</p>

        {companies.fields.map((field, index) => (
          <div
            key={field.id}
            className="cursor-pointer"
            style={{ backgroundColor: field.selected ? "" : "inherit" }}
            onClick={() => companies.update(index, { company: field.company, selected: !field.selected })}>

            <div className="checkbox--corner">
              <input name={field.id} type="checkbox" checked={field.selected} />
              <label htmlFor={field.id}>{field.company.name}</label>
            </div>

          </div>

        ))}
      </div>

      <Button
        name="Confirmer"
        loading={isPending}
        onClick={onSubmit} />
    </Modal>
  )
}