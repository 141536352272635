import type { Company } from "./company.interface";
import type { Sale } from "./sale.interface";
import type { Base, ID } from "./shared.interface";
import type { BookableRecord } from "./slot.interface";

export type Diagnostic = {
  invoiceContactEmail: string;
  keyPickupAddress: string;
  keyDropoffAddress: string;
  contactPerson: string;
  instructions: string;
  signedCompanyDiagnostic: ID;
  sale: ID;
  expand: {
    sale: Sale;
    signedCompanyDiagnostic: CompanyDiagnostic;
    companyDiagnostics_via_diagnostic: CompanyDiagnostic[];
  }
} & Base & BookableRecord;

export enum CompanyDiagnosticState {
  waiting = "waiting", // Le devis a été déposé sur la plateforme, le mandataire doit le signer
  accepted = "accepted", // Le mandataire a signé un devis
  refused = "refused", // Le mandataire a signé un devis, tout les autres sont refusés
}

export interface CompanyDiagnostic extends Base {
  company: ID;
  diagnostic: ID;
  quotation: string;
  invoice: string;
  diagnosticFile: string;
  price: number;
  state: CompanyDiagnosticState;
  expand: {
    diagnostic: Diagnostic;
    company: Company;
  }
}