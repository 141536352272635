import { Fragment } from "react";
import "./PropertyDocsList.scss";
import { useModal } from "@hooks/useModal";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { trpc } from "@client/index";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { getQueryKey } from "@trpc/react-query";
import { Form } from "@components/form/Form";
import { Button } from "@components/buttons/Buttons";
import { UploadModal } from "@components/modal/UploadModal";
import { usePbDownload } from "@hooks/usePbDownload";
import { UploadSalePieceDocumentSchema } from "@schemas/salePiece.schema";
import { useDropdown } from "@hooks/useDropdown";

interface Props {
  saleId: string;
}

const UPLOAD_MODAL_ID = "energy-audit-upload";

export const EnergyAudit = ({ saleId }: Props) => {
  const modal = useModal();
  const dropdown = useDropdown();
  const queryClient = useQueryClient();
  const downloadMutation = usePbDownload();

  const { data: salePieceData } = trpc.sale.salePiece.getBySaleId.useQuery(saleId);

  const formMethods = useForm<z.infer<typeof UploadSalePieceDocumentSchema>>({
    resolver: zodResolver(UploadSalePieceDocumentSchema),
    defaultValues: {
      document: undefined,
    },
  });

  const mutation = trpc.sale.salePiece.energyAudit.upload.useMutation({
    onSuccess: () => {
      formMethods.reset();

      modal.close(UPLOAD_MODAL_ID);

      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.salePiece.getBySaleId) });

      toast.success("Votre document a bien été ajouté.");
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const handleDelete = trpc.sale.salePiece.energyAudit.remove.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.salePiece.getBySaleId) });
      toast.success("Le document a bien été supprimé.");
    },
    onError() {
      toast.error("Une erreur est survenue lors de la suppression.");
    },
  });

  const isUploaded = Boolean(salePieceData?.energyAudit);

  const handleSubmit = (body: z.infer<typeof UploadSalePieceDocumentSchema>) => {
    const formData = new FormData();
    formData.append("saleId", saleId);
    formData.append("document", body.document);
    mutation.mutate(formData);
  };

  const handleDeleteConfirmation = () => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer ce document ?");
    if (confirmDelete) {
      handleDelete.mutate({ saleId });
    }
  };

  return (
    <Fragment>
      <li className={isUploaded ? "valid" : "non-valid"}>
        <p>Audit énergétique</p>
        <div>
          <Button.Small
            xs
            valid
            name={isUploaded ? "Modifier" : "Ajouter"}
            onClick={() => modal.open(UPLOAD_MODAL_ID)}
          />

          {isUploaded && (
            <Fragment>
              <Button.Small
                xs
                valid
                name="Supprimer"
                onClick={handleDeleteConfirmation}
              />
              <Button.Small
                xs
                valid
                name="Télécharger"
                onClick={() =>
                  downloadMutation.mutate({
                    resource: salePieceData,
                    filename: salePieceData!["energyAudit"],
                  })
                }
              />
            </Fragment>
          )}

          {!isUploaded && (
            <Button.Small
              xs
              valid
              name="Demander"
              onClick={() => {
                dropdown.closeAll();
                dropdown.open("diagnostic");
              }}
            />
          )}
        </div>
      </li>

      <Form formMethods={formMethods}>
        <UploadModal
          id={UPLOAD_MODAL_ID}
          name="document"
          title="Audit énergétique"
          isLoading={mutation.isPending}
          onConfirm={formMethods.handleSubmit(handleSubmit)}
        />
      </Form>
    </Fragment>
  );
};
