import { trpc } from "@client/index"
import { Button } from "@components/buttons/Buttons";
import { ExternalNotificationCard } from "@components/ExternalNotificationCard";
import { downloadFileFromLink } from "@utils/utils";
import { Fragment } from "react";

export const DownloadFiles = () => {
  const query = trpc.downloadFile.getDownloadLinks.useQuery(undefined, {
    retry: false
  });

  const errorStatus = query.error?.data?.httpStatus;

  if (typeof errorStatus === "number") {
    return <ExternalNotificationCard title="Une erreur est survenue. Merci de réessayer plus tard." />
  }

  if (!query.isFetched) {
    return <ExternalNotificationCard title="Chargement en cours.." />
  }

  if (!query.data) {
    return <ExternalNotificationCard title="Cette page n'est plus disponible" />
  }

  const documents = query.data || [];

  if (!documents.length) {
    return <ExternalNotificationCard title="Aucuns documents disponible" />
  }

  return (
    <Fragment>
      <div className="form--container">
        <h3>Télécharger vos documents</h3>
        <br />

        {documents.map(document => (
          <Button
            key={document.filename}
            name={document.filename}
            onClick={() => downloadFileFromLink(document.url)} />
        ))}
      </div>
    </Fragment>
  );
}