import { z } from "zod";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Form } from "@components/form/Form";
import { Button } from "@components/buttons/Buttons";
import { Toggle } from "@components/inputs/Toggle";
import { zodResolver } from "@hookform/resolvers/zod";
import "./StorageConfirm.scss"
import { ExternalNotificationCard } from "@components/ExternalNotificationCard";
import { ConfirmInterestSchema } from "@schemas/common.schema";
import { trpc } from "@client/index";
import { getQueryKey } from "@trpc/react-query";

export const StorageConfirm = () => {
  const queryClient = useQueryClient();

  const { storageId } = useParams<{ storageId: string; }>();

  const formMethods = useForm<z.infer<typeof ConfirmInterestSchema>>({
    resolver: zodResolver(ConfirmInterestSchema),
    defaultValues: {
      confirmation: "yes",
    },
  });

  const guardQueryResult = trpc.external.storage.confirmGetResources.useQuery({ storageId: storageId! }, {
    retry: false
  })

  const mutation = trpc.external.storage.confirm.useMutation({
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.external.storage.confirmGetResources) });
    }
  })

  const errorStatus = guardQueryResult.error?.data?.httpStatus;

  if (mutation.isSuccess) {
    return <ExternalNotificationCard title="Merci d'avoir répondu à cette demande de devis débarras" />
  }

  if (errorStatus === 403 || errorStatus === 404) {
    return <ExternalNotificationCard title="Cette page n'est plus disponible" />
  }

  if (typeof errorStatus === "number") {
    return <ExternalNotificationCard title="Une erreur est survenue. Merci de réessayer plus tard." />
  }

  if (!guardQueryResult.data) {
    return null;
  }

  const { sale } = guardQueryResult.data;

  return (
    <Form.DefaultLayout formMethods={formMethods}>
      <div className="estimate-without--container">
        <h3>Nouvelle demande de devis débarras</h3>
        <small><b>Ville:</b> {sale?.expand.address.city}</small>

        <span>Merci de confirmer votre intérêt pour la demande de devis débarras</span>

        <Toggle
          name="confirmation"
          required
          darker
          choices={[
            {
              label: "Oui",
              value: "yes",
            },
            {
              label: "Non",
              value: "no",
            },
          ]} />

        <p>Une fois votre choix confirmé, vous recevrez les informations concernant les clés et l'adresse précise.</p>

        <p>Attention, ce choix bloque les autres entreprises du département.</p>
      </div>

      <Form.Actions centered>
        <Button
          name="Valider"
          onClick={formMethods.handleSubmit((values) => mutation.mutate({ storageId: storageId!, ...values }))}
          loading={mutation.isPending} />
      </Form.Actions>
    </Form.DefaultLayout>
  );
};
