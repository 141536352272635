import { ADMIN_COOKIE_NAME, PLATFORM_COOKIE_NAME } from "@interfaces/shared.interface";
import type { User } from "@interfaces/user.interface";
import type { TypedPocketBase } from "@libraries/pocketbase";
import PocketBase from "pocketbase";

const pb = new PocketBase(import.meta.env.PUBLIC_POCKETBASE_URL) as TypedPocketBase;

pb.autoCancellation(false);

// Pratique, mais il faudrait arrêter d'utiliser ça. Privilégier trpc pour ne pas se disperser.
export const usePb = () => {
  pb.authStore.loadFromCookie(document.cookie, PLATFORM_COOKIE_NAME);

  return pb;
}

export const useMe = <T extends User>() => {
  const pb = usePb();

  return pb.authStore.model as T;
}