import { trpc } from "@client/index";
import { Button } from "@components/buttons/Buttons";
import { Form } from "@components/form/Form";
import { Select } from "@components/inputs/Select";
import Modal from "@components/modal/Modal"
import { useModal } from "@hooks/useModal";
import type { CompanyOffer } from "@interfaces/offer.interface";
import { OfferRefusedSchema } from "@schemas/offer.schema";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";

interface Props {
  id: string;
  companyOffer: CompanyOffer;
}

const selectReasons = {
  "Offre trop basse": "Offre trop basse",
  "Offre un peu trop basse": "Offre un peu trop basse",
  "Pas d'indication": "Pas d'indication"
}

export const OfferRefusedModal = ({ id, companyOffer }: Props) => {
  const modal = useModal();
  const queryClient = useQueryClient();

  const formMethods = useForm<z.infer<typeof OfferRefusedSchema>>({
    defaultValues: {
      reason: selectReasons["Offre trop basse"],
    }
  });

  const mutation = trpc.sale.offer.refuse.useMutation({
    onSuccess: () => {
      toast.success("L'offre a été refusée.");

      queryClient.invalidateQueries({ queryKey: ["companyOffers", companyOffer.sale] });

      formMethods.reset();

      modal.closeAll();
    },
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
  });

  return (
    <Modal id={id} title="Refuser l'offre">
      <Form formMethods={formMethods}>
        <p style={{color: "#FFF"}}>Motif de votre refus (facultatif)</p>

        <Select
          name="reason"
          options={[
            { label: selectReasons["Offre trop basse"], value: selectReasons["Offre trop basse"] },
            { label: selectReasons["Offre un peu trop basse"], value: selectReasons["Offre un peu trop basse"] },
            { label: selectReasons["Pas d'indication"], value: selectReasons["Pas d'indication"] },
          ]} />

        <Form.Actions centered>
          <Button
            name="Refuser l'offre"
            loading={mutation.isPending}
            onClick={formMethods.handleSubmit((values) => mutation.mutate({ companyOfferId: companyOffer.id, reason: values.reason }))} />
        </Form.Actions>
      </Form>
    </Modal>
  )
}