import { OfferActionTable } from "./OfferActionTable";

interface Props {
  saleId: string;
}

export const OfferDropdown = ({ saleId }: Props) => {
  return (
    <>
      <OfferActionTable saleId={saleId!} />
    </>
  )
}