import { Button } from "@components/buttons/Buttons";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input";
import { Select } from "@components/inputs/Select";
import Modal from "@components/modal/Modal";
import { Table } from "@components/table/Table";
import { zodResolver } from "@hookform/resolvers/zod";
import { useModal } from "@hooks/useModal";
import { usePb } from "@hooks/usePb";
import {
  type ColumnDef,
  type SortingState,
  useReactTable,
  getSortedRowModel,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  createColumnHelper,
} from "@tanstack/react-table";
import { Fragment } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { z } from "zod";
import { CompanyType, type Company } from "@interfaces/company.interface";
import { CreateCompanySchema } from "@schemas/company.schema";
import { trpc } from "@client/index";
import { getEnv } from "@utils/utils";

const columnHelper = createColumnHelper<Company>();

const types = {
  realestate: "Agence immobilière",
  auditor: "Entreprise d'audit",
  cleaner: "Entreprise de débarras",
};

export const CompanyTable = () => {
  const pb = usePb();
  const modal = useModal();
  const queryClient = useQueryClient();

  const [selectedCompany, setSelectedCompany] = React.useState<Company | null>(null);

  // Ajout de l'état pour gérer le mode "fictif" ou non
  const [showFictives, setShowFictives] = React.useState(false);

  const formMethods = useForm<z.infer<typeof CreateCompanySchema>>({
    resolver: zodResolver(CreateCompanySchema),
    defaultValues: {
      name: "",
      type: CompanyType.realestate,
      phone: "",
      email: "",
      firstname: "",
      lastname: "",
      address: "",
      zipcode: "",
      city: "",
    },
  });

  const { data: companies = [] } = useQuery<Company[]>({
    queryKey: ["companies"],
    queryFn: async () => {
      return pb.collection("companies").getFullList({
        batch: 9999,
        expand: "companyEmails_via_company"
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      return pb.collection("companies").delete(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies"] });
      toast.success(`L'entreprise a bien été supprimée.`);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const createMutation = trpc.company.create.useMutation({
    onSuccess: () => {
      toast.success(`L'entreprise a bien été créée.`);

      queryClient.invalidateQueries({ queryKey: ["companies"] });

      formMethods.reset();

      modal.close("new-company");
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const updateMutation = trpc.company.update.useMutation({
    onSuccess: () => {
      toast.success(`L'entreprise a bien été modifiée.`);

      queryClient.invalidateQueries({ queryKey: ["companies"] });

      formMethods.reset();

      setSelectedCompany(null);

      modal.close("edit-company");
    },
    onError() {
      toast.error("Une erreur est survenue lors de la mise à jour.");
    },
  });

  const handleEditClick = (company: Company) => {
    const companyEmail = company.expand.companyEmails_via_company[0];

    setSelectedCompany(company);

    formMethods.reset({ ...company, ...companyEmail });

    modal.open("edit-company");
  };

  // Filtrer les entreprises fictives en fonction de showFictives, avec useMemo pour éviter la boucle
  const filteredCompanies = React.useMemo(() => {
    return companies.filter((company) => {
      const email = company.email || "";
      const isFictive = /\+.{10,}@/.test(email); // Vérification si l'email est "fictif"
      return showFictives ? isFictive : !isFictive; // Filtrer selon le mode
    });
  }, [companies, showFictives]);

  const columns = React.useMemo<ColumnDef<Company>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Nom",
        enableSorting: true,
        cell: Table.BasicCell,
      },
      {
        accessorKey: "zipcode",
        header: "Code postal",
        enableSorting: true,
        cell: Table.BasicCell,
      },
      columnHelper.display({
        header: "Type",
        cell: ({ row }) => types[row.original.type],
      }),
      {
        accessorKey: "phone",
        header: "Téléphone",
        enableSorting: true,
        cell: Table.BasicCell,
      },
      {
        accessorKey: "email",
        header: "Email",
        enableSorting: true,
        cell: Table.BasicCell,
      },
      columnHelper.display({
        id: "actions",
        header: () => <Button name="Ajouter une entreprise" onClick={() => modal.open("new-company")} />,
        cell: ({ row }) => (
          <>
            <Button.Small name="Modifier" onClick={() => handleEditClick(row.original)} />
            &nbsp;&nbsp;
            <Button.Small name="Supprimer" onClick={() => deleteMutation.mutate(row.original.id)} />
          </>
        ),
      }),
    ],
    []
  );

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data: filteredCompanies,
    columns,
    state: {
      sorting,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: false,
  });

  return (
    <Fragment>
      <Table table={table} />

      <Modal id="edit-company" title="Modifier une entreprise">
        <Form formMethods={formMethods}>
          <Input placeholder="Nom de l'entreprise" name="name" />
          <Select
            name="type"
            options={[
              { label: "Agence immobilière", value: "realestate" },
              { label: "Entreprise de diagnostics", value: "auditor" },
              { label: "Entreprise de débarras", value: "cleaner" },
            ]}
            border
          />
          <Input placeholder="Téléphone" name="phone" />
          <Input placeholder="Email" name="email" type="email" />
          <Input placeholder="Nom" name="lastname" />
          <Input placeholder="Prénom" name="firstname" />
          <Input placeholder="Adresse" name="address" />
          <Input placeholder="Code postaux séparés par une virgule (exemple: 49000,44000)" name="zipcode" />
          <Input placeholder="Ville" name="city" />
          <Button
            name="Enregistrer"
            onClick={formMethods.handleSubmit((values) => updateMutation.mutate({ companyId: selectedCompany?.id!, ...values }))}
            loading={updateMutation.isPending}
          />
        </Form>
      </Modal>

      <Modal id="new-company" title="Ajouter une entreprise">
        <Form formMethods={formMethods}>
          <Input placeholder="Nom de l'entreprise" name="name" />
          <Select
            name="type"
            options={[
              { label: "Agence immobilière", value: "realestate" },
              { label: "Entreprise de diagnostics", value: "auditor" },
              { label: "Entreprise de débarras", value: "cleaner" },
            ]}
            border
          />
          <Input placeholder="Téléphone" name="phone" />
          <Input placeholder="Email" name="email" type="email" />
          <Input placeholder="Nom" name="lastname" />
          <Input placeholder="Prénom" name="firstname" />
          <Input placeholder="Adresse" name="address" />
          <Input placeholder="Code postaux séparés par une virgule (exemple: 49000,44000)" name="zipcode" />
          <Input placeholder="Ville" name="city" />
          <Button
            name="Enregistrer"
            onClick={formMethods.handleSubmit((values) => createMutation.mutate(values))}
            loading={createMutation.isPending}
          />
        </Form>
      </Modal>

      {/* Ajout du toggle flottant pour activer/désactiver l'affichage des entreprises fictives */}
      <div style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000
      }}>
        <Button.Small
          style={{padding: '10px'}}
          name={showFictives ? "Afficher entreprises réelles" : "Afficher entreprises fictives"}
          onClick={() => setShowFictives(!showFictives)}
        />
      </div>
    </Fragment>
  );
};
