import { Button } from "@components/buttons/Buttons"
import { Fragment } from "react"
import { useModal } from "@hooks/useModal";
import Modal from "@components/modal/Modal";
import { trpc } from "@client/index";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { Tabs } from "@components/Tabs";
import { CompanyReportActionTable } from "./CompanyReportActionTable";
import { Form } from "@components/form/Form";
import { useForm } from "react-hook-form";
import Input from "@components/inputs/Input";
import { zodResolver } from "@hookform/resolvers/zod";
import { CreateAmendmentSchema } from "@schemas/mandate.schema";
import type { z } from "astro:content";
import { CompanyAmendmentActionTable } from "./CompanyAmendmentActionTable";
import { ActualisedEstimateActionTable } from "./ActualisedEstimateActionTable";
import "./PropertyDoesNotSellDropdown.scss";
interface Props {
  saleId: string;
}

const ActualisedEstimateInfosModal = ({ saleId }: Props) => {
  const modal = useModal();
  const queryClient = useQueryClient();

  const mutation = trpc.sale.estimate.createActualised.useMutation({
    onSuccess() {
      toast.success("Votre demande d'estimation actualisée a été effectuée.");

      queryClient.invalidateQueries({ queryKey: [] });

      modal.closeAll();
    },
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
  });

  return (
    <Modal id={ActualisedEstimateInfosModal.name} title="Confirmer votre action">
      <p className="consult--doc">
        Demander une estimation réactualisée aux agences en charge du dossier.
      </p>

      <Button
        name="Confirmer"
        loading={mutation.isPending}
        onClick={() => mutation.mutate({ saleId })} />

      <p className="cursor-pointer link" onClick={() => modal.close(ActualisedEstimateInfosModal.name)}>
        Annuler
      </p>
    </Modal>
  )
}

const ReportInfosModal = ({ saleId }: Props) => {
  const modal = useModal();
  const queryClient = useQueryClient();

  const mutation = trpc.sale.mandate.askReport.useMutation({
    onSuccess() {
      toast.success("Votre demande d'estimation actualisée a été effectuée.");

      queryClient.invalidateQueries({ queryKey: [] });

      modal.closeAll();
    },
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
  });

  return (
    <Modal id={ReportInfosModal.name} title="Confirmer votre action">
      <p>
        Demander un compte-rendu des actions menées et des recommandations aux agences en charge du dossier.
      </p>

      <Button
        name="Confirmer"
        loading={mutation.isPending}
        onClick={() => mutation.mutate({ saleId })} />

      <p className="cursor-pointer link" onClick={() => modal.close(ReportInfosModal.name)}>
        Annuler
      </p>
    </Modal>
  )
}

const AmendmentInfosModal = ({ saleId }: Props) => {
  const modal = useModal();
  const queryClient = useQueryClient();

  const formMethods = useForm<z.infer<typeof CreateAmendmentSchema>>({
    resolver: zodResolver(CreateAmendmentSchema),
    defaultValues: {
      price: undefined
    }
  });

  const mutation = trpc.sale.mandate.createAmendment.useMutation({
    onSuccess() {
      toast.success("Votre demande d'avenant a été effectuée.");

      queryClient.invalidateQueries({ queryKey: [] });

      modal.closeAll();
    },
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
  });

  return (
    <Modal id={AmendmentInfosModal.name} title="Confirmer votre action">
      <p>
        Demander un avenant au mandat pour baisser le prix de vente du bien aux agences en charge du dossier.
      </p>

      <Form formMethods={formMethods}>
        <Input name="price" placeholder="Prix net vendeur" />
      </Form>

      <Button
        name="Confirmer"
        loading={mutation.isPending}
        onClick={formMethods.handleSubmit(values => mutation.mutate({ saleId, price: values.price }))} />

      <p className="cursor-pointer link" onClick={() => modal.close(AmendmentInfosModal.name)}>
        Annuler
      </p>
    </Modal>
  )
}

export const PropertyDoesNotSellDropdown = ({ saleId }: Props) => {
  const modal = useModal();

  return (
    <Fragment>
      <Tabs
        defaultKey="create"
        tabs={[
          { name: "Action à mener", key: "create" },
          { name: "Consulter documents", key: "show" },
        ] as const}
        components={{
          create: (
            <Fragment>
              <div className="dropdown--actions empty">
                <Button
                  name="Demander une estimation réactualisée"
                  onClick={() => modal.open(ActualisedEstimateInfosModal.name)} />

                <Button
                  name="Demander un compte rendu"
                  onClick={() => modal.open(ReportInfosModal.name)} />

                <Button
                  name="Demander un avenant pour baisser le prix"
                  onClick={() => modal.open(AmendmentInfosModal.name)} />
              </div>
            </Fragment>
          ),
          show: (
            <Fragment>
              <p className="consult--doc">Estimations actualisées</p>
              <ActualisedEstimateActionTable saleId={saleId} />

              <p className="consult--doc">Compte rendus</p>
              <CompanyReportActionTable saleId={saleId} />

              <p className="consult--doc">Avenants</p>
              <CompanyAmendmentActionTable saleId={saleId} />
            </Fragment>
          )
        }} />

      <ReportInfosModal saleId={saleId} />
      <AmendmentInfosModal saleId={saleId} />
      <ActualisedEstimateInfosModal saleId={saleId} />
    </Fragment>
  )
}