import "./Header.scss";

export const Header = () => {
  return (
    <div className="header">
      <figure>
        <img src="/assets/appicons/logo.svg" alt="Logo Athena" />
      </figure>
    </div>
  )
}
