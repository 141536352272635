import { Fragment, useEffect } from "react";
import Modal from "@components/modal/Modal";
import "./MandateIndivisairesModal.scss";
import { Toggle } from "@components/inputs/Toggle";
import { Button } from "@components/buttons/Buttons";
import { useFieldArray, useFormContext } from "react-hook-form";
import { type CreateMandateSchema } from "@schemas/mandate.schema";
import { z } from "zod";
import Input from "@components/inputs/Input";
import { useModal } from "@hooks/useModal";
import { MandateSelectCompaniesModal } from "./MandateSelectCompaniesModal";

interface Props {
  saleId: string;
  isPending: boolean;
  onSubmit: () => void;
}

const MINIMUM_COMPANY_SELECTED = 3;

export const MandateIndivisairesModal = ({ isPending, onSubmit }: Props) => {
  const modal = useModal();

  const formContext = useFormContext<z.infer<typeof CreateMandateSchema>>();

  const [companies, hasIndivisaires] = formContext.watch(["companies", "hasIndivisaires"])

  const indivisaires = useFieldArray<z.infer<typeof CreateMandateSchema>>({
    name: "indivisaires",
    control: formContext.control
  });

  const handleSubmit = async () => {
    const isValid = await formContext.trigger("indivisaires");

    if (!isValid) {
      return;
    }

    const shouldSelectCompanies = companies.length > MINIMUM_COMPANY_SELECTED;

    if (shouldSelectCompanies) {
      modal.close(MandateIndivisairesModal.name);
      modal.open(MandateSelectCompaniesModal.name);

      return
    }

    onSubmit();
  }

  useEffect(() => {
    if (hasIndivisaires === "no") {
      formContext.setValue("indivisaires", []);
    }
  }, [hasIndivisaires]);

  return (
    <Fragment>
      <Modal id={MandateIndivisairesModal.name} title="Ajout des indivisaires">
        <div className="indivisaires-modal">
          <p>A votre connaissance, y a-t-il des indivisaires ?</p>

          <div className="buttons">
            <Toggle
              name="hasIndivisaires"
              className="input-toggle-indivisaires"
              choices={[
                {
                  label: "Oui",
                  value: "yes",
                },
                {
                  label: "Non",
                  value: "no",
                },
              ]} />
          </div>

          <div className="indivisaires-list">
            {indivisaires.fields.map((indivisaire, index) => (
              <div key={indivisaire.id} className="indivisaire-item">
                <Input
                  border
                  name={`indivisaires.${index}.name`}
                  placeholder="Nom et Prénom" />

                <Input
                  border
                  name={`indivisaires.${index}.phone`}
                  type="tel"
                  placeholder="Téléphone" />

                <Input
                  border
                  name={`indivisaires.${index}.email`}
                  type="email"
                  placeholder="Email" />

                <Button name="Supprimer" onClick={() => indivisaires.remove(index)} />
              </div>
            ))}
          </div>

          {hasIndivisaires === "yes" && (
            <div>
              <Button
                type="button"
                name="Ajouter un indivisaire"
                onClick={() => indivisaires.append({
                  name: "",
                  phone: "",
                  email: "",
                })}
                className="c-btn--outline" />
            </div>
          )}

          <Button
            name="Confirmer"
            loading={isPending}
            className="confirm-button"
            onClick={handleSubmit} />
        </div>
      </Modal>

      <MandateSelectCompaniesModal
        isPending={isPending}
        onSubmit={onSubmit} />
    </Fragment>
  );
};
