import { useDropdownContext } from "@contexts/dropdown.context";

export const useDropdown = () => {
  const context = useDropdownContext();

  const open = (id: string) => {

    if(!context.isDisabled(id)) {
      console.log('test');
      alert("Cette fonctionnalité sera accessible dès que vous aurez passé l'étape estimation.");
      return;
    }

    context.open(id);
  }

  const close = (id: string) => {
    context.close(id);
  }

  const closeAll = () => {
    context.closeAll();
  }

  return { open, close, closeAll }
}