import { useState, useEffect, useRef } from 'react';
import Modal from "@components/modal/Modal";
import './MandateClausesModal.scss';
import { Button } from '@components/buttons/Buttons';
import { useFieldArray, useFormContext, type UseFieldArrayReturn } from 'react-hook-form';
import { useModal } from '@hooks/useModal';
import { Helper } from '@components/Helper';
import type { z } from 'zod';
import type { CreateMandateSchema } from '@schemas/mandate.schema';
import Input from '@components/inputs/Input';
import { useMe } from '@hooks/usePb';

interface Props {}

export const MandateClausesModal = ({ }: Props) => {
  const modal = useModal();
  const [description, setDescription] = useState("");
  const formContext = useFormContext<z.infer<typeof CreateMandateSchema>>();

  // Utilisation de useFieldArray pour les clauses du formulaire
  const clauses = useFieldArray<z.infer<typeof CreateMandateSchema>>({
    name: "clauses",
    control: formContext.control
  }) as UseFieldArrayReturn<z.infer<typeof CreateMandateSchema>, "clauses", "id">;

  const me = useMe();

  // État pour savoir si les clauses de l'utilisateur ont déjà été initialisées
  const hasInitializedClauses = useRef(false);

  // Ajout des clauses par défaut de l'utilisateur (une seule fois)
  useEffect(() => {
    if (me && me.clauses && me.clauses.length > 0 && !hasInitializedClauses.current) {
      // Remplace les clauses actuelles par celles de l'utilisateur (me)
      clauses.replace(me.clauses.map((clause) => ({ description: clause.text || '' })));
      hasInitializedClauses.current = true; // Marquer les clauses comme initialisées
    }
  }, [me]); // On surveille seulement `me`, pour que cela ne se déclenche qu'une seule fois

  const addClause = () => {
    clauses.append({ description });
    setDescription("");
  }

  const onValidate = async () => {
    const isValid = await formContext.trigger("clauses");

    if (isValid) {
      modal.close(MandateClausesModal.name);
    }
  }

  return (
    <Modal id={MandateClausesModal.name} title="Définir les clauses du mandat">
      <div className="mandate-clauses-modal">
        <div className="clauses-list">
          {clauses.fields.map((clause, index) => (
            <div key={clause.id} className="clause-item">
              <Input.Textarea
                name={`clauses.${index}.description`}
                placeholder="Ecrivez ici les clauses souhaitées. Par exemple : Si vous souhaitez un mandat simple écrivez « mandat simple ». Cette information sera alors envoyée à chacune des agences."
                border
              />
              <Button name="Supprimer" onClick={() => clauses.remove(index)} />
            </div>
          ))}
        </div>

        <Button
          className="c-btn--outline"
          onClick={addClause}
          name="Ajouter une clause"
        />

        <Button name="Valider les clauses" onClick={onValidate} />
      </div>
    </Modal>
  );
};
