import { Button } from "@components/buttons/Buttons"
import { Form } from "@components/form/Form"
import Input from "@components/inputs/Input"
import Modal from "@components/modal/Modal"
import { zodResolver } from "@hookform/resolvers/zod"
import { useModal } from "@hooks/useModal"
import { CreateProtecteeSchema } from "@schemas/protectee.schema"
import { useForm } from "react-hook-form"
import { useQueryClient } from "@tanstack/react-query"
import { toast } from "react-toastify"
import type { z } from "zod"
import { trpc } from "@client/index"
import { getQueryKey } from "@trpc/react-query"
import { useNavigate } from "react-router-dom"

export const ProtecteeCreateModal = () => {
  const modal = useModal();
  const naviguate = useNavigate();
  const queryClient = useQueryClient();

  const formMethods = useForm<z.infer<typeof CreateProtecteeSchema>>({
    resolver: zodResolver(CreateProtecteeSchema),
    defaultValues: {
      city: '',
      firstname: '',
      lastname: '',
      street: '',
      zipcode: ''
    }
  });

  const mutation = trpc.protectee.create.useMutation({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.protectee.search) });

      naviguate(`/dashboard/sales/${data.sale.id}`);

      toast.success(`Le majeur protégé a bien été ajouté.`);

      formMethods.reset();

      modal.close(ProtecteeCreateModal.name);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  return (
    <Modal id={ProtecteeCreateModal.name} title="Ajouter un majeur protégé">
      <Form formMethods={formMethods}>
        <Input
          name="firstname"
          placeholder="Prénom" />

        <Input
          name="lastname"
          placeholder="Nom" />

        <Input
          name="street"
          placeholder="Adresse du bien à vendre" />

        <div className="form-address-extra">
          <Input
            name="zipcode"
            placeholder="Code postal" />
          <Input
            name="city"
            placeholder="Ville" />
        </div>

        <Button
          name="Enregistrer le majeur"
          onClick={formMethods.handleSubmit((values) => mutation.mutate(values))}
          loading={mutation.isPending} />
      </Form>
    </Modal>
  )
}