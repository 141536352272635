import "./Home.scss";

export const Home = () => {
  return (
    <div className="c-sell--driving">
      <div className="c-sell--empty">
        <p>
          Sélectionnez un majeur protégé pour accèder<br /> à la vente
        </p>
      </div>
    </div>
  )
}