import validator from "validator";
import { z } from "zod";
import { validateFile } from "./shared.schema";

export const CreatePropertyTitleRequestSchema = z.object({
  ownerName: z.string().min(1, "Le nom du propriétaire est requis"),
  lotNumber: z.string().optional(),
});

export const CreateCopropertyTitleRequestSchema = z.object({
  syndicName: z.string().min(1, "Ce champ est requis"),
  phone: z.string().refine(validator.isMobilePhone),
  email: z.string().refine(validator.isEmail),
  certificate: validateFile()
});

export const UploadSalePieceDocumentSchema = z.object({
  document: validateFile(),
});

export const UploadSalePieceMultipleDocumentSchema = z.object({
  documents: z.array(validateFile())
});
