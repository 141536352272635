import { Button } from "@components/buttons/Buttons"
import { Fragment } from "react"
import { StorageActionTable } from "./StorageActionTable"
import { StorageRequestModal } from "./StorageRequestModal";
import { useModal } from "@hooks/useModal";

interface Props {
  saleId: string;
}

export const StorageDropdown = ({ saleId }: Props) => {
  const modal = useModal();

  return (
    <Fragment>
      <StorageActionTable saleId={saleId} />
      <div className="estimate-input--wrapper empty">
      <Button
        name="Faire une demande de débarras"
        onClick={() => modal.open(StorageRequestModal.name)} />
      </div>  
      <StorageRequestModal saleId={saleId} />
    </Fragment>
  )
}