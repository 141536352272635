import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { Form } from "@components/form/Form";
import { ExternalPropertyDesc } from "@components/ExternalPropertyDesc";
import Input from "@components/inputs/Input";
import { Button } from "@components/buttons/Buttons";
import { ExternalNotificationCard } from "@components/ExternalNotificationCard";
import { trpc } from "@client/index";
import { getQueryKey } from "@trpc/react-query";
import { Fragment } from "react";
import { IndivisionSchema, OfferDepositSchema } from "@schemas/offer.schema";
import { formatPrice } from "@utils/utils";
import { Toggle } from "@components/inputs/Toggle";
import type { Mandate } from "@interfaces/mandate.interface";

const IndivisionForm = () => {
  const queryClient = useQueryClient();

  const formMethods = useForm<z.infer<typeof IndivisionSchema>>({
    resolver: zodResolver(IndivisionSchema),
    defaultValues: {
      informations: "",
      price: undefined,
      hasSuspensiveConditions: "no",
      suspensiveConditions: "",
    },
  });

  const hasSuspensiveConditions = formMethods.watch("hasSuspensiveConditions");

  const mutation = trpc.external.offer.indivision.useMutation({
    onSuccess() {
      toast.success("Merci d'avoir soumis ce formulaire");

      formMethods.reset();
    },
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.external.offer.depositGetResources) });
    }
  });

  return (
    <Form formMethods={formMethods}>
      <p>Vous pouvez signaler au mandataire qu'une offre est soumise pour ce bien mais vous devrez la replublier via ce lien avec la signature de tout les indivisaires.</p>

      <Input
        type="number"
        name="price"
        placeholder="Prix net vendeur de l'offre"
        border />

      <Input.Textarea
        name="informations"
        placeholder="Informations complémentaires"
        border />

      <p>Y a-t-il des conditions suspensives ?</p>
      <Toggle
        name="hasSuspensiveConditions"
        darker
        choices={[
          { label: "Oui", value: "yes" },
          { label: "Non", value: "no" }
        ]} />

      {hasSuspensiveConditions === "yes" && (
        <Input.Textarea
          name="suspensiveConditions"
          placeholder="Conditions suspensives"
          border />
      )}

      <Form.Actions centered>
        <Button
          name="Valider"
          loading={mutation.isPending}
          onClick={formMethods.handleSubmit((values) => mutation.mutate(values))} />
      </Form.Actions>
    </Form>
  )
}

interface DepositFormProps {
  mandate: Mandate;
}

const DepositForm = ({ mandate }: DepositFormProps) => {
  const queryClient = useQueryClient();

  const formMethods = useForm<z.infer<typeof OfferDepositSchema>>({
    resolver: zodResolver(OfferDepositSchema),
    defaultValues: {
      buyerCni: null,
      buyerName: "",
      informations: "",
      notaryName: "",
      offer: null,
      price: undefined,
      hasSuspensiveConditions: "no",
      suspensiveConditions: "",
      freePieces: []
    },
  });

  const hasSuspensiveConditions = formMethods.watch("hasSuspensiveConditions");

  const mutation = trpc.external.offer.deposit.useMutation({
    onSuccess() {
      toast.success("Merci d'avoir déposé votre offre");

      formMethods.reset();
    },
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.external.offer.depositGetResources) });
    }
  });

  const handleSubmit = (body: z.infer<typeof OfferDepositSchema>) => {
    const formData = new FormData();

    const { freePieces, ...params } = body;

    for (const name in params) {
      // @ts-expect-error
      formData.append(name, params[name]);
    }

    for (const freePiece of (freePieces || [])) {
      formData.append("freePieces[]", freePiece);
    }

    mutation.mutate(formData);
  }

  return (
    <Form formMethods={formMethods}>
      <h3>
        Prix net vendeur du mandat {formatPrice(mandate.price)}
      </h3>

      <Input
        name="buyerName"
        placeholder="Nom de l'acquéreur"
        border />

      <Input
        type="number"
        name="price"
        placeholder="Prix net vendeur de l'offre"
        border />

      <Input
        name="notaryName"
        placeholder="Nom du notaire acquéreur (facultatif)"
        border />

      <Input.File
        name="offer"
        label="Insérer l'offre en PDF"
        accept="application/pdf" />

      <Input.File
        name="buyerCni"
        label="Insérer la CNI de l'acquéreur en PDF"
        accept="application/pdf" />

      <Input.File
        name="freePieces[]"
        label="Insérer d'autres pièces"
        multiple
        accept="application/pdf" />

      <Input.Textarea
        name="informations"
        placeholder="Informations complémentaires"
        border />

      <p>Y a-t-il des conditions suspensives ?</p>
      <Toggle
        name="hasSuspensiveConditions"
        darker
        choices={[
          { label: "Oui", value: "yes" },
          { label: "Non", value: "no" }
        ]} />

      {hasSuspensiveConditions === "yes" && (
        <Input.Textarea
          name="suspensiveConditions"
          placeholder="Conditions suspensives"
          border />
      )}

      <Form.Actions centered>
        <Button
          name="Valider"
          loading={mutation.isPending}
          onClick={formMethods.handleSubmit(handleSubmit)} />
      </Form.Actions>
    </Form>
  )
}

const FormSchema = z.object({
  isIndivision: z.enum(["yes", "no"]),
  isSignedByAllIndivisaires: z.enum(["yes", "no"]),
});

export const MandateOfferDeposit = () => {
  const formMethods = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      isIndivision: "no",
      isSignedByAllIndivisaires: "yes",
    },
  });

  const [isIndivision, isSignedByAllIndivisaires] = formMethods.watch(["isIndivision", "isSignedByAllIndivisaires"]);

  const guardQueryResult = trpc.external.offer.depositGetResources.useQuery(undefined, {
    retry: false,
  });

  const errorStatus = guardQueryResult.error?.data?.httpStatus;

  if (errorStatus === 403 || errorStatus === 404) {
    return <ExternalNotificationCard title="Cette page n'est plus disponible" />
  }

  if (typeof errorStatus === "number") {
    return <ExternalNotificationCard title="Une erreur est survenue. Merci de réessayer plus tard." />
  }

  if (!guardQueryResult.data) {
    return null;
  }

  const { mandate, sale } = guardQueryResult.data;

  const address = sale.expand.address;

  return (
    <div className="form--container">
      <div className="mailForm last">
        <h1 className="title">Dépôt offre</h1>

        <ExternalPropertyDesc address={address} />

        <Form formMethods={formMethods}>

          <Toggle
            name="isIndivision"
            darker
            choices={[
              { label: "Déposer une offre en indivision", value: "yes" },
              { label: "Déposer une offre SANS indivisaire", value: "no" }
            ]} />

          {isIndivision === "yes" && (
            <Fragment>
              <p>
                Pour pouvoir être signé par le mandataire, une offre doit être préalablement signée par l'ensemble des indivisaires.<br />

                <b>L'offre est-elle signée par l'ensemble des indivisaires ?</b>
              </p>

              <Toggle
                name="isSignedByAllIndivisaires"
                darker
                choices={[
                  { label: "Oui", value: "yes" },
                  { label: "Non", value: "no" }
                ]} />

            </Fragment>
          )}
        </Form>

        {isIndivision === "yes"
          ? <Fragment>
            {isSignedByAllIndivisaires === "yes"
              ? <DepositForm mandate={mandate as Mandate} />
              : <IndivisionForm />}
          </Fragment>
          : <DepositForm mandate={mandate as Mandate} />}

      </div>
    </div>
  )
}
