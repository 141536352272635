import { Button } from "@components/buttons/Buttons";
import { Table } from "@components/table/Table";
import { usePb } from "@hooks/usePb";
import { protectorRoleToFR, type User } from "@interfaces/user.interface";
import { type ColumnDef, type SortingState, useReactTable, getSortedRowModel, getCoreRowModel, getPaginationRowModel, getFilteredRowModel, getExpandedRowModel, createColumnHelper } from "@tanstack/react-table";
import React, { Fragment } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { trpc } from "@client/index";

const columnHelper = createColumnHelper<User>();

export const RegisterTable = () => {
  const pb = usePb();
  const queryClient = useQueryClient();

  const { data } = useQuery<any>({
    queryKey: ["registers"],
    queryFn: async () => {
      return pb.collection("users").getList(1, 20, {
        filter: "acceptedAt = '' && rejectedAt = ''",
      })
    }
  });

  const rejectMutation = useMutation({
    mutationFn: async (id: string) => {
      return pb.collection("users").update(id, {
        verified: false,
        rejectedAt: new Date(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['registers'] });

      toast.success(`L\'inscription a été rejetée.`);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const acceptMutation = trpc.admin.user.confirmUser.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['registers'] });

      toast.success(`L\'inscription a été acceptée.`);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const columns = React.useMemo<ColumnDef<User>[]>(
    () => [
      columnHelper.display({
        header: "Mandataire",
        cell: ({ row }) => `${row.original.lastname} ${row.original.firstname}`,
      }),
      columnHelper.display({
        header: "Type",
        cell: ({ row }) => protectorRoleToFR[row.original.role],
      }),
      columnHelper.display({
        id: 'status',
        header: "Statut",
        cell: "En attente",
      }),
      columnHelper.accessor("phone", {
        header: "Téléphone",
        cell: Table.BasicCell,
      }),
      {
        accessorKey: 'email',
        header: "Email",
        enableSorting: true,
        cell: info => info.getValue(),
      },
      {
        accessorKey: 'department',
        header: "Département",
        enableSorting: true,
        cell: info => info.getValue(),
      },
      columnHelper.display({
        id: 'actions',
        header: "Actions",
        cell: ({ row }) => (
          <Fragment>
            <Button.Small
              name="Accepter"
              onClick={() => acceptMutation.mutate({ userId: row.original.id })}
              loading={acceptMutation.isPending} />

            <Button.Small
              name="Rejeter"
              onClick={() => rejectMutation.mutate(row.original.id)}
              loading={rejectMutation.isPending}
              dark />
          </Fragment>
        ),
      })
    ],
    []
  )

  const users = data?.items || [];

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data: users,
    columns,
    state: {
      sorting
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: false,
  })

  return (
    <Table table={table} />
  )
}