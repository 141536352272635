import { z } from "zod";
import { validateFile } from "./shared.schema";

export const CreateEstimateSchema = z
  .object({
    participation: z.enum(["with-you", "without-you"]),
    keysAddress: z.string().optional(),
    keysInstructions: z.string().optional(),
    otherInstructions: z.string().optional(),
    nameThirdParty: z.string().optional(),
    namePhone: z.string().optional(),
    meetingType: z
      .enum(["with-third-party", "with-keys", "with-other"])
      .optional(),
    numberOfParticipants: z.coerce.number(),
    slots: z
      .array(
        z.coerce
          .date({ required_error: "Ce champ est requis." })
          .min(new Date(), { message: "Ce champ est requis." })
      )
      .optional()
      .default([]),
  })

export const CreateSheetSchema = z.object({
  surface: z.coerce.number().min(1).max(999999),
  numberOfRooms: z.coerce.number(),
  yearOfConstruction: z.coerce.number(),
  typeOfBuilding: z.string().min(1),
  numberOfFloors: z.any().optional(),
  typeOfHeating: z.string().optional(),
})

export const CreateEstimateCompanySchema = z.object({
  sellerPriceLow: z
    .string({ required_error: "Ce champ est requis." })
    .min(1, { message: "Ce champ est requis" }),
  sellerPriceHigh: z
    .string({ required_error: "Ce champ est requis." })
    .min(1, { message: "Ce champ est requis" }),
  ownerName: z.string().min(1),
  report: validateFile()
});

export const CreateActualisedEstimateCompanySchema = CreateEstimateCompanySchema.pick({
  sellerPriceHigh: true,
  sellerPriceLow: true,
  report: true
});

export const CreateEstimateWithYouSchema = z.object({
  phone: z
    .string({ required_error: "Ce champ est requis." })
    .min(1, { message: "Ce champ est requis" }),
  slots: z
    .array(z.string())
    .min(1, { message: "Ce champ est requis" }),
})

export const WarnProtectorSchema = z.object({
  price: z.enum(["price-net", "price-agency"]),
  owner: z.enum(["owner-yes", "owner-no"]),
});

export const ConfirmSlotSchema = z.object({
  startTime: z.coerce
    .date({ required_error: "Ce champ est requis." })
});


export type WarnProtector = z.infer<typeof WarnProtectorSchema>;
export type CreateEstimate = z.infer<typeof CreateEstimateSchema>;
export type CreateSheet = z.infer<typeof CreateSheetSchema>;
export type CreateEstimateCompany = z.infer<typeof CreateEstimateCompanySchema>;
