export enum TypeOfBuilding {
  maison = "maison",
  appartement = "appartement"
}

export interface Address {
  id: string;
  street: string;
  zipcode: string;
  department: string;
  city: string;
  surface: string;
  numberOfRooms: number;
  yearOfConstruction: number;
  typeOfBuilding: TypeOfBuilding;
  numberOfFloors: number;
  typeOfHeating: string;
  isComplete: boolean;
}

export const zipcodesToDepartments = (zipcodes: string) => {
  if (!zipcodes.includes(',')) {
    return zipcodes.substring(0, 2);
  }

  return zipcodes.split(',')
    .map(zipcode => zipcode.substring(0, 2))
    .join(',');
}

export function formatFullAddress(address: Address): string {
  return `${address.street}, ${address.zipcode} ${address.city}`;
}
