import { Button } from "@components/buttons/Buttons";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input.tsx";
import { Toggle } from "@components/inputs/Toggle";
import "./EstimateForm.scss";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CreateEstimateSchema } from "@schemas/estimate.schema";
import { z } from "zod";
import { Select } from "@components/inputs/Select";
import { toast } from "react-toastify";
import { Fragment, useEffect } from "react";
import { useModal } from "@hooks/useModal";
import { Helper } from "@components/Helper";
import Modal from "@components/modal/Modal";
import { useNavigate } from "react-router-dom";
import { trpc } from "@client/index";
import { slotsForThree, slotsForTwo } from "@interfaces/sale.interface";
import { formatTime, isDateValid } from "@utils/utils";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";

const informations = `Votre demande sert à obtenir 2 ou 3 estimations d'agences immobilières dans le cadre d’un projet de vente d’un bien immobilier et non d’un inventaire de patrimoine.

Veuillez noter qu’il sera indiqué aux agences qui répondront favorablement à votre demande que l’estimation est faite dans le cadre d’un projet de vente d'où le caractère gratuit de leur intervention.

Les estimations obtenues seront valables dans le cadre d'une requête pour vente au juge, mais ne peuvent pas être demandées pour un inventaire d'ouverture de mesure, ni d’un partage ou d’une déclaration successorale.

Pour toutes questions : `;

interface Props {
  saleId: string;
}

const today = new Date();

const minDate = today.toISOString().slice(0, 16);

const ThirdPartyView = () => (
  <div className="estimate-input--wrapper empty">
    <Input
      name="nameThirdParty"
      placeholder="Nom et prénom du tiers..."
    />
    <Input
      name="namePhone"
      type="tel"
      placeholder="Téléphone du tiers..."
    />
  </div>
);

const KeysView = () => (
  <div className="estimate-input--wrapper empty">

    <Input
      name="keysAddress"
      type="tel"
      placeholder="Adresse où récupérer les clés..."
    />
    <Input
      name="keysInstructions"
      placeholder="Instructions..."
    />
  </div>
);

const OtherView = () => (
  <>
    <div className="estimate-input--wrapper empty">
      <Input
        name="otherInstructions"
        placeholder="Champ libre…"
      />
    </div>
  </>
);

const SlotDescription = ({ index }: { index: number }) => {
  const formContext = useFormContext<z.infer<typeof FormSchema>>();

  const [slot, numberOfParticipants] = formContext.watch([`slots.${index}`, "numberOfParticipants"]);

  const date = new Date(slot);

  const slots = numberOfParticipants.toString() === "2"
    ? slotsForTwo(date)
    : slotsForThree(date);

  const duration = numberOfParticipants.toString() === "2" ? "1H30" : "2H";

  return (
    <div className="desc--horaires">
      <span>Durée : {duration}</span>

      {isDateValid(date) && (
        <ul>
          {slots.map(slot => {
            const startTime = formatTime(slot.startTime);
            const endTime = formatTime(slot.endTime);

            return (
              <li key={startTime}>{startTime} - {endTime}</li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

const NewSlot = ({
  index,
  onRemove,
}: {
  index: number;
  onRemove: () => void;
}) => {
  return (
    <div className="form-friends">
      <div className="estimate-input--wrapper duo">
        <Input
          name={`slots.${index}`}
          type="datetime-local"
          placeholder="Date (JJ/MM/AA HH:MM)"
          min={minDate} />

        <SlotDescription index={index} />

        <Helper description="Chaque agence disposera d’un créneau séparé de 40min ou 45min avec vous sur place." />
      </div>

      {index >= 1 && (
        <button
          className="c-slot--remove"
          aria-label="Enlever le créneau supplémentaire"
          onClick={onRemove}
        />
      )}
    </div>
  );
};

const MeetingTypes = {
  "with-third-party": ThirdPartyView,
  "with-keys": KeysView,
  "with-other": OtherView,
};

const FormSchema = CreateEstimateSchema
  .superRefine((data, ctx) => {
    if (data.participation === "with-you" && data.slots.length === 0) {

      toast.error("Merci de compléter tout le formulaire.");

      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Missing slot",
        path: ["slots"],
      });
    }

    if (data.participation === "without-you" && data.meetingType === "with-third-party" && (!data.nameThirdParty || !data.namePhone)) {

      toast.error("Merci de compléter tout le formulaire.");

      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "nameThirdParty and namePhone are required when meetingType is with-third-party",
        path: ["meetingType"],
      });
    }

    if (data.participation === "without-you" && data.meetingType === "with-keys" && (!data.keysAddress || !data.keysInstructions)) {

      toast.error("Merci de compléter tout le formulaire.");

      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "keysAddress and keysInstructions are required when meetingType is with-keys",
        path: ["meetingType"],
      });
    }

    if (data.participation === "without-you" && data.meetingType === "with-other" && !data.otherInstructions) {

      toast.error("Merci de compléter tout le formulaire.");

      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "otherInstructions is required when meetingType is with-other",
        path: ["meetingType"],
      });
    }
  });

function EstimateForm({ saleId }: Props) {
  const modal = useModal();
  const naviguate = useNavigate();
  const queryClient = useQueryClient();

  const formMethods = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      participation: "with-you",
      keysAddress: undefined,
      keysInstructions: undefined,
      otherInstructions: undefined,
      nameThirdParty: undefined,
      namePhone: undefined,
      meetingType: "with-third-party",
      numberOfParticipants: 2,
      slots: [],
    },
  });

  const slots = useFieldArray({
    // @ts-expect-error
    name: "slots",
    control: formMethods.control,
  });

  const [meetingType, participation] = formMethods.watch([
    "meetingType",
    "participation",
  ]);

  const estimateQuery = trpc.sale.estimate.getEstimates.useQuery({ saleId }, {
    enabled: !!saleId,
  });

  const hasEstimates = (estimateQuery?.data || []).length > 1;

  const MeetingTypeView = MeetingTypes[meetingType!] || null;

  useEffect(() => {
    if (participation === "with-you") {
      // @ts-expect-error
      formMethods.setValue("slots", [undefined])
    } else {
      formMethods.setValue("slots", []);
    }
  }, [participation]);

  const mutation = trpc.sale.estimate.create.useMutation({
    onSuccess: () => {
      formMethods.reset({ slots: [undefined] });

      modal.close("ConfirmModal");

      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.getSaleDropwdownsStatus) });

      toast.success(`Votre demande d\'estimation a été créée avec succès.`);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    }
  });

  const onSubmit = async () => {
    const isValid = await formMethods.trigger();

    if (isValid) {
      modal.open("ConfirmModal");
    }
  }

  return (
    <Form formMethods={formMethods} formProps={{ className: "form-estimate" }}>
      <div className="estimate-input--wrapper">
        <Select
          name="numberOfParticipants"
          options={[
            { value: "2", label: "Nombre d'agences souhaitées : 2 (deux)" },
            { value: "3", label: "Nombre d'agences souhaitées : 3 (trois)" },
          ]} />

        <Helper description="L’ensemble des agences référencées sur le département dans lequel se situe le bien recevront votre demande. Les deux ou trois premières agences ayant confirmé leur disponibilité auront le créneau. Seules ces agences recevront l’adresse précise du bien et le nom du propriétaire." />

      </div>

      <div className="estimate-input--wrapper">
        <Toggle
          name="participation"
          choices={[
            {
              label: "Avec vous",
              value: "with-you",
            },
            {
              label: "Sans vous",
              value: "without-you",
            },
          ]} />

        <Helper description="Rendez-vous avec un tiers demandera aux agences d'appeler une personne tierce pour fixer un rendez-vous. Récupérer les clés indiquera aux agences de venir récupérer les clées à un endroit donné (ex : vos bureaux) et de les redéposer. Le champ libre vous permettra de formuler une demande spécifique (ex: besoin devis serrurier)." />
      </div>

      {participation === "without-you" ? (
        <div className="without-you">
          <div className="estimate-input--wrapper empty">

            <Select
              name="meetingType"
              options={[
                { value: "with-third-party", label: "Rendez-vous avec un tiers" },
                { value: "with-keys", label: "Récupérer les clés" },
                { value: "with-other", label: "Autre" },
              ]}
            />
          </div>

          <MeetingTypeView />
        </div>
      ) : (
        <div className="with-you">
          {slots.fields.map((slot, index) => (
            <NewSlot
              key={slot.id}
              index={index}
              onRemove={() => slots.remove(index)}
            />
          ))}

          <div className="estimate-input--wrapper">

            <Button
              className="c-btn--outline"
              name="Proposer un autre créneau"
              onClick={() => slots.append(undefined)} />

            <Helper description="Plus vous proposerez de créneaux plus vous aurez de chance d’avoir une réponse positive." />
          </div>
        </div>
      )}

      <Modal id="ConfirmModal" title="Confirmer la demande d'estimation">

        <p style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}>
          {informations}
        </p>

        <Button
          className="c-btn--outline"
          name="Contact"
          onClick={() => naviguate("/dashboard/contact")} />

        <Button
          name="Valider la demande d'estimation"
          onClick={formMethods.handleSubmit((values) => mutation.mutate({ saleId, ...values }))}
          loading={mutation.isPending} />

      </Modal>

      <div className="estimate-input--wrapper empty">
        <Button
          name={hasEstimates ? "Refaire une demande d’estimation auprès d’autres agences" : "Faire une demande d'estimation"}
          onClick={onSubmit} />
      </div>
    </Form>
  );
}

export default EstimateForm;
