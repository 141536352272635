import { useModal } from "@hooks/useModal";
import Modal, { type ModalProps } from "./Modal";
import { Button } from "@components/buttons/Buttons";
import { DragNdropUpload } from "@components/inputs/DragNdropUpload";

interface Props extends Pick<ModalProps, 'id' | 'title'> {
  name: string;
  isLoading?: boolean;
  multiple?: boolean;
  onConfirm: () => void;
}

export const UploadModal = ({ name, onConfirm, isLoading, multiple,...modalProps }: Props) => {
  const modal = useModal();

  return (
    <Modal {...modalProps}>
      <div>
        <DragNdropUpload name={name} multiple={multiple} />

        <Button
          name="Confirmer"
          loading={isLoading}
          onClick={onConfirm} />
      </div>
    </Modal>
  );
};
