import { useLocation, useNavigate } from "react-router-dom";
import "./AdminAside.scss";
import AlertsPanel from "./AlertsPanel";

const dashboardItems = [
  { label: "Inscriptions", link: "/admin/registers" },
  { label: "Mandataires", link: "/admin/users" },
  { label: "Entreprises", link: "/admin/companies" },
  { label: "Ventes", link: "/admin/sales" },
  { label: "Estimations", link: "/admin/estimates" },
  { label: "Mandats", link: "/admin/mandates" },
  { label: "Offres", link: "/admin/offers" },
  { label: "Devis diagnostics", link: "/admin/diagnostics" },
  { label: "Devis debarras", link: "/admin/storages" },
  { label: "Titre de propriété", link: "/admin/property-titles" },
  { label: "Pièces de copropriété", link: "/admin/coproperty-titles" },
  { label: "Notaires", link: "/admin/notary-files" },
  { label: "Commissions", link: "/admin/commissions" },
  { label: "Biens à l'étranger", link: "/admin/abroad-goods" },
  { label: "Ventes stoppées", link: "/admin/stopped-sales" },
];

export const AdminAside = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="c-side" style={{background: '#04043e', color: 'white', textAlign: 'center'}}>

      <figure>
        <img src="/assets/icons/logo.svg" alt="Logo" />
      </figure>

      <br/>

      <hr/><br/>




      <div className="cursor-pointer name" onClick={() => navigate("/admin/me")}>
        <h2 style={{cursor: 'pointer'}}>Administrateur</h2>
      </div>
   

      <AlertsPanel/>

      <br/><br/>

      <div className="c-side--header dashboard">

        <ul>
          {dashboardItems.map((item) => (
            <li key={item.label} className={`${location.pathname === item.link ? "active" : ""}`}>
              <a onClick={() => navigate(item.link)}>{item.label}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}