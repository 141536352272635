import { Fragment } from "react";
import "./PropertyDocsList.scss";
import { Button } from "@components/buttons/Buttons";
import { usePbDownload } from "@hooks/usePbDownload";
import { trpc } from "@client/index";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { getQueryKey } from "@trpc/react-query";
import type { SalePiece } from "@interfaces/salePieces.interface";

interface Props {
  saleId: string;
  salePiece: SalePiece;
  freePiece: string;
}

export const FreeSalePiece = ({ saleId, salePiece, freePiece }: Props) => {
  const queryClient = useQueryClient();
  const downloadMutation = usePbDownload();

  const mutation = trpc.sale.salePiece.freePiece.remove.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.salePiece.getBySaleId) });
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  return (
    <Fragment>
      <li className="valid">
        <p>{freePiece}</p>
        <div>

          <Button.Small
            xs
            valid
            name="Supprimer"
            onClick={() => mutation.mutate({ saleId, filename: freePiece })} />

          <Button.Small
            xs
            valid
            name="Télécharger"
            onClick={() => downloadMutation.mutate({ resource: salePiece, filename: freePiece })} />

        </div>
      </li>
    </Fragment>
  );
};
