import "./SaleAside.scss";

export const SaleGoodAbroadAside = () => {
  return (
    <div className={"c-side textuel"}>
      <div className="c-side--header textuel">
      <h1>
       AU SUJET DES BIENS À L'ÉTRANGER
      </h1>
      <p>
        Notre structure peut vous assister sur des sujets à l'étranger
        uniquement sur des projets de vente de biens immobiliers.
      </p>
      <p>
        Nous vous proposons dans un premier temps de prendre rendez-vous en
        ligne avec notre équipe, afin de convenir d'un échange téléphonique qui
        nous permettra de vous indiquer si nous sommes en mesure d'intervenir
        sur votre sujet, selon le pays concerné et les éléments dont vous
        disposez.
      </p>
      </div>
    </div>
  );
};
