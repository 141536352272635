import { Fragment, useState } from "react";
import "./PropertyDocsList.scss";
import { useModal } from "@hooks/useModal";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { trpc } from "@client/index";
import { useQueryClient } from "@tanstack/react-query";
import { formatFullAddress } from "@interfaces/address.interface";
import { toast } from "react-toastify";
import { getQueryKey } from "@trpc/react-query";
import Modal from "@components/modal/Modal";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input";
import { Button } from "@components/buttons/Buttons";
import { CreatePropertyTitleRequestSchema, UploadSalePieceDocumentSchema } from "@schemas/salePiece.schema";
import { UploadModal } from "@components/modal/UploadModal";
import { usePbDownload } from "@hooks/usePbDownload";
import { downloadPublicFile } from "@utils/utils";
import { PropertyTitleRequestState } from "@interfaces/salePieces.interface";

interface Props {
  saleId: string;
  readOnly: boolean;
}

const Requests = ({ saleId }: Props) => {
  const modal = useModal();
  const queryClient = useQueryClient();

  const [isRIBDownloaded, setIsRIBDownloaded] = useState(false);

  const { data: salePieceData } = trpc.sale.salePiece.getBySaleId.useQuery(saleId);

  const address = salePieceData?.expand.sale.expand?.address;

  const formMethods = useForm<z.infer<typeof CreatePropertyTitleRequestSchema>>({
    resolver: zodResolver(CreatePropertyTitleRequestSchema),
    defaultValues: {
      ownerName: "",
      lotNumber: "",
    },
  });

  const mutation = trpc.sale.salePiece.propertyTitle.updateFields.useMutation({
    onSuccess: () => {
      formMethods.reset();
      modal.close("payment-title");
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.salePiece.getBySaleId) });
      toast.success("Votre demande de titre de propriété a bien été prise en compte");
    },
    onError: () => {
      toast.error("Erreur lors de l'envoi de la demande de titre de propriété.");
    },
  });

  const downloadRIB = () => {
    downloadPublicFile("RIB_ATHENA.pdf", "Athena - RIB.pdf");
    setIsRIBDownloaded(true);
  };

  const onConfirm = async () => {
    const isValid = await formMethods.trigger();
    if (isValid) {
      modal.close("request-title");
      modal.open("payment-title");
    }
  };

  return (
    <Fragment>
      <Modal id="request-title" title="Demande de titre de propriété - 1/2">
        <Form formMethods={formMethods}>
          <Input name="ownerName" placeholder="Nom et Prénom du propriétaire" />
          <input
            className="c-input"
            value={address && formatFullAddress(address)}
            placeholder="Adresse"
            disabled
            readOnly
          />
          <Input name="lotNumber" placeholder="Numéro du lot (facultatif)" />
        </Form>

        <Button name="Confirmer la demande" onClick={onConfirm} />
      </Modal>

      <Modal id="payment-title" title="Demande de titre de propriété - 2/2">
        <p>Un versement de 48€ vous est demandé pour la recherche du titre.</p>
        <p>
          Vous pouvez télécharger notre RIB et faire un virement en mettant en
          motif le nom du propriétaire.
        </p>

        <Button name="Télécharger le RIB" onClick={downloadRIB} />

        <Button
          name="Valider la demande"
          disabled={!isRIBDownloaded}
          loading={mutation.isPending}
          onClick={formMethods.handleSubmit((values) => {
            mutation.mutate({ salePieceId: salePieceData?.id!, ...values });
            modal.close("payment-title");
          })}
        />
      </Modal>
    </Fragment>
  );
};

const UPLOAD_MODAL_ID = "UPLOAD_MODAL_ID";

export const ProperyTitle = ({ saleId, readOnly = false }: Props) => {
  const modal = useModal();
  const queryClient = useQueryClient();
  const downloadMutation = usePbDownload();

  const { data: salePieceData } = trpc.sale.salePiece.getBySaleId.useQuery(saleId);
  const { data: isAddressCompleted } = trpc.sale.estimate.isAddressCompleted.useQuery({ saleId: saleId! });

  const formMethods = useForm<z.infer<typeof UploadSalePieceDocumentSchema>>({
    resolver: zodResolver(UploadSalePieceDocumentSchema),
    defaultValues: {
      document: undefined,
    },
  });

  const mutation = trpc.sale.salePiece.propertyTitle.upload.useMutation({
    onSuccess: () => {
      formMethods.reset();
      modal.close(UPLOAD_MODAL_ID);
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.salePiece.getBySaleId) });
      toast.success("Le titre de propriété a bien été envoyé.");
    },
    onError: () => {
      toast.error("Une erreur est survenue.");
    },
  });

  const handleDelete = trpc.sale.salePiece.propertyTitle.remove.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.salePiece.getBySaleId) });
      toast.success("Le document a bien été supprimé.");
    },
    onError: () => {
      toast.error("Erreur lors de la suppression.");
    },
  });

  const handleDeleteConfirmation = () => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer ce document ?");
    if (confirmDelete) {
      handleDelete.mutate({ saleId });
    }
  };

  const isUploaded = Boolean(salePieceData?.propertyTitle);

  const isWaiting =
    !isUploaded && salePieceData?.expand?.propertyTitleRequests_via_salePiece?.state === PropertyTitleRequestState.waiting;

  const status = isUploaded ? "valid" : isWaiting ? "asked" : "non-valid";

  const handleSubmit = (body: z.infer<typeof UploadSalePieceDocumentSchema>) => {
    const formData = new FormData();
    formData.append("saleId", saleId);
    formData.append("document", body.document);
    mutation.mutate(formData);
  };

  const handleDownload = () => {
    if (salePieceData?.propertyTitle) {
      downloadMutation.mutate({
        resource: salePieceData,
        filename: salePieceData.propertyTitle,
      });
    } else {
      toast.error("Aucun document disponible à télécharger.");
    }
  };

  return (
    <Fragment>
      <li className={status}>
        <p>Titre de propriété</p>
        <div>
          {isWaiting ? (
            <Button.Small xs valid name="Demande en cours" disabled />
          ) : (
            <Fragment>
              <Button.Small
                xs
                valid
                name={isUploaded ? "Modifier" : "Ajouter"}
                // disabled={!isAddressCompleted}
                onClick={() => modal.open(UPLOAD_MODAL_ID)}
              />

              {isUploaded && (
                <Fragment>
                  <Button.Small
                    xs
                    valid
                    name="Supprimer"
                    onClick={handleDeleteConfirmation}
                  />
                  <Button.Small xs valid name="Télécharger" onClick={handleDownload} />
                </Fragment>
              )}

              {(!isUploaded && !readOnly) && (
                <Button.Small
                  xs
                  valid
                  name="Demander"
                  disabled={!isAddressCompleted}
                  onClick={() => modal.open("request-title")}
                />
              )}
            </Fragment>
          )}
        </div>
      </li>

      <Form formMethods={formMethods}>
        <UploadModal
          id={UPLOAD_MODAL_ID}
          name="document"
          title="Titre de propriété"
          isLoading={mutation.isPending}
          onConfirm={formMethods.handleSubmit(handleSubmit)}
        />
      </Form>

      <Requests saleId={saleId} />
    </Fragment>
  );
};
