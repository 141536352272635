import { Button } from "@components/buttons/Buttons";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { z } from "zod";
import { Fragment } from "react";
import { useLogout } from "@hooks/useLogout";

const FormValues = z.object({
  password: z.string().min(8),
  passwordConfirm: z.string().min(8),
}).superRefine(({ passwordConfirm, password }, ctx) => {
  if (passwordConfirm !== password) {
    ctx.addIssue({
      code: "custom",
      message: "Les mots de passent ne concordent pas",
      path: ['passwordConfirm']
    });
  }
});

export const Me = () => {
  const formMethods = useForm({
    resolver: zodResolver(FormValues),
    defaultValues: {
      password: '',
      passwordConfirm: ''
    }
  });

  const { mutate: logout } = useLogout();

  const { mutate, isPending } = useMutation({
    mutationFn: async (body: z.infer<typeof FormValues>) => {
      return axios.put('/api/admin/profile', body);
    },
    onSuccess: () => {
      toast.success(`Votre mot de passe a bien été modifié.`);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  return (
    <Fragment>
      <Form.DefaultLayout formMethods={formMethods} title="Modifier le mot de passe">
        <Input
          name="password"
          type="password"
          autoComplete="new-password"
          placeholder="Mot de passe"
          border
        />

        <Input
          name="passwordConfirm"
          type="password"
          autoComplete="new-password"
          placeholder="Mot de passe confirmation"
          border
        />

        <Button
          name="Modifier"
          loading={isPending}
          onClick={formMethods.handleSubmit(values => mutate(values))}
        />

        <Button name="Se déconnecter" onClick={() => logout()} />

      </Form.DefaultLayout>
    </Fragment>
  )
}