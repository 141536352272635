import type { Base, ID } from "@interfaces/shared.interface";
import type { Company } from "./company.interface";
import { formatDate } from "@utils/utils";

export interface Slot extends Base {
  startTime: string;
  endTime: string;
  duration: number;
  slug: string;
  bookedBy: ID[];
  assignedTo?: ID;
  expand: {
    assignedTo: Company;
  }
}

export interface BookableRecord {
  slots: string[];
  bookableCompanies: string[];
  expand: {
    slots: Slot[];
    bookableCompanies: Company[];
  }
}

export const getSlotsGroupedBySlug = (slots: Slot[]): Record<string, Slot[]> => {
  return slots.reduce((acc, slot) => {
    if (!acc[slot.slug]) {
      acc[slot.slug] = [];
    }

    acc[slot.slug].push(slot);

    return acc;
  }, {} as Record<string, Slot[]>);
}

export const sortSlotByStartTime = (a: Slot, b: Slot) => {
  return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
}

export const getBookableRecordBookedInfos = <T extends BookableRecord>(record: T, numberOfParticipants: number) => {
  const slots = record.expand.slots;

  const bookedBy = slots.flatMap(slot => slot.bookedBy);

  const selectedCompaniesIds = bookedBy.filter(companyId => slots.find(slot => slot.assignedTo === companyId));
  const rejectedCompaniesIds = record.bookableCompanies.filter(companyId => !selectedCompaniesIds.includes(companyId));

  const isBooked = selectedCompaniesIds.length >= numberOfParticipants;

  return {
    selectedCompaniesIds,
    rejectedCompaniesIds,
    isBooked
  }
}

function formatTime(date: string): string {
  return new Date(date).toLocaleTimeString("fr-FR", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/Paris"
  });
}

export function getGroupedAndFormattedSlotsByDate(slots: Slot[]) {
  const formattedDates = {} as Record<
    string,
    { label: string; dates: { start: string | Date; end: string | Date }[] }
  >;

  slots.forEach((slot) => {
    const key = formatDate(slot.startTime);
    if (!formattedDates[key]) {
      formattedDates[key] = {
        label: key,
        dates: [
          { start: formatTime(slot.startTime), end: formatTime(slot.endTime) },
        ],
      };
    } else {
      formattedDates[key].dates.push({
        start: formatTime(slot.startTime),
        end: formatTime(slot.endTime),
      });
    }
  });

  return Object.values(formattedDates);
}