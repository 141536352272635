import type { Base, ID } from "@interfaces/shared.interface";
import type { Address } from "@interfaces/address.interface";
import type { Company } from "@interfaces/company.interface";
import type { Protectee } from "@interfaces/protectee.interface";
import type { User } from "@interfaces/user.interface";
import type { Slot } from "@interfaces/slot.interface";
import type { CompanyOffer } from "./offer.interface";
import type { CompanyReport, Mandate } from "./mandate.interface";
import { v4 as uuidv4 } from "uuid";
import type { SalePiece } from "./salePieces.interface";
import type { Diagnostic } from "./diagnostic.interface";
import type { EnergyAudit } from "./energyAudit.interface";

export enum SaleState {
  started = "started",
  finalized = "finalized",
  "in-progress" = "in-progress",
  canceled = "canceled",
  stopped = "stopped",
  archived = "archived"
}

export interface Sale extends Base {
  protectee: ID;
  protector: ID;
  address: ID;
  state: SaleState;
  isEnergyAuditRequired: boolean;
  cause: string;
  keysInstructions: string;
  expand: {
    address: Address;
    protectee: Protectee;
    protector: User;
    companyOffers_via_sale: CompanyOffer[];
    mandates_via_sale: Mandate[];
    salePieces_via_sale: SalePiece[];
    estimates_via_sale: Estimate[];
    diagnostics_via_sale: Diagnostic[];
    energyAudits_via_sale: EnergyAudit[];
    companyReports_via_sale: CompanyReport[];
  };
}

export enum EstimateState {
  started = "started",
  finalized = "finalized",
  inProgress = "in-progress",
  canceled = "canceled",
  stopped = "stopped",
}

export enum EstimateType {
  classic = "classic",
  actualised = "actualised",
}

export const getSaleStateInFR = (state: SaleState) => {
  switch (state) {
    case SaleState.started:
    case SaleState["in-progress"]:
      return "Ouvrir";
    case SaleState.stopped:
      return "Vente stoppée";
    case SaleState.finalized:
      return "Vente terminée";
    case SaleState.canceled:
      return "Vente annulée";
    case SaleState.archived:
      return "Vente archivée";
  }
}

const addMinutes = (date: Date, minutes: number) => {
  return new Date(date.getTime() + minutes * 60000);
};

export const slotsForTwo = (date: Date) => {
  const DURATION = 45;

  const slug = uuidv4();

  return [
    {
      slug,
      startTime: date,
      endTime: addMinutes(date, DURATION),
      duration: DURATION,
    },
    {
      slug,
      startTime: addMinutes(date, DURATION),
      endTime: addMinutes(date, DURATION * 2),
      duration: DURATION,
    },
  ];
};

export const slotsForThree = (date: Date) => {
  const DURATION = 40;

  const slug = uuidv4();

  return [
    {
      slug,
      startTime: date,
      endTime: addMinutes(date, DURATION),
      duration: DURATION,
    },
    {
      slug,
      startTime: addMinutes(date, DURATION),
      endTime: addMinutes(date, DURATION * 2),
      duration: DURATION,
    },
    {
      slug,
      startTime: addMinutes(date, DURATION * 2),
      endTime: addMinutes(date, DURATION * 3),
      duration: DURATION,
    },
  ];
};

export interface Estimate extends Base {
  sale: ID;
  slots: ID[];
  state: EstimateState;
  participation: "with-you" | "without-you";
  numberOfParticipants: number;
  meetingType: "with-third-party" | "with-keys" | "with-other";
  type: EstimateType;
  keysAddress: string;
  keysInstructions: string;
  otherInstructions: string;
  nameThirdParty: string;
  namePhone: string;
  bookableCompanies: string[]
  expand: {
    sale: Sale;
    slots: Slot[];
    bookableCompanies: Company[];
    companyEstimates_via_estimate: CompanyEstimate[];
    estimateContacts_via_estimate: EstimateContact[];
  };
}

export interface CompanyEstimate extends Base {
  sellerPriceLow: number;
  sellerPriceHigh: number;
  report: string;
  ownerName: string;
  estimate: ID;
  company: ID;
  expand: {
    estimate: Estimate;
    company: Company;
  };
}

export interface EstimateContact extends Base {
  estimate: ID;
  slots: ID[];
  company: ID;
  phone: string;
  expand: {
    company: Company;
  };
}