import type { Base } from "@interfaces/shared.interface"

// Note: Si le tableau est vide, pocketbase fera une requete qui retournera tous sans conditions. Pour éviter ça,
// on fait une requete qui ne retounera rien en recherchant sur un FAKE_ID
export const filterByIds = <TArray extends (Base | string)[]>(records: TArray, idOrRelationIdKey: string = "id"): string => {
  if (!records.length) {
    return `id = "FAKE_ID"`
  }

  return records.map((record) => {
    if (typeof record === "string") {
      return `${idOrRelationIdKey}='${record}'`;
    }

    return `${idOrRelationIdKey}='${record.id}'`;
  }).join("||");
}

export const expand = (expands: string[]) => {
  return expands.join(',');
}