import validator from "validator";
import { z } from "zod";
import { validateFile } from "./shared.schema";

export const CreateIndivisaireSchema = z.object({
  name: z.string().min(1),
  phone: z.string().refine(validator.isMobilePhone),
  email: z.string().refine(validator.isEmail),
});

export const CreateMandateSchema = z.object({
  price: z.coerce.number(),
  keysInstructions: z.string().optional(),
  clauses: z
    .array(z.object({ description: z.string().min(3) }))
    .optional()
    .default([]),
  indivisaires: z.array(CreateIndivisaireSchema).default([]),
  hasIndivisaires: z.enum(["yes", "no"]),
  companies: z
    .array(z.object({ company: z.object({ id: z.string(), name: z.string() }), selected: z.boolean() }))
    .refine((arg) => {
      const selectedCompanies = arg.filter(item => item.selected);

      return selectedCompanies.length >= 2;
    })
    .optional()
    .default([])
});

export const MandateDepositSchema = z.object({
  mandateFile: validateFile(),
});

export const MandateMissingPiecesSchema = z.object({
  missingPieces: z.string().min(1)
});

export const SignCompanyMandateSchema = z.object({
  signedMandate: validateFile(),
});

export const CompanyReportDepositSchema = z.object({
  reportFile: validateFile(),
});

export const CreateAmendmentSchema = z.object({
  price: z.coerce.number()
});

export const CompanyAmendmentDepositSchema = z.object({
  amendment: validateFile(),
});

export const SignCompanyAmendmentSchema = z.object({
  amendment: validateFile(),
});