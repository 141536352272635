import { Button } from "@components/buttons/Buttons"
import { Form } from "@components/form/Form"
import Input from "@components/inputs/Input"
import { zodResolver } from "@hookform/resolvers/zod"
import { usePb } from "@hooks/usePb"
import React from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { z } from "zod"

const FormValues = z.object({
  password: z.string().min(8),
  passwordConfirm: z.string().min(8),
}).superRefine(({ passwordConfirm, password }, ctx) => {
  if (passwordConfirm !== password) {
    ctx.addIssue({
      code: "custom",
      message: "Les mots de passent ne concordent pas",
      path: ['passwordConfirm']
    });
  }
});

const ProfileForm = z.object({
  email: z.string().email().optional(),
  firstname: z.string().min(1).optional(),
  lastname: z.string().min(1).optional(),
});

export const UserUpdate = () => {
  const pb = usePb();
  const queryClient = useQueryClient();

  const { userId } = useParams<{ userId: string }>();

  const { data: user } = useQuery<any>({
    queryKey: ["users", userId],
    queryFn: async () => {
      return pb.collection("users").getOne(userId!);
    }
  });

  const formMethods1 = useForm<z.infer<typeof ProfileForm>>({
    resolver: zodResolver(ProfileForm),
    defaultValues: {
      email: user?.email,
      firstname: user?.firstname,
      lastname: user?.lastname,
    }
  });
  const formMethods2 = useForm<z.infer<typeof FormValues>>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      password: "",
      passwordConfirm: "",
    }
  });

  React.useEffect(() => {
    if (user) {
      formMethods1.reset(user);
    }
  }, [user]);

  const profileMutation = useMutation({
    mutationFn: async (body: z.infer<typeof ProfileForm>) => {
      return pb.collection("users").update(user.id, {
        email: body.email,
        firstname: body.firstname,
        lastname: body.lastname,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });

      toast.success(`L'utilisateur a bien été modifié.`);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const passwordMutation = useMutation({
    mutationFn: async (body: z.infer<typeof FormValues>) => {
      return pb.collection("users").update(user.id, {
        password: body.password,
        passwordConfirm: body.passwordConfirm,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });

      toast.success(`L'utilisateur a bien été modifié.`);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  return (
    <div style={{ width: "100%" }}>
      <Form.DefaultLayout
        formMethods={formMethods1}
        title={`Changer le profil ${user?.firstname} ${user?.lastname}`}>
        <Input
          name="firstname"
          type="text"
          placeholder="Prénom"
          border
        />
        <Input
          name="lastname"
          type="text"
          placeholder="Nom"
          border
        />
        <Input
          name="email"
          type="text"
          placeholder="Email"
          border
        />

        <Button
          name="Mettre à jour le profil"
          onClick={formMethods1.handleSubmit((values => profileMutation.mutate(values)))}
          loading={profileMutation.isPending}
        />
      </Form.DefaultLayout>

      <Form.DefaultLayout
        formMethods={formMethods2}
        title={`Changer le mot de passe de ${user?.firstname} ${user?.lastname}`}>
        <Input
          name="password"
          type="password"
          placeholder="Mot de passe"
          border
        />
        <Input
          name="passwordConfirm"
          type="password"
          placeholder="Mot de passe confirmation"
          border
        />
        <Button
          name="Changer le mot de passe"
          onClick={formMethods2.handleSubmit((values => passwordMutation.mutate(values)))}
          loading={passwordMutation.isPending} />
      </Form.DefaultLayout>
    </div>
  )
}