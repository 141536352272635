import { Button } from "@components/buttons/Buttons";
import Input from "@components/inputs/Input";
import Modal from "@components/modal/Modal";
import "./ProtecteeCreateAddressModal.scss";
import { Form } from "@components/form/Form";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { CreateProtecteeAddressSchema } from "@schemas/protectee.schema";
import { toast } from "react-toastify";
import { useModal } from "@hooks/useModal";
import { zodResolver } from "@hookform/resolvers/zod";
import { trpc } from "@client/index";
import { getQueryKey } from "@trpc/react-query";
import { useNavigate } from "react-router-dom";

interface Props {
  protecteeId: string;
}

export const ProtecteeCreateAddressModal = ({ protecteeId }: Props) => {
  const modal = useModal();
  const naviguate = useNavigate();
  const queryClient = useQueryClient();

  const formMethods = useForm({
    resolver: zodResolver(CreateProtecteeAddressSchema),
    defaultValues: {
      street: '',
      zipcode: '',
      city: '',
    }
  });

  const mutation = trpc.protectee.createAddress.useMutation({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.protectee.search) });

      naviguate(`/dashboard/sales/${data.sale.id}`);

      toast.success(`L'adresse a bien été ajoutée`);

      formMethods.reset();

      modal.close(ProtecteeCreateAddressModal.name);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  })

  return (
    <Modal id={ProtecteeCreateAddressModal.name} title="Ajouter une adresse au majeur protégé">
      <Form formMethods={formMethods}>

        <Input
          name="street"
          placeholder="Adresse du bien à vendre"
          
        />

        <div className="form-address-extra">
          <Input
            name="zipcode"
            placeholder="Code postal"
            
          />
          <Input name="city" type="text" placeholder="Ville"  />
        </div>

        <Button
          name="Ajouter l'adresse"
          onClick={formMethods.handleSubmit((values) => mutation.mutate({ protecteeId, ...values }))}
          loading={mutation.isPending} />
      </Form>
    </Modal>
  )
}
