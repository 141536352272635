import type { Company } from "@interfaces/company.interface";
import Modal from "./Modal";
import { Button } from "@components/buttons/Buttons";
import { useModal } from "@hooks/useModal";

interface Props {
  id: string;
  company: Company;
}

export const CompanyInfosModal = ({ id, company }: Props) => {
  const modal = useModal();

  return (
    <Modal id={id} title="Plus d'information">
      <p>Voir les coordonnées de l’entreprise dans le devis à télécharger.</p>

      <Button name="Fermer" onClick={() => modal.close(id)} />
    </Modal>
  )
}