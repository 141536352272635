import { Button } from "@components/buttons/Buttons";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input";
import Modal from "@components/modal/Modal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useModal } from "@hooks/useModal";
import { CreateDiagnosticSchema } from "@schemas/diagnostic.schema";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { z } from "zod";
import { trpc } from "@client/index";
import { Helper } from "@components/Helper";
import { getQueryKey } from "@trpc/react-query";

interface Props {
  saleId: string;
}

export const DiagnosticRequestModal = ({ saleId }: Props) => {
  const modal = useModal();
  const queryClient = useQueryClient();

  const formMethods = useForm<z.infer<typeof CreateDiagnosticSchema>>({
    resolver: zodResolver(CreateDiagnosticSchema),
    defaultValues: {
      invoiceContactEmail: "",
      keyPickupAddress: "",
      keyDropoffAddress: "",
      contactPerson: "",
      instructions: "",
    }
  });

  const mutation = trpc.sale.diagnostic.create.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["diagnostics"] });
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.getSaleDropwdownsStatus) });

      formMethods.reset();

      toast.success("Votre demande de diagnostics a bien été créée.");

      modal.close(DiagnosticRequestModal.name);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  return (
    <Modal id={DiagnosticRequestModal.name} title="Demande de devis">
      <Form formMethods={formMethods}>

        <div className="estimate-input--wrapper empty">
          <Input
            type="email"
            name="invoiceContactEmail"
            placeholder="Adresse e-mail à laquelle envoyer RIB et facture"
          />

        </div>

        <div className="estimate-input--wrapper">

          <Input
            name="keyPickupAddress"
            placeholder="Où récupérer les clés ? (Adresse)"
          />

          <Helper description="Vous pouvez mettre ici l’adresse d’une agence immobilière, de votre bureau ou encore d’un tiers. Cette information sera transmise uniquement à l’entreprise dont vous aurez validé le devis en ligne. L’entreprise sélectionnée récupérera et déposera ensuite les clés aux adresses indiquées" />

        </div>

        <div className="estimate-input--wrapper empty">

          <Input
            name="keyDropoffAddress"
            placeholder="Où déposer les clés ? (Adresse)"
          />
        </div>

        <div className="estimate-input--wrapper empty">

          <Input
            name="contactPerson"
            placeholder="Téléphone du contact"
          />
        </div>

        <div className="estimate-input--wrapper empty">

          <Input.Textarea
            name="instructions"
            placeholder="Instructions spécifiques"
          />
        </div>

        <div className="estimate-input--wrapper empty">

          <Button
            name="Confirmer la demande"
            onClick={formMethods.handleSubmit((values) => mutation.mutate({ saleId, ...values }))}
            loading={mutation.isPending} />
        </div>
      </Form>
    </Modal>
  );
};
