import { Button } from "@components/buttons/Buttons";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input";
import Modal from "@components/modal/Modal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useModal } from "@hooks/useModal";
import { CreateEnergyAuditSchema } from "@schemas/energyAudit.schema";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { z } from "zod";
import { trpc } from "@client/index";
import { getQueryKey } from "@trpc/react-query";

interface Props {
  saleId: string;
}

export const EnergyAuditRequestModal = ({ saleId }: Props) => {
  const modal = useModal();
  const queryClient = useQueryClient();

  const formMethods = useForm<z.infer<typeof CreateEnergyAuditSchema>>({
    resolver: zodResolver(CreateEnergyAuditSchema),
    defaultValues: {
      invoiceContactEmail: "",
      keyPickupAddress: "",
      keyDropoffAddress: "",
      contactPerson: "",
      instructions: "",
    }
  });

  const mutation = trpc.sale.energyAudit.create.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["energyAudits", saleId] });
      queryClient.invalidateQueries({ queryKey: ["companyEnergyAudits", saleId] });
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.getSaleDropwdownsStatus) });

      formMethods.reset();

      toast.success("Votre demande de diagnostic a bien été créée.");

      modal.close(EnergyAuditRequestModal.name);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  return (
    <Modal id={EnergyAuditRequestModal.name} title="Demande de devis">
      <Form formMethods={formMethods}>

        <Input
          type="email"
          name="invoiceContactEmail"
          placeholder="Adresse e-mail pour réception RIB et facture"
        />

        <Input
          name="keyPickupAddress"
          placeholder="Où récupérer les clés ? (Adresse)"
        />

        <Input
          name="keyDropoffAddress"
          placeholder="Où déposer les clés ? (Adresse)"
        />

        <Input
          name="contactPerson"
          placeholder="Téléphone du contact"
        />

        <Input.Textarea
          name="instructions"
          placeholder="Instructions spécifiques"
        />

        <Button
          name="Confirmer la demande"
          onClick={formMethods.handleSubmit((values) => mutation.mutate({ saleId, ...values }))}
          loading={mutation.isPending} />

      </Form>
    </Modal>
  );
};
