import { Button } from "@components/buttons/Buttons"
import { Fragment } from "react"
import { Form } from "@components/form/Form"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import "./EnergyAuditQuotationDeposit.scss";
import Input from "@components/inputs/Input"
import { useParams } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { ExternalNotificationCard } from "@components/ExternalNotificationCard"
import { toast } from "react-toastify"
import { ExternalPropertyDesc } from "@components/ExternalPropertyDesc"
import { trpc } from "@client/index"
import { EnergyAuditQuotationDepositSchema } from "@schemas/energyAudit.schema"
import { getQueryKey } from "@trpc/react-query"
import { ExternalPageTitle } from "@components/ExternalPageTitle"

export const EnergyAuditQuotationDeposit = () => {
  const queryClient = useQueryClient();

  const { energyAuditId } = useParams<{ energyAuditId: string; }>();

  const formMethods = useForm<z.infer<typeof EnergyAuditQuotationDepositSchema>>({
    resolver: zodResolver(EnergyAuditQuotationDepositSchema),
    defaultValues: {
      price: undefined,
      quotation: null
    },
  });

  const guardQueryResult = trpc.external.energyAudit.quotationDepositResources.useQuery({ energyAuditId: energyAuditId! }, {
    retry: false,
    enabled: !!energyAuditId
  });

  const mutation = trpc.external.energyAudit.quotationDeposit.useMutation({
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.external.energyAudit.quotationDepositResources) });
    }
  });

  const handleSubmit = (body: z.infer<typeof EnergyAuditQuotationDepositSchema>) => {
    const formData = new FormData();

    formData.append("energyAuditId", energyAuditId!);

    formData.append("price", body["price"].toString());
    formData.append("quotation", body["quotation"]);

    mutation.mutate(formData);
  }

  const errorStatus = guardQueryResult.error?.data?.httpStatus;

  if (mutation.isSuccess) {
    return <ExternalNotificationCard title="Merci d'avoir déposé votre devis d'audit énergétique" />
  }

  if (errorStatus === 403 || errorStatus === 404) {
    return <ExternalNotificationCard title="Cette page n'est plus disponible" />
  }

  if (typeof errorStatus === "number") {
    return <ExternalNotificationCard title="Une erreur est survenue. Merci de réessayer plus tard." />
  }

  if (!guardQueryResult.data) {
    return null;
  }

  const { sale } = guardQueryResult.data.expand;

  const address = sale.expand.address;

  return (
    <Fragment>
      <Form.DefaultLayout formMethods={formMethods}>

        <div className="mailForm last">
          <ExternalPageTitle title="Dépôt du devis d'audit énergétique" />

          <ExternalPropertyDesc address={address} />

          <Input
            name="price"
            type="number"
            min={0}
            border
            placeholder="Montant TTC du devis" />

          <Input.File
            name="quotation"
            label="Insérer le devis"
            accept="application/pdf" />

        </div>

      <div className="actions--mail">

        <Form.Actions centered>
          <Button
            name="Valider"
            loading={mutation.isPending}
            onClick={formMethods.handleSubmit(handleSubmit)} />
        </Form.Actions>
        </div>
      </Form.DefaultLayout>
    </Fragment>
  )
}