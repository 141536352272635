import { usePb } from "@hooks/usePb";
import React, { createContext, memo, useContext } from "react";

interface AuthContextProps {
  isLogged: boolean;
  setLogged: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const useAuthContext = () => {
  return useContext(AuthContext);
}

interface ApiErrorsProviderProps {
  children: (props: AuthContextProps) => React.ReactNode;
}

export const AuthProvider = memo(({ children }: ApiErrorsProviderProps) => {
  const pb = usePb()
  const [isLogged, setLogged] = React.useState<boolean>(pb.authStore.isValid);

  return (
    <AuthContext.Provider value={{ isLogged, setLogged }}>
      {children({ isLogged, setLogged })}
    </AuthContext.Provider>
  )
});