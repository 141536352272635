import type { Base } from "./shared.interface";

export interface User extends Base {
  email: string;
  verified: boolean;
  firstname: string;
  lastname: string;
  phone: string;
  department: number;
  role: ProtectorRole;
  establishment: string;
  association: string;
  roleDetails: string;
  reject: any;
  clauses: string[];
}

export enum ProtectorRole {
  representative = "representative",
  attendant = "attendant",
  tutelary = "tutelary"
}

export type Protector = User;

export const protectorRoleToFR = {
  attendant: "Mandataire indépendant(e)",
  representative: "Préposé(e) d'établissement",
  tutelary: "Salarié(e) en Association Tutélaire",
};

export interface Admin extends User {
  verified: true;
}
