import { trpc } from "@client/index";
import { Button } from "@components/buttons/Buttons";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input";
import Modal from "@components/modal/Modal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useModal } from "@hooks/useModal";
import { usePbDownload } from "@hooks/usePbDownload";
import type { CompanyOffer } from "@interfaces/offer.interface";
import { OfferSignSchema } from "@schemas/offer.schema";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";

interface OfferSignModal {
  id: string;
  companyOffer: CompanyOffer;
}

export const OfferSignModal = ({ id: modalId, companyOffer }: OfferSignModal) => {
  const modal = useModal();

  const queryClient = useQueryClient();

  const downloadMutation = usePbDownload();

  const formMethods = useForm<z.infer<typeof OfferSignSchema>>({
    resolver: zodResolver(OfferSignSchema),
    defaultValues: {
      keysInstructions: "",
      signedOffer: undefined
    }
  });

  const signedMandate = formMethods.watch("signedOffer");

  const mutation = trpc.sale.offer.uploadSignedCompanyOffer.useMutation({
    onSuccess: () => {
      formMethods.reset();

      modal.close(modalId);

      queryClient.invalidateQueries({ queryKey: ["companyOffers", companyOffer.sale] });

      toast.success(`Merci d'avoir accepté une offre !
  Vous pouvez dès maintenant compléter l'étape notaire pour l'ouverture du dossier par nos équipes.`);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const handleSubmit = (body: z.infer<typeof OfferSignSchema>) => {
    const formData = new FormData();

    formData.append("companyOfferId", companyOffer.id);

    for (const name in body) {
      // @ts-expect-error
      formData.append(name, body[name]);
    }

    mutation.mutate(formData);
  }

  return (
    <Modal id={modalId} title="Signature de l'offre">
      <Form formMethods={formMethods}>
        <p style={{ color: "white" }}>Une fois que vous validez une offre, vous ne pouvez plus en accepter une autre ultérieurement. Dès validation de votre part, les autres agences immobilières seront automatiquement informées qu’elles doivent cesser la commercialisation.</p>
        <p style={{ color: "white" }}>Où souhaitez-vous que les autres agences mandatées remettent les clés du bien?</p>

        <Input.Textarea name="keysInstructions" placeholder="Texte libre.." />

        <div className="devis-diag--text">
          <label>
            Télécharger le document pour l’imprimer. Le signer et le scanner pour le déposer à nouveau à l'aide du bouton insérer.
          </label>

          <br />

          <p>
            Elle sera ensuite automatiquement transmise à l’agence concernée.
          </p>
        </div>

        <div className="modal--actions">
          <Form.Actions>
            <Button
              name="Télécharger"
              onClick={() => downloadMutation.mutate({ resource: companyOffer, filename: companyOffer["offer"] })} />

            <Input.File
              name="signedOffer"
              label="Insérer"
              disabled={!downloadMutation.isSuccess}
              accept="application/pdf"
              asButton />

            <Button
              name="Envoyer"
              disabled={!Boolean(signedMandate)}
              loading={mutation.isPending}
              onClick={formMethods.handleSubmit(handleSubmit)} />
          </Form.Actions>
        </div>
      </Form>

    </Modal>
  )
}