import { MEGABYTES_100_IN_BYTES } from "@interfaces/shared.interface";
import { z } from "zod";

interface Options {
  required?: boolean;
  fileTypes?: string[];
  maxSize?: number;
}

export const validateFile = (options: Options = {}) => {
  let validate = z.instanceof(File);

  const {
    required = true,
    fileTypes = ["application/pdf"],
    maxSize = MEGABYTES_100_IN_BYTES
  } = options;

  if (required) {
    validate = validate.refine((file) => Boolean(file), "Ce champ est requis")
  }

  if (fileTypes.length >= 1) {
    validate = validate.refine((file: File) => fileTypes.includes(file.type), "Le format du fichier est incorrect");
  }

  if (typeof maxSize === "number") {
    validate = validate.refine((file: File) => file.size <= maxSize, "Le fichier est trop volumineux");
  }

  return validate;
}