import { useParams } from "react-router-dom";
import "./StorageDeposit.scss";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { Form } from "@components/form/Form";
import { ExternalPropertyDesc } from "@components/ExternalPropertyDesc";
import Input from "@components/inputs/Input";
import { Button } from "@components/buttons/Buttons";
import { ExternalNotificationCard } from "@components/ExternalNotificationCard";
import { StorageDepositSchema } from "@schemas/storage.schema";
import { Fragment } from "react";
import { trpc } from "@client/index";
import { getQueryKey } from "@trpc/react-query";
import { ExternalGenericInfos } from "@components/ExternalGenericInfos";

export const StorageDeposit = () => {
  const queryClient = useQueryClient();

  const { storageId } = useParams<{ saleId: string; storageId: string; }>();

  const formMethods = useForm<z.infer<typeof StorageDepositSchema>>({
    resolver: zodResolver(StorageDepositSchema),
    defaultValues: {
      invoice: null,
    },
  });

  const guardQueryResult = trpc.external.storage.invoiceDepositGetResources.useQuery({ storageId: storageId! }, {
    retry: false,
  });

  const mutation = trpc.external.storage.invoiceDeposit.useMutation({
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.external.storage.invoiceDepositGetResources) });
    }
  });

  const handleSubmit = (body: z.infer<typeof StorageDepositSchema>) => {
    const formData = new FormData();

    formData.append("storageId", storageId!);

    formData.append("invoice", body.invoice);

    mutation.mutate(formData);
  }

  const errorStatus = guardQueryResult.error?.data?.httpStatus;

  if (mutation.isSuccess) {
    return <ExternalNotificationCard title="Merci d'avoir déposé votre facture!" />
  }

  if (errorStatus === 403 || errorStatus === 404) {
    return <ExternalNotificationCard title="Cette page n'est plus disponible" />
  }

  if (typeof errorStatus === "number") {
    return <ExternalNotificationCard title="Une erreur est survenue. Merci de réessayer plus tard." />
  }

  if (!guardQueryResult.data) {
    return null;
  }

  const { storage, sale } = guardQueryResult.data;

  const address = sale.expand.address;

  return (
    <Form.DefaultLayout formMethods={formMethods}>

      <div className="mailForm last">
        <h1 className="title">Dépot débarras et facture</h1>
        <ExternalPropertyDesc address={address} />
        
        <div className="confirm-estim--text_deposit">
          <ExternalGenericInfos
            title="Informations clés"
            informations={{
              "Adresse de récupération des clés": storage.keyPickupAddress,
              "Adresse de dépôt des clés": storage.keyDropoffAddress,
              "Instructions": storage.instructions,
              "Contact": storage.contactPerson
            }} />

        </div>

        <Input.File
          name="invoice"
          label="Insérer la facture"
          accept="application/pdf" />

      </div>

      <div className="actions--mail">

        <Form.Actions centered>
          <Button
            name="Valider"
            loading={mutation.isPending}
            onClick={formMethods.handleSubmit(handleSubmit)} />
        </Form.Actions>
      </div>
    </Form.DefaultLayout >
  )
}