import { useAuthContext } from "@contexts/auth.context";
import { usePb } from "@hooks/usePb";
import { trpc } from "@client/index";

export const useLogout = () => {
  const pb = usePb();
  const { setLogged } = useAuthContext();

  return trpc.auth.logout.useMutation({
    onSettled() {
      pb.authStore.clear();

      setLogged(false);
    },
  })
}