import { ActionTable } from "@components/ActionTable"
import { Button } from "@components/buttons/Buttons";
import { usePb } from "@hooks/usePb";
import { Fragment } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePbDownload } from "@hooks/usePbDownload";
import { expand } from "@utils/pb";
import { CompanyAmendmentState, type CompanyAmendment } from "@interfaces/mandate.interface";
import { trpc } from "@client/index";
import Input from "@components/inputs/Input";
import Modal from "@components/modal/Modal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useModal } from "@hooks/useModal";
import { SignCompanyAmendmentSchema } from "@schemas/mandate.schema";
import type { z } from "astro:content";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Form } from "@components/form/Form";
import { CompanyInfosModal } from "@components/modal/CompanyInfosModal";

const formateDate = (date: string) => {
  return new Date(date).toLocaleString('fr-FR', {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
}

interface CompanyAmendmentRequestActionTableRowProps {
  companyAmendment: CompanyAmendment;
  readOnly: boolean;
}

const modalId = (modalId: string, companyAmendmentId: string) => {
  return `${modalId}::${companyAmendmentId}`;
}

export const SignModal = ({ companyAmendment }: { companyAmendment: CompanyAmendment }) => {
  const modal = useModal();

  const queryClient = useQueryClient();

  const MODAL_ID = modalId(SignModal.name, companyAmendment.id);

  const formMethods = useForm<z.infer<typeof SignCompanyAmendmentSchema>>({
    resolver: zodResolver(SignCompanyAmendmentSchema),
    defaultValues: {
      amendment: undefined
    }
  });

  const amendment = formMethods.watch("amendment");

  const downloadMutation = usePbDownload();

  const mutation = trpc.sale.mandate.uploadSignedCompanyAmendment.useMutation({
    onSuccess: () => {
      formMethods.reset();

      modal.close(MODAL_ID);

      queryClient.invalidateQueries({ queryKey: ["diagnostics"] });

      toast.success(`Votre signature a bien été prise en compte`);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const handleSubmit = (values: z.infer<typeof SignCompanyAmendmentSchema>) => {
    const formData = new FormData();

    formData.set("companyAmendmentId", companyAmendment.id);
    formData.set("amendment", values["amendment"]);

    mutation.mutate(formData);
  }

  return (
    <Modal id={MODAL_ID} title="Signer l'avenant au mandat">
      <div className="devis-diag--text">
        <label>
          Télécharger le document pour l’imprimer. Le signer et le scanner pour le déposer à nouveau à l'aide du bouton insérer.
        </label>

        <br />

        <p>
          Il sera ensuite automatiquement transmis à l'entreprise concernée.
        </p>
      </div>

      <Form formMethods={formMethods}>
        <div className="modal--actions">

          <Form.Actions>
            <Button
              name="Télécharger"
              onClick={() => downloadMutation.mutate({ resource: companyAmendment, filename: companyAmendment["amendment"] })} />

            <Input.File
              name="amendment"
              label="Insérer"
              disabled={!downloadMutation.isSuccess}
              accept="application/pdf"
              asButton />

            <Button
              name="Envoyer"
              disabled={!Boolean(amendment)}
              loading={mutation.isPending}
              onClick={formMethods.handleSubmit(handleSubmit)} />
          </Form.Actions>
        </div>
      </Form>

    </Modal>
  )
}

const CompanyAmendmentRequestActionTableRow = ({ companyAmendment, readOnly = false }: CompanyAmendmentRequestActionTableRowProps) => {
  const modal = useModal();
  const MODAL_ID = modalId(SignModal.name, companyAmendment.id);

  const downloadMutation = usePbDownload();

  const COMPANY_INFOS_MODAL_ID = modalId(CompanyInfosModal.name, companyAmendment.id);

  return (
    <Fragment>
      <ActionTable.Row>
        {formateDate(companyAmendment.created)}
      </ActionTable.Row>

      <ActionTable.Row>
        {companyAmendment.expand.company.name}
      </ActionTable.Row>

      {readOnly ? 
        <ActionTable.Row>
          <Button.Small
            name="Télécharger"
            onClick={() => downloadMutation.mutate({ resource: companyAmendment, filename: companyAmendment["amendment"] })} />
        </ActionTable.Row>
      :
      <>
      {(!companyAmendment.amendment && companyAmendment.state === CompanyAmendmentState.waiting) ?
        <div className="estima-action--wrapper empty">
          <ActionTable.Row>
            En attente
          </ActionTable.Row>
        </div>
        :
        <ActionTable.ActionsButton>
          <Button.Small
            name="Signer"
            disabled={companyAmendment.state === CompanyAmendmentState.signed}
            onClick={() => modal.open(MODAL_ID)} />

          <Button.Small
            name="Télécharger"
            onClick={() => downloadMutation.mutate({ resource: companyAmendment, filename: companyAmendment["amendment"] })} />

          <Button.Small
            name="Plus d'infos"
            onClick={() => modal.open(COMPANY_INFOS_MODAL_ID)} />
        </ActionTable.ActionsButton>
      }
      </>
      }

      <SignModal companyAmendment={companyAmendment} />
      <CompanyInfosModal id={COMPANY_INFOS_MODAL_ID} company={companyAmendment.expand.company} />
    </Fragment>
  )
}

interface Props {
  saleId: string;
  readOnly: boolean;
}

export const CompanyAmendmentActionTable = ({ saleId, readOnly = false }: Props) => {
  const pb = usePb();

  const { data: companyAmendments = [], isPending } = useQuery<CompanyAmendment[]>({
    queryKey: ["companyAmendments", saleId],
    queryFn: async () => {
      return pb.collection("companyAmendments").getFullList({
        filter: `sale = "${saleId}"`,
        expand: expand([
          "company"
        ])
      });
    },
    enabled: !!saleId
  });

  return (
    <ActionTable isPending={isPending} emptyMessage="Aucune demande d'avenants en cours">
      {companyAmendments.map(companyAmendment => (
        <CompanyAmendmentRequestActionTableRow key={companyAmendment.id} companyAmendment={companyAmendment} readOnly={readOnly} />
      ))}
    </ActionTable>
  )
}