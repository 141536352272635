import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import "./Sale.scss";
import { Dropdown } from '@components/dropdown/Dropdown';
import { PropertyDesc } from '@components/PropertyDesc';
import { EstimateActionTable } from '@components/features/dashboard/sales/estimates/EstimateActionTable';
import { PropertyDocsList } from '@components/property-docs/PropertyDocsList';
import { MandateActionTable } from '@components/features/dashboard/sales/mandates/MandateActionTable';
import { DiagnosticActionTable } from '@components/features/dashboard/sales/diagnostics/DiagnosticActionTable';
import { EnergyAuditActionTable } from '@components/features/dashboard/sales/energyAudits/EnergyAuditActionTable';
import { StorageActionTable } from '@components/features/dashboard/sales/storages/StorageActionTable';
import { OfferDropdown } from '@components/features/dashboard/sales/offers/OfferDropdown';
import { StorageDropdown } from '@components/features/dashboard/sales/storages/StorageDropdown';
import { ActualisedEstimateActionTable } from '@components/features/dashboard/sales/propertyDoesNotSell/ActualisedEstimateActionTable';
import { CompanyReportActionTable } from '@components/features/dashboard/sales/propertyDoesNotSell/CompanyReportActionTable';
import { CompanyAmendmentActionTable } from '@components/features/dashboard/sales/propertyDoesNotSell/CompanyAmendmentActionTable';
import { OfferActionTable } from '@components/features/dashboard/sales/offers/OfferActionTable';

export const SaleFile = () => {
  const { saleId } = useParams<{ saleId: string }>();

  return (
    <Fragment>
      <div className="c-sell--driving">

        <Dropdown id="estimate-sale-file" title="Estimation du bien">
          <EstimateActionTable saleId={saleId!} readOnly />
        </Dropdown>

        <Dropdown id="diagnostic-sale-file" title="Diagnostics & Audits énergétiques">
          <p className="consult--doc">Diagnostics</p>
          <DiagnosticActionTable saleId={saleId!} readOnly />

          <p className="consult--doc">Audits énergétiques</p>
          <EnergyAuditActionTable saleId={saleId!} readOnly />
        </Dropdown>

        <Dropdown id="storage-sale-file" title="Débarras">
          <StorageActionTable saleId={saleId!} readOnly />
        </Dropdown>

        <Dropdown id="mandate" title="Mandats">
          <MandateActionTable saleId={saleId!} readOnly />
        </Dropdown>

        <Dropdown id="offer" title="Offres">
          <OfferActionTable saleId={saleId!} readOnly />
        </Dropdown>

        <Dropdown id="documents" title="Autres documents">
          <p className="consult--doc">Estimations actualisées</p>
          <ActualisedEstimateActionTable saleId={saleId!} />

          <p className="consult--doc">Compte rendus</p>
          <CompanyReportActionTable saleId={saleId!} />

          <p className="consult--doc">Avenants</p>
          <CompanyAmendmentActionTable saleId={saleId!} readOnly />

        </Dropdown>
      </div>

      <div className="c-block-sheet">
        <PropertyDesc saleId={saleId} />

        <div className="c-propertydocs">
          <span>Pièces de la vente</span>
          <ul>
            <PropertyDocsList saleId={saleId!} readOnly/>
          </ul>
        </div>
      </div>
    </Fragment>
  )
}