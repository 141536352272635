import { useParams } from "react-router-dom";
import "./CompanyEnergyAuditDeposit.scss";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { Form } from "@components/form/Form";
import { ExternalPropertyDesc } from "@components/ExternalPropertyDesc";
import Input from "@components/inputs/Input";
import { Button } from "@components/buttons/Buttons";
import { ExternalNotificationCard } from "@components/ExternalNotificationCard";
import { trpc } from "@client/index";
import { getQueryKey } from "@trpc/react-query";
import { CompanyEnergyAuditDepositSchema } from "@schemas/energyAudit.schema";

export const CompanyEnergyAuditDeposit = () => {
  const queryClient = useQueryClient();

  const { companyEnergyAuditId } = useParams<{ companyEnergyAuditId: string; }>();

  const formMethods = useForm<z.infer<typeof CompanyEnergyAuditDepositSchema>>({
    resolver: zodResolver(CompanyEnergyAuditDepositSchema),
    defaultValues: {
      invoice: undefined,
      energyAuditFile: undefined
    },
  });

  const guardQueryResult = trpc.external.companyEnergyAudit.depositGetResources.useQuery({ companyEnergyAuditId: companyEnergyAuditId! }, {
    retry: false
  });

  const mutation = trpc.external.companyEnergyAudit.deposit.useMutation({
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.external.companyEnergyAudit.depositGetResources) });
    }
  });

  const handleSubmit = (body: z.infer<typeof CompanyEnergyAuditDepositSchema>) => {
    const formData = new FormData();

    formData.set("companyEnergyAuditId", companyEnergyAuditId!);

    for (const name in body) {
      // @ts-expect-error
      formData.append(name, body[name]);
    }

    mutation.mutate(formData);
  }

  const errorStatus = guardQueryResult.error?.data?.httpStatus;

  if (mutation.isSuccess) {
    return <ExternalNotificationCard title="Merci d'avoir déposé votre facture!" />
  }

  if (errorStatus === 403 || errorStatus === 404) {
    return <ExternalNotificationCard title="Cette page n'est plus disponible" />
  }

  if (typeof errorStatus === "number") {
    return <ExternalNotificationCard title="Une erreur est survenue. Merci de réessayer plus tard." />
  }

  if (!guardQueryResult.data) {
    return null;
  }

  const address = guardQueryResult.data?.sale.expand.address!;

  return (
    <Form.DefaultLayout formMethods={formMethods}>

      <div className="mailForm last">
        <ExternalPropertyDesc address={address} />

        <Input.File
          name="energyAuditFile"
          label="Insérer l'audit énergétique"
          accept="application/pdf" />

        <Input.File
          name="invoice"
          label="Insérer la facture"
          accept="application/pdf" />
      </div>

      <Form.Actions centered>
        <Button
          name="Valider"
          loading={mutation.isPending}
          onClick={formMethods.handleSubmit(handleSubmit)} />
      </Form.Actions>

    </Form.DefaultLayout>
  )
}