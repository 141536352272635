import "./ExternalNotificationCard.scss";

interface Props {
  title: string;
  description?: string;
}

export const ExternalNotificationCard = ({ title, description }: Props) => {
  return (
    <div className="c-notication-card--container">
      <span>{title}</span>
      {description && (
        <div className="block-details">
          <p>{description}</p>
        </div>
      )}
    </div>
  )
}
