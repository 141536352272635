import { Button } from "@components/buttons/Buttons";
import { DiagnosticRequestModal } from "./DiagnosticRequestModal";
import "./DiagnosticsDropdown.scss";
import { useModal } from "@hooks/useModal";
import { Tabs } from "@components/Tabs";
import { DiagnosticActionTable } from "./DiagnosticActionTable";
import { EnergyAuditActionTable } from "../energyAudits/EnergyAuditActionTable";
import { EnergyAuditRequestModal } from "../energyAudits/EnergyAuditRequestModal";
import { trpc } from "@client/index";

interface Props {
  saleId: string;
}

export const DiagnosticsDropdown = ({ saleId }: Props) => {
  const modal = useModal();

  const { data: sale, isFetched } = trpc.sale.getOne.useQuery({ saledId: saleId! });

  const isEnergyAuditDisabled = isFetched && !sale?.isEnergyAuditRequired;

  return (
    <div>
      <Tabs
        defaultKey='diagnostics'
        tabs={[
          { name: 'Diagnostics', key: "diagnostics" },
          { name: 'Audits Énergétiques', key: "audits", disabled: isEnergyAuditDisabled },
        ] as const}
        components={{
          diagnostics: (
            <div className="diag--list ">
              <DiagnosticActionTable saleId={saleId} />
              <p style={{color: 'white'}}>Les diagnostics techniques sont obligatoires lors d'une vente immobilière. Merci de faire une première demande de diagnostics ou, si vous en possédez déjà un, de l'ajouter dans les pièces de la vente à droite.</p>

              <div className="diag-input--wrapper empty">
                <Button
                  name="Faire une demande de devis diagnostics"
                  onClick={() => modal.open(DiagnosticRequestModal.name)} />
              </div>
            </div>
          ),
          audits: (
            <div className="diag--list audit">
              <p style={{color: 'white'}}>Depuis le 1er avril 2023, les logements les plus énergivores doivent obligatoirement faire l'objet d'un audit énergétique en cas de vente. Une fois le diagnostic technique réalisé, l’entreprise indiquera si celui-ci est ou pas soumis à audit.</p>

              <EnergyAuditActionTable saleId={saleId} />

              <Button
                name="Demander un autre devis"
                className="audit"
                onClick={() => modal.open(EnergyAuditRequestModal.name)} />
            </div>
          )
        }} />

      <DiagnosticRequestModal saleId={saleId} />
      <EnergyAuditRequestModal saleId={saleId} />
    </div>
  );
};
