import { Button } from "@components/buttons/Buttons";
import Input from "@components/inputs/Input";
import "./Me.scss";
import { useForm } from "react-hook-form";
import { Form } from "@components/form/Form";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { UpdateProfileSchema } from "@schemas/user.schema";
import type { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMe } from "@hooks/usePb";
import type { User } from "@interfaces/user.interface";
import { useLogout } from "@hooks/useLogout";
import { trpc } from "@client/index";

export const Me = () => {
  const me = useMe<User>();

  const queryClient = useQueryClient();

  const formMethods = useForm<z.infer<typeof UpdateProfileSchema>>({
    resolver: zodResolver(UpdateProfileSchema),
    defaultValues: {
      firstname: me.firstname,
      lastname: me.lastname
    }
  });

  const { mutate: logout } = useLogout();

  const mutation = trpc.user.updateProfile.useMutation<z.infer<typeof UpdateProfileSchema>>({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['me'] });

      toast.success('Vos informations ont bien été mises à jour.');
    },
    onError() {
      toast.error('Une erreur est survenue, veuillez réessayer plus tard.');
    },
  });

  return (
    <Form.DefaultLayout
      formMethods={formMethods}
      title="Modifier mes informations"
      subtitle="Pour modifier votre email veuillez contacter un administrateur.">

      <Input
        name="firstname"
        placeholder="Prénom"
        border />

      <Input
        name="lastname"
        placeholder="Nom"
        border />

      <Button
        name="Modifier mon profil"
        onClick={formMethods.handleSubmit((values) => mutation.mutate(values))}
        loading={mutation.isPending} />

      <Button name="Se deconnecter" onClick={() => logout()} />
    </Form.DefaultLayout>
  )
}