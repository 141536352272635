import { Fragment } from "react";
import "./ProtecteeCard.scss";
import { Button } from "@components/buttons/Buttons";
import { useNavigate, useParams } from "react-router-dom";
import { Badge } from "@components/Badge";
import { useModal } from "@hooks/useModal";
import { ProtecteeCreateAddressModal } from "./ProtecteeCreateAddressModal";
import type { Protectee } from "@interfaces/protectee.interface";
import { getSaleStateInFR, type Sale } from "@interfaces/sale.interface";
import type { Address } from "@interfaces/address.interface";
import { classNames } from "@utils/utils";
import { useDropdown } from "@hooks/useDropdown";
import { useIsSaleFile } from "@hooks/useIsSaleFile";

interface Props {
  open: boolean;
  onOpen: () => void;
  protectee: Protectee;
}

const ShowAddress = ({ address }: { address: Address }) => {
  return (
    <span style={{
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    }}>
      {address.street}, {address.city} {address.zipcode}
    </span>
  )
}

const ShowAddressOrNumber = ({ sales }: { sales: Sale[] }) => {
  if (sales.length === 1 && Boolean(sales[0]?.expand?.address)) {
    const address = sales[0].expand.address;

    return <ShowAddress address={address} />;
  }

  return <span>{sales.length} adresses</span>;
}

const ShowAdresses = ({ sales }: { sales: Sale[] }) => {
  const modal = useModal();
  const navigate = useNavigate();
  const dropdown = useDropdown();
  const isSaleFile = useIsSaleFile();

  const protecteeId = sales[0].protectee;

  const onClick = (id: string) => {
    dropdown.closeAll();

    if (isSaleFile) {
      navigate(`/dashboard/sales-file/${id}`);
    } else {
      navigate(`/dashboard/sales/${id}`);
    }
  }

  return (
    <Fragment>
      {sales.map((sale) => {
        const status = getSaleStateInFR(sale.state);

        return (
          <div key={sale.expand.address.id} onClick={() => onClick(sale.id)}>
            <p className="c-adress--infos">
              {sale.expand.address.street}, {sale.expand.address.city}{" "}
              {sale.expand.address.zipcode}
            </p>

            <Badge name={status} />
          </div>
        )
      })}

      <Button.Small
        name="Autre bien à vendre"
        onClick={() => modal.open(ProtecteeCreateAddressModal.name)} />

      <ProtecteeCreateAddressModal protecteeId={protecteeId} />
    </Fragment>
  )
}

export const ProtecteeDisplayCard = ({ protectee, address }: { address: Address; protectee: Protectee; }) => {
  const name = `${protectee.firstname} ${protectee.lastname}`;

  return (
    <div className="c-adress">
      <label className="open blue cursor-pointer">
        {name} <ShowAddress address={address} />
      </label>
    </div>
  )
}

export const ProtecteeCard = ({ protectee, open, onOpen }: Props) => {
  const name = `${protectee.firstname} ${protectee.lastname}`;

  return (
    <div className="c-adress cursor-pointer">
      <label onClick={onOpen} className={classNames("cursor-pointer", open ? "open" : "")}>
        {name} {!open && <ShowAddressOrNumber sales={protectee.expand.sales_via_protectee} />}
      </label>

      {open && (
        <div className="c-adress--open">
          <ShowAdresses sales={protectee.expand.sales_via_protectee} />
        </div>
      )}
    </div>
  )
}
