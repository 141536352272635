import { trpc } from "@client/index";
import { Button } from "../buttons/Buttons";
import { CNI } from "./CNI";
import "./PropertyDocsList.scss";
import { PropertyTax } from "./PropertyTax";
import { ProperyTitle } from "./PropertyTitle";
import { ProtectionOrder } from "./ProtectionOrder";
import { SaleOrder } from "./SaleOrder";
import { useModal } from "@hooks/useModal";
import { UploadModal } from "@components/modal/UploadModal";
import { Form } from "@components/form/Form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { UploadSalePieceDocumentSchema } from "@schemas/salePiece.schema";
import { toast } from "react-toastify";
import { getQueryKey } from "@trpc/react-query";
import { useQueryClient } from "@tanstack/react-query";
import type { z } from "zod";
import { FreeSalePiece } from "./FreeSalePiece";
import { handleSalePieceFiles, type SalePiece } from "@interfaces/salePieces.interface";
import { CoproperyTitle } from "./CopropertyTitle";
import { usePbDownloadZip } from "@hooks/usePbDownloadZip";
import { Diagnostic } from "./Diagnostic";
import { EnergyAudit } from "./EnergyAudit";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import type { Estimate } from "@interfaces/sale.interface";

interface Props {
  saleId: string;
  readOnly: boolean;
}

// non-valid | valid | asked

const FREE_SALE_PIECE_UPLOAD_MODAL_ID = "FREE_SALE_PIECE_UPLOAD_MODAL_ID";

export const PropertyDocsList = ({ saleId, readOnly = false}: Props) => {
  const modal = useModal();
  const queryClient = useQueryClient();
  const downloadMutation = usePbDownloadZip();

  const formMethods = useForm<z.infer<typeof UploadSalePieceDocumentSchema>>({
    resolver: zodResolver(UploadSalePieceDocumentSchema),
    defaultValues: {
      document: undefined,
    },
  });

  // const { data, isPending } = trpc.sale.estimate.getEstimates.useQuery({ saleId }, {
  //   enabled: !!saleId,
  // });
  // const estimates = (data || []) as any[];

  const mutation = trpc.sale.salePiece.freePiece.upload.useMutation({
    onSuccess: () => {
      formMethods.reset();

      modal.close(FREE_SALE_PIECE_UPLOAD_MODAL_ID);

      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.salePiece.getBySaleId) });

      toast.success("Votre document a bien été ajouté.");
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const { data: salePiece } = trpc.sale.salePiece.getBySaleId.useQuery(saleId);

  const { data: files } = trpc.sale.getAllFiles.useQuery({saleId: saleId});

  const handleSubmit = (body: z.infer<typeof UploadSalePieceDocumentSchema>) => {
    const formData = new FormData();

    formData.append("saleId", saleId);
    formData.append("document", body.document);

    mutation.mutate(formData);
  }

  const downloadAll = async () => {
    const zip = new JSZip();
  
    // Ajoute les fichiers de salePiece dans le zip
    await handleSalePieceFiles(salePiece as SalePiece, async ({ filename }) => {
      const blob = await downloadMutation.mutateAsync({ resource: salePiece, filename });
      zip.file(filename, blob);
    });
  
    // Vérifie si 'files' n'est pas undefined avant d'ajouter les fichiers dans le zip
    if (files && files.length > 0) {
      for (const file of files as Array<Record<string, any>>) {  // On utilise Record<string, any> pour représenter un objet à clés dynamiques
        // Recherche un champ contenant ".pdf"
        let filename = null;
        
        for (const key in file) {
          const value = file[key];
          if (typeof value === 'string' && value.endsWith('.pdf')) {
            filename = value;
            break;  // On a trouvé le fichier PDF, on peut sortir de la boucle
          }
        }
  
        // Si un fichier PDF est trouvé, l'ajouter au zip
        if (filename) {
          const blob = await downloadMutation.mutateAsync({ resource: file, filename });
          zip.file(filename, blob);
        }
      }
    }
  
    // Utilise le nom de la vente pour nommer le fichier ZIP
    const saleName = "documents";
    const zipBlob = await zip.generateAsync({ type: "blob" });
  
    // Télécharge le fichier ZIP
    saveAs(zipBlob, `${saleName}.zip`);
  };

  return (
    <ul className="propertyDocsList">
      <CNI saleId={saleId} />

      <PropertyTax saleId={saleId} />

      <ProtectionOrder saleId={saleId} />

      <SaleOrder saleId={saleId} />

      <Diagnostic saleId={saleId} />

      <EnergyAudit saleId={saleId} />

      <ProperyTitle saleId={saleId} readOnly={readOnly}/>

      <CoproperyTitle saleId={saleId} readOnly={readOnly} />

      {salePiece?.freePieces.map(freePiece => (
        <FreeSalePiece
          key={freePiece}
          saleId={saleId}
          salePiece={salePiece as SalePiece}
          freePiece={freePiece} />
      ))}

      <Form formMethods={formMethods}>
        <UploadModal
          id={FREE_SALE_PIECE_UPLOAD_MODAL_ID}
          name="document"
          title="Document libre"
          isLoading={mutation.isPending}
          onConfirm={formMethods.handleSubmit(handleSubmit)} />
      </Form>

      <li className="property--actions">
        <Button name="Ajouter un autre document" onClick={() => modal.open(FREE_SALE_PIECE_UPLOAD_MODAL_ID)} />

        <Button name="Télécharger toutes les pièces" onClick={downloadAll} />
      </li>
    </ul>
  );
};
