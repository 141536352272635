import type { Company } from "./company.interface";
import type { Sale } from "./sale.interface";
import type { Base, ID } from "./shared.interface";

export enum CompanyOfferState {
  waiting = "waiting",
  refused = "refused",
  signed = "signed"
}

export enum CompanyOfferCommissionState {
  waiting = "waiting",
  payed = "payed",
}

export interface CompanyOffer extends Base {
  buyerName: string;
  price: number;
  notaryName: string;
  sale: ID;
  company: ID;
  offer: any;
  buyerCni: any;
  informations: string;
  suspensiveConditions: string;
  state: CompanyOfferState;
  commissionState: CompanyOfferCommissionState;
  freePieces: string[];
  expand: {
    sale: Sale;
    company: Company;
  }
}