import { z } from "zod";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Form } from "@components/form/Form";
import { Button } from "@components/buttons/Buttons";
import Input from "@components/inputs/Input";
import { ConfirmSlotSchema } from "@schemas/estimate.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import "./EstimateWithoutYouSlotConfirm.scss"
import type { Estimate, Sale } from "@interfaces/sale.interface";
import { formatFullAddress } from "@interfaces/address.interface";
import { ExternalNotificationCard } from "@components/ExternalNotificationCard";
import { trpc } from "@client/index";
import { getQueryKey } from "@trpc/react-query";

interface EstimateInstructionsProps {
  sale: Sale;
  estimate: Estimate;
}

const EstimateInstructions = ({ sale, estimate }: EstimateInstructionsProps) => {
  return (
    <div className="confirm-estim--text">
      <h2>Confirmation rendez-vous estimation</h2>
      <p><span>Adresse :</span> {formatFullAddress(sale.expand.address)}</p>
      <div className="confirm-estim--text_t">

        <span>Voici les instructions pour organiser la visite :</span>

        <ul>
          {estimate.nameThirdParty && <li><b>Nom du contact:</b> {estimate.nameThirdParty}</li>}
          {estimate.namePhone && <li><b>Téléphone:</b> {estimate.namePhone}</li>}

          {estimate.keysAddress && <li><b>Adresse où récupérer les clés:</b> {estimate.keysAddress}</li>}
          {estimate.keysInstructions && <li> <b>Instructions:</b> {estimate.keysInstructions}</li>}

          {estimate.otherInstructions && <li><b>Instructions:</b> {estimate.otherInstructions}</li>}
        </ul>
      </div>

      <span>Merci de confirmer ci-dessous l'heure et la date de la visite prévue </span>
    </div>
  )
}

export const EstimateWithoutYouSlotConfirm = () => {
  const queryClient = useQueryClient();

  const { estimateId } = useParams<{ estimateId: string; }>();

  const formMethods = useForm<z.infer<typeof ConfirmSlotSchema>>({
    resolver: zodResolver(ConfirmSlotSchema),
    defaultValues: {
      startTime: undefined
    }
  });

  const guardQueryResult = trpc.external.estimate.withoutYou.slotConfirmGetResources.useQuery({ estimateId: estimateId! }, {
    retry: false
  });

  const errorStatus = guardQueryResult.error?.data?.httpStatus;

  const mutation = trpc.external.estimate.withoutYou.slotConfirm.useMutation({
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.external.estimate.withoutYou.slotConfirmGetResources) });
    },
  })

  if (mutation.isSuccess) {
    return <ExternalNotificationCard title="Merci d'avoir confirmé la date de l'estimation" />
  }

  if (errorStatus === 403 || errorStatus === 404) {
    return <ExternalNotificationCard title="Cette page n'est plus disponible" />
  }

  if (typeof errorStatus === "number") {
    return <ExternalNotificationCard title="Une erreur est survenue. Merci de réessayer plus tard." />
  }

  if (!guardQueryResult.data) {
    return null;
  }

  const { estimate, sale } = guardQueryResult.data;

  return (
    <Form.DefaultLayout formMethods={formMethods}>
      <div className="confirm-estim">
        <EstimateInstructions estimate={estimate as Estimate} sale={sale as Sale} />

        <div className="confirm-estim--form">
          <Input
            name="startTime"
            border
            type="datetime-local"
            placeholder="Date et heure"
          />
        </div>
      </div>

      <Form.Actions centered>
        <Button
          name="Valider"
          onClick={formMethods.handleSubmit((values) => mutation.mutate({ estimateId: estimateId!, ...values }))}
          loading={mutation.isPending} />
      </Form.Actions>
    </Form.DefaultLayout>
  );
};
