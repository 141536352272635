import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { usePb } from "./usePb";

export const usePbDownload = () => {
  const pb = usePb();

  return useMutation({
    mutationFn: async ({ resource, filename }: { resource: any, filename: string }) => {
      const fileToken = await pb.files.getToken();

      const downloadUrl = pb.files.getUrl(resource, filename, { token: fileToken, download: true });

      // const link = document.createElement("a");
      // link.href = downloadUrl;
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);

      return window.open(downloadUrl, "_blank");
    },
    onError() {
      toast.error("Une erreur est survenue.")
    },
  });
}