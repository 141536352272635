import { Header } from "@components/Header";
import { Fragment } from "react";
import { Outlet } from "react-router-dom";

export const LayoutExternal = () => (
  <Fragment>
    <Header />
    <Outlet />
  </Fragment>
);
