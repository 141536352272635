import { Button } from "@components/buttons/Buttons"
import { useState } from "react"
import { Form } from "@components/form/Form"
import Input from "@components/inputs/Input"
import { Select } from "@components/inputs/Select"
import { zodResolver } from "@hookform/resolvers/zod"
import { RegisterSchema } from "@schemas/login.schema"
import { useForm } from "react-hook-form"
import type { z } from "zod"
import "./Register.scss";
import { useNavigate } from "react-router-dom"
import { ProtectorRole, protectorRoleToFR } from "@interfaces/user.interface"
import { trpc } from "@client/index"

const ThankYou = () => {
  const navigate = useNavigate();

  return <div className="thank-you">
    <h1>Merci de votre inscription.</h1>
    <p>
      Nos équipes vérifient vos informations avant de vous fournir vos accès
      personnalisés à la plateforme.
    </p>

    <Button
      name="Revenir au site Athena"
      onClick={() => navigate("/")}
      full />
  </div>
}

export const Register = () => {
  const [thankYou, setThankYou] = useState(false);

  const formMethods = useForm<z.infer<typeof RegisterSchema>>({
    resolver: zodResolver(RegisterSchema),
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      password: "",
      passwordConfirm: "",
      role: ProtectorRole.representative,
      establishment: "",
      association: "",
      department: undefined,
      approvalYear: undefined,
    }
  });

  const mutation = trpc.auth.register.useMutation({
    onSuccess: () => {
      formMethods.reset();

      setThankYou(true);
    },
    onError() {
      setThankYou(false);
    },
  });

  const role = formMethods.watch('role');

  if (thankYou) {
    return <ThankYou />
  }

  return (
    <>
    <Form.DefaultLayout
      formMethods={formMethods}
      style={{marginBottom: '100px'}}
      title="Inscription à la plateforme Athena"
      subtitle="Veuillez renseigner les champs dans le formulaire suivant.">

      <div className="row">
        <Input
          name="firstname"
          type="text"
          placeholder="Prénom"
          border
        />
        <Input
          name="lastname"
          type="text"
          placeholder="Nom"
          border
        />
      </div>

      <Input
        name="email"
        type="email"
        placeholder="Email"
        border
      />
      <Input
        name="phone"
        type="text"
        placeholder="Téléphone"
        border
      />
      <Input
        name="password"
        type="password"
        placeholder="Mot de passe"
        border
        message="Votre mot de passe doit contenir au minimum 8 caractères"
      />
      <Input
        name="passwordConfirm"
        type="password"
        placeholder="Mot de passe confirmation"
        border

      />

      <Input
        name="department"
        type="number"
        min={0}
        max={999}
        placeholder="Département"
        border
      />

      <div className="row">
        <Select
          name="role"
          options={[
            { label: protectorRoleToFR[ProtectorRole.representative], value: ProtectorRole.representative },
            { label: protectorRoleToFR[ProtectorRole.attendant], value: ProtectorRole.attendant },
            { label: protectorRoleToFR[ProtectorRole.tutelary], value: ProtectorRole.tutelary },
          ]}
          border
        />

        {role === ProtectorRole.representative && (
          <Input
            name="approvalYear"
            type="text"
            placeholder="Nom d'établissement"
            border
          />
        )}

        {role == ProtectorRole.attendant && (
          <Input
            name="establishment"
            type="number"
            placeholder="Année d’agrément"
            border />
        )}

        {role === ProtectorRole.tutelary && (
          <Input
            name="association"
            type="text"
            placeholder="Nom de l'association"
            border />
        )}
      </div>
      <Button
        name="Compléter l'inscription"
        loading={mutation.isPending}
        onClick={formMethods.handleSubmit(values => mutation.mutate(values))} />

      <Button.Link
        dark
        to="/"
        title="J'ai déjà un compte, me connecter"
      />
    </Form.DefaultLayout>

    <br/><br/><br/>

    </>
  )
}