import { z } from "zod";
import { validateFile } from "./shared.schema";

export const CreateEnergyAuditSchema = z
  .object({
    invoiceContactEmail: z.string({
      required_error: "Ce champ est requis",
    }).min(1, { message: "Ce champ est requis" }),
    keyPickupAddress: z.string({
      required_error: "Ce champ est requis",
    }).min(1, { message: "Ce champ est requis" }),
    keyDropoffAddress: z.string({
      required_error: "Ce champ est requis",
    }).min(1, { message: "Ce champ est requis" }),
    contactPerson: z.string({
      required_error: "Ce champ est requis",
    }).min(1, { message: "Ce champ est requis" }),
    instructions: z.string({
      required_error: "Ce champ est requis",
    }).min(1, { message: "Ce champ est requis" }),
  });

export const SignEnergyAuditQuotationSchema = z.object({
  quotation: validateFile(),
});

export const CompanyEnergyAuditDepositSchema = z.object({
  invoice: validateFile(),
  energyAuditFile: validateFile(),
})

export const EnergyAuditQuotationDepositSchema = z.object({
  price: z.string().min(0),
  quotation: validateFile(),
});