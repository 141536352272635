import { Button } from "@components/buttons/Buttons";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input";
import Modal from "@components/modal/Modal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useModal } from "@hooks/useModal";
import { CreateStorageSchema } from "@schemas/storage.schema";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { z } from "zod";
import { trpc } from "@client/index";
import { Helper } from "@components/Helper";
import { getQueryKey } from "@trpc/react-query";

interface Props {
  saleId: string;
}

export const StorageRequestModal = ({ saleId }: Props) => {
  const modal = useModal();
  const queryClient = useQueryClient();

  const formMethods = useForm<z.infer<typeof CreateStorageSchema>>({
    resolver: zodResolver(CreateStorageSchema),
    defaultValues: {
      invoiceContactEmail: "",
      keyPickupAddress: "",
      keyDropoffAddress: "",
      contactPerson: "",
      instructions: "",
    }
  });

  const mutation = trpc.sale.storage.create.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["storages", saleId] });
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.getSaleDropwdownsStatus) });

      formMethods.reset();

      toast.success("Votre demande de débarras a bien été créée.");

      modal.close(StorageRequestModal.name);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  return (
    <Modal id={StorageRequestModal.name} title="Demande de devis">
      <Form formMethods={formMethods}>

        <div className="estimate-input--wrapper empty">
          <Input
            type="email"
            name="invoiceContactEmail"
            placeholder="Adresse e-mail pour réception RIB et facture"
          />

        </div>

        <div className="estimate-input--wrapper">

          <Input
            name="keyPickupAddress"
            placeholder="Où récupérer les clés ? (Adresse)"
          />

          <Helper description="Les débarrasseurs avant de vous envoyer un devis devront se rendre sur place, afin d’estimer le cout de leur intervention. Seules les deux premières entreprises qui répondront à votre demande d’établissement de devis auront l’information relative au clé et à l’adresse du bien. Après leur venue sur place, elles déposeront les clés à l’adresse indiquée et vous enverront leurs devis respectifs par la plateforme" />

        </div>

        <div className="estimate-input--wrapper empty">

          <Input
            name="keyDropoffAddress"
            placeholder="Où déposer les clés ? (Adresse)"
          />
        </div>

        <div className="estimate-input--wrapper empty">

          <Input
            name="contactPerson"
            placeholder="Téléphone du contact"
          />
        </div>

        <div className="estimate-input--wrapper empty">

          <Input.Textarea
            name="instructions"
            placeholder="Instructions spécifiques"
          />

          <Helper description="Précisez ici  vos instructions pour le débarras. Par exemple si vous souhaitez que le débarrasseur laisse sur place certains effets, comme des photos, des documents administratifs ou des objets personnels ou de valeurs" />

        </div>

        <div className="estimate-input--wrapper empty">

          <Button
            name="Confirmer la demande"
            onClick={formMethods.handleSubmit((values) => mutation.mutate({ saleId, ...values }))}
            loading={mutation.isPending} />
        </div>
      </Form>
    </Modal>
  );
};
