import { useParams } from "react-router-dom";
import "./DiagnosticDeposit.scss";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { Form } from "@components/form/Form";
import { ExternalPropertyDesc } from "@components/ExternalPropertyDesc";
import Input from "@components/inputs/Input";
import { Button } from "@components/buttons/Buttons";
import { ExternalNotificationCard } from "@components/ExternalNotificationCard";
import { DiagnosticDepositSchema } from "@schemas/diagnostic.schema";
import { Fragment } from "react";
import { trpc } from "@client/index";
import { getQueryKey } from "@trpc/react-query";
import { ExternalGenericInfos } from "@components/ExternalGenericInfos";

export const DiagnosticDeposit = () => {
  const queryClient = useQueryClient();

  const { diagnosticId } = useParams<{ saleId: string; diagnosticId: string; }>();

  const formMethods = useForm<z.infer<typeof DiagnosticDepositSchema>>({
    resolver: zodResolver(DiagnosticDepositSchema),
    defaultValues: {
      isEnergyAuditRequired: false,
      diagnosticFile: undefined,
      energyAuditQuotation: null,
      invoice: undefined,
      energyAuditPrice: undefined
    },
  });

  const isEnergyAuditRequired = formMethods.watch("isEnergyAuditRequired");

  const guardQueryResult = trpc.external.diagnostic.diagnosticDepositGetResources.useQuery({ diagnosticId: diagnosticId! }, {
    retry: false,
  });

  const mutation = trpc.external.diagnostic.diagnosticDeposit.useMutation({
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.external.diagnostic.diagnosticDepositGetResources) });
    }
  });

  const handleSubmit = (body: z.infer<typeof DiagnosticDepositSchema>) => {
    const formData = new FormData();

    formData.append("diagnosticId", diagnosticId!);

    formData.append("invoice", body.invoice);
    formData.append("diagnosticFile", body.diagnosticFile);
    formData.append("isEnergyAuditRequired", JSON.stringify(body.isEnergyAuditRequired));

    if (Boolean(body.energyAuditQuotation)) {
      formData.append("energyAuditQuotation", body.energyAuditQuotation);
      formData.append("energyAuditPrice", body.energyAuditPrice?.toString()!)
    }

    mutation.mutate(formData);
  }

  const errorStatus = guardQueryResult.error?.data?.httpStatus;

  if (mutation.isSuccess) {
    return <ExternalNotificationCard title="Merci d'avoir déposé votre facture!" />
  }

  if (errorStatus === 403 || errorStatus === 404) {
    return <ExternalNotificationCard title="Cette page n'est plus disponible" />
  }

  if (typeof errorStatus === "number") {
    return <ExternalNotificationCard title="Une erreur est survenue. Merci de réessayer plus tard." />
  }

  if (!guardQueryResult.data) {
    return null;
  }

  const { diagnostic, sale } = guardQueryResult.data;

  const address = sale.expand.address;

  return (
    <Form.DefaultLayout formMethods={formMethods}>

      <div className="mailForm last">
        <h1 className="title">Dépot diagnostic technique et facture</h1>
        <ExternalPropertyDesc address={address} />
        <div className="confirm-estim--text_deposit">
          <ExternalGenericInfos
            title="Informations clés"
            informations={{
              "Adresse de récupération des clés": diagnostic.keyPickupAddress,
              "Adresse de dépôt des clés": diagnostic.keyDropoffAddress,
              "Instructions": diagnostic.instructions,
              "Contact": diagnostic.contactPerson
            }} />

        </div>
        < Input.File
          name="diagnosticFile"
          label="Insérer le diagnostic"
          accept="application/pdf" />

        <Input.File
          name="invoice"
          label="Insérer la facture"
          accept="application/pdf" />

        <div className="diag-checkbox">

          <Input.Checkbox id="isEnergyAuditRequired" name="isEnergyAuditRequired" />

          <label htmlFor="isEnergyAuditRequired">
            Un audit énergétique est nécessaire
          </label>

        </div>
        {
          isEnergyAuditRequired && (
            <Fragment>
              <Input.File
                name="energyAuditQuotation"
                label="Insérer le devis d'audit énergétique"
                accept="application/pdf" />

              <Input
                name="energyAuditPrice"
                type="number"
                min={0}
                border
                placeholder="Montant TTC du devis d'audit énérgétique" />
            </Fragment>
          )
        }
      </div >

      <div className="actions--mail">

        <Form.Actions centered>
          <Button
            name="Valider"
            loading={mutation.isPending}
            onClick={formMethods.handleSubmit(handleSubmit)} />
        </Form.Actions>
      </div>
    </Form.DefaultLayout >
  )
}