import "./SaleAside.scss";
import { Button } from "@components/buttons/Buttons";
import { ProtecteesList } from "@components/features/dashboard/protectees/ProtecteesList";
import { ProtecteeCreateModal } from "../protectees/ProtecteeCreateModal";
import { useModal } from "@hooks/useModal";
import { Form } from "@components/form/Form";
import { useForm } from "react-hook-form";
import { useDebounce } from "use-debounce";
import Input from "@components/inputs/Input";
import { trpc } from "@client/index";
import type { Protectee } from "@interfaces/protectee.interface";
import { useIsSaleFile } from "@hooks/useIsSaleFile";
import { SaleState } from "@interfaces/sale.interface";

export const SaleAside = () => {
  const modal = useModal();
  const isSaleFile = useIsSaleFile();

  const formMethods = useForm({
    defaultValues: {
      search: ''
    }
  });

  const saleState = isSaleFile
    ? [SaleState.stopped, SaleState.archived]
    : [SaleState.started, SaleState["in-progress"], SaleState.finalized];

  const search = formMethods.watch('search');

  const [term] = useDebounce(search.trim(), 300);

  const { data: protectees = [] } = trpc.protectee.search.useQuery({ term, saleState });

  return (
    <div className={"c-side"}>

      <div className="c-side--header">

        <Button
          name="Ajouter un majeur protégé"
          onClick={() => modal.open(ProtecteeCreateModal.name)} />

        <div className="c-side--header_search">
          <div className="c-header--choice">
            <span>Majeurs protégés</span>
          </div>
          <div>
            <Form formMethods={formMethods}>
              <Input
                border
                background
                name="search"
                className={`c-input icon ico--search_blue`}
                placeholder="Rechercher" />
            </Form>
          </div>
        </div>
      </div>
      <div className="c-list">
        <ProtecteesList protectees={protectees as Protectee[]} />
      </div>
    </div>
  )
}