import type { Base, ID } from "./shared.interface";
import type { User } from "./user.interface";

export enum AbroadGoodState {
  finalized = "finalized",
  inProgress = "in-progress",
  awaitingAppointment = "awaiting-appointment",
}

export interface AbroadGood extends Base {
  protector: ID;
  state: AbroadGoodState;
  email: string;
  phone: string;
  country: string;
  expand: {
    protector: User;
  };
}
