import { ActionTable } from "@components/ActionTable";
import { Button } from "@components/buttons/Buttons";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input";
import Modal from "@components/modal/Modal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useModal } from "@hooks/useModal";
import { usePb } from "@hooks/usePb";
import { usePbDownload } from "@hooks/usePbDownload";
import { CompanyStorageState, type CompanyStorage, type Storage } from "@interfaces/storage.interface";
import { SignStorageQuotationSchema } from "@schemas/storage.schema";
import { formatPrice } from "@utils/utils";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import type { z } from "zod";
import "./StorageActionTable.scss";
import { trpc } from "@client/index";
import { CompanyInfosModal } from "@components/modal/CompanyInfosModal";
import { Helper } from "@components/Helper";

const formateDate = (date: string) => {
  return new Date(date).toLocaleString('fr-FR', {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
};

interface StorageSignModalProps {
  companyStorage: CompanyStorage;
}

const modalId = (modalId: string, companyStorageId: string) => {
  return `${modalId}::${companyStorageId}`;
};

export const StorageSignModal = ({ companyStorage }: StorageSignModalProps) => {
  const modal = useModal();
  const queryClient = useQueryClient();
  const { saleId } = useParams<{ saleId: string }>();
  const MODAL_ID = modalId(StorageSignModal.name, companyStorage.id);

  const formMethods = useForm<z.infer<typeof SignStorageQuotationSchema>>({
    resolver: zodResolver(SignStorageQuotationSchema),
    defaultValues: {
      quotation: undefined,
      keyPickupAddress: "",
      keyDropoffAddress: "",
      contactPerson: "",
    }
  });

  const quotation = formMethods.watch("quotation");
  const downloadMutation = usePbDownload();

  const mutation = trpc.sale.companyStorage.uploadSignedStorageQuotation.useMutation({
    onSuccess: () => {
      formMethods.reset();
      modal.close(MODAL_ID);
      queryClient.invalidateQueries({ queryKey: ["storages", saleId] });
      toast.success(`Votre signature a bien été prise en compte`);
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const handleSubmit = (body: z.infer<typeof SignStorageQuotationSchema>) => {
    const formData = new FormData();
    formData.set("saleId", saleId!);
    formData.set("companyStorageId", companyStorage.id);
    for (const name in body) {
      // @ts-expect-error
      formData.append(name, body[name]);
    }
    mutation.mutate(formData);
  };

  return (
    <Modal id={MODAL_ID} title="Signer le devis de débarras">
      <div className="devis-diag--text">
        <label>
          Télécharger le document pour l’imprimer. Le signer lui-même et le scanner pour le déposer à nouveau dans insérer.
        </label>
        <br />
        <p>
          Il sera ensuite automatiquement transmis à l'entreprise concernée. L'entreprise dont le devis n'a pas été accepté sera également automatiquement prévenue.
        </p>
        <br />
        <label>
          Merci également d'indiquer les informations sur les clés du bien.
        </label>
      </div>

      <Form formMethods={formMethods}>
        <div className="modal--actions">
          <div className="estimate-input--wrapper">
            <Input name="keyPickupAddress" placeholder="Où récupérer les clés ? (Adresse)" />
            <Helper description="Vous pouvez mettre ici l’adresse d’une agence immobilière, de votre bureau ou encore d’un tiers. Cette information sera transmise uniquement à l’entreprise dont vous aurez validé le devis en ligne. L’entreprise sélectionnée récupérera et déposera ensuite les clés aux adresses indiquées" />
          </div>
          <div className="estimate-input--wrapper empty">
            <Input name="keyDropoffAddress" placeholder="Où déposer les clés ? (Adresse)" />
          </div>
          <div className="estimate-input--wrapper empty">
            <Input name="contactPerson" placeholder="Téléphone du contact" />
          </div>
          <Form.Actions>
            <Button name="Télécharger" onClick={() => downloadMutation.mutate({ resource: companyStorage, filename: companyStorage["quotation"] })} />
            <Input.File name="quotation" label="Insérer" disabled={!downloadMutation.isSuccess} accept="application/pdf" asButton />
            <Button name="Envoyer" disabled={!Boolean(quotation)} loading={mutation.isPending} onClick={formMethods.handleSubmit(handleSubmit)} />
          </Form.Actions>
        </div>
      </Form>
    </Modal>
  );
};

interface EstimateActionTableRowProps {
  companyStorage: CompanyStorage;
  readOnly: boolean;
}

const CompanyStorageTableRow = ({ companyStorage, readOnly = false }: EstimateActionTableRowProps) => {
  const modal = useModal();
  const CompanyInfosModalId = modalId(CompanyInfosModal.name, companyStorage.company);
  const StorageSignModalId = modalId(StorageSignModal.name, companyStorage.id);

  const [isActionMenuOpen, setActionMenuOpen] = useState(false);
  const mutation = usePbDownload();

  const status = companyStorage.state === CompanyStorageState.accepted
    ? "Accepté"
    : companyStorage.state === CompanyStorageState.refused
      ? "Refusé"
      : "En attente";

  return (
    <Fragment>
      <ActionTable.Row>{formateDate(companyStorage.created)}</ActionTable.Row>
      <ActionTable.Row>{status}</ActionTable.Row>
      <ActionTable.Row>{companyStorage.expand.company.name}</ActionTable.Row>
      <ActionTable.Row>{formatPrice(companyStorage.price)}</ActionTable.Row>
      {readOnly ?
        <ActionTable.Row>
          <Button.Small
              style={{marginBottom: '10px'}}
              name="Devis"
              disabled={!companyStorage?.quotation}
              onClick={() => mutation.mutate({ resource: companyStorage, filename: companyStorage["quotation"] })}
            />
            <Button.Small
              name="Facture"
              disabled={!companyStorage?.invoice}
              onClick={() => mutation.mutate({ resource: companyStorage, filename: companyStorage["invoice"] })}
            />
        </ActionTable.Row>
      :
      <ActionTable.Row>
        <Button.Small
          className="actions-close"
          name={`${isActionMenuOpen ? "Fermer" : "Actions"}`}
          onClick={() => setActionMenuOpen(prevState => !prevState)}
        />
        {isActionMenuOpen && (
          <div className="actions--menu">
            <Button.Small
              name="Signer"
              disabled={!companyStorage?.quotation || companyStorage.state !== CompanyStorageState.waiting}
              onClick={() => modal.open(StorageSignModalId)}
            />
            <Button.Small
              name="Devis"
              disabled={!companyStorage?.quotation}
              onClick={() => mutation.mutate({ resource: companyStorage, filename: companyStorage["quotation"] })}
            />
            <Button.Small
              name="Facture"
              disabled={!companyStorage?.invoice}
              onClick={() => mutation.mutate({ resource: companyStorage, filename: companyStorage["invoice"] })}
            />
            <Button.Small name="Plus d'infos" onClick={() => modal.open(CompanyInfosModalId)} />
          </div>
        )}
      </ActionTable.Row>
      }
    </Fragment>
  );
};

interface StorageWaitingTableRowProps {
  storage: Storage;
}

const StorageWaitingTableRow = ({ storage }: StorageWaitingTableRowProps) => {
  return (
    <Fragment>
      <ActionTable.Row>{formateDate(storage.created)}</ActionTable.Row>
      <ActionTable.Row>En attente</ActionTable.Row>
      <ActionTable.Row>N.C</ActionTable.Row>
      <ActionTable.Row>N.C</ActionTable.Row>
      <ActionTable.Row>-</ActionTable.Row>
    </Fragment>
  );
};

interface Props {
  saleId: string;
  readOnly: boolean;
}

export const StorageActionTable = ({ saleId, readOnly }: Props) => {
  const pb = usePb();
  const waitingStorages: Storage[] = [];
  const companyStorages: CompanyStorage[] = [];

  const { data: storages = [], isPending } = useQuery<Storage[]>({
    queryKey: ["storages", saleId],
    queryFn: async () => {
      return pb.collection("storages").getFullList({
        filter: `sale = "${saleId}"`,
        expand: "companyStorages_via_storage.company",
      });
    },
    enabled: !!saleId
  });

  for (const storage of storages) {
    if (Boolean(storage?.expand?.companyStorages_via_storage?.length)) {
      companyStorages.push(...storage.expand.companyStorages_via_storage);
    } else {
      waitingStorages.push(storage);
    }
  }

  if (readOnly) {
    return (
      <Fragment>
        <ActionTable isPending={isPending} emptyMessage="Aucune demande effectuée à ce jour." headers={['Date', 'Statut', 'Entreprise', 'Montant', 'Actions']}>
          {companyStorages.map(companyStorage => (
            <CompanyStorageTableRow key={companyStorage.id} companyStorage={companyStorage} readOnly/>
          ))}
        </ActionTable>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <ActionTable isPending={isPending} emptyMessage="Aucune demande effectuée à ce jour." headers={['Date', 'Statut', 'Entreprise', 'Montant', 'Actions']}>
        {waitingStorages.map(storage => (
          <StorageWaitingTableRow key={storage.id} storage={storage} />
        ))}
        {companyStorages.map(companyStorage => (
          <CompanyStorageTableRow key={companyStorage.id} companyStorage={companyStorage} />
        ))}
      </ActionTable>

      {/* Rendu des modales en dehors du tableau */}
      {companyStorages.map(companyStorage => (
        <Fragment key={companyStorage.id}>
          <StorageSignModal companyStorage={companyStorage} />
          <CompanyInfosModal id={modalId(CompanyInfosModal.name, companyStorage.company)} company={companyStorage.expand.company} />
        </Fragment>
      ))}
    </Fragment>
  );
};
