import type { z } from "zod";
import type { Sale } from "@interfaces/sale.interface";
import { useParams } from "react-router-dom";
import { useForm, type UseFormReturn } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Form } from "@components/form/Form";
import { Button } from "@components/buttons/Buttons";
import Input from "@components/inputs/Input";
import { EstimateDepositModal } from "./EstimateDepositModal";
import { CreateSheetSchema, CreateEstimateCompanySchema } from "@schemas/estimate.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useModal } from "@hooks/useModal";
import { Select } from "@components/inputs/Select";
import "./EstimateDeposit.scss"
import { Fragment } from "react";
import { TypeOfBuilding, formatFullAddress } from "@interfaces/address.interface";
import { ExternalNotificationCard } from "@components/ExternalNotificationCard";
import { trpc } from "@client/index";
import { getQueryKey } from "@trpc/react-query";

interface SaleDescriptionProps {
  sale: Sale;
}

const SaleDescription = ({ sale }: SaleDescriptionProps) => {
  return (
    <div className="mailTitle">
      <h2>Rendez-vous d'estimation</h2>
      <p>Adresse : {formatFullAddress(sale.expand.address)}</p>
      <span>Une fois l’estimation réalisée, merci de remplir les champs ci-dessous et d’insérer le document</span>
    </div>
  )
}

interface SheetFormProps {
  formMethods: UseFormReturn<z.infer<typeof FormSchema>>;
}

const SheetForm = ({ formMethods }: SheetFormProps) => {
  const typeOfBuilding = formMethods.watch("typeOfBuilding");

  return (
    <div className="mailForm">
      <h3>Fiche d'identité du bien</h3>
      <Select
        name="typeOfBuilding"
        border
        options={[
          { label: "Maison", value: TypeOfBuilding.maison },
          { label: "Appartement", value: TypeOfBuilding.appartement },
        ]} />

      <Input
        name="surface"
        border
        min={0}
        placeholder="Surface (approximative)"
        type="number" />

      <Input
        name="numberOfRooms"
        border
        min={0}
        placeholder="Nombre de pièce"
        type="number" />

      <Input
        name="yearOfConstruction"
        border
        min={0}
        placeholder="Année de construction (approximative)"
        type="number" />

      {typeOfBuilding === TypeOfBuilding.appartement && (
        <Input
          name="numberOfFloors"
          border
          min={0}
          placeholder="Etage"
          type="number" />
      )}

      <Input
        name="typeOfHeating"
        border
        placeholder="Type de chauffage" />
    </div>
  );
};

const EstimateForm = () => {
  return (
    <div className="mailForm last">
      <h3>Estimation du bien</h3>

      <Input
        name="sellerPriceLow"
        border
        min={1}
        placeholder="Prix net vendeur (fourchette basse)"
        type="number" />

      <Input
        name="sellerPriceHigh"
        border
        min={1}
        placeholder="Prix net vendeur (fourchette haute)"
        type="number" />

      <Input
        name="ownerName"
        border
        placeholder="Nom du propriétaire" />

      <Input.File
        name="report"
        label="Insérer l'estimation réalisée en PDF"
        accept="application/pdf" />
    </div>
  );
};

const FormSchema = CreateSheetSchema.merge(CreateEstimateCompanySchema).superRefine(({ typeOfBuilding, numberOfFloors }, ctx) => {
  if (typeOfBuilding === TypeOfBuilding.appartement && !numberOfFloors) {
    ctx.addIssue({
      code: "custom",
      message: "Ce champ est requis",
      path: ['numberOfFloors']
    });
  }
});

export const EstimateDeposit = () => {
  const modal = useModal();
  const queryClient = useQueryClient();

  const { estimateId } = useParams<{ estimateId: string; }>();

  const formMethods = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      surface: undefined,
      numberOfRooms: undefined,
      yearOfConstruction: undefined,
      typeOfBuilding: TypeOfBuilding.maison,
      numberOfFloors: undefined,
      typeOfHeating: "",
      sellerPriceLow: "",
      sellerPriceHigh: "",
      ownerName: "",
      report: undefined,
    },
  });

  const { error, data } = trpc.external.estimate.depositGetResources.useQuery({ estimateId: estimateId! }, { retry: false });

  const errorStatus = error?.data?.httpStatus;

  const depositMutation = trpc.external.estimate.deposit.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.external.estimate.depositGetResources) });

      modal.close(EstimateDepositModal.name);
    },
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    }
  });

  const depositWithAddressSheetMutation = trpc.external.estimate.depositWithAddressSheet.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.external.estimate.depositGetResources) });

      modal.close(EstimateDepositModal.name);
    },
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    }
  });

  const isSuccess = depositMutation.isSuccess || depositWithAddressSheetMutation.isSuccess;
  const isPending = depositMutation.isPending || depositWithAddressSheetMutation.isPending;

  if (isSuccess) {
    return <ExternalNotificationCard title="Merci d'avoir déposé votre estimation!" />
  }

  if (errorStatus === 403 || errorStatus === 404) {
    return <ExternalNotificationCard title="Cette page n'est plus disponible" />
  }

  if (typeof errorStatus === "number") {
    return <ExternalNotificationCard title="Une erreur est survenue. Merci de réessayer plus tard." />
  }

  if (!data) {
    return null;
  }

  const { sale } = data;

  const isSheetAlreadyFilled = sale.expand.address.isComplete;

  const isValid = async () => {
    return isSheetAlreadyFilled
      ? await formMethods.trigger(['sellerPriceHigh', 'sellerPriceLow', 'ownerName', 'report'])
      : await formMethods.trigger();
  }

  const onSubmit = async () => {
    if (!await isValid()) {
      return;
    }

    const values = formMethods.getValues();

    const formData = new FormData();

    formData.set("estimateId", estimateId!);

    for (const name in values) {
      // @ts-expect-error
      formData.append(name, values[name]);
    }

    if (isSheetAlreadyFilled) {
      depositMutation.mutate(formData);
    } else {
      depositWithAddressSheetMutation.mutate(formData);
    }
  }

  return (
    <Fragment>
      <Form.DefaultLayout formMethods={formMethods}>

        <SaleDescription sale={sale as Sale} />

        {!isSheetAlreadyFilled && (
          <SheetForm formMethods={formMethods} />
        )}

        <EstimateForm />

        <div className="actions--mail">
          <Form.Actions centered>
            <Button
              name="Déposer l'estimation"
              onClick={async () => {
                if (await isValid()) {
                  modal.open(EstimateDepositModal.name)
                }
              }} />
          </Form.Actions>
        </div>

      </Form.DefaultLayout>

      <EstimateDepositModal
        handleForm={onSubmit}
        isFetching={isPending} />

    </Fragment>
  );
};
