import React, { useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ModalProvider } from '@contexts/modal.context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { AuthProvider } from '@contexts/auth.context';
import { router } from './router';
import { httpLink } from '@trpc/client';
import { trpc } from '@client/index';
import 'react-loading-skeleton/dist/skeleton.css'
import { DropdownProvider } from '@contexts/dropdown.context';

export const App = () => {
  const [queryClient] = useState(() => new QueryClient({
    queryCache: new QueryCache({
      onError: (error) => {
        console.error(error)
      },
    })
  }));
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        // httpLink can perform multipart/form-data requests, not httpBatchLink
        httpLink({
          url: "/api/trpc",
          headers: () => {
            const params = new URLSearchParams(window.location.search);

            const iv = params.get('iv')!;
            const token = params.get('token')!;

            return {
              iv,
              token
            }
          },
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: 'include',
            });
          },
        }),
      ],
    }),
  );

  return (
    <React.StrictMode>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <DropdownProvider>
            <ModalProvider>
              <AuthProvider>
                {({ isLogged }) => (
                  <RouterProvider router={router(isLogged)} />
                )}
              </AuthProvider>

              <ToastContainer
                position="top-center"
                autoClose={3000}
                limit={4}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                pauseOnHover={false}
                style={{
                  height: '100vh',
                  width: '100vw',
                  position: 'fixed',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: 0,
                  backgroundColor: '#0101019e',
                  left: 0,
                  transform: 'none',
                  padding: "0 13vw",
                }}
                rtl={false}
                theme="colored"
              />
            </ModalProvider>
          </DropdownProvider>
        </QueryClientProvider>
      </trpc.Provider>
    </React.StrictMode>
  );
};