import { z } from "zod";
import validator from "validator";
import { inferEnum } from "@utils/utils";
import { CompanyType } from "@interfaces/company.interface";

export const CreateCompanySchema = z.object({
  name: z.string().min(1),
  type: z.enum(inferEnum(CompanyType)),
  phone: z.string().refine(validator.isMobilePhone),
  email: z.string().refine(validator.isEmail),
  firstname: z.string().min(1),
  lastname: z.string().min(1),
  address: z.string().min(1).max(255),
  zipcode: z.string(),
  city: z.string().min(1).max(255),
});

export const UpdateCompanySchema = CreateCompanySchema;

export type CreateCompany = z.infer<typeof CreateCompanySchema>;
