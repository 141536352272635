import { Fragment, useEffect, useState } from "react";
import { ProtecteeCard, ProtecteeDisplayCard } from "@components/features/dashboard/protectees/ProtecteeCard";
import type { Protectee } from "@interfaces/protectee.interface";
import { useParams } from "react-router-dom";

interface Props {
  protectees: Protectee[];
}

export const ProtecteesList = ({ protectees }: Props) => {
  const { saleId } = useParams<{ saleId: string }>();

  const [toggle, setToggle] = useState(true);
  const [selectedProtecteeId, setSelectedProtecteeId] = useState<string>();

  const selectedProtectee = protectees.find(protectee => protectee.expand.sales_via_protectee.find(sale => sale.id === saleId));

  const sale = selectedProtectee?.expand.sales_via_protectee.find(sale => sale.id === saleId);

  const address = sale?.expand.address;

  useEffect(() => {
    if (address) {
      setToggle(false);
    }

    if (selectedProtectee) {
      setSelectedProtecteeId(selectedProtectee.id)
    }
  }, [address, selectedProtectee]);

  return (
    <div>
      {(selectedProtectee && address) && (
        <ProtecteeDisplayCard protectee={selectedProtectee} address={address} />
      )}

      <div className="f-sale--list-toggle cursor-pointer" onClick={() => setToggle(prevState => !prevState)}>
        <span>{toggle ? "Replier les dossiers" : "Déplier les dossiers"}</span>
      </div>

      {toggle && (
        <Fragment>
          {protectees.map((protectee) => {
            const sale = protectee.expand.sales_via_protectee.find(sale => sale.id === saleId)!;

            return (
              <ProtecteeCard
                key={protectee.id}
                protectee={protectee}
                open={selectedProtecteeId === protectee.id}
                onOpen={() => setSelectedProtecteeId(protectee.id)} />
            )
          })}
        </Fragment>
      )}
    </div>
  );
};
