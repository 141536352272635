import { useParams } from "react-router-dom";
import "./MandateDeposit.scss";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { Form } from "@components/form/Form";
import { ExternalPropertyDesc } from "@components/ExternalPropertyDesc";
import Input from "@components/inputs/Input";
import { Button } from "@components/buttons/Buttons";
import { ExternalNotificationCard } from "@components/ExternalNotificationCard";
import { MandateDepositSchema, MandateMissingPiecesSchema } from "@schemas/mandate.schema";
import { trpc } from "@client/index";
import { getQueryKey } from "@trpc/react-query";
import { ExternalGenericInfos } from "@components/ExternalGenericInfos";
import { ExternalPageTitle } from "@components/ExternalPageTitle";
import Modal from "@components/modal/Modal";
import { useModal } from "@hooks/useModal";
import { Fragment } from "react";
import { formatPrice } from "@utils/utils";
import type { Mandate } from "@interfaces/mandate.interface";
import { Toggle } from "@components/inputs/Toggle";
import { Warning } from "@components/Warning";

interface MissingSalePiecesModalProps {
  mandateId: string;
}

const MissingSalePiecesModal = ({ mandateId }: MissingSalePiecesModalProps) => {
  const modal = useModal();

  const formMethods = useForm<z.infer<typeof MandateMissingPiecesSchema>>({
    resolver: zodResolver(MandateMissingPiecesSchema),
    defaultValues: {
      missingPieces: ""
    },
  });

  const mutation = trpc.external.mandate.missingPieces.useMutation({
    onSuccess() {
      formMethods.reset();

      modal.close(MissingSalePiecesModal.name);

      toast.success("Le mandataire a été informé des pièces manquantes de la vente.");
    },
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
  });

  return (
    <Modal id={MissingSalePiecesModal.name} title="Pièces de la vente manquantes">
      <Form.DefaultLayout formMethods={formMethods}>

        <div className="missing--pieces fx fx--col">
          <p>
            Merci d'indiquer les pièces de la vente manquantes à la rédaction du mandat
          </p>

          <Input.Textarea name="missingPieces" />

          <p>
            Les pièces vous seront envoyées par mails lorsque le mandataire les aura déposé sur Athena
          </p>
        </div>

        <Form.Actions centered>
          <Button
            name="Valider"
            loading={mutation.isPending}
            onClick={formMethods.handleSubmit(values => mutation.mutate({ mandateId, ...values }))} />
        </Form.Actions>
      </Form.DefaultLayout>
    </Modal>
  )
}

interface LastCheckBeforeSubmitModalProps {
  mandate: Mandate;
  loading: boolean;
  onSubmit: () => void;
}

const FormSchema = z.object({
  confirmClauses: z.enum(["yes"]),
  mandatePrice: z.enum(["net"]),
  confirmIndivisaires: z.enum(["yes"]),
});

const LastCheckBeforeSubmitModal = ({ mandate, loading, onSubmit }: LastCheckBeforeSubmitModalProps) => {
  const formMethods = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      confirmClauses: "yes",
      mandatePrice: "net",
      confirmIndivisaires: "yes",
    },
  });

  return (
    <Modal id={LastCheckBeforeSubmitModal.name} title="Dernières verifications">

      <div className="warning--wrapper">

        {Boolean(formMethods.formState.errors.confirmClauses) && (
          <Warning
            message="Attention, même si le mandat a été signé sans ces stipulations, cet oubli pourra entraîner la responsabilité de l'agence."
            redMessage="Merci d'intégrer les clauses demandées à votre mandat." />
        )}

        {Boolean(formMethods.formState.errors.mandatePrice) && (
          <Warning message="Le prix sur le mandat doit être indiquer en net vendeur" redMessage="Merci de modifier votre document." />
        )}

        {Boolean(formMethods.formState.errors.confirmIndivisaires) && (
          <Warning
            message="Le mandat doit être signé par l'ensemble des indivisaires avant d'être transmis à la plateforme."
            redMessage="Merci de procéder à ces signatures." />
        )}
      </div>


      <Form.DefaultLayout formMethods={formMethods}>
        
        <div className="last-verif">

        {mandate.clauses.map((clause, index) => {
            return (
              <p style={{ color: "#fff" }}>
                Clause n°{index + 1}: {clause}
              </p>
            )
          })}

          <h3>Je confirme avoir intégré les clauses ci-dessus</h3>
          <Toggle
            name="confirmClauses"
            choices={[
              { value: "yes", label: "OUI" },
              { value: "no", label: "NON" },
            ]} />

          <h3>Le prix dans le mandat est indiqué en</h3>
          <Toggle
            name="mandatePrice"
            choices={[
              { value: "net", label: "Prix net vendeur" },
              { value: "agency", label: "Prix frais d'agence inclus" },
            ]} />

          <h3>L'ensemble des indivisaires (s'il y en a), ont signés</h3>
          <Toggle
            name="confirmIndivisaires"
            choices={[
              { value: "yes", label: "OUI" },
              { value: "no", label: "NON" },
            ]} />
        </div>
        <Form.Actions centered>
          <Button
            name="Valider"
            loading={loading}
            onClick={formMethods.handleSubmit(() => onSubmit())} />
        </Form.Actions>
      </Form.DefaultLayout>
    </Modal>
  )
}

export const MandateDeposit = () => {
  const modal = useModal();
  const queryClient = useQueryClient();

  const { mandateId } = useParams<{ mandateId: string; }>();

  const formMethods = useForm<z.infer<typeof MandateDepositSchema>>({
    resolver: zodResolver(MandateDepositSchema),
    defaultValues: {
      mandateFile: undefined
    },
  });

  const guardQueryResult = trpc.external.mandate.depositGetResources.useQuery({ mandateId: mandateId! }, {
    retry: false,
  });

  const mutation = trpc.external.mandate.deposit.useMutation({
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.external.mandate.depositGetResources) });
    }
  });

  const handleSubmit = (body: z.infer<typeof MandateDepositSchema>) => {
    const formData = new FormData();

    formData.append("mandateId", mandateId!);
    formData.append("mandateFile", body.mandateFile!);

    mutation.mutate(formData);
  }

  const onValidate = async () => {
    const isValid = await formMethods.trigger();

    if (isValid) {
      modal.open(LastCheckBeforeSubmitModal.name)
    }
  }

  const errorStatus = guardQueryResult.error?.data?.httpStatus;

  if (mutation.isSuccess) {
    return <ExternalNotificationCard title="Merci d'avoir déposé votre mandat" />
  }

  if (errorStatus === 403 || errorStatus === 404) {
    return <ExternalNotificationCard title="Cette page n'est plus disponible" />
  }

  if (typeof errorStatus === "number") {
    return <ExternalNotificationCard title="Une erreur est survenue. Merci de réessayer plus tard." />
  }

  if (!guardQueryResult.data) {
    return null;
  }

  const { mandate, sale } = guardQueryResult.data;

  const address = sale.expand.address;

  const protecteeName = `${sale.expand.protectee.lastname} ${sale.expand.protectee.firstname}`;

  return (
    <Fragment>
      <Form.DefaultLayout formMethods={formMethods}>

        <div className="mailForm last">
          <h1 className="title">Dépôt du mandat</h1>
          <ExternalPropertyDesc address={address} />

          <div className="important-infos">
            <h4>Merci d'intégrer les clauses ci-dessous à votre mandat</h4>

            {mandate.clauses.map((clause, index) => {
              return (
                <p key={index}>
                  Clause n°{index + 1}: {clause}
                </p>
              )
            })}

            <ExternalGenericInfos
              title=""
              informations={{
                "NOM DU PROPRIETAIRE": protecteeName,
                "PRIX NET VENDEUR": formatPrice(mandate.price),
              }} />
          </div>

          <Input.File
            name="mandateFile"
            label="Insérer le mandat réalisé en PDF"
            accept="application/pdf" />

        </div>

        <Form.Actions centered>
          <Button
            name="Valider"
            onClick={onValidate} />
        </Form.Actions>

        {/* Fonctionnalité permettant de demander d'autres pièces de la vente. Ce bouton va envoyer un mail au mandataire
        Je laisse en commentaire car il a été décidé de retirer cette fonctionnalité pour le moment */}

        {/* <div className="actions--mail">
          <h3>
            S'il vous manque des pièces de la vente pour rédiger le mandat, cliquer ici
          </h3>

          <Button name="Pièces manquantes" onClick={() => modal.open(MissingSalePiecesModal.name)} />
        </div> */}

      </Form.DefaultLayout>

      {/* <MissingSalePiecesModal mandateId={mandateId!} /> */}
      <LastCheckBeforeSubmitModal
        mandate={mandate as Mandate}
        loading={mutation.isPending}
        onSubmit={formMethods.handleSubmit(handleSubmit)} />
    </Fragment>
  )
}