import { z } from "zod";

export const LoginSchema = z.object({
  email: z
    .string({
      invalid_type_error: "Email must be a valid email",
      required_error: "Email is required",
    })
    .email("Email must be a valid email"),
  password: z
    .string({
      invalid_type_error: "Password must be a string",
      required_error: "Password is required",
    })
    .min(1),
});

export const LoginRoleSchema = z.object({
  email: z
    .string()
    .email()
    .endsWith("@horizons.eu.com")
    .or(z.string().email().endsWith("@2pulse.com")),
});

export const RegisterSchema = z
  .object({
    email: z
      .string({
        invalid_type_error: "L'email doit être valide",
        required_error: "L'email est requis",
      })
      .email("L'email doit être valide"),
    firstname: z
      .string({
        invalid_type_error: "Le prénom doit être une chaine de caractères",
        required_error: "Le prénom est requis",
      })
      .min(1, "Le prénom est requis"),
    lastname: z
      .string({
        invalid_type_error: "Le nom doit être une chaine de caractères",
        required_error: "Le nom est requis",
      })
      .min(1, "Le nom est requis"),
    phone: z.string({
      invalid_type_error:
        "Le numéro de téléphone doit être une chaine de caractères",
      required_error: "Le numéro de téléphone est requis",
    }),
    password: z.string({
      invalid_type_error: "Le mot de passe doit être une chaine de caractères",
      required_error: "Le mot de passe est requis",
    }).min(8),
    passwordConfirm: z.string({
      invalid_type_error:
        "La confirmation du mot de passe doit être une chaine de caractères",
      required_error: "La confirmation du mot de passe est requise",
    }).min(8),
    role: z.enum(["representative", "attendant", "tutelary"], {
      invalid_type_error:
        "Le rôle doit être un mandataire indépendant, un préposé d'établissement ou une association tutélaire",
      required_error:
        "Le rôle doit être un mandataire indépendant, un préposé d'établissement ou une association tutélaire",
    }),
    department: z.string(),
    establishment: z.string().optional(),
    association: z.string().optional(),
    approvalYear: z.string().optional(),
  })
  .superRefine((data, context) => {
    if (data.passwordConfirm !== data.password) {
      context.addIssue({
        code: "custom",
        message: "Les mots de passent ne concordent pas",
        path: ['passwordConfirm']
      });
    }

    if (data.role === "representative" && !data.approvalYear) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["approvalYear"],
        message: "L'année d'agrément est requise",
      });
    }
    if (data.role === "attendant" && !data.establishment) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["establishment"],
        message: "L'établissement est requis",
      });
    }
    if (data.role === "tutelary" && !data.association) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["association"],
        message: "L'association est requise",
      });
    }
  });