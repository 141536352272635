import React, { createContext, memo, useContext } from "react";

interface Dropdown {
  id: string;
  isOpen: boolean;
  disabled?: boolean;
}

interface DropdownContextProps {
  dropdowns: Dropdown[];
  open: (id: string) => void;
  close: (id: string) => void;
  closeAll: () => void;
  isOpen: (id: string) => boolean;
  registerDropdown: (id: string) => void;
  unregisterDropdown: (id: string) => void;
  isDisabled: (id: string) => boolean;
}

const DropdownContext = createContext<DropdownContextProps>({} as DropdownContextProps);

export const useDropdownContext = () => {
  return useContext(DropdownContext);
}

interface DropwdownProviderProps {
  children: React.ReactNode;
}

export const DropdownProvider = memo(({ children }: DropwdownProviderProps) => {
  const [dropdowns, setDropdowns] = React.useState<Dropdown[]>([]);

  const registerDropdown = (id: string) => {
    setDropdowns((prevState) => ([...prevState, { id, isOpen: false }]))
  }

  const unregisterDropdown = (id: string) => {
    setDropdowns((prevState) => prevState.filter(dropdown => dropdown.id !== id))
  }

  const isOpen = (id: string) => {
    return !!dropdowns.find(dropdown => dropdown.id === id)?.isOpen;
  }

  const isDisabled = (id: string) => {
    return !!dropdowns.find(dropdown => dropdown.id === id)?.disabled;
  }

  const open = (id: string) => {
    setDropdowns((prevState) => prevState.map(dropdown => {
      if (dropdown.id === id) {
        dropdown.isOpen = true;
      }

      return dropdown;
    }));
  }

  const close = (id: string) => {
    setDropdowns((prevState) => prevState.map(dropdown => {
      if (dropdown.id === id) {
        dropdown.isOpen = false;
      }

      return dropdown;
    }));
  }

  const closeAll = () => {
    setDropdowns((prevState) => prevState.map(dropdown => {
      dropdown.isOpen = false;

      return dropdown;
    }));
  }

  return (
    <DropdownContext.Provider value={{ dropdowns, open, close, isOpen, isDisabled, closeAll, registerDropdown, unregisterDropdown }}>
      {children}
    </DropdownContext.Provider>
  )
});