import { trpc } from "@client/index";
import { Button } from "@components/buttons/Buttons";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input";
import Modal from "@components/modal/Modal"
import { zodResolver } from "@hookform/resolvers/zod";
import { useModal } from "@hooks/useModal";
import type { CompanyOffer } from "@interfaces/offer.interface";
import { SendCompanyOfferMessageSchema } from "@schemas/offer.schema";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface Props {
  id: string;
  companyOffer: CompanyOffer;
}

export const OfferSendMessageToCompanyModal = ({ id: modalId, companyOffer }: Props) => {
  const modal = useModal();

  const formMethods = useForm({
    resolver: zodResolver(SendCompanyOfferMessageSchema),
    defaultValues: {
      message: ""
    }
  });

  const mutation = trpc.sale.offer.sendMessageToCompany.useMutation({
    onSuccess: () => {
      toast.success("Votre message a bien été envoyé.");

      formMethods.reset();

      modal.closeAll();
    },
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
  });

  return (
    <Modal id={modalId} title="Écrire à l'agence">
      <Form formMethods={formMethods}>
        <Input.Textarea name="message" placeholder="Votre message..." />

        <Form.Actions centered>
          <Button
            name="Envoyer"
            loading={mutation.isPending}
            onClick={formMethods.handleSubmit((values) => mutation.mutate({ companyOfferId: companyOffer.id, ...values }))} />
        </Form.Actions>
      </Form>
    </Modal>
  )
}