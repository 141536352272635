import { useModalContext } from "@contexts/modal.context";

export const useModal = () => {
  const context = useModalContext();

  const open = (id: string) => {
    context.open(id);
  }

  const close = (id: string) => {
    context.close(id);
  }

  const closeAll = () => {
    context.modals.forEach(modal => {
      context.close(modal.id);
    });
  }

  return { open, close, closeAll }
}