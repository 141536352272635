import { z } from "zod";

export const ContactAdminSchema = z.object({
  phone: z.string({
    invalid_type_error: "Phone must be a string",
    required_error: "Phone is required",
  }).min(1, { message: "Ce champ est requis" }),
  message: z.string({
    invalid_type_error: "Message must be a string",
    required_error: "Message is required",
  }).min(1, { message: "Ce champ est requis" }),
});
