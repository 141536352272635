import React, { createContext, memo, useContext } from "react";

interface Modal {
  id: string;
  isOpen: boolean;
}

interface ModalContextProps {
  modals: Modal[];
  open: (id: string) => void;
  close: (id: string) => void;
  isOpen: (id: string) => boolean;
  registerModal: (id: string) => void;
  unregisterModal: (id: string) => void;
}

const ModalContext = createContext<ModalContextProps>({} as ModalContextProps);

export const useModalContext = () => {
  return useContext(ModalContext);
}

interface ApiErrorsProviderProps {
  children: React.ReactNode;
}

export const ModalProvider = memo(({ children }: ApiErrorsProviderProps) => {
  const [modals, setModals] = React.useState<Modal[]>([]);

  const registerModal = (id: string) => {
    setModals((prevState) => ([...prevState, { id, isOpen: false }]))
  }

  const unregisterModal = (id: string) => {
    setModals((prevState) => prevState.filter(modal => modal.id !== id))
  }

  const isOpen = (id: string) => {
    return !!modals.find(modal => modal.id === id)?.isOpen;
  }

  const open = (id: string) => {
    setModals((prevState) => prevState.map(modal => {
      if (modal.id === id) {
        modal.isOpen = true;
      }

      return modal;
    }));
  }

  const close = (id: string) => {
    setModals((prevState) => prevState.map(modal => {
      if (modal.id === id) {
        modal.isOpen = false;
      }

      return modal;
    }));
  }

  return (
    <ModalContext.Provider value={{ modals, open, close, isOpen, registerModal, unregisterModal }}>
      {children}
    </ModalContext.Provider>
  )
});