import { Button } from "@components/buttons/Buttons";
import { Table } from "@components/table/Table";
import { usePb } from "@hooks/usePb";
import {
  type ColumnDef,
  type SortingState,
  useReactTable,
  getSortedRowModel,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  createColumnHelper,
  type PaginationState,
} from "@tanstack/react-table";
import { expand } from "@utils/pb";
import type { ListResult } from "pocketbase";
import React, { Fragment } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Form } from "@components/form/Form";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CopropertyTitleRequestState, type CopropertyTitleRequest } from "@interfaces/salePieces.interface";
import { trpc } from "@client/index";
import { formatFullAddress } from "@interfaces/address.interface";
import Input from "@components/inputs/Input";
import Modal from "@components/modal/Modal";
import { useModal } from "@hooks/useModal";
import { UploadSalePieceMultipleDocumentSchema } from "@schemas/salePiece.schema";
import { usePbDownload } from "@hooks/usePbDownload";

const columnHelper = createColumnHelper<CopropertyTitleRequest>();

export const CopropertyTitleTable = () => {
  const pb = usePb();
  const modal = useModal();
  const queryClient = useQueryClient();

  const downloadMutation = usePbDownload();

  const [selectedCopropertyTitleRequestId, setSelectedCopropertyTitleRequestId] = React.useState<string | null>(null);

  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const page = pageIndex + 1;

  const { data, isFetching } = useQuery<ListResult<CopropertyTitleRequest>>({
    queryKey: ["copropertyTitleRequests", page, pageSize],
    queryFn: async () => {
      return pb.collection("copropertyTitleRequests").getList(page, pageSize, {
        expand: expand([
          "salePiece.sale.address",
          "salePiece.sale.protector",
          "salePiece.sale.protectee",
        ]),
      });
    },
  });

  const { items: salePiecesData = [], totalPages } = data! || {};

  const formMethods = useForm<z.infer<typeof UploadSalePieceMultipleDocumentSchema>>({
    resolver: zodResolver(UploadSalePieceMultipleDocumentSchema),
    defaultValues: {
      documents: [],
    },
  });

  const mutation = trpc.sale.salePiece.copropertyTitle.uploadFromAdmin.useMutation({
    onSuccess: () => {
      formMethods.reset();

      modal.close("copropertyTitleInputModal");

      queryClient.invalidateQueries({ queryKey: ["copropertyTitleRequests", page, pageSize] });

      toast.success("Votre document a bien été ajouté.");
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const handleFileUpload = (body: z.infer<typeof UploadSalePieceMultipleDocumentSchema>) => {
    const formData = new FormData();

    formData.append("copropertyTitleRequestId", selectedCopropertyTitleRequestId!);

    for (const document of (body.documents || [])) {
      formData.append("documents[]", document);
    }

    mutation.mutate(formData);
  };

  const columns = React.useMemo<ColumnDef<CopropertyTitleRequest>[]>(
    () => [
      columnHelper.display({
        header: "MANDATAIRE",
        cell: ({ row }) => {
          const salePiece = row.original.expand.salePiece;
          const sale = salePiece.expand.sale;

          return (
            <Table.Cell
              value={`${sale.expand.protector.lastname} ${sale.expand.protector.firstname}`}
              bold />
          );
        },
      }),
      columnHelper.display({
        header: "MAJEUR",
        cell: ({ row }) => {
          const salePiece = row.original.expand.salePiece;
          const sale = salePiece.expand.sale;

          return `${sale.expand.protectee.lastname} ${sale.expand.protectee.firstname}`;
        },
      }),
      columnHelper.display({
        header: "ADRESSE",
        cell: ({ row }) => {
          const salePiece = row.original.expand.salePiece;
          const sale = salePiece.expand.sale;

          return formatFullAddress(sale.expand.address);
        },
      }),
      columnHelper.display({
        enableSorting: true,
        header: "DATE",
        cell: ({ row }) => {
          const formattedDate = new Intl.DateTimeFormat("fr-FR", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour12: false,
            timeZone: "Europe/Paris",
          }).format(new Date(row.original.created));

          return <Table.Cell value={formattedDate} bold />;
        },
      }),
      columnHelper.display({
        header: "ETAT",
        cell: ({ row }) => {
          return row.original.state === CopropertyTitleRequestState.waiting
            ? "En attente"
            : "Envoyé";
        },
      }),
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => {
          return (
            <Fragment>
              <Button.Small
                name="Attestation"
                onClick={() => downloadMutation.mutate({ resource: row.original, filename: row.original["certificate"] })} />

              <Button.Small
                name="Document(s)"
                onClick={() => {
                  setSelectedCopropertyTitleRequestId(row.original.id);

                  modal.open("copropertyTitleInputModal");
                }} />
            </Fragment>
          )
        },
      }),
    ],
    []
  );

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: salePiecesData,
    columns,
    enableMultiSort: true,
    manualPagination: true,
    pageCount: totalPages,
    state: {
      pagination,
      sorting,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <>
      <Table table={table} isFetching={isFetching} />

      <Modal id="copropertyTitleInputModal" title="Insérer les pièces de co-propriété">

        <Form formMethods={formMethods}>
          <Input.File
            name="documents[]"
            multiple
            label="Insérer les pièces de co-propriété au format PDF"
            accept="application/pdf"
          />
          <Button.Small
            name="Envoyer"
            loading={mutation.isPending}
            onClick={formMethods.handleSubmit(handleFileUpload)} />
        </Form>
      </Modal>
    </>
  );
};
