import { z } from "zod";
import { validateFile } from "./shared.schema";

export const OfferDepositSchema = z.object({
  buyerName: z.string().min(1),
  price: z.coerce.number(),
  notaryName: z.string().min(1),
  suspensiveConditions: z.string(),
  hasSuspensiveConditions: z.enum(["yes", "no"]),
  offer: validateFile(),
  buyerCni: validateFile(),
  informations: z.string().optional(),
  freePieces: z.any().optional()
}).superRefine((data, ctx) => {
  if (data.hasSuspensiveConditions === "yes" && data.suspensiveConditions.length < 3) {
    ctx.addIssue({
      code: "custom",
      message: "Ce champ est requis.",
      path: ['suspensiveConditions']
    });
  }
});

export const IndivisionSchema = z.object({
  informations: z.string().min(3),
  price: z.coerce.number(),
  suspensiveConditions: z.string(),
  hasSuspensiveConditions: z.enum(["yes", "no"]),
}).superRefine((data, ctx) => {
  if (data.hasSuspensiveConditions === "yes" && data.suspensiveConditions.length < 3) {
    ctx.addIssue({
      code: "custom",
      message: "Ce champ est requis.",
      path: ['suspensiveConditions']
    });
  }
})

export const OfferSignSchema = z.object({
  keysInstructions: z.string().min(3),
  signedOffer: validateFile(),
})

export const SendCompanyOfferMessageSchema = z.object({
  message: z.string().min(3)
});

export const OfferRefusedSchema = z.object({
  reason: z.string().min(3)
})