import { usePb } from "@hooks/usePb";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input";
import { Button } from "@components/buttons/Buttons";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";


export const ForgotPasswordSchema = z.object({
  email: z
    .string({
      invalid_type_error: "Email must be a valid email",
      required_error: "Email is required",
    })
    .email("Email must be a valid email"),
});

export const ForgotPassword = () => {
  const pb = usePb();

  const formMethods = useForm<z.infer<typeof ForgotPasswordSchema>>({
    resolver: zodResolver(ForgotPasswordSchema),
    defaultValues: {
      email: "",
    },
  });

  const mutation = useMutation({

    mutationFn: async (body: z.infer<typeof ForgotPasswordSchema>) => {

      return pb
        .collection("users")
        .requestPasswordReset(body.email);
    },
    onSuccess: (data) => {
      data ?
        toast.success(
          "Un email de réinitialisation de mot de passe a été envoyé."
        )
        : toast.error(
          "Erreur lors de l'envoi de l'email de réinitialisation."
        );
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  }
  );

  return (
    <Form.DefaultLayout
      title="Réinitialiser le mot de passe"
      subtitle="Entrez votre mail dans le formulaire"
      formMethods={formMethods}
    >
      <Input
        border
        autoComplete="email"
        placeholder="Adresse email"
        name="email"
      />

      <Button
        name="Envoyer"
        onClick={formMethods.handleSubmit((values) => mutation.mutate(values))}
        loading={mutation.isPending}
      />

      <Form.Actions>
        <Button.Link
          dark
          to="/register"
          title="Je n'ai pas de compte, m'inscrire"
        />
        <Button.Link
          dark
          to="/"
          title="J'ai déjà un compte, me connecter"
        />
      </Form.Actions>
    </Form.DefaultLayout>
  );
};