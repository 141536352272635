import { Button } from "@components/buttons/Buttons";
import { Table } from "@components/table/Table";
import { usePb } from "@hooks/usePb";
import { formatFullAddress } from "@interfaces/address.interface";
import type { Protectee } from "@interfaces/protectee.interface";
import type { User } from "@interfaces/user.interface";
import {
  type ColumnDef,
  type SortingState,
  useReactTable,
  getSortedRowModel,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  createColumnHelper,
  type PaginationState,
  type Row,
} from "@tanstack/react-table";
import type { ListResult } from "pocketbase";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { usePbDownload } from "@hooks/usePbDownload";
import {
  CompanyMandateState,
  type CompanyMandate,
  type Mandate,
} from "@interfaces/mandate.interface";
import { expand } from "@utils/pb";
import { formatDateDDmmYYYY, formatPrice } from "@utils/utils";
import { useParams } from "react-router-dom";

const columnHelper = createColumnHelper<Mandate>();
const subColumnHelper = createColumnHelper<CompanyMandate>();

const getCompanyMandateState = (companyMandate: CompanyMandate) => {
  if (!companyMandate.mandateFile) {
    return "En attente dépôt";
  }

  if (companyMandate.state === CompanyMandateState.signed) {
    return "Signé";
  }

  return "A signer";
};

const getMandateState = (companyMandates: CompanyMandate[]) => {
  if (companyMandates.some(
    (companyMandate) => getCompanyMandateState(companyMandate) === "En attente dépôt"
  )) {
    return "Demandé";
  }

  if (companyMandates.some(
    (companyMandate) => getCompanyMandateState(companyMandate) === "A signer"
  )) {
    return "A signer";
  }

  if (companyMandates.every(
    (companyMandate) => getCompanyMandateState(companyMandate) === "Signé"
  )) {
    return "Signé";
  }

  return "Demandé";
};

export const MandateTable = () => {
  const pb = usePb();
  const { id } = useParams(); // Utilisation de useParams pour récupérer l'id

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 100,
    });

  const page = pageIndex + 1;

  // Modifie la requête pour récupérer soit tous les mandats, soit un mandat spécifique par ID
  const { data, isFetching } = useQuery<ListResult<Mandate>>({
    queryKey: ["mandates", page, pageSize, id], // Ajoute id dans la clé de requête
    queryFn: async () => {
      if (id) {
        return pb.collection("mandates").getOne(id, {
          expand: expand([
            "sale.protector",
            "sale.protectee",
            "sale.address",
            "companies",
            "companyMandates_via_mandate.company",
            "companyMandates_via_mandate.mandate",
          ]),
        });
      } else {
        return pb.collection("mandates").getList(page, pageSize, {
          expand: expand([
            "sale.protector",
            "sale.protectee",
            "sale.address",
            "companies",
            "companyMandates_via_mandate.company",
            "companyMandates_via_mandate.mandate",
          ]),
        });
      }
    },
  });

  // Gère les cas où l'id est présent et renvoie un seul mandat
  let mandates = [];
  let totalPages = 1;

  if (id) {
    mandates = data ? [data] : [];
  } else {
    mandates = data?.items || [];
    totalPages = data?.totalPages || 1;
  }

  const columns = React.useMemo<ColumnDef<Mandate>[]>(
    () => [
      // @ts-expect-error
      columnHelper.accessor("expand.sale.expand.protector", {
        header: "MANDATAIRE",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: (
          rowA: Row<Mandate>,
          rowB: Row<Mandate>,
          columnId: string
        ) => {
          const protectorA = rowA.getValue<User>(columnId);
          const protectorB = rowB.getValue<User>(columnId);

          const nameA = `${protectorA.lastname} ${protectorA.firstname}`;
          const nameB = `${protectorB.lastname} ${protectorB.firstname}`;

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        cell: (ctx) => {
          const { firstname, lastname } = ctx.getValue();
          return `${lastname} ${firstname}`;
        },
      }),
      // @ts-expect-error
      columnHelper.accessor("expand.sale.expand.protectee", {
        header: "MAJEUR",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: (
          rowA: Row<Mandate>,
          rowB: Row<Mandate>,
          columnId: string
        ) => {
          const protecteeA = rowA.getValue<Protectee>(columnId);
          const protecteeB = rowB.getValue<Protectee>(columnId);

          const nameA = `${protecteeA.lastname} ${protecteeA.firstname}`;
          const nameB = `${protecteeB.lastname} ${protecteeB.firstname}`;

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        cell: (ctx) => {
          const { firstname, lastname } = ctx.getValue();
          return <Table.Cell value={`${lastname} ${firstname}`} bold />;
        },
      }),
      columnHelper.display({
        header: "ADRESSE",
        cell: ({ row }) => {
          return formatFullAddress(row.original.expand.sale.expand.address);
        },
      }),
      // @ts-expect-error
      columnHelper.accessor("created", {
        header: "DATE",
        enableSorting: true,
        sortingFn: (rowA: Row<Mandate>, rowB: Row<Mandate>) => {
          const dateA = new Date(rowA.original.created);
          const dateB = new Date(rowB.original.created);
          return dateA.getTime() - dateB.getTime();
        },
        cell: ({ row }) => {
          return <Table.Cell value={formatDateDDmmYYYY(row.original.created)} />;
        },
      }),
      columnHelper.display({
        header: "PRIX N.V.",
        cell: ({ row }) => {
          return <Table.Cell value={formatPrice(row.original.price)} />;
        },
      }),
      columnHelper.display({
        header: "ETAT",
        cell: ({ row }) => {
          const mandateState = getMandateState(row.original.expand?.companyMandates_via_mandate || []);
          return <Table.Cell value={mandateState} bold />;
        },
      }),
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <Button.Small
            name={row.getIsExpanded() ? "Fermer" : "Détails"}
            disabled={!row.original.expand.companyMandates_via_mandate}
            onClick={() => row.toggleExpanded()}
          />
        ),
      }),
    ],
    []
  );

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: mandates,
    columns,
    enableMultiSort: true,
    manualPagination: true,
    pageCount: totalPages,
    state: {
      pagination,
      sorting,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <Table table={table} isFetching={isFetching}>
      {({ row }) => <SubTable companyMandates={row.original.expand?.companyMandates_via_mandate || []} mandates={mandates} />}
    </Table>
  );
};

interface SubTableProps {
  companyMandates: CompanyMandate[];
  mandates: Mandate[];
}

const SubTable = ({ companyMandates, mandates }: SubTableProps) => {
  const mutation = usePbDownload();

  const subColumns = React.useMemo<ColumnDef<CompanyMandate>[]>(
    () => [
      subColumnHelper.display({
        id: "name",
        cell: ({ row }) => {
          if (row.original) {
            return (
              <Table.Cell
                value={row.original?.expand?.company?.name ? row.original.expand.company.name : '-'}
                bold
                uppercase
              />
            );
          }
        },
      }),
      subColumnHelper.display({
        id: "phone",
        cell: ({ row }) => {
          return <Table.Cell value={row.original?.expand?.company?.phone ? row.original.expand.company.phone : '-'} />;
        },
      }),
      subColumnHelper.display({
        id: "email",
        cell: ({ row }) => {
          return <Table.Cell value={row.original?.expand?.company?.email ? row.original.expand.company.email : '-'} />;
        },
      }),
      subColumnHelper.display({
        id: "date",
        cell: ({ row }) => {
          return <Table.Cell value={formatDateDDmmYYYY(row.original.updated)} />;
        },
      }),
      subColumnHelper.display({
        id: "status",
        cell: ({ row }) => {
          return <Table.Cell value={getCompanyMandateState(row.original)} />;
        },
      }),
      subColumnHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => {
          const companyMandate = companyMandates.find(
            (companyMandate) => companyMandate.company === row.original.company
          );

          if (companyMandate) {
            return (
              <Button.Small
                name="Télécharger"
                disabled={!companyMandate.mandateFile}
                onClick={() =>
                  mutation.mutate({
                    resource: companyMandate,
                    filename: companyMandate["mandateFile"],
                  })
                }
              />
            );
          }
        },
      }),
    ],
    [companyMandates, mandates]
  );

  const table = useReactTable({
    data: companyMandates,
    autoResetPageIndex: false,
    autoResetExpanded: false,
    columns: subColumns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
  });

  return <Table.Subtable table={table} />;
};