import { z } from "zod";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Form } from "@components/form/Form";
import { Button } from "@components/buttons/Buttons";
import { formatDate } from "@utils/utils";
import { CreateEstimateWithYouSchema } from "@schemas/estimate.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import Input from "@components/inputs/Input";
import type { Address } from "@interfaces/address.interface";
import { getSlotsGroupedBySlug, type Slot } from "@interfaces/slot.interface";
import { ExternalNotificationCard } from "@components/ExternalNotificationCard";
import { trpc } from "@client/index";
import { getQueryKey } from "@trpc/react-query";

function formatSlotTime(startTime: string, durationInSeconds: number) {
  const startDate = new Date(startTime);

  const endDate = new Date(startDate.getTime() + durationInSeconds * 1000);

  const format = (date: Date) => {
    return new Date(date).toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Europe/Paris"
    }).replace(":", "h")
  };

  return `${format(startDate)} - ${format(endDate)}`;
}

const EstimateDescription = ({ address }: { address: Address }) => {
  return (
    <div className="estimate-without--container">
      <h3>Nouvelle demande d'estimation dans le cadre d'un projet de vente</h3>
      <small>
        <b>Ville:</b> {address.city}
      </small>

      <span>
        Merci de sélectionner un ou plusieurs créneaux sur lesquels vous êtes disponible pour une visite d’estimation dans le cadre d’un projet de vente.</span>


      <p><b>Important: </b>Si vous êtes disponible à plusieurs créneaux le même jour, vous pouvez sélectionner l'ensemble des créneaux, cela maximisera vos chances d'obtenir le dossier</p>
      {/* <p>
        Une fois votre choix confirmé, vous recevrez les coordonnées de la
        personne avec qui prendre rendez-vous pour organiser la visite
        d'estimation.{" "}
      </p> */}
      {/* <p>Attention, ce choix bloque les autres agences du département</p> */}
    </div>
  )
}

const groupSlotsByDate = (slots: Slot[]) => {
  return slots.reduce((acc, slot) => {
    const date = slot.startTime.split(" ")[0];

    if (!acc[date]) {
      acc[date] = [];
    }

    acc[date].push(slot);

    return acc;
  }, {} as Record<string, Slot[]>);
}

export const EstimateWithYouSlotConfirm = () => {
  const queryClient = useQueryClient();

  const { estimateId } = useParams<{ agencyId: string; estimateId: string; }>();

  const formMethods = useForm<z.infer<typeof CreateEstimateWithYouSchema>>({
    resolver: zodResolver(CreateEstimateWithYouSchema),
    defaultValues: {
      slots: [],
      phone: "",
    },
  });

  const selectedSlotsIds = formMethods.watch("slots");

  const guardQueryResult = trpc.external.estimate.withYou.slotConfirmGetResources.useQuery({ estimateId: estimateId! }, {
    retry: false
  });

  const errorStatus = guardQueryResult.error?.data?.httpStatus;

  const mutation = trpc.external.estimate.withYou.slotConfirm.useMutation({
    onError() {
      toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
    },
    onSettled() {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.external.estimate.withYou.slotConfirmGetResources) });
    },
  })

  const onChange = (slot: Slot) => {
    const slots = formMethods.getValues("slots");

    const index = slots.findIndex(slotId => slotId === slot.id);

    if (index === -1) {
      formMethods.setValue("slots", [...slots, slot.id]);
    } else {
      const filteredSlots = slots.filter(slotId => slotId !== slot.id);

      formMethods.setValue("slots", filteredSlots);
    }
  }

  if (mutation.isSuccess) {
    return <ExternalNotificationCard title="Merci d'avoir répondu à cette demande d'estimation!" />
  }

  if (errorStatus === 403 || errorStatus === 404) {
    return <ExternalNotificationCard title="Cette page n'est plus disponible" />
  }

  if (typeof errorStatus === "number") {
    return <ExternalNotificationCard title="Une erreur est survenue. Merci de réessayer plus tard." />
  }

  if (!guardQueryResult.data) {
    return null;
  }

  const { sale, estimate } = guardQueryResult.data;

  return (
    <Form.DefaultLayout formMethods={formMethods}>
      <EstimateDescription address={sale.expand.address} />

      {Object.entries(groupSlotsByDate(estimate.expand.slots)).map(([date, slotsGroupedByDate]) => {
        const dateFormated = formatDate(date);

        return (
          <div key={date} className="horaires--estim">
            <h3>{dateFormated}</h3>

            {Object.entries(getSlotsGroupedBySlug(slotsGroupedByDate)).map(([slug, slotsGroupedBySlug]) => {
              return (
                <ul key={slug} className={`input-toggle-form dark-toggle`} >
                  {slotsGroupedBySlug.map(slot => {
                    const isSlotSelected = !!selectedSlotsIds.find(slotId => slotId === slot.id);

                    return (
                      <li key={slot.id}>
                        <input
                          id={slot.id}
                          type="checkbox"
                          checked={isSlotSelected}
                          onChange={() => onChange(slot)} />

                        <label className="dark" htmlFor={slot.id}>
                          {formatSlotTime(slot.startTime, slot.duration * 60)}
                        </label>
                      </li>
                    )
                  })}
                </ul>
              )
            })}
          </div>
        )
      })}

      <Input
        name="phone"
        border
        placeholder="Numéro de portable sur lequel vous êtes joignable"
        type="tel" />
      <div className="actions--mail">
        <Form.Actions centered>
          <Button
            name="Valider"
            onClick={formMethods.handleSubmit((values) => mutation.mutate({ estimateId: estimateId!, ...values }))}
            loading={mutation.isPending} />
        </Form.Actions>
      </div>
    </Form.DefaultLayout>
  )
}