import { Fragment, useState } from "react";
import "./PropertyDocsList.scss";
import { useModal } from "@hooks/useModal";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { trpc } from "@client/index";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { getQueryKey } from "@trpc/react-query";
import Modal from "@components/modal/Modal";
import { Form } from "@components/form/Form";
import Input from "@components/inputs/Input";
import { Button } from "@components/buttons/Buttons";
import { CreateCopropertyTitleRequestSchema, UploadSalePieceMultipleDocumentSchema } from "@schemas/salePiece.schema";
import { UploadModal } from "@components/modal/UploadModal";
import { usePbDownload } from "@hooks/usePbDownload";
import { downloadPublicFile } from "@utils/utils";
import { CopropertyTitleRequestState } from "@interfaces/salePieces.interface";

interface Props {
  saleId: string;
  readOnly: boolean;
}

const Requests = ({ saleId }: Props) => {
  const modal = useModal();
  const queryClient = useQueryClient();

  const [isCertificateDownloaded, setIsCertificateDownloaded] = useState(false);

  const { data: salePieceData } = trpc.sale.salePiece.getBySaleId.useQuery(saleId);

  const formMethods = useForm<z.infer<typeof CreateCopropertyTitleRequestSchema>>({
    resolver: zodResolver(CreateCopropertyTitleRequestSchema),
    defaultValues: {
      syndicName: "",
      phone: "",
      email: "",
      certificate: undefined,
    },
  });

  const certificate = formMethods.watch("certificate");

  const mutation = trpc.sale.salePiece.copropertyTitle.updateFields.useMutation({
    onSuccess: () => {
      formMethods.reset();
      modal.close("payment-coproperty-title");
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.salePiece.getBySaleId) });
      toast.success("Votre demande a bien été prise en compte");
    },
    onError: () => {
      toast.error("Une erreur est survenue.");
    },
  });

  const downloadCertificate = () => {
    downloadPublicFile("Autorisation_co-propriete.pdf", "Athena - Autorisation co-propriete.pdf");
    setIsCertificateDownloaded(true);
  };

  const handleSubmit = (body: z.infer<typeof CreateCopropertyTitleRequestSchema>) => {
    const formData = new FormData();
    formData.append("salePieceId", salePieceData?.id!);
    for (const name in body) {
      formData.append(name, (body as any)[name]);
    }
    mutation.mutate(formData);
  };

  const onConfirm = async () => {
    const isValid = await formMethods.trigger(["syndicName", "email", "phone"]);
    if (isValid) {
      modal.close("request-coproperty-title");
      modal.open("payment-coproperty-title");
    }
  };

  return (
    <Fragment>
      <Modal id="request-coproperty-title" title="Demande de pièces de co-propriété - 1/2">
        <Form formMethods={formMethods}>
          <Input name="syndicName" placeholder="Nom du syndic de copropriété" />
          <Input name="phone" placeholder="Téléphone" />
          <Input name="email" placeholder="Email" />
        </Form>
        <Button name="Confirmer la demande" onClick={onConfirm} />
        <p className="cursor-pointer" onClick={modal.closeAll}>
          Je n'ai pas les informations concernant le syndic
        </p>
      </Modal>

      <Modal id="payment-coproperty-title" title="Autorisation de demande de pièces de co-propriété - 2/2">
        <p>Télécharger, signer et envoyer le document</p>
        <Form formMethods={formMethods}>
          <div className="modal--actions">
            <Form.Actions>
              <Button name="Télécharger" onClick={downloadCertificate} />
              <Input.File
                name="certificate"
                label="Insérer"
                disabled={!isCertificateDownloaded}
                accept="application/pdf"
                asButton
              />
              <Button
                name="Valider la demande"
                disabled={!certificate}
                loading={mutation.isPending}
                onClick={formMethods.handleSubmit(handleSubmit)}
              />
            </Form.Actions>
          </div>
        </Form>
      </Modal>
    </Fragment>
  );
};

const UPLOAD_MODAL_ID = `CoproperyTitle::UPLOAD_MODAL_ID`;

export const CoproperyTitle = ({ saleId, readOnly = false }: Props) => {
  const modal = useModal();
  const queryClient = useQueryClient();
  const downloadMutation = usePbDownload();

  const { data: salePieceData } = trpc.sale.salePiece.getBySaleId.useQuery(saleId);
  const { data: isAddressCompleted } = trpc.sale.estimate.isAddressCompleted.useQuery({ saleId: saleId! });

  const formMethods = useForm<z.infer<typeof UploadSalePieceMultipleDocumentSchema>>({
    resolver: zodResolver(UploadSalePieceMultipleDocumentSchema),
    defaultValues: {
      documents: [],
    },
  });

  const mutation = trpc.sale.salePiece.copropertyTitle.upload.useMutation({
    onSuccess: () => {
      formMethods.reset();
      modal.close(UPLOAD_MODAL_ID);
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.salePiece.getBySaleId) });
      toast.success("Les pièces de co-propriété ont bien été envoyées.");
    },
    onError() {
      toast.error("Une erreur est survenue.");
    },
  });

  const handleDelete = trpc.sale.salePiece.copropertyTitle.remove.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(trpc.sale.salePiece.getBySaleId) });
      toast.success("Le document a bien été supprimé.");
    },
    onError() {
      toast.error("Une erreur est survenue lors de la suppression.");
    },
  });

  const handleDeleteConfirmation = (document: string) => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer ce document ?");
    if (confirmDelete) {
      
      console.log(document);
      handleDelete.mutate({ saleId, document });
    }
  };

  const isUploaded = Boolean(salePieceData?.copropertyTitles.length);
  const isWaiting = !isUploaded && salePieceData?.expand?.copropertyTitleRequests_via_salePiece?.state === CopropertyTitleRequestState.waiting;
  const status = isUploaded ? "valid" : isWaiting ? "asked" : "non-valid";

  const handleSubmit = (body: z.infer<typeof UploadSalePieceMultipleDocumentSchema>) => {
    const formData = new FormData();
    formData.append("saleId", saleId);
    for (const document of body.documents || []) {
      formData.append("documents[]", document);
    }
    mutation.mutate(formData);
  };

  const download = (document: string) => {
    downloadMutation.mutate({ resource: salePieceData, filename: document });
  };

  return (
    <Fragment>
      <li className={status}>
        <p>Pièces de co-propriété</p>
        <div>
          {salePieceData?.copropertyTitles?.length ? (
            <ul>
            {salePieceData.copropertyTitles.map((document, index) => {
              // Tronquer le nom du document s'il dépasse 50 caractères
              const truncatedDocumentName = document.length > 20 ? `${document.slice(0, 20)}...pdf` : document;
              console.log(truncatedDocumentName);
              return (
                <li key={index}>
                  <span>{truncatedDocumentName}</span>
                  <Button.Small xs valid name="Télécharger" onClick={() => download(document)} style={{height: '30px', marginBottom: '8px'}}/>
                  <Button.Small xs valid name="Supprimer" onClick={() => handleDeleteConfirmation(document)} style={{height: '30px'}}/>
                </li>
              );
            })}
            </ul>
          ) : (
            <></>
          )}
        </div>
        <br/>
        <div>
          {/* Bouton pour ajouter un nouveau document */}
          {!readOnly && (
            <Button.Small
              valid
              name="Ajouter une pièce"
              onClick={() => modal.open(UPLOAD_MODAL_ID)}
            />
          )}
          
          {isWaiting && <Button.Small xs valid name="Demande en cours" disabled />}
          {!isUploaded && !readOnly && (
            <Button.Small
              xs
              valid
              name="Demander"
              disabled={!isAddressCompleted}
              onClick={() => modal.open("request-coproperty-title")}
            />
          )}

        </div>

        {!isAddressCompleted && (
          <small style={{ marginTop: '10px', display: 'block' }}>
            Les demandes de titre de propriété et pièce de copropriété seront accessibles après avoir reçu au moins deux estimations.
          </small>
        )}
      </li>

      <Form formMethods={formMethods}>
        <UploadModal
          id={UPLOAD_MODAL_ID}
          multiple
          name="documents[]"
          title="Pièces de co-propriété"
          isLoading={mutation.isPending}
          onConfirm={formMethods.handleSubmit(handleSubmit)}
        />
      </Form>

      <Requests saleId={saleId} />
    </Fragment>
  );
};