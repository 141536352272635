export type ID = string;

export interface Base {
  id: string;
  collectionId: string;
  collectionName: string;
  updated: string;
  created: string;
}

export interface DownloadDocument extends Base {
  token: string;
  records: {
    recordId: string;
    collectionName: string;
    collectionId: string;
    property: string;
  }[];
}

// Même nom de cookie pour le moment
export const PLATFORM_COOKIE_NAME = "pb_auth_platform";
export const ADMIN_COOKIE_NAME = PLATFORM_COOKIE_NAME; // "pb_auth_admin";

export const MEGABYTES_50_IN_BYTES = 52428800;
export const MEGABYTES_100_IN_BYTES = 104857600;