import { Sidebar } from "@components/Sidebar";
import { SaleGoodAbroadAside } from "@components/features/dashboard/sales/SaleGoodAbroadAside";
import { Fragment } from "react";
import { Outlet } from "react-router-dom";

export const LayoutGoodAbroad = () => {
  return (
    <Fragment>
      <section className="fx" style={{ width: "98%" }}>
        <Sidebar />

        <SaleGoodAbroadAside />

        <Outlet />
      </section>
    </Fragment>
  );
};
