import React, { Fragment } from 'react';
import './Modal.scss';
import { useModalContext } from '@contexts/modal.context';

export interface ModalProps {
  id: string;
  title: string;
  description?: string;
  children: React.ReactNode;
}

const Modal = ({ id, title, description, children }: ModalProps) => {
  const context = useModalContext();

  React.useEffect(() => {
    context.registerModal(id);

    return () => {
      context.unregisterModal(id);
    }
  }, []);

  if (!context.isOpen(id)) {
    return null;
  }

  return (
    <Fragment>
      <div className="c-modal open">
        <div className="c-modal--header">
          <div className="c-modal--title">
            <p>
              {title}
            </p>
          </div>
          <button onClick={() => context.close(id)} className="c-modal--close" aria-label="fermer la modal"></button>
        </div>

        <div className="c-modal--content">
          {description && (
            <p>
              {description}
            </p>
          )}

          {children}
        </div>
      </div>

      <div onClick={() => context.close(id)} className="c-modal-shadow"></div>
    </Fragment>
  )
};

export default Modal;
